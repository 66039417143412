/* eslint no-param-reassign: 0 */
import * as Sentry from '@sentry/browser'
import events from './events'
import requestFormatter from '../../utils/requestFormatter'
import { apiCRUD } from '../../utils/apiHttp'

export default {
  namespaced: true,

  modules: {
    events
  },

  state: {
    hauler: {
      locations: [],
      active: false
    }
  },

  mutations: {
    /**
     * Set Hauler to state from api call
     */
    // because the api does not return consistent PUT/POST payloads
    // we have to object.assign or else things start to disa
    setHauler (state, data) {
      state.hauler = { ...state.hauler, ...data }
    },

    clearHauler (state) {
      state.hauler = {}
    },

    /**
     * Update single property of the hauler object
     *
     * FIXME this seems to be the same exact thing as "setHauler", not sure why this is a different function.
     *
     * @param {object} state - the state object
     * @param {object} object - the key val pair to update on the hauler object
     */
    updateHaulerProperty (state, object) {
      state.hauler = { ...state.hauler, ...object }
    }
  },
  actions: {
    /**
     * Call the api to get a hauler
     * @param {object} context - exposes all store functionality
     * @param {object} context.commit - access to mutations
     * @param {string} id - the id of the hauler
     */
    setHauler ({ commit }, id) {
      commit('events/setLoading', true)
      return apiCRUD.record('hauler', id)
        .then(response => commit('setHauler', response.data))
        .catch(e => Sentry.captureException(e))
        .finally(() => commit('events/setLoading', false))
    },

    /**
     * Update a hauler
     * @param {object} context
     * @param {function} context.commit - access to mutations
     * @param {object} payload - the payload for the request
     * @param {object} payload.data - the updated data
     * @param {string} payload.user - the id of the user requesting update
     */
    updateHauler ({ commit }, { data, user }) {
      const payload = requestFormatter.hauler(data, user)
      commit('events/setLoading', true)
      return apiCRUD.update('hauler', data.id, payload)
        .then(response => {
          commit('events/runFlash', {
            message: 'Hauler successfully updated',
            severity: 'success',
            timeout: 2500
          })
          commit('setHauler', response.data)
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: `Could not update hauler: ${e.message}`,
            severity: 'error',
            timeout: -1
          })
        })
        .finally(() => commit('events/setLoading', false))
    },

    /**
     * Delete a hauler
     * @param {object} context
     * @param {function} context.commit - access to mutations
     * @param {object} payload - the payload for the request
     * @param {string} payload.id - the id of the record to delete
     */
    deleteHauler ({ commit }, id) {
      commit('events/setLoading', true)
      return apiCRUD.delete('hauler', id)
        .then(() => {
          commit('events/runFlash', {
            message: 'Hauler removed successfully',
            severity: 'success',
            timeout: 2000
          })
          commit('clearHauler')
        }).catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: `Hauler was not removed: ${e.messsage}`,
            severity: 'error',
            timeout: 2000
          })
        })
        .finally(() => commit('events/setLoading', false))
    },

    /**
     * Remove the current state info for hauler
     * @param {object} context
     * @param {function} context.commit - access to mutations
     */
    clearHauler ({ commit }) { commit('clearHauler') }
  }
}
