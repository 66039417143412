import axios from 'axios'
import { addMinutes, format } from 'date-fns'

/**
 * @name fetchTimezoneOffset
 * @author Justin Voelkel
 * @description Return promise from timezone api with given lat/lng
 * @param {float} latitude
 * @param {float} longitude
 * @return {promise} a promise that resolves to the offset info of the given location
 */
const fetchTimezoneOffset = (latitude = null, longitude = null) => {
  // google wants this in minutes not milliseconds
  const currentTimestamp = Math.floor(Date.now() / 1000)
  const location = `${latitude},${longitude}`
  // TODO - this is due to a current bug in axios
  // make this a normal request when the beta version has been
  // released as stable
  const req = `https://maps.googleapis.com/maps/api/timezone/json?location=${location}&timestamp=${currentTimestamp}&key=${process.env.VUE_APP_GOOGLE_TIMEZONE_API_KEY}`
  return axios.get(req, {
    transformRequest: [(data, headers) => {
      delete headers.common.Authorization
      return data
    }]
  })
}

/**
 * @name formatRange
 * @description Format each date and return as a range. Return only one date if both are the same.
 * @param {Date|Number} start
 * @param {Date|Number} end
 * @param {string} formatString
 */
const formatRange = (start, end, formatString = 'M/DD/YYYY') => {
  start = format(start, formatString)
  end = format(end, formatString)

  return (start === end)
    ? start
    : `${start} - ${end}`
}

/**
 * @name currentLocalTime
 * @author Justin Voelkel
 * @description Return the current local time given a particular offset
 * @param {Object} offset contains timezone information from googles timezone api
 * @return {integer} Unix timestamp in mins
 */
const currentLocalTime = (offset, date = new Date()) => {
  // given the passed offset (in minutes) derive the true offset based on system time
  const diff = (offset.rawOffset / 60) + (offset.dstOffset / 60) + date.getTimezoneOffset()
  // get the local time by adding (a negative number) the diff and convert to mins
  const localTime = Math.floor(addMinutes(date, diff) / 1000)

  return localTime
}

/**
 * @name daysOfWeek
 * @description Get a list of full names of the days of the week (0-6) with Sunday last
 * @param {array} filterDays List of days to include
 * @return {array}
 */
const daysOfWeek = (filterDays) => {
  const days = [
    { day: 1, name: 'Monday', namePlural: 'Mondays' },
    { day: 2, name: 'Tuesday', namePlural: 'Tuesdays' },
    { day: 3, name: 'Wednesday', namePlural: 'Wednesdays' },
    { day: 4, name: 'Thursday', namePlural: 'Thursdays' },
    { day: 5, name: 'Friday', namePlural: 'Fridays' },
    { day: 6, name: 'Saturday', namePlural: 'Saturdays' },
    { day: 0, name: 'Sunday', namePlural: 'Sundays' }
  ]

  return (typeof filterDays === 'undefined')
    ? days
    : days.filter((day) => { return filterDays.includes(day.day) })
}

/**
 * @name daysDiff
 * @description Get the number of full days different between two dates
 * @param Date dateA
 * @param Date dateB
 * @return int
 */
const daysDiff = (dateA, dateB) => {
  const diff = Math.abs(dateA.getTime() - dateB.getTime())

  return Math.floor(diff / 86400000)
}

export {
  addMinutes, format, formatRange, fetchTimezoneOffset, currentLocalTime, daysOfWeek, daysDiff
}
