import * as Sentry from '@sentry/browser'
import { find, eq, compose, prop, update, remove, omit, findIndex } from 'kyanite'
import events from './events'
import { apiHttp } from '../../utils/apiHttp'

const store = {
  namespaced: true,

  state: {
    alerts: [],
    selectedAlert: {}
  },

  modules: {
    events
  },

  mutations: {
    setAlerts (state, alerts) {
      state.alerts = alerts
    },

    selectAlert (state, id) {
      state.selectedAlert = find(compose(eq(id), prop('id')), state.alerts) || {}
    },

    replaceAlert (state, alert) {
      const index = findIndex(compose(eq(alert.id), prop('id')), state.alerts)

      state.alerts = update(index, alert, state.alerts)
    },

    addAlert (state, alert) {
      state.alerts.push(alert)
    },

    removeAlert (state, id) {
      const index = findIndex(compose(eq(id), prop('id')), state.alerts)

      state.alerts = remove(index, state.alerts)
    }
  },

  actions: {
    fetchAlerts ({ commit }, { level, id }) {
      return apiHttp.get(`/${level}/${id}/alerts`)
        .then(({ data }) => commit('setAlerts', data.collection))
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error retrieving alerts',
            severity: 'error',
            timeout: -1
          })
        })
    },

    saveAlert ({ commit }, alert) {
      const payload = omit([
        '_links',
        'alert',
        'pivot',
        'service_area_id',
        'service_area_name',
        'region_id',
        'region_name',
        'hauler_id',
        'hauler_name'
      ], alert)

      return apiHttp.put(`/alert/${alert.id}`, payload)
        .then(({ data }) => {
          commit('replaceAlert', data)
          commit('events/runFlash', {
            message: 'Saved Alert Successfully',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error saving the alert',
            severity: 'error',
            timeout: -1
          })
        })
    },

    createAlert ({ commit }, alert) {
      return apiHttp.post('/alerts', alert)
        .then(({ data }) => commit('addAlert', data))
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error creating the alert',
            severity: 'error',
            timeout: -1
          })
        })
    },

    deleteAlert ({ commit }, id) {
      return apiHttp.delete(`/alert/${id}`)
        .then(() => commit('removeAlert', id))
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error deleting the alert',
            severity: 'error',
            timeout: -1
          })
        })
    }
  }
}

export default store
