<template>
    <master></master>
</template>

<script>
import master from './layouts/Master.vue'

export default {
  name: 'app',
  components: {
    master
  }
}
</script>

<style lang="scss">
    @import 'styles/main.scss';
</style>
