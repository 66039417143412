import { format, fetchTimezoneOffset, currentLocalTime } from '../../utils/time'

export default {
  namespaced: true,
  state: {
    // current time in unix timestamp (mins)
    currentTime: Date.now(),
    timezoneOffset: {},
    // set interval identifier
    clock: 0
  },
  mutations: {
    // associated setter
    setCurrentTime (state, time) {
      state.currentTime = time
    },
    // associated setter
    setTimezoneOffset (state, offset) {
      state.timezoneOffset = offset
    },
    // assign the setInterval in state
    runClock (state) {
      state.clock = setInterval(() => {
        // utils -> time
        state.currentTime = currentLocalTime(state.timezoneOffset)
      }, 1000)
    },
    // remove the interval
    killClock (state) {
      clearInterval(state.clock)
    }
  },
  getters: {
    /**
     * @name currentDate
     * @description The current date as a Date object
     * @param {object} state
     * @return Date
     */
    currentDate: state => new Date(state.currentTime * 1000),

    /**
     * @name formattedTime
     * @author Justin Voelkel
     * @description Unix timestamp formatted in HH:MM
     * @param {object} state
     * @return {string} the formatted date string
     */
    formattedTime: state =>
    // convert current time from mins to milli
      format(new Date(state.currentTime * 1000), 'h:mm A')
  },
  actions: {
    /**
     * @name initializeTimezone
     * @author Justin Voelkel
     * @description Initialize what timezone a location is in by it's lat/lng combo
     * @param {function} context.commit
     * @param {object} data lat and lng properties expected
     */
    runLocalTimeClock: ({ commit }, { lat, lng }) => {
      // utils -> time
      fetchTimezoneOffset(lat, lng)
        .then(({ data }) => commit('setTimezoneOffset', data))
        .then(() => commit('runClock'))
    },
    /**
     * @name killClock
     * @author Justin Voelkel
     * @description Kill the current state clock
     * @param {object} context.state
     */
    killClock: ({ state }) => {
      clearInterval(state.clock)
    }
  }
}
