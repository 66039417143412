/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import * as Sentry from '@sentry/browser'
import { apiCRUD } from '../../utils/apiHttp'

export default {
  namespaced: true,
  state: {
    sizes: []
  },
  mutations: {
    /**
     * @name setSizes
     * @description set the state sizes array
     * @param {object} state current state object
     * @param {array} data array of size objects
     */
    setSizes (state, data) {
      state.sizes = data
    },
    /**
     * @name resetSize
     * @description clear current state by replacing with initial state
     * @param {object} state
     */
    resetSize (state) {
      state.sizes = []
    }
  },
  actions: {
    /**
     * @name fetchSizes
     * @description fetch the full collection of sizes
     * @param {object} context.commit
     */
    fetchSizes ({ commit }) {
      return apiCRUD.collection('sizes', { per_page: -1 })
        .then(({ data }) => commit('setSizes', data.data))
        .catch(e => {
          console.error(e)
          Sentry.captureException(e)
          throw new Error(`Cannot complete fetch: ${e}`)
        })
    }
  }
}
