<template v-if="query">
<span>
  <base-dropdown
  v-bind="$attrs"
  v-validate="req"
  v-if="meta"
  v-model="selection"
  :items="metaItems"
  :options="{value:'id', display:'name', selected: selected}"
  :callback="emitType"
  name="smart-dropdown"
  />
  <form-error :activeErrors="errors" field="smart-dropdown"/>
</span>

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import BaseDropdown from '../ui/BaseDropdown.vue'
import FormError from '../ui/FormError.vue'
import { convertToLowerCamelCase } from '../utils/stringManipulation'

export default {
  inject: ['$validator'],
  inheritAttrs: false,
  name: 'SmartDropdown',
  components: {
    'base-dropdown': BaseDropdown,
    'form-error': FormError
  },
  props: {
    /**
     * The model to query from the api
     * @type {string}
     */
    query: {
      required: true,
      type: String
    },
    /**
     * Should the dropdown be validated as required
     * @type {boolean}
     */
    required: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
     * An optional value to display as selected from the dropdown
     * @type {string}
     */
    selected: {
      required: false,
      type: String
    },
    /**
     * An optional sort function to be applied to the query results
     * @type {function} - the function to be applied to the sort method
     */
    sort: {
      required: false,
      type: Function
    }
  },
  data () {
    return {
      req: { required: this.required },
      selection: ''
    }
  },
  methods: {
    ...mapActions([
      'clearMeta',
      'setMeta'
    ]),
    /**
     * When a value is selected emit it to the parent
     * and set the model value to satisfy validation
     * @public
     * @param {string} val - the value of the dropdown selection
     * @event selection
     * @type {string}
     */
    emitType (val) {
      this.selection = val
      this.$emit('selection', val)
    }
  },
  computed: {
    ...mapGetters([
      'meta',
      'sortedMeta'
    ]),
    formattedStateName () {
      return convertToLowerCamelCase(this.query)
    },
    sortedMetaItems () {
      return this.sortedMeta(this.formattedStateName, this.sort)
    },
    metaItems () {
      return this.sort ? this.sortedMetaItems : this.meta(this.formattedStateName)
    }
  },
  created () {
    this.setMeta(this.query)
  }
}
</script>
