import { omit } from 'kyanite'

const store = {
  namespaced: true,
  state: {
    yard_waste: {
      note: '',
      special: true
    },
    stumps: {
      note: '',
      special: true
    },
    appliances: {
      note: '',
      special: false
    },
    electronics: {
      note: '',
      special: false
    },
    mattress: {
      note: '',
      special: false
    },
    concrete: {
      note: '',
      special: true
    },
    dirt: {
      note: '',
      special: true
    },
    brick: {
      note: '',
      special: true
    },
    block: {
      note: '',
      special: true
    },
    gravel: {
      note: '',
      special: true
    },
    asphalt: {
      note: '',
      special: true
    },
    leed: {
      note: '',
      special: true
    },
    other: {
      note: '',
      special: false,
      force: true
    },
    selectedDebrisTypes: []
  },
  getters: {
    getDebrisTypeKeys (state) {
      return Object.keys(omit(['selectedDebrisTypes'], state))
    },
    getDebris (state) {
      return omit(['selectedDebrisTypes'], state)
    },
    getSelectedDebrisTypes (state) { return state.selectedDebrisTypes }
  },

  mutations: {
    setDebrisNote (state, { type, note }) {
      Object.assign(state[type], { note })
    },

    updateSelectedTypes (state, data) {
      state.selectedDebrisTypes = data
    },

    resetDebris (state) {
      Object.assign(state, {
        yard_waste: {
          note: '',
          special: true
        },
        stumps: {
          note: '',
          special: true
        },
        appliances: {
          note: '',
          special: false
        },
        electronics: {
          note: '',
          special: false
        },
        mattress: {
          note: '',
          special: false
        },
        concrete: {
          note: '',
          special: true
        },
        dirt: {
          note: '',
          special: true
        },
        brick: {
          note: '',
          special: true
        },
        block: {
          note: '',
          special: true
        },
        gravel: {
          note: '',
          special: true
        },
        asphalt: {
          note: '',
          special: true
        },
        leed: {
          note: '',
          special: true
        },
        other: {
          note: '',
          special: false,
          force: true
        },
        selectedDebrisTypes: []
      })
    }
  }
}

export default store
