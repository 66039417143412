<template>
    <section>
      <select class="browser-default" :disabled="disabled" @change="selectionHandler">
        <option disabled selected>pick one</option>
        <option :value="null">none</option>
        <option
          v-for="(item, key) of items"
          :value="item[options.value]"
          :key='key'
          :selected="options.selected === item[options.value]">{{item[options.display]}}</option>
      </select>
    </section>
</template>
<script>
export default {
  name: 'BaseDropdown',
  props: {
    /**
     * An array of items to list as options inside of the select
     * @type {Array}
     */
    items: {
      required: true,
      type: Array
    },
    /**
     * The configuration of how the data should be displayed inside of the dropdown
     * Required properties are 'value' and 'display' (value of an option and the value to display)
     * Optional properties are 'selected' (the option that should be selected by default)
     * EX: {value:'id', display:'name', selected:'foo'}
     * @type {Object}
     */
    options: {
      required: true,
      type: Object,
      default: () => ({
        value: 'id', display: 'name', selected: null
      }),
      validate: val => Object.keys(val).every(key => ['value', 'display'].includes(key))
    },
    /**
     * Optional state to add to disable selection of new values
     * @type {Boolean}
     */
    disabled: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
     * Optional callback to perform when a value is changed
     * @type {Function}
     */
    callback: {
      required: false,
      type: Function,
      default (val) { this.$emit('selected', val) }
    }
  },
  data () {
    return {
      selectedOption: this.options.selected
    }
  },
  methods: {
    selectionHandler (e) {
      this.callback(e.target.value)
    }
  },
  created () {
    this.options.selected = Object.prototype.hasOwnProperty.call('selected', this.options)
      ? this.options.selected : ''
  }
}
</script>
