<template>
  <div :class="filterClass">
    <base-checkboxes
      :key="title"
      :legend="title"
      :options="options"
      @checked="forwardCheck"
     />
    <div class="show_more"
      v-on:click="showMore"
    >
      + Show More
    </div>
    <div class="show_fewer"
      v-on:click="showFewer"
      >
      - Show Fewer
    </div>
  </div>
</template>

<script>
import BaseCheckboxes from '../ui/BaseCheckboxes.vue'
export default {
  name: 'FilterOptions',
  components: {
    'base-checkboxes': BaseCheckboxes
  },
  props: {
    title: {
      required: true,
      type: String
    },
    options: {
      required: true
    },
    collapsible: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      collapsibleState: 'closed'
    }
  },
  methods: {
    showMore () {
      this.collapsibleState = 'open'
    },
    showFewer () {
      this.collapsibleState = 'closed'
    },
    forwardCheck ({ legend, checked }) {
      this.$emit('checked', { legend, checked })
    }
  },
  computed: {
    filterClass () {
      if (this.collapsible) {
        return `c-filter ${this.collapsibleState}`
      }
      return 'filter'
    }
  }
}
</script>
<style lang="scss">
@import 'src/styles/base/_variables.scss';
.filter .show_fewer, .filter .show_more {
  display: none;
}
.c-filter.closed fieldset {
  div.check-container:nth-child(n+5) {
    display: none;
  }
}
.c-filter.closed .show_more, .c-filter.open .show_fewer {
  color: $primary;
  display: block;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.c-filter.open .show_more, .c-filter.closed .show_fewer {
  display: none;
}
</style>
