/* eslint no-param-reassign: 0 */

const formatter = (() => {
  /**
   * Check updated by and created by for vals and apply the current user
   * id if required
   * @param {object} data - the data to be sent to the api
   * @param {string} id - the current user id
   */
  const attachUser = (data, id) => {
    if (!Object.hasOwnProperty.call(data, 'created_by') || data.created_by === null || data.created_by === '1') {
      data.created_by = id
    }
    data.updated_by = id
    return data
  }
  /**
     * Return a read only enumerable object with the given
     * required and optional properties set to their values
     * @param {object} data - the data object to be formatted
     * @param {array} required - an array of required properties
     * @param {array} optional - an array for optional properties
     * @return {object}
     */
  const format = (data, required, optional) => Object.entries(data).reduce((obj, p) => {
    if (((required.includes(p[0]) || optional.includes(p[0])) && (p[1] !== null))) {
      Object.defineProperty(obj, p[0], {
        value: p[1],
        writable: false,
        enumerable: true
      })
    }
    return obj
  }, {})

  return {
    /**
     * Accepts proposed data to create or modify an alert and
     * formulates a valid request object based on the api validators.
     * Return object is read only to prevent further mutation.
     * @param {object} data - the proposed data to send to the api
     * @param {string} userId - the user id from session
     * @return {object} the formatted read only request object
     */
    alert (data, userId) {
      const required = ['text']
      const optional = [
        'id',
        'expires',
        'check_back',
        'service_areas',
        'severity',
        'markets',
        'haulers',
        'rolloff_pricing',
        'commercial_pricing',
        'created_by',
        'updated_by'
      ]
      // if service areas are attached set the service_areas array
      // with the included ids
      if ('_embedded' in data && 'serviceAreas' in data._embedded) {
        data.service_areas = data._embedded.serviceAreas.map(area => area.id)
      }
      return format(attachUser(data, userId), required, optional)
    },
    /**
    * Accepts proposed data to create or modify a hauler and
    * formulates a valid request object based on the api validators.
    * Return object is read only to prevent further mutation.
    * @param {object} data - the proposed data to send to the api
    * @param {string} userId - the user id from session
    * @return {object}
    */
    hauler (data, userId) {
      const required = ['name']
      const optional = [
        'active',
        'type',
        'general_notes',
        'hauler_score',
        'standard_service',
        'saturday_service',
        'created_by',
        'updated_by']
      return format(attachUser(data, userId), required, optional)
    },
    /**
    * Accepts proposed data to create or modify a hauler and
    * formulates a valid request object based on the api validators.
    * Return object is read only to prevent further mutation.
    * @param {object} data - the proposed data to send to the api
    * @param {string} userId - the user id from session
    * @return {object}
    */
    location (data, userId) {
      const required = [
        'address',
        'city',
        'state',
        'zip_code',
        'hauler_id'
      ]
      const optional = [
        'website',
        'phone',
        'latitude',
        'longitude',
        'created_by',
        'updated_by'
      ]
      return format(attachUser(data, userId), required, optional)
    },
    /**
     * Accepts proposed data to create or modify an market and
     * formulates a valid request object based on the api validators.
     * Return object is read only to prevent further mutation.
     * @param {object} data - the proposed data to send to the api
     * @param {string} userId - the user id from session
     * @return {object}
     */
    market (data, userId) {
      const required = ['name', 'timezone']
      const optional = [
        'id',
        'our_phone',
        'our_address',
        'alt_phone',
        'alt_address',
        'created_by',
        'updated_by'
      ]
      return format(attachUser(data, userId), required, optional)
    },
    /**
     * Accepts proposed data to create or modify a note and
     * formulates a valid request object based on the api validators.
     * Return object is read only to prevent further mutation.
     * @param {object} data - the proposed data to send to the api
     * @param {string} userId - the user id from session
     * @return {object}
     */
    note (data, userId) {
      const required = ['name', 'description']
      const optional = [
        'haulers',
        'service_areas',
        'rolloff_pricing',
        'created_by',
        'updated_by'
      ]
      return format(attachUser(data, userId), required, optional)
    },
    /**
     * Accepts proposed data to create or modify roll off pricing and
     * formulates a valid request object based on the api validators.
     * Return object is read only to prevent further mutation.
     * @param {object} data - the proposed data to send to the api
     * @param {string} userId - the user id from session
     * @return {object}
     */
    rollOffPricing (data, userId) {
      const required = [
        'size_id',
        'waste_type_id',
        'service_area_id',
        'brand_id',
        'product_id',
        'service_type_id',
        'cost_structure_id',
        'cost_structure_note',
        'price',
        'cost',
        'tonnage',
        'rental_period',
        'extra_day_price',
        'extra_day_cost',
        'price_per_ton',
        'cost_per_ton',
        'yard_waste',
        'stumps',
        'appliances',
        'electronics',
        'mattress',
        'other',
        'rank',
        'created_by',
        'updated_by'
      ]
      const optional = [
        'sell_as',
        'height',
        'width',
        'length',
        'extra_day_cost_note',
        'product_notes',
        'yard_waste_note',
        'stumps_note',
        'appliances_note',
        'electronics_note',
        'mattress_note',
        'other_note',
        'concrete',
        'concrete_note',
        'dirt',
        'dirt_note',
        'brick',
        'brick_note',
        'block',
        'block_note',
        'gravel',
        'gravel_note',
        'asphalt',
        'asphalt_note',
        'leed',
        'leed_note',
        'preferred',
        'mako_preferred',
        'quote_preferred'
      ]
      return format(attachUser(data, userId), required, optional)
    },
    /**
     * Accepts proposed data to create or modify a service and
     * formulates a valid request object based on the api validators.
     * Return object is read only to prevent further mutation.
     * @param {object} data - the proposed data to send to the api
     * @param {string} userId - the user id from session
     * @return {object}
     */
    serviceArea (data, userId) {
      const required = ['name', 'hauler_id']
      const optional = [
        'id',
        'active',
        'alerts',
        'boundaries',
        'search_text',
        'netsuite_name',
        'market_id',
        'permit_info',
        'service_area_defined',
        'special_use',
        'region_id',
        // 'rolloff_pricing',
        'created_by',
        'updated_by'
      ]
      // can't send null or 500 error
      if (data.boundaries === 'null') delete data.boundaries
      data.created_by = userId
      if (data.boundaries === 'null') delete data.boundaries
      // if relational alerts are included format them
      if (data.alerts) data.alerts = data.alerts.map(a => this.alert(a, userId))
      return format(attachUser(data, userId), required, optional)
    },
    /**
     * Accepts proposed data to create or modify a size and
     * formulates a valid request object based on the api validators.
     * Return object is read only to prevent further mutation.
     * @param {object} data - the proposed data to send to the api
     * @param {string} userId - the user id from session
     * @return {object}
     */
    size (data, userId) {
      // append the yard string
      data.name += ' Yard'
      const required = ['product_id', 'name']
      const optional = ['created_by', 'updated_by']
      return format(attachUser(data, userId), required, optional)
    },
    /**
     * Accepts proposed data to create or modify a waste type and
     * formulates a valid request object based on the api validators.
     * Return object is read only to prevent further mutation.
     * @param {object} data - the proposed data to send to the api
     * @param {string} userId - the user id from session
     * @return {object}
     */
    wasteType (data, userId) {
      const required = ['name']
      const optional = [
        'id',
        'created_by',
        'updated_by',
        'construction_debris',
        'household_debris',
        'special_debris'
      ]
      return format(attachUser(data, userId), required, optional)
    }
  }
})()

export default formatter
