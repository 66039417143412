/* eslint no-param-reassign: 0 */
import * as Sentry from '@sentry/browser'
import requestFormatter from '../../utils/requestFormatter'
import { apiCRUD } from '../../utils/apiHttp'
import events from './events'

export default {
  namespaced: true,
  state: {
    hauler: [],
    serviceArea: [],
    product: []
  },
  modules: {
    events
  },
  mutations: {
    clearNotes: (state, types) => types.forEach(type => { state[type] = [] }),
    setNote: (state, { data, type, relId }) => {
      const copy = { ...data }
      // notes have no relation so this is almost mandatory
      copy.rel_id = relId
      state[type].push(copy)
    },
    createNote: (state, { data, type, relId }) => {
      const copy = { ...data }
      // notes have no relation so this is almost mandatory
      copy.rel_id = relId || null
      state[type] = copy
    },
    /**
     * Handle the api response of a PUT request
     */
    updateNote: (state, { data, type, relId }) => {
      const copy = { ...data }
      // PUT response returns only id and not note_id
      copy.note_id = data.id
      // notes have no relation so this is almost mandatory
      copy.rel_id = relId || null
      const index = state[type].findIndex(note => note.note_id === copy.note_id)
      state[type][index] = copy
    },
    /**
     * Update only the description of a note in state
     */
    updateNoteDescription: (state, { noteId, type, description }) => {
      const index = state[type].findIndex(note => note.note_id === noteId)
      state[type][index].description = description
    },
    removeNote: (state, { id, type }) => {
      const index = state[type].findIndex(note => note.note_id === id)
      state[type].splice(index, 1)
    }
  },
  getters: {
    haulerNotes: state => state.hauler,
    productNotes: state => state.product,
    getNoteById: state => (id, type) => state[type].find(note => note.id === id),
    getNotesByType: state => type => state[type]
  },
  actions: {
    /**
     * Create a new hauler note and commit to state object
     * @param {object} context - destructured to commit
     * @param {object} data - note object to be created
     */
    createNote ({ commit }, {
      data, userId, type, relId
    }) {
      const payload = requestFormatter.note(data, userId)
      return apiCRUD.create('notes', payload)
        .then(response => {
          // ON CREATE COMES BACK AS ID BUT
          // ON REQUESTS THIS ID IS ACTUALLY NOTE_ID
          const copy = response.data
          copy.note_id = response.data.id
          commit('setNote', {
            data: copy, type, relId
          })
          commit('events/runFlash', {
            message: 'Your note has been saved successfully',
            severity: 'success',
            timeout: 2500
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error saving this note',
            severity: 'error',
            timeout: 2500
          })
        })
    },
    /**
     * Update a hauler note and push changes to state
     * @param {object} context - application context
     * @param {object} - destructured request payload
     */
    updateNote ({ commit }, {
      data, userId, type, relId
    }) {
      const payload = requestFormatter.note(data, userId)
      return apiCRUD.update('note', data.note_id, payload)
        .then(response => {
          commit('updateNote', {
            data: response.data, type, relId
          })
          commit('events/runFlash', {
            message: 'Your note has been updated successfully',
            severity: 'success',
            timeout: 2500
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error updating this note',
            severity: 'error',
            timeout: 2500
          })
        })
    },
    /**
     * Delete an existing hauler note
     * @param {string} id - the id of the record to remove
     * @public
     */
    removeNote ({ commit }, { id, type }) {
      return apiCRUD.delete('note', id)
        .then(() => {
          commit('removeNote', { id, type })
          commit('events/runFlash', {
            message: 'Your note was removed successfully',
            severity: 'success',
            timeout: 2500
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error removing your note',
            severity: 'error',
            timeout: 2500
          })
        })
    }
  }
}
