<template>
  <div id="content" class="container">
    <div class="row">
      <div class="col s12">
        <h2>{{getMarket.name}} Market</h2>
      </div>
      <div class="col s3">
        <ul class="collection">
          <li
          :class="['collection-item', { active: view === 'MarketBoundaries' }]"
          @click="view = 'MarketBoundaries'">
            <i class="material-icons circle sec">edit_location</i>
            <span class="title">Manage Boundaries</span>
          </li>
          <li
          :class="['collection-item', { active: view === 'MarketForm' }]"
          @click="view = 'MarketForm'">
            <i class="material-icons circle sec">edit</i>
            <span class="title">Edit Market</span>
          </li>
          <li :class="['collection-item', { red: confirm }]" @click="confirmDel">
            <i class="material-icons circle red">delete_forever</i>
            <span class="title">{{ delTxt }}</span>
          </li>
        </ul>

        <transition name="fade" mode="out-in">
          <div v-if="saving" class="stateful">
            <span class="stateful__spinner"></span>
            <span>Saving...</span>
          </div>
        </transition>
      </div>
      <div class="col s9">
        <transition
        enter-active-class="animated animated--fade-in-right"
        leave-active-class="animated animated--fade-out-left"
        mode="out-in">
          <keep-alive>
            <component
            :is="view"
            :key="view"
            :editing="true"
            @saved="editing = false"
            :enable="true" />
          </keep-alive>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { isEmpty } from 'kyanite'
import MarketForm from '../components/MarketForm'
import MarketBoundaries from '../components/MarketBoundaries'

export default {
  name: 'MarketSingle',
  components: {
    MarketForm,
    MarketBoundaries
  },
  data () {
    return {
      editing: false,
      confirm: false,
      view: 'MarketBoundaries'
    }
  },
  computed: {
    ...mapGetters('market', ['getMarket']),
    ...mapState('events', ['saving']),
    delTxt () {
      return this.confirm ? 'Are You Sure?' : 'Delete'
    }
  },
  watch: {
    getMarket (data) {
      if (!isEmpty(data)) {
        this.setBoundaries(data)
      }
    }
  },
  methods: {
    ...mapActions('market', ['deleteMarket', 'setMarket']),
    ...mapMutations(['clearChips']),
    ...mapMutations('geometry', ['setBoundaries', 'resetGeometryStore']),
    confirmDel () {
      if (!this.confirm) {
        this.confirm = true
      } else {
        this.confirm = false
        this.deleteMarket(this.getMarket.id).then(() => this.$router.replace('/markets/collection'))
      }
    }
  },
  created () {
    // Make sure the state is reset completely
    // To avoid fallout from hauler
    this.clearChips()
    this.resetGeometryStore()
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/base/_variables.scss';

.ml-0 {
  margin-left: 0;
}

.collection {
  margin-top: 0;
  .collection-item {
    background-color: $primary-light;
    color: #FFF;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
      background-color: $gray;
    }

    &.active {
      background-color: $secondary;
      .sec {
        background-color: $primary-light;
      }
    }
  }
  .red {
    color: #FFF;
  }

  .sec {
    background-color: $secondary;
  }

  .title {
    font-size: 16px;
    vertical-align: middle;
  }

  .circle {
    vertical-align: middle;
    font-size: 16px;
    text-align: center;
    line-height: 1.3rem;
    width: 1.3rem;
  }
}
</style>
