/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import * as Sentry from '@sentry/browser'
import { isEmpty } from 'kyanite'
import { apiCRUD } from '../../../utils/apiHttp'

import debris from './debris-type'

/**
 * @description Returns obj literal of inital state values
 * @returns {object} initial state of the module
 */
const initState = () => ({
  wasteType: {
    name: '',
    construction_debris: false,
    household_debris: false,
    special_debris: false
  },
  selectedDebris: [],
  debrisTypes: ['construction_debris', 'household_debris', 'special_debris'],
  wasteTypes: []
})

export default {
  namespaced: true,
  state: initState(),

  modules: {
    debris
  },

  mutations: {
    /**
     * @name updateWasteType
     * @description Mutation to update the state of the current waste type
     * @param {object} state
     * @param {object} data an updated waste type object
     */
    updateWasteType (state, data) {
      state.wasteType = isEmpty(data) ? initState() : data
    },
    /**
     * @name updateSelectedDebris
     * @description Mutation to update the true/false debris values
     * @param {object} state
     * @param {array} arr an array of checked debris type names
     */
    updateSelectedDebris (state, arr) {
      state.selectedDebris = arr
    },
    /**
     * @name updateWasteTypeName
     * @description Mutation to update the waste type name
     * @param {object} state
     * @param {string} name
     */
    updateWasteTypeName (state, name) {
      Object.assign(state.wasteType, { name })
    },
    /**
     * @name reset
     * @description Reset current state to inital state
     * @param {object} state current state of the module
     */
    reset (state) {
      Object.assign(state, initState())
    },
    /**
     * @name setWasteTypes
     * @description Set the waste types collection array
     * @param {object} state
     * @param {array} data
     */
    setWasteTypes (state, data) {
      state.wasteTypes = data
    }
  },
  actions: {
    /**
     * @name fetchWasteType
     * @description Get a single waste type record given an id
     * @param {object} context.state
     * @param {function} context.commit
     * @param {string} id
     * @return {promise}
     */
    fetchWasteType ({ state, commit }, id) {
      return apiCRUD.record('waste-type', id)
        .then(({ data }) => commit('updateWasteType', data))
        .then(() => commit('updateSelectedDebris', state.debrisTypes.filter(type => state.wasteType[type])))
        .catch(e => {
          console.error(e)
          Sentry.captureException(e)
          throw new Error(`Cannot complete fetch: ${e}`)
        })
    },
    /**
     * @name fetchWasteTypes
     * @description Get the full collection of waste types
     * @param {function} context.commit
     * @return {promise}
     */
    fetchWasteTypes ({ commit }) {
      return apiCRUD
        .collection('waste-types', { per_page: -1 })
        .then(({ data }) => commit('setWasteTypes', data.data))
        .catch(e => {
          console.error(e)
          Sentry.captureException(e)
          throw new Error(`Cannot complete fetch: ${e}`)
        })
    },
    /**
     * @name deleteWasteType
     * @description Delete a single waste type given an id
     * @param {function} context.commit
     * @param {string} id
     * @return {promise}
     */
    deleteWasteType ({ commit }, id) {
      return apiCRUD.delete('waste-type', id)
        .then(() => commit('updateWasteType', initState()))
        .catch(e => {
          console.error(e)
          Sentry.captureException(e)
          throw new Error(`Cannot complete delete: ${e}`)
        })
    },
    /**
     * @name updateWasteTypeRecord
     * @description Update a single waste type record
     * @param {function} context.commit
     * @param {object} payload
     * @return {promise}
     */
    updateWasteTypeRecord ({ commit }, payload) {
      return apiCRUD.update('waste-type', payload.id, payload)
        .then(({ data }) => commit('updateWasteType', data))
        .catch(e => {
          console.error(e)
          Sentry.captureException(e)
          throw new Error(`Cannot complete update: ${e}`)
        })
    }
  }
}
