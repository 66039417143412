<template>
    <div class="row collection">
      <table class="highlight bordered">
      <thead>
        <tr>
          <th @click="sorting('hauler_name')">Hauler
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'hauler_name'">
              arrow_drop_up
            </i>
          </th>
          <th @click="sorting('name')">Region
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'name'">
              arrow_drop_up
            </i>
          </th>
          <th @click="sorting('territory')">Territory
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'territory'">
              arrow_drop_up
            </i>
          </th>
          <th @click="sorting('last_saved_at')">Last Saved
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'last_saved_at'">
              arrow_drop_up
            </i>
          </th>
          <th> Saved By </th>
          <th> &nbsp; </th>
        </tr>
      </thead>
      <tbody v-if="loading" class="stateful centered">
        <span class="stateful__spinner stateful__spinner--huge stateful__spinner--block"></span>
        <span class="stateful__msg">Loading Results...</span>
      </tbody>
      <tbody
        v-else
        v-for="(region, index) in regions"
        :key="region.id">
        <tr @click="toggleVisibility(region)">
          <td>
            <router-link :to="`/haulers/${region.hauler_id}`" class="hauler-link">
              {{region.hauler_name || ''}}
            </router-link>
          </td>
          <td>{{region.name}}</td>
          <td>{{region.territory || 'None'}}</td>
          <td><span v-html="formatUpdatedAt(region.last_saved_at)" /></td>
          <td><user-name :userId="region.last_saved_by || ''" /></td>
          <td class="icons">
            <i v-if="!region.current_service_exceptions.no_service" class="material-icons service">location_on</i>
            <i v-else class="material-icons service-red">location_off</i>
            <i v-if="!region.current_service_exceptions.must_call" class="material-icons service">phone</i>
            <i v-else class="material-icons service-red">phone_disabled</i>
            <i v-if="!region.current_service_exceptions.window_active" class="material-icons service">calendar_today</i>
            <i v-else class="material-icons">event_note</i>
            <i v-if="region.current_service_exceptions.days_out" class="material-icons">filter_3</i>
            <i v-else class="material-icons service">crop_square</i>
          </td>
        </tr>
        <tr v-if="region.isVisible && availabilities.newDeliveries[index]" class="availability-row">
          <td class="table-title"><div>CURRENT<br>AVAILABILITY</div></td>
          <td colspan="5">
            <table class="availability-details">
            <thead>
              <th
                v-for="delivery in availabilities.newDeliveries[index]"
                :key="delivery.id"
                >
                {{delivery.size.name}}
              </th>
              <th class="divider-header">Empty & Return</th>
              <th>Pickup</th>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="delivery in availabilities.newDeliveries[index]"
                  :key="delivery.id"
                  :class="{ bad: region.new_deliveries_no_service || delivery.must_call }">
                  {{ region.new_deliveries_no_service ?
                    'No Service' : determineAvailability(delivery)}}
                </td>
                <td :class="[{ bad: region.availability.emptyReturn.no_service }, 'divider-td']">
                  {{ region.availability.emptyReturn.no_service ?
                    'No Service' : determineAvailability(availabilities.returns[index])}}
                    <span v-if="windows.emptyReturn[index].window_active">
                      | 1-{{ windows.emptyReturn[index].window_days }} Day Window
                    </span>
                </td>
                <td :class="{ bad: region.availability.pickUp.no_service }">
                  {{ region.availability.pickUp.no_service ? 'No Service' :
                    determineAvailability(availabilities.pickUps[index])}}
                    <span v-if="windows.pickUp[index].window_active">
                      | 1-{{ windows.pickUp[index].window_days }} Day Window
                    </span>
                </td>
              </tr>
              <tr class="small-table-row" v-if="windows.newDeliveries[index].window_active">
                <td :colspan="availabilities.newDeliveries[index].length" class="delivery-window">
                  <strong>Delivery Window </strong> 1-{{ windows.newDeliveries[index].window_days }} Day</td>
              </tr>
            </tbody>
            </table>
          </td>
        </tr>
        <tr v-if="region.isVisible && !availabilities.newDeliveries[index]" class="availability-row">
          <td colspan="6" class="center">Loading…</td>
        </tr>
      </tbody>
    </table>
    </div>
</template>
<script>
import { format } from '../utils/time'
import UserName from '../components/UserName'

export default {
  name: 'AvailabilityCollectionTable',
  components: {
    UserName
  },
  props: {
    regions: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      sortField: 'last_saved_at',
      sortDir: 'desc',
      visibleRegions: {},
      territories: [
        {
          name: 'Midwest',
          value: 'Midwest'
        }, {
          name: 'Southeast',
          value: 'Southeast'
        }, {
          name: 'Northeast',
          value: 'Northeast'
        }, {
          name: 'West',
          value: 'West'
        }
      ]
    }
  },
  computed: {
    availabilities () {
      const availability = {
        pickUps: [],
        returns: [],
        newDeliveries: []
      }

      if (this.regions[0].current_availability) {
        availability.pickUps = this.regions.map(region => region.current_availability.find(i => i.delivery_type === 'pickUp').pickUp || [])
        availability.returns = this.regions.map(region => region.current_availability.find(i => i.delivery_type === 'emptyReturn').emptyReturn || [])
        availability.newDeliveries = this.regions.map((region) => {
          const list = region.current_availability.find(i => i.delivery_type === 'newDeliveries').newDeliveries

          return list
            ? list.sort((a, b) => {
              const aName = Number(a.size.name.split(' ')[0])
              const bName = Number(b.size.name.split(' ')[0])
              return aName - bName
            })
            : []
        })
      }

      return availability
    },
    windows () {
      return {
        newDeliveries: this.regions.map(region => region.current_availability.find(i => i.delivery_type === 'newDeliveries')),
        pickUp: this.regions.map(region => region.current_availability.find(i => i.delivery_type === 'pickUp')),
        emptyReturn: this.regions.map(region => region.current_availability.find(i => i.delivery_type === 'emptyReturn'))
      }
    }
  },
  methods: {
    toggleVisibility (region) {
      this.visibleRegions[region.id] = !this.visibleRegions[region.id]
      this.$set(region, 'isVisible', this.visibleRegions[region.id])
    },
    isVisible (region) {
      return this.visibleRegions[region.id]
    },
    sorting (field) {
      this.sortField = field

      if (!this.sortDir || this.sortDir === 'desc') {
        this.sortDir = 'asc'
      } else {
        this.sortDir = 'desc'
      }

      this.$emit('sorted', { field, sortDir: this.sortDir })
    },
    formatUpdatedAt (date) {
      if (Number.isNaN(Date.parse(date))) {
        return ''
      }

      const updatedDate = new Date(`${date}.400Z`)

      return `<div>${format(updatedDate, 'M/DD/YYYY')}</div> <div>${format(date, 'h:mm a')}</div>`
    },
    determineAvailability (avail) {
      if (avail.must_call) {
        return 'Must Call'
      }

      if (avail.delivery_date.availabilityType !== 'Two Day Notice') {
        return avail.delivery_date.availabilityType
      }

      if (avail.next_available_service) {
        return format(avail.next_available_service, 'M/DD/YYYY')
      }

      if (avail.days_out) {
        return `${avail.days_out} Days Out`
      }

      if (avail.saturday_active) {
        return 'Saturday'
      }

      return ''
    }
  },
  watch: {
    regions () {
      this.regions.forEach((region) => {
        if (this.isVisible(region)) {
          this.$set(region, 'isVisible', true)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

.bad {
  color: $danger;
}
.black {
  color: #000;
}
.service {
  color: $gray
}
.service-red {
  color: #f34a61;
}

.centered {
  height: 100px;
  display: table-row;
  position: relative;

  span {
    position: absolute;
  }

  .stateful__spinner {
    bottom: 20px;
    left: 45%;
  }

  .stateful__msg {
    bottom: 0px;
    left: 43%;
  }
}

.hauler-link {
  color: #FA8D29;
  cursor: pointer;
}

.availability-row {

  .table-title {
    position: relative;
    color: $dark-gray;
    text-align: left;
    div {
       margin-left: 20px;
    }
  }

  .table-title:after {
    bottom: 10px;
    top: 20px;
    right: 24px;
    height: 60%;
  }

  .divider-header, .divider-td  {
    position: relative;
  }

  .table-title:after, .divider-header:before,
  .divider-header:after, .divider-td:after, .divider-td:before {
    content: "";
    position: absolute;
    width: 1px;
    background: $dark-gray;
  }

   .divider-header:after,  .divider-header:before {
     top: 3px;
     height: 120%
   }

  .divider-header:before {
    left: -20px;
  }

  .divider-header:after {
    right: 20px;
  }

  .divider-td:after, .divider-td:before {
    bottom: 12px;
    height: 120%;
  }

  .divider-td:after {
    right: 20px;
  }

  .divider-td:before {
    left: -20px;
  }

  .availability-details {
    thead {
      border-bottom: none;
      th {
        padding-bottom: 0px;
      }
    }
  }
}

tr.small-table-row {
  margin: 0;
  padding: 0;
  td {
    padding:0;
    margin:0;
  }
}

th .material-icons {
  vertical-align: middle;
}

.material-icons.down {
  transform: rotate(180deg);
}

.material-icons {
  transition: transform 0.3s;
  font-size: 24px;
  margin: 0 2px;
}
.delivery-window {
    text-align: center;
}
</style>
