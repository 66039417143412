<template v-if="pricing">
    <section>
      <dumpster-price
      v-for="price of list" :key="price.id"
      :haulerName="price.hauler_name"
      :dumpster="price"
      :data-target-id="price.hauler_id"
      :data-key="price.hauler_id">
      </dumpster-price>
      <infinite-loading
      @infinite="infiniteHandler"
      :identifier="infiniteId">
        <span slot="no-more"></span>
        <span slot="no-results"></span>
      </infinite-loading>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import DumpsterPrice from './DumpsterPrice.vue'
import { colors } from '../config/colors'

/**
 * Will display the quote search results by product with hauler labels
 */
export default {
  name: 'ProductPricingResults',
  props: {
  /**
   * The search result from the API call (All haulers, their products, and related data)
   * @type {Array}
   */
    pricing: {
      required: true,
      type: Array
    }
  },
  components: {
    InfiniteLoading,
    DumpsterPrice
  },
  data () {
    return {
      buffer: 0,
      infiniteId: +new Date(),
      list: [],
      colors,
      targetId: null
    }
  },
  computed: {
    ...mapGetters('filter', ['filteredList'])
  },
  watch: {
    filteredList (prods) {
      this.list = prods.slice(0, 3)
      this.infiniteId += 1
      this.buffer = 3
    }
  },
  methods: {
    /**
     * @name infiniteHandler
     * @summary Handles the infinite load state, resetting when needed and updating the buffer
     * @param {Object} $state This is the current state of the component
     * @memberof methods
     * @return {Void}
     */
    infiniteHandler ($state) {
      if (this.filteredList.length === this.list.length) {
        return $state.complete()
      }

      const currBuffer = this.buffer

      this.buffer += 6
      this.list = this.list.concat(this.filteredList.slice(currBuffer, this.buffer))
      $state.loaded()
      return true
    }
  }
}
</script>
