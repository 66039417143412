<template>
    <div class="chips"></div>
</template>

<script>
export default {
  name: 'Chipcollection',
  props: {
    /**
     * An array of chip values used to create the chips
     * @type {Array}
     */
    chipValues: {
      required: true,
      type: Array
    },
    /**
     * Class name used to group the chips together
     * @type {String}
     */
    chipCls: {
      required: false,
      type: String,
      default: ''
    }
  },
  watch: {
    chipValues (values) {
      const data = values.map(value => ({ tag: value.search_term }))
      $('.chips').material_chip({ data })
      this.$forceUpdate()
    }
  },
  mounted () {
    $('.chips').on('chip.delete', (e, c) => {
      this.$emit('chip_removed', { value: c.tag })
    })
  }
}
</script>

<style lang="scss">
    @import 'src/styles/base/_variables.scss';
    .merge {
        .chip {
            background-color: $success;
        }
    }
    .cut {
        .chip {
            background-color: $danger;
            color: #fff;
        }
    }
</style>
