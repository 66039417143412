import * as Sentry from '@sentry/browser'
import Vue from 'vue'
import {
  compose,
  eq,
  filter,
  find,
  findIndex,
  omit,
  prop,
  remove,
  sortBy,
  update
} from 'kyanite'
import { apiHttp } from '../../utils/apiHttp'
import geo from './geometry'
import events from './events'
import requestFormatter from '../../utils/requestFormatter'

const store = {
  namespaced: true,

  state: {
    serviceAreas: [],
    selectedArea: {},
    currentAvailability: []
  },

  modules: {
    events,
    geo
  },

  getters: {
    getAreasByRegion (state) {
      return id =>
        sortBy(prop('name'), filter(compose(eq(id), prop('region_id')), state.serviceAreas))
    },

    getZoneBoundaries (state) {
      return state.serviceAreas.map(prop('boundaries'))
    },

    getSpecialUseAreas (state) {
      return state.serviceAreas.filter(a => a.special_use)
    }
  },

  mutations: {
    addArea (state, area) {
      state.serviceAreas.push(area)
    },

    overwriteArea (state, area) {
      state.selectedArea = { ...state.selectedArea, ...area }
    },

    setServiceAreas (state, data) {
      state.serviceAreas = data
    },

    selectArea (state, id) {
      state.selectedArea = { ...find(compose(eq(id), prop('id')), state.serviceAreas) } || {}
    },

    replaceArea (state, area) {
      state.serviceAreas = update(
        findIndex(compose(eq(area.id), prop('id')), state.serviceAreas),
        area,
        state.serviceAreas
      )
    },

    updateArea (state, { key, value }) {
      state.selectedArea[key] = value
    },

    updateAreaZone (state, { key, value }) {
      Vue.set(state.selectedArea, [key], value)
    },

    updateSelectedAreaPricing (state, data) {
      const index = state.selectedArea.rolloff_pricing.findIndex(price => data.id === price.id)

      if (index > -1) {
        state.selectedArea.rolloff_pricing = update(index, data, state.selectedArea.rolloff_pricing)
      } else {
        state.selectedArea.rolloff_pricing.push(data)
      }
    },

    removeArea (state, id) {
      state.serviceAreas = remove(findIndex(compose(eq(id), prop('id')), state.serviceAreas), state.serviceAreas)
    },

    clearServiceAreas (state) {
      state.serviceAreas = []
    }
  },

  actions: {
    fetchServiceArea ({ commit }, id) {
      return apiHttp.get(`/service-area/${id}`)
        .then(({ data }) => {
          commit('replaceArea', data)

          return data
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: `There was an error retrieving service area ${id}`,
            severity: 'error',
            timeout: -1
          })
        })
    },

    fetchServiceAreas ({ commit }, id) {
      return apiHttp.get(`/hauler/${id}/service-areas`)
        .then(({ data }) => {
          commit('setServiceAreas', data.collection)

          return data.collection
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error retrieving service areas',
            severity: 'error',
            timeout: -1
          })
        })
    },

    saveArea ({ commit, state }, payload) {
      const sendingData = payload ? requestFormatter.serviceArea(payload.data, payload.userId) : state.selectedArea

      return apiHttp.put(`/service-area/${payload.data.id}`, omit(['rolloff_pricing'], sendingData))
        .then(({ data }) => {
          commit('replaceArea', data)

          commit('events/runFlash', {
            message: 'Successfully Saved Zone',
            severity: 'success',
            timeout: 2000
          })

          return data
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error updating zone',
            severity: 'error',
            timeout: -1
          })
        })
    },

    createArea ({ commit, state }) {
      return apiHttp.post('/service-areas', state.selectedArea)
        .then(({ data }) => {
          commit('addArea', data)
          commit('geo/addArea', data)
          commit('overwriteArea', data)
          commit('events/runFlash', {
            message: 'Successfully Created Zone',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error creating the zone',
            severity: 'error',
            timeout: -1
          })
        })
    },

    deleteArea ({ commit }, id) {
      return apiHttp.delete(`service-area/${id}`)
        .then(() => {
          commit('selectArea', {})
          commit('removeArea', id)
          commit('events/runFlash', {
            message: 'Successfully Deleted Zone',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error deleting the zone',
            severity: 'error',
            timeout: -1
          })
        })
    }
  }
}

export default store
