<template>
  <div class="mt container">
    <div>
      <p>
        <small>Please note: Bulk exceptions are added to all haulers/all regions. For any region it does not apply to, you can delete it from that individual region.</small>
        <button
          v-show="!editing"
          type="button"
          class="btn right"
          @click="setEditMode"
        >
          Add Bulk Exception
        </button>
      </p>
    </div>

    <div class="form" v-if="editing">
      <ExceptionForm
        @saved="setViewMode"
        @cancel="setViewMode"
        :bulk="true"
        :record="exceptionRecord"/>
    </div>

    <div v-show="!editing">
      <exception-table-bulk
        :exceptions="sortedExceptions"
        :loading="loading"
        @sorted='(value) => updateSort(value.field, value.sortDir)'
        @reload="search"
        @edit="handleEdit"
      />
      <p v-if="!loading && sortedExceptions.length === 0" class="center">There are no active bulk exceptions.</p>

      <transition name="fade" mode="out-in">
        <div class="row">
          <paginate
            v-show="totalPages"
            :model='""'
            :totalPages="totalPages"
            @pageChange="pageChange" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import ExceptionForm from './ExceptionForm'
import ExceptionTableBulk from '../components/ExceptionTableBulk'
import Paginate from '../components/PaginateCollection'

export default {
  name: 'ExceptionCollectionBulk',
  components: {
    ExceptionForm,
    ExceptionTableBulk,
    Paginate
  },
  data () {
    return {
      searchPayload: {
        search_type: 'exclusive',
        sort: 'action_date',
        sort_order: 'asc',
        page: 1,
        per_page: 25,
        field: null,
        operator: null,
        value: null,
        conditions: [{ field: 'bulk', value: 1 }]
      },
      editing: false,
      totalPages: 0,
      currentPage: 1,
      sortField: 'action_date',
      sortDir: 'asc'
    }
  },
  computed: {
    ...mapState('events', ['loading']),
    ...mapState('exceptions', ['exceptions', 'selectedException']),
    exceptionRecord () {
      return Object.assign({}, this.selectedException)
    },
    sortedExceptions () {
      const exceptions = [...this.exceptions]
      exceptions.forEach(exception => {
        this.$set(exception, 'toggleVisibility', false)
      })
      return exceptions
    }
  },
  methods: {
    ...mapMutations('events', ['setLoading']),
    ...mapMutations('exceptions', ['setSelectedException']),
    ...mapActions('exceptions', ['getAllExceptions', 'searchExceptions']),
    updateSort (field, sortDir) {
      this.sortField = field
      this.sortDir = sortDir
      this.searchPayload.sort = this.sortField
      this.searchPayload.sort_order = this.sortDir
      this.search()
    },
    pageChange (page) {
      this.searchPayload.page = page
      this.search()
    },
    search () {
      this.setLoading(true)
      this.searchExceptions(this.searchPayload).then((result) => {
        this.totalPages = result.total_pages
        this.setLoading(false)
      })
    },
    handleEdit (exception) {
      this.editing = true
      this.setSelectedException(exception)
    },
    setEditMode () {
      this.editing = true
      this.setSelectedException({})
    },
    setViewMode (saved) {
      if (saved) {
        this.search()
      }

      this.editing = false
      this.setSelectedException({})
    }
  },
  mounted () {
    this.updateSort(this.sortField, this.sortDir)
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

div.container > div:first-child {
  margin-bottom: 2em;
}
</style>
