import * as Sentry from '@sentry/browser'
import Vue from 'vue'
import {
  compose, eq, filter, find, findIndex, omit, path, prop, remove, sortBy, update, sift
} from 'kyanite'
import { apiHttp } from '../../utils/apiHttp'
import { objectsSort } from '../../utils/sort'
import events from './events'
import requestFormatter from '../../utils/requestFormatter'

export function getUpdatedByInfo (availability) {
  if (Object.hasOwnProperty.call(availability, 'newDeliveries') && availability.newDeliveries.length) {
    return objectsSort.descendingByDate(availability.newDeliveries.map(getUpdatedByInfo), 'updatedAt')[0]
  }

  return {
    updatedAt: availability.updated_at || '',
    updatedBy: availability.updated_by || ''
  }
}

const store = {
  namespaced: true,

  state: {
    regions: [],
    selectedRegion: {},
    selectedArea: {},
    serviceAreas: [],
    availabilityRegions: [],
    availabilityRegionPages: 0
  },

  modules: {
    events
  },

  mutations: {
    addArea (state, area) {
      state.serviceAreas.push(area)
    },

    replaceArea (state, area) {
      state.serviceAreas = update(
        findIndex(compose(eq(area.id), prop('id')), state.serviceAreas),
        area,
        state.serviceAreas
      )
    },

    updateArea (state, { key, value }) {
      state.selectedArea[key] = value
    },

    updateAreaZone (state, { key, value }) {
      Vue.set(state.selectedArea, [key], value)
    },

    addRegion (state, data) {
      state.regions.push(data)
    },

    setRegions (state, data) {
      state.regions = data
    },
    setServiceAreas (state, data) {
      state.serviceAreas = [].concat(state.serviceAreas, data)
    },

    overwriteArea (state, area) {
      state.selectedArea = { ...state.selectedArea, ...area }
    },

    clearServiceAreas (state) {
      state.serviceAreas = []
    },
    setAvailabilityRegions (state, data) {
      state.availabilityRegions = data
    },

    selectRegion (state, id) {
      state.selectedRegion = { ...find(compose(eq(id), prop('id')), state.regions) } || {}
    },

    selectArea (state, id) {
      state.selectedArea = { ...find(compose(eq(id), prop('id')), state.serviceAreas) } || {}
    },

    replaceRegion (state, region) {
      state.regions = update(findIndex(compose(eq(region.id), prop('id')), state.regions), region, state.regions)
    },

    updateRegion (state, { key, value }) {
      state.selectedRegion[key] = value
    },

    removeRegion (state, id) {
      state.regions = remove(findIndex(compose(eq(id), prop('id')), state.regions), state.regions)
    },

    removeArea (state, id) {
      state.serviceAreas = remove(findIndex(compose(eq(id), prop('id')), state.serviceAreas), state.serviceAreas)
    },

    setAvailabilityRegionsPages (state, data) {
      state.availabilityRegionPages = data
    }
  },

  actions: {
    fetchServiceArea ({ commit }, id) {
      return apiHttp.get(`/service-area/${id}`)
        .then(({ data }) => {
          commit('replaceArea', data)

          return data
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: `There was an error retrieving service area ${id}`,
            severity: 'error',
            timeout: -1
          })
        })
    },
    saveArea ({ commit, state }, payload) {
      const sendingData = payload ? requestFormatter.serviceArea(payload.data, payload.userId) : state.selectedArea
      return apiHttp.put(`/service-area/${payload.data.id}`, omit(['rolloff_pricing'], sendingData))
        .then(({ data }) => {
          commit('replaceArea', data)

          commit('events/runFlash', {
            message: 'Successfully Saved Zone',
            severity: 'success',
            timeout: 2000
          })

          return data
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error updating zone',
            severity: 'error',
            timeout: -1
          })
        })
    },
    createArea ({ commit, state }) {
      return apiHttp.post('/service-areas', state.selectedArea)
        .then(({ data }) => {
          commit('addArea', data)
          commit('geo/addArea', data)
          commit('overwriteArea', data)
          commit('events/runFlash', {
            message: 'Successfully Created Zone',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error creating the zone',
            severity: 'error',
            timeout: -1
          })
        })
    },
    deleteArea ({ commit }, id) {
      return apiHttp.delete(`service-area/${id}`)
        .then(() => {
          commit('selectArea', {})
          commit('removeArea', id)
          commit('events/runFlash', {
            message: 'Successfully Deleted Zone',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error deleting the zone',
            severity: 'error',
            timeout: -1
          })
        })
    },
    fetchServiceAreas ({ commit }, id) {
      return apiHttp.get(`/region/${id}/service-areas`)
        .then(({ data }) => {
          commit('setServiceAreas', data.collection)
          return data.collection
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: `There was an error retrieving service areas for ${id}`,
            severity: 'error',
            timeout: -1
          })
        })
    },
    searchRegions ({ commit }, payload) {
      let abort = false

      payload.includeAvailability = true
      const req1 = apiHttp.post('/region/search', payload)
        .then(({ data }) => {
          abort = true
          commit('setAvailabilityRegions', data.models)
          commit('setAvailabilityRegionsPages', data.total_pages)

          return data.collection
        })

      payload.includeAvailability = false
      const req2 = apiHttp.post('/region/search', payload)
        .then(({ data }) => {
          if (!abort) {
            commit('setAvailabilityRegions', data.models)
            commit('setAvailabilityRegionsPages', data.total_pages)
          }

          return data.collection
        })

      return Promise.race([req1, req2])
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error searching service areas',
            severity: 'error',
            timeout: -1
          })
        })
    },
    fetchRegions ({ commit }, id) {
      return apiHttp.get(`/hauler/${id}/regions`)
        .then(({ data }) => {
          commit('setRegions', data.collection)
          return data.collection
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error retrieving regions',
            severity: 'error',
            timeout: -1
          })
        })
    },

    fetchAvailabilityRegions ({ commit }, { page, sort, sortOrder, searchField }) {
      const paramsList = {
        page,
        has: 'current_availability',
        per_page: 20,
        sort,
        sort_order: sortOrder
      }

      const searchParams = {
        field: searchField.field,
        operator: searchField.operator,
        value: searchField.value
      }

      if (Object.values(searchParams).every(val => val)) {
        Object.assign(paramsList, searchParams)
      }

      return apiHttp.get('regions', { params: sift(paramVal => paramVal, paramsList) })
        .then(({ data }) => {
          commit('setAvailabilityRegions',
            data.models.filter(region => region.current_availability.length && region.active))
          commit('setAvailabilityRegionsPages', data.total_pages)
        })
        .catch(e => {
          Sentry.captureException(e)
          throw new Error(`Cannot complete fetch: ${e}`)
        })
    },

    saveRegion ({ commit, state }) {
      return apiHttp.put(
        `/region/${state.selectedRegion.id}`,
        omit([
          'current_availability',
          'availability',
          'last_saved_at',
          'last_saved_by',
          'last_saved',
          'new_deliveries_no_service',
          'hauler_name'
        ], state.selectedRegion)
      )
        .then(() => {
          commit('replaceRegion', state.selectedRegion)

          commit('events/runFlash', {
            message: 'Saved Region Successfully',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error updating region',
            severity: 'error',
            timeout: -1
          })
        })
    },

    createRegion ({ commit, state }) {
      return apiHttp.post('/regions',
        omit([
          'current_availability',
          'availability',
          'last_saved_at',
          'last_saved_by',
          'last_saved',
          'new_deliveries_no_service',
          'hauler_name'
        ], state.selectedRegion)
      )
        .then(({ data }) => {
          commit('addRegion', data)
          commit('selectRegion', data)

          commit('events/runFlash', {
            message: 'Created Region Successfully',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error creating the region',
            severity: 'error',
            timeout: -1
          })
        })
    },

    deleteRegion ({ commit }, id) {
      return apiHttp.delete(`region/${id}`)
        .then(() => {
          commit('selectRegion', {})
          commit('removeRegion', id)

          commit('events/runFlash', {
            message: 'Deleted Region Successfully',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error deleting the region',
            severity: 'error',
            timeout: -1
          })
        })
    }
  },
  getters: {
    getZoneBoundaries (state) {
      return state.serviceAreas.map(prop('boundaries'))
    },

    getAreasByRegion (state) {
      return id =>
        sortBy(prop('name'), filter(compose(eq(id), prop('region_id')), state.serviceAreas))
    },

    selectedRegionCurrentAvailabilityTopLevel ({ selectedRegion }) {
      const currentAvailability = selectedRegion.current_availability
      if (!currentAvailability || currentAvailability.length === 0) {
        return null
      }

      return currentAvailability.map((item) => {
        const itemCopy = { ...item }
        delete itemCopy.newDeliveries
        delete itemCopy.emptyReturn
        delete itemCopy.pickUp
        delete itemCopy.last_saved_by
        delete itemCopy.created_by
        delete itemCopy.updated_by
        delete itemCopy.last_saved_at
        delete itemCopy.created_at
        delete itemCopy.updated_at
        return itemCopy
      }).reduce((acc, item) => {
        acc[item.delivery_type] = item
        return acc
      }, { newDeliveries: null, emptyReturn: null, pickUp: null })
    },

    selectedRegionCurrentAvailability ({ selectedRegion }) {
      const currentAvailability = path(['current_availability'], selectedRegion)
      if (!currentAvailability || currentAvailability.length === 0) {
        return null
      }

      return currentAvailability.reduce((accumulator, currentValue) => {
        switch (currentValue.delivery_type) {
          case 'emptyReturn':
            accumulator.emptyReturn = [{ title: 'Empty & Return', ...currentValue.emptyReturn }]
            return accumulator
          case 'pickUp':
            accumulator.pickUp = [{ title: 'Pickup', ...currentValue.pickUp }]
            return accumulator
          case 'newDeliveries':
            if (currentValue.newDeliveries.length === 0) {
              return accumulator
            }
            accumulator.newDeliveries = currentValue.newDeliveries
              .map(delivery => ({ title: path(['size', 'name'], delivery), ...delivery }))
              .sort((a, b) => {
                // Assuming titles are coming in as "20 Yard", "10 Yard", etc...
                const aTitle = Number(a.title.split(' ')[0])
                const bTitle = Number(b.title.split(' ')[0])
                return aTitle - bTitle
              })
            return accumulator
          default:
            return accumulator
        }
      }, {
        newDeliveries: [], emptyReturn: [], pickUp: []
      })
    },
    getSpecialUseAreas (state) {
      return state.serviceAreas.filter(a => a.special_use)
    }
  }
}

export default store
