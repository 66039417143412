<template>
  <div>
    <edit-bar
    :resourceFieldName="'id'"
    :resourceType="'market'"
    :mapCb="saveMarketMap"
    :selectedArea="market"
    :enable="true" />
    <div class="input-field col s12">
      <input
      v-model="mapNote"
      id="note"
      name="note"
      type="text">
      <label for="alt-address" :class="{ active: mapNote }">
        Note <small>(optional)</small>
      </label>
    </div>
    <button @click="saveMarketMap()" class="btn">Save Note</button>
  </div>
</template>

<script>
import { omit, path } from 'kyanite'
import { mapGetters, mapActions, mapState } from 'vuex'
import EditBar from './EditingBar.vue'

export default {
  components: {
    EditBar
  },

  data () {
    return {
      mapNote: ''
    }
  },

  computed: {
    ...mapGetters('market', ['getMarket']),
    ...mapState('market', ['market'])
  },

  methods: {
    ...mapActions('market', ['updateMarket', 'setMarket', 'clearMarket']),
    saveMarketMap () {
      const boundaries = this.getMarket.boundaries
      const boundsPayload = typeof boundaries === 'string' ? boundaries : JSON.stringify(boundaries)

      const payload = {

        ...omit(['_links', 'haulers', 'rolloff_pricing'], this.getMarket),
        boundaries: boundsPayload,
        note: this.mapNote
      }

      this.updateMarket(payload)
    }
  },

  mounted () {
    if (path(['note'], this.getMarket)) {
      this.mapNote = this.getMarket.note
    }
  },
  beforeMount () {
    this.clearMarket()
    this.setMarket(this.$route.params.id)
  }
}
</script>
