const CollectionManagerSort = (() => {
  const props = {
    sortField: null,
    sortDirection: null
  }

  const asc = (a, b) => {
    const { sortField } = props
    if (a[sortField] > b[sortField]) {
      return 1
    }

    if (a[sortField] < b[sortField]) {
      return -1
    }

    return 0
  }

  const desc = (a, b) => {
    const { sortField } = props
    if (a[sortField] < b[sortField]) {
      return 1
    }

    if (a[sortField] > b[sortField]) {
      return -1
    }
    return 0
  }

  const getCallback = () => {
    if (props.sortDirection === null || props.sortDirection === 'desc') {
      props.sortDirection = 'asc'
      return asc
    }
    props.sortDirection = 'desc'
    return desc
  }

  const setSortField = fieldName => {
    props.sortField = fieldName
  }

  const sort = fieldName => {
    setSortField(fieldName)
    return getCallback()
  }

  const getSortDirection = () => props.sortDirection

  const getSortField = () => props.sortField

  return {
    sort,
    getSortDirection,
    getSortField
  }
})()

export default CollectionManagerSort
