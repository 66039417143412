/* eslint-disable semi, arrow-parens, camelcase */
import { compose, ensureArray, first, has, isEmpty, remove } from 'kyanite'
import { AllHtmlEntities } from 'html-entities'
import * as Sentry from '@sentry/browser'
import events from './events'
import { apiHttp, apiCRUD } from '../../utils/apiHttp'

/**
 * @name convertToFeature
 * @description Take a geography array and converts it into a feature set for google maps
 * @param {Array} list An Array of geography data
 * @returns {Array} A new feature set array
 */
const convertToFeature = list => {
  if (compose(has('features'), first, list)) {
    return list
  }

  return list.map(({ id, boundaries, color, special_use }) => ({
    type: 'FeatureCollection',
    id,
    features: [{
      type: 'Feature',
      geometry: JSON.parse(boundaries),
      id,
      properties: {
        color: special_use ? '#ff0000' : color
      }
    }]
  }))
}

const convertToSingleFeature = geom => {
  const id = geom.id
  const boundaries = geom.boundaries
  const color = geom.color
  const special_use = geom.special_use
  return {
    type: 'FeatureCollection',
    id,
    features: [{
      type: 'Feature',
      geometry: JSON.parse(boundaries),
      id,
      properties: {
        color: special_use ? '#ff0000' : color
      }
    }]
  }
}

const store = {
  namespaced: true,
  state: {
    selectedArea: {},
    selectedGeoNote: {},
    areaList: [],
    boundaries: [],
    boundaryCollection: []
  },

  modules: {
    events
  },

  mutations: {
    /**
     * Add an element to the existing area list
     * @name addArea
     * @param {object} state
     * @param {object} area
     */
    addArea (state, area) {
      const foundIndex = state.areaList.findIndex(p => p.id === area.id)

      if (foundIndex) {
        state.areaList = remove(foundIndex, state.areaList)

        return
      }

      state.areaList.push(area)
    },

    overwriteGeoNote (state, data) {
      state.selectedGeoNote = { ...state.selectedGeoNote, ...data }
    },

    selectGeoNote (state, id) {
      const area = state.areaList.find(a => a.id === id) || {}

      if (area.permit_info) area.permit_info = AllHtmlEntities.decode(area.permit_info)
      if (area.service_area_defined) area.service_area_defined = AllHtmlEntities.decode(area.service_area_defined)
      state.selectedGeoNote = area
    },

    updateBoundaryCollection (state, data) {
      if (isEmpty(data)) {
        state.boundaryCollection = []
      } else {
        state.boundaryCollection = state.boundaryCollection.concat(convertToFeature(data))
      }
    },
    /**
     * Add a feature collection to the boundaries array
     * @param {object} state
     * @param {object} bounds - feature collection for an area
     */
    addBoundaries (state, bounds) {
      state.boundaries.push(convertToSingleFeature(bounds))
    },
    setBoundaries (state, data) {
      if (isEmpty(data)) {
        state.boundaries = []
      } else {
        state.boundaries = convertToFeature(ensureArray(data))
      }
    },

    updateSelectedGeoNote (state, val) {
      state.selectedGeoNote = { ...state.selectedGeoNote, ...val }
    },

    setAreaList (state, list) {
      state.areaList = list
    },

    updateAreaList (state) {
      state.areaList = state.areaList.reduce((acc, val) => {
        if (val.id === state.selectedGeoNote.id) {
          acc.push({ ...val, ...state.selectedGeoNote })
        } else {
          acc.push(val)
        }

        return acc
      }, [])
    },

    updateBoundaries (state, { data, currId }) {
      // In this case data is really weird on first load of a
      // Hauler in haulerSingle it is a string of coords, but if you modify the map
      // It transforms into an object. I can see where that happens
      // However I can't seem to figure out where this string is coming from?
      state.selectedGeoNote.boundaries = data

      if (!state.boundaries.length) {
        state.boundaries.push(data)
      } else {
        state.boundaries = state.boundaries.reduce((acc, val) => {
          if (val.id === currId) {
            acc.push({ ...val, ...data })
          } else {
            acc.push(val)
          }
          return acc
        }, [])
      }
    },

    resetGeometryStore (state) {
      // If not passed an empty object as the first param
      // Object.assign will mutate by reference
      Object.assign(state, {
        selectedGeoNote: {},
        areaList: [],
        boundaries: [],
        boundaryCollection: []
      })
    }
  },

  actions: {
    fetchNotes ({ commit }, idList) {
      return Promise.all(idList.map(id => apiHttp({
        method: 'GET',
        url: `/geo-note/${id}`
      })
        .then(({ data }) => data)))
        .then(noteList => {
          commit('updateBoundaryCollection', noteList)

          return noteList
        })
        .catch(e => Sentry.captureException(e))
    },

    fetchNote ({ commit }, id) {
      return apiHttp({
        method: 'GET',
        url: `/geo-note/${id}`
      })
        .then(({ data }) => {
          commit('overwriteGeoNote', data)
          commit('setBoundaries', ensureArray(data))

          return data
        })
        .catch(e => Sentry.captureException(e))
    },

    createNote ({ commit }, payload) {
      commit('events/setLoading', true)

      return apiHttp({
        method: 'POST',
        url: '/geo-notes',
        data: payload
      })
        .then(({ data }) => {
          commit('overwriteGeoNote', data)
          commit('events/runFlash', {
            message: 'Note was successfully created',
            severity: 'success',
            timeout: 2500
          });

          return data.id
        })
        .catch(e => {
          commit('events/runFlash', {
            message: 'Note could not be created',
            severity: 'error',
            timeout: -1
          });
          Sentry.captureException(e)
        })
        .finally(() => commit('events/setLoading', false))
    },

    updateNote ({ commit }, payload) {
      commit('events/setLoading', true)

      return apiHttp({
        method: 'PUT',
        url: `/geo-note/${payload.id}`,
        data: payload
      })
        .then(({ data }) => {
          commit('overwriteGeoNote', data)
          commit('events/runFlash', {
            message: 'Note was updated successfully',
            severity: 'success',
            timeout: 2500
          });
          return data.id
        })
        .catch(e => {
          commit('events/runFlash', {
            message: `Note could not be updated: ${e.message}`,
            severity: 'error',
            timeout: -1
          });
          Sentry.captureException(e)
        })
        .finally(() => commit('events/setLoading', false))
    },

    deleteNote ({ commit }, id) {
      commit('events/setLoading', true)

      return apiCRUD.delete('geo-note', id)
        .then(() => {
          commit('overwriteGeoNote', {})
          commit('events/runFlash', {
            message: 'Note was deleted successfully',
            severity: 'success',
            timeout: 2500
          })
        })
        .catch(e => {
          commit('events/runFlash', {
            message: `Note could not be deleted: ${e.message}`,
            severity: 'error',
            timeout: -1
          });
          Sentry.captureException(e)
        })
        .finally(() => commit('events/setLoading', false))
    }
  }
}

export default store
