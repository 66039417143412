<template>
  <div class="exception-card">
    <div class="topbar">
      <div class="title">
        <p class="exception-type">{{ type }} <span>{{ titleDetails }}</span></p>
        <p class="exception-reason">{{ reason }}</p>
      </div>
      <div v-show="!del" class="icons">
        <div @click="editException()" :class="{ icon: true, disabled: exception.bulk }">
          <i class="fa fa-pencil"></i>
        </div>
        <div @click="setDelete" class="icon">
          <i class="fa fa-trash"></i>
        </div>
      </div>
      <div v-show="del" class="delete-confirmation">
        <div class="confirmation container">
          <span v-if="!exception.bulk" class="confirmation">Are you sure you wish to delete this Exception?</span>
          <span v-if="exception.bulk" class="confirmation">Do you wish to exclude this BULK exception from this region?</span>
          <button class="delete-btn" type="button" id="delete" @click="handleDelete">
            <span v-if="!exception.bulk">Yes, Delete</span>
            <span v-if="exception.bulk">Yes, Remove</span>
          </button>
          <button class="cancel-btn" type="button" id="cancelDelete" @click="unsetDelete">No, Cancel</button>
        </div>
      </div>
    </div>
    <div class="fieldset">
      <div class="field">
        <span class="bold">Zones: </span>
        <span class="field">{{ zones }}</span>
      </div>
      <div class="field">
        <span class="bold">Service Types: </span>
        <span class="field">{{ serviceTypes }}</span>
      </div>
      <div class="field">
        <span class="bold">Sizes: </span>
        <span class="field">{{ sizes }}</span>
      </div>
      <div class="field">
        <span class="bold">Waste Types: </span>
        <span class="field">{{ wasteTypes }}</span>
      </div>
    </div>
    <div class="fieldset">
      <div class="field">
        <span class="bold">{{actionType}} </span>
        <span class="field" v-show="exception.action_type !== 'permanent'">{{ actionDate }}</span>
      </div>
    </div>
    <div class="footer">
      <div><span class="bold">Created By:</span> <span>{{exception.created_by.name}}</span></div>
      <div><span class="bold">Created At:</span> <span>{{exception.created_at}}</span></div>
      <div><span class="bold">Last Updated By:</span> <span>{{exception.updated_by.name}}</span></div>
      <div><span class="bold">Last Updated At:</span> <span>{{exception.updated_at}}</span></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { daysOfWeek, format, formatRange } from '../utils/time'

export default {
  name: 'ExceptionCard',
  props: {
    exception: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      del: false
    }
  },
  methods: {
    ...mapActions('exceptions', ['getRegionPricing', 'deleteException', 'excludeBulkException', 'getExceptions']),
    ...mapMutations('exceptions', ['setSelectedException']),
    editException () {
      if (this.exception.bulk) {
        // not allowed
        return
      }

      this.setSelectedException(this.exception)
      this.$emit('edit', {
        value: true,
        data: this.exception
      })
    },
    setDelete () {
      this.del = true
    },
    unsetDelete () {
      this.del = false
    },
    handleDelete () {
      this.del = false

      if (!this.exception.bulk) {
        this.deleteException(this.exception.id)
          .then(() => {
            this.$emit('deleted')
          })
      } else {
        this.excludeBulkException({ exceptionId: this.exception.id, regionId: this.selectedRegion.id })
          .then((success) => {
            if (success) {
              this.$emit('deleted')
            }
          })
      }
    }
  },
  computed: {
    ...mapState('exceptions', ['region']),
    ...mapState('regions', ['selectedRegion']),
    ...mapGetters('exceptions', ['getZoneDictionary', 'getSizeDictionary', 'getWasteTypeDictionary', 'getServiceTypeDictionary', 'getTypeDictionary', 'getReasonDictionary', 'getActionTypeDictionary', 'getAvailabilityActionTypeDictionary']),
    zones () {
      if (this.exception.service_areas === '') {
        return 'All'
      }
      const areaIds = this.exception.service_areas.split(',').map((item) => {
        return this.getZoneDictionary[item]
      })

      return areaIds.join(', ')
    },
    sizes () {
      if (this.exception.sizes === '') {
        return 'All'
      }

      const sizeIds = this.exception.sizes.split(',').map((item) => {
        return this.getSizeDictionary[item]
      })

      return sizeIds.join(', ')
    },
    wasteTypes () {
      if (this.exception.waste_types === '') {
        return 'All'
      }

      const wasteTypeIds = this.exception.waste_types.split(',').map((item) => {
        return this.getWasteTypeDictionary[item]
      })

      return wasteTypeIds.join(', ')
    },
    serviceTypes () {
      const types = this.exception.delivery_type.split(',').map((item) => {
        return this.getServiceTypeDictionary[item]
      })
      return types.join(', ')
    },
    titleDetails () {
      if (['availabilityByDate', 'closure'].includes(this.exception.type)) {
        return formatRange(this.exception.start_date, this.exception.end_date, 'MM/DD/YYYY')
      }
      if (this.exception.type === 'dayOfWeek') {
        return daysOfWeek(this.exception.days_of_week).map((day) => {
          return day.name
        }).join(', ')
      }
      if (this.exception.type === 'serviceArea') {
        return this.exception.boundaries_description
      }
      if (this.exception.type === 'window') {
        return '1 - ' + this.exception.window_days + ' days'
      }

      switch (this.exception.availability_action) {
        case 'mustCall':
          return this.getAvailabilityActionTypeDictionary[this.exception.availability_action]

        case 'nextAvailableDate':
          return this.exception.next_available_date
            ? format(this.exception.next_available_date, 'MM/DD/YYYY')
            : ''

        default:
          return ''
      }
    },
    type () {
      return this.getTypeDictionary[this.exception.type] || this.exception.type
    },
    reason () {
      return this.getReasonDictionary[this.exception.reason] || this.exception.reason
    },
    actionType () {
      return this.getActionTypeDictionary[this.exception.action_type] || this.exception.action_type
    },
    actionDate () {
      const fmt = d => (d ? format(d, 'MM/DD/YYYY') : null)
      return fmt(this.exception.action_date)
    }
  },
  mounted () {
    this.getRegionPricing(this.selectedRegion.id)
  }
}
</script>
<style lang="scss">
  @import 'src/styles/base/_variables.scss';
  div.exception-card {
    border: 1px solid #ccc;
    margin: 10px 0;
    width: 100%;
    div.fieldset {
      margin: 0 5px 0 5px;
      padding: 10px 0;
      border-top: 1px solid #ccc;
    }
    p.exception-type {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      padding: 0;

      span {
        font-weight: normal;
      }
    }
    p.exception-reason {
      font-size: 16px;
      font-style: italic;
    }
    span.bold {
      font-weight: bold;
    }
  }
  div.topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    div.title {
      margin: 10px;
      p {
        margin: 0 auto;
        padding: 0;
      }
    }
    div.delete-confirmation {
      width: 70% ;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      text-align: right;
      .confirmation {
        button {
          border: 1px solid #ccc;
          padding: 2px;
          margin: 0 2px;
        }
        .delete-btn {
          background-color: red;
          color: white;
        }
        .cancel-btn {
          background-color: #fff;
          color: red;
        }
      }
    }
    div.icons {
      padding: 0 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      div {
        border: 1px solid #ddd;
        background-color: #ededed;
        padding: 8px;
        cursor: pointer;
        font-size: 18px;
      }
      div.disabled {
        cursor: not-allowed;
        i {
          color: $gray;
        }
      }
    }
  }
  button.delete, button.cancel {
    display: inline;
  }
  .footer {
    padding: .5em;
    font-size: $font-small;
    background-color: $lighter-gray;
    border: 1px solid $light-gray;

    div {
      display: inline-block;
      margin: auto .5em;
    }
  }
</style>
