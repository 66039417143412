<template>
  <div class="mt container">
    <div class="row">
      <div class="search-top sm-6">
        <input
          type="text"
          v-model="searchValue"
          v-debounce:400ms="haulerSearch"
          placeholder="Search By Hauler"
        />
      </div>
    </div>
    <div class="row search-filter">
      <div class="col sm 3 filter-dropdown">
        <label for="territory">Filter By Territory</label>
        <select class="browser-default"
          id="territory"
          v-model="territoryValue"
          @change="territoryFilter($event.target.value)"
        >
          <option disabled value='' selected>pick one</option>
          <option value='null' selected>All</option>
          <option v-for="territory in territories" :key="territory.value" :value="territory.name">
            {{ territory.name }}
          </option>
        </select>
      </div>
      <div class="col 3 sm filter-dropdown">
        <label for="exceptionType">Exception Type:</label>
        <select class="browser-default"
          id="exceptionType"
          v-model="exceptionTypeValue"
          @change="handleExceptionTypes($event.target.value, 'exceptionTypeValue')"
        >
          <option disabled value='' selected>pick one</option>
          <option value='null'>All</option>
          <option v-for="name, type in getTypeDictionary()" :key="type" :value="type">{{name}}</option>
        </select>
      </div>
      <div class="col 3 sm filter-dropdown">
        <label for="exceptionReason">Exception Reason:</label>
        <select class="browser-default"
          id="exceptionReason"
          v-model="exceptionReasonValue"
          @change="handleExceptionReason($event.target.value, 'exceptionReasonValue')"
        >
          <option disabled value=''>pick one</option>
          <option value='null' selected>All</option>
          <option v-for="name, key in getReasonDictionary()" :key="key" :value="key">{{name}}</option>
        </select>
      </div>
      <div class="col 3 sm filter-dropdown">
        <label for="actionType">End Type:</label>
        <select class="browser-default"
          id="actionType"
          v-model="actionTypeValue"
          @change="handleActionType($event.target.value, 'actionTypeValue')"
        >
          <option disabled value=''>pick one</option>
          <option value='null' selected>All</option>
          <option v-for="name, type in getActionTypeDictionary()" :key="type" :value="type">{{name}}</option>
        </select>
      </div>
      <div class="col 3 sm filter-dropdown">
        <button class="hollow-btn--smol reset" @click="resetFilters">Reset Filters</button>
      </div>
    </div>
    <exception-table
      :exceptions="sortedExceptions"
      :loading="loading"
      @sorted='(value) => updateSort(value.field, value.sortDir)'
    />
    <transition name="fade" mode="out-in">
      <div class="row">
        <paginate
          v-show="totalPages"
          :model='""'
          :totalPages="totalPages"
          @pageChange="pageChange" />
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import ExceptionTable from '../components/ExceptionTable'
import Paginate from '../components/PaginateCollection'

export default {
  name: 'ExceptionCollectionHauler',
  components: {
    ExceptionTable,
    Paginate
  },
  data () {
    return {
      searchPayload: {
        search_type: 'exclusive',
        sort: 'action_date',
        sort_order: 'asc',
        page: 1,
        per_page: 25,
        field: null,
        operator: null,
        value: null,
        conditions: [{ field: 'bulk', value: '' }]
      },
      filtersSet: [],
      searchType: '',
      searchValue: '',
      territory: '',
      territoryValue: '',
      exceptionTypeValue: '',
      exceptionReasonValue: '',
      actionTypeValue: '',
      totalPages: 0,
      currentPage: 1,
      sortField: 'action_date',
      sortDir: 'asc',
      territories: [
        {
          name: 'Midwest',
          value: 'Midwest'
        }, {
          name: 'Southeast',
          value: 'Southeast'
        }, {
          name: 'Northeast',
          value: 'Northeast'
        }, {
          name: 'West',
          value: 'West'
        }
      ]
    }
  },
  computed: {
    ...mapState('events', ['loading']),
    ...mapState('exceptions', ['exceptions']),
    sortedExceptions () {
      const exceptions = [...this.exceptions]
      exceptions.forEach(exception => {
        this.$set(exception, 'toggleVisibility', false)
      })
      return exceptions
    }
  },
  methods: {
    ...mapMutations('events', ['setLoading']),
    ...mapActions('exceptions', ['getAllExceptions', 'searchExceptions']),
    ...mapGetters('exceptions', ['getActionTypeDictionary', 'getReasonDictionary', 'getTypeDictionary']),
    resetFilters () {
      this.exceptionTypeValue = 'null'
      this.exceptionReasonValue = 'null'
      this.territoryValue = 'null'
      this.actionTypeValue = 'null'
      this.searchValue = ''
      this.searchPayload.conditions = [{ field: 'bulk', value: '' }]
      this.resetSearchValue()
      this.search()
    },
    resetSearchValue () {
      this.searchPayload.field = null
      this.searchPayload.value = null
      this.searchPayload.operator = null
    },
    handleActionType (val, model) {
      const conditions = [...this.searchPayload.conditions]
      this.searchPayload.conditions = conditions.filter((condition) => condition.field !== 'action_type')
      if (val === 'null') {
        this[model] = val
      } else {
        this.searchPayload.conditions.push({
          field: 'action_type',
          value: val
        })
      }
      this.search()
    },
    handleExceptionReason (val, model) {
      const conditions = [...this.searchPayload.conditions]
      this.searchPayload.conditions = conditions.filter((condition) => condition.field !== 'reason')
      if (val === 'null') {
        this[model] = val
      } else {
        this.searchPayload.conditions.push({
          field: 'reason',
          value: val
        })
      }
      this.search()
    },
    handleExceptionTypes (val, model) {
      const conditions = [...this.searchPayload.conditions]
      this.searchPayload.conditions = conditions.filter((condition) => condition.field !== 'type')
      if (val === 'null') {
        this.searchType = ''
      } else {
        this.searchPayload.conditions.push({
          field: 'type',
          value: val
        })
      }
      this.search()
    },
    territoryFilter (val) {
      const conditions = [...this.searchPayload.conditions]
      this.searchPayload.conditions = conditions.filter((condition) => condition.field !== 'territory')
      if (val !== 'null') {
        this.territoryValue = val
        this.searchPayload.conditions.push({
          field: 'territory',
          value: val
        })
      } else {
        this.territoryValue = val
      }

      this.search()
    },
    updateSort (field, sortDir) {
      this.sortField = field
      this.sortDir = sortDir
      this.searchPayload.sort = this.sortField
      this.searchPayload.sort_order = this.sortDir
      this.search()
    },
    haulerSearch () {
      if (this.searchValue.length && this.searchValue.length < 3) {
        return
      }

      this.filtersSet = []
      this.searchType = 'hauler_name'

      this.resetSearchValue()
      if (this.searchValue.length) {
        this.searchPayload.field = 'hauler_name'
        this.searchPayload.operator = 'ilike'
        this.searchPayload.value = this.searchValue
      }

      this.search()
    },
    pageChange (page) {
      this.searchPayload.page = page
      this.search()
    },
    search () {
      this.setLoading(true)
      this.searchExceptions(this.searchPayload).then((result) => {
        this.totalPages = result.total_pages
        this.setLoading(false)
      })
    },
    setFilters (e) {
    }
  },
  mounted () {
    this.updateSort(this.sortField, this.sortDir)
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

.search-filter {
  position: relative;
  margin-bottom: 40px;

  .col {
    padding-left: 0;
  }
}

.search {
  position: absolute;
  width: 338px;
  bottom: 0;
  right: 0;
  input {
    margin-bottom: 0 !important;
  }
}

.service-filters {
  display: flex;
  margin-top: 21px;
  .material-icons {
    font-size: 16px;
  }
  .filter {
    cursor: pointer;
    border: 1px solid #ededed;
    padding: 10px 12px;
    border-radius: 15px;
    margin-right: 15px;
  }
  .selected {
    background-color: #007e80;
    color: #fff;
  }
}
.filter-dropdown {
  width: 200px;
}
.instructions {
  font-size: 0.8rem;
  color: #9e9e9e;
  font-style: italic;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.search-top {
  width: 40%;
}
.title {
  width: 100%;
}
button.reset {
  margin-top:23px;
}
</style>
