/* eslint no-undef: 0 */
import { isEmpty, isNil, prop, path } from 'kyanite'
import { dynamicFeatureStyling, createPolylineArrayFromGeoJsonCoordinates } from '../../utils/maps'

const defaultZoom = 10
// this will just hold any/all removed features from the feature collection
// if this is added in state things go sideways quickly because these are large objects
// that vuex does.not.like
let removedFeatures = []

const store = {
  namespaced: true,
  state: {
    // we will need to toggle layer colors on/off at some points
    colorsVisible: true,
    // an array of feature ids that are currently not visible (only turns off styles)
    hiddenFeatures: [],
    // an array of feature objects removed from the map (actually removed from map instance)
    // removedFeatures: [],
    // the id of the hovered service area to highlight
    lastHover: '',
    // instance of google.maps.Map tied to a component DOM $ref
    map: new google.maps.Map(document.createElement('div')),
    // instace of google.maps.Marker tied to state.map
    marker: new google.maps.Marker(),
    // is the picture in picture mini map visible?
    pipActive: false,
    // is the picture in picture mini map opened or closed?
    pipOpen: false,
    // track all of the polygons added independently of the data layer (polylines for dashes)
    polygons: { id: [] }
  },
  getters: {},
  mutations: {
    addPolygon: (state, { id, polygon }) => {
      // add the polygon to the map instance
      polygon.setMap(state.map)
      // if the id isn't already in state create a new property with it's own array
      if (!prop(id, state.polygons)) state.polygons[id] = []
      // track it through the polygons object in state by the given id
      state.polygons[id].push(polygon)
    },
    clearRemovedFeatures: () => { removedFeatures = [] },
    hideFeature: (state, id) => { state.hiddenFeatures.push(id) },
    unHideFeature: (state, id) => { state.hiddenFeatures.splice(state.hiddenFeatures.indexOf(id), 1) },
    setLastHover: (state, id) => { state.lastHover = id },
    setMap: (state, map) => { state.map = map },
    setMarker: (state, marker) => { state.marker = marker },
    toggleColorsVisible: state => { state.colorsVisible = !state.colorsVisible },
    togglePipActive: state => { state.pipActive = !state.pipActive },
    togglePipOpen: state => { state.pipOpen = !state.pipOpen }
  },
  actions: {
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name addFeatureToCollection
     * @description add an individual feature to the existing feature collection
     * @param {object} state
     * @param {string} id a unique identifier for the feature layer
     * @param {object} geometry type of Polygon or Multipolygon etc.
     * @param {object} styles https://developers.google.com/maps/documentation/javascript/reference/data#Data.StyleOptions
     * @returns {void}
     */
    addFeatureToCollection: ({ state }, { properties, geometry, styles }) => {
      // if geometry isn't supplied don't waste any time
      if (isEmpty(geometry)) throw new Error(`No geometry provided for feature ${properties.id}`)
      const [feature] = state.map.data.addGeoJson({
        type: 'Feature',
        geometry,
        properties
      }, { idPropertyName: 'id' })
      // override the global styles for this feature only
      state.map.data.overrideStyle(feature, styles)
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name addPolylineToMap
     * @description accepts plain coordinates, converts them into latLng instances, flattens the array and creates a custom polyline polygon to be applied to the map instance
     * @param {function} context.commit
     * @param {array} obj.coordinates the coordinates to be used in the path definition
     * @param {string} obj.id an identifier for the polygon
     * @param {object} obj.style any styles that should be applied to the polyline
     */
    addPolylineToMap: ({ commit }, { coordinates, id, styles }) => {
      const polygons = createPolylineArrayFromGeoJsonCoordinates(coordinates, styles)
      polygons.forEach(polygon => commit('addPolygon', { id, polygon }))
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name toggleFeatureVisibility
     * @description toggle if a specific layer is hidden/visible on the map
     * @param {object} context.state
     * @param {string} id the id of layer to toggle (typically service area id, msa, locale)
     */
    toggleFeatureVisibility: ({ state, dispatch }, id) => {
      // check if it's already hidden
      const hidden = state.hiddenFeatures.includes(id)
      // get the feature from the map
      const feature = state.map.data.getFeatureById(id)
      // this could be 'undefined' from a gmaps hover listener
      if (isEmpty(feature)) return
      // revert to standard styles (passing null as vals will revert automatically)
      if (hidden) {
        dispatch('showFeature', id)
        return
      }
      dispatch('hideFeature', id)
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name hideFeature
     * @description turn off visual styles for a given feature
     * @param {object} context.state
     * @param {function} context.commit
     * @param {string} id the id of a feature on the map
     * @returns {void}
     */
    hideFeature: ({ state, commit }, id) => {
      const feature = state.map.data.getFeatureById(id)
      commit('hideFeature', id)
      state.map.data.overrideStyle(feature, { visible: false })
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name hidePolygon
     * @description hide a polygon from the map - its annoying to duplicate this functionality but these are handled separately from map features in the data layer
     * @param {object} context.state
     * @param {string} id the id of the polygon to hide on the map (should be an object key in state)
     */
    hidePolygon: ({ state }, id) => {
      // check to see if there is a polygon for this id
      if (prop(id, state.polygons)) {
        state.polygons[id].forEach(p => p.setMap(null))
      }
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name showPolygon
     * @description show a polygon on the map - its annoying to duplicate this functionality but these are handled separately from map features in the data layer
     * @param {object} context.state
     * @param {string} id the id of the polygon to show on the map (should be an object key in state)
     */
    showPolygon: ({ state }, id) => {
      if (prop(id, state.polygons)) {
        state.polygons[id].forEach(p => p.setMap(state.map))
      }
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name showFeature
     * @description turn on visual styles for a given feature
     * @param {object} context.state
     * @param {function} context.commit
     * @param {string} id the id of a feature on the map
     * @returns {void}
     */
    showFeature: ({ state, commit }, id) => {
      const feature = state.map.data.getFeatureById(id)
      commit('unHideFeature', id)
      state.map.data.overrideStyle(feature, { visible: true })
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name toggleMarker
     * @description toggle the visibility of the 'pin' marker
     * @param {object} context.state
     * @returns {void}
     */
    toggleMarker: ({ state }, visible = null) => {
      // indicates no visibility is passed in so just do the inverse of current val
      if (isNil(visible)) return state.marker.setVisible(!state.marker.getVisible())
      // otherwise apply the passed in visibility
      return state.marker.setVisible(visible)
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name removeAllFeaturesFromCollection
     * @description clear out all of the current features on the map
     * @param {object} context.state
     * @param {object} context.dispatch
     * @returns {void}
     */
    removeAllFeaturesFromCollection: ({ state, dispatch }) => {
      state.map.data.forEach(feature => {
        dispatch('removeFeatureFromCollection', feature.getId())
      })
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name removeFeature
     * @description totally remove a feature from the map
     * @param {object} context.state
     * @param {function} context.commit
     * @param {string} id the id of a feature on the map
     * @returns {object} the feature that was removed
     */
    removeFeatureFromCollection: ({ state }, id) => {
      // check if the id represents an already removed feature
      const removed = removedFeatures.find(f => f.getId() === id)
      // if no id is supplied or if it was alread removed return early
      if (isEmpty(id) || !isEmpty(removed)) return
      // get the feature from the current map instance
      const feature = state.map.data.getFeatureById(id)
      // there is a listener on the map instance that will push this feature to state.removedFeatures array
      // it was the only way I could find to track removals as the remove method does not return the removed feature
      if (!isEmpty(feature)) {
        state.map.data.remove(feature)
      }
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name restoreFeatureToCollection
     * @description restore a previously removed feature - must be in state.removedFeatures
     * @param {object} context.state
     * @param {function} context.commit
     * @returns {void}
     */
    restoreFeatureToCollection: ({ state }, id) => {
      // check if the id represents a removed feature (will return the id or undefined)
      const feature = removedFeatures.find(f => f.getId() === id)
      // if feature has not been removed or the id not given return early
      if (isEmpty(id) || isEmpty(feature)) return
      // remove the feature from the log of removed features
      removedFeatures.splice(removedFeatures.findIndex(f => f.getId() === id), 1)
      // add the feature back into the data layer
      state.map.data.add(feature)
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name toggleHover
     * @description accepts a service area id to apply hover styling to. Exclude id to clear
     * @param {object} context.state
     * @param {string} id service area id
     * @returns {void}
     */
    toggleHover: ({ state, commit }, id = '') => {
      // if the map doesn't have a feature for this id just eject
      if (!state.map.data.getFeatureById(id)) return
      // clear last
      // TODO - this needs to be cleaned up
      // the problem I'm running into is too much variability in what is available
      // in the feature collection at any given point (filters etc.)
      if (!isEmpty(state.lastHover) && !isNil(state.lastHover) && state.map.data.getFeatureById(state.lastHover)) {
        const last = state.map.data.getFeatureById(state.lastHover)
        const { standard } = last.getProperty('styles')
        state.map.data.overrideStyle(last, standard)
      }
      // only track hover ons
      commit('setLastHover', id)
      const feature = state.map.data.getFeatureById(state.lastHover)
      // an empty id is a hover off
      if (isEmpty(feature)) return
      const { hover } = feature.getProperty('styles')
      state.map.data.overrideStyle(feature, hover)
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name createMap
     * @description create a new instance of a google map with a passed in DOM ref
     * @param {object} context.commit access to mutations
     * @param {object} obj.ref a DOM ref passed in from a component
     * @param {object} obj.centroid optional - a lat/lng combo to center the map on
     * @returns {void}
     */
    createMap: ({ commit, dispatch, state }, { ref, centroid }) => {
      // trying to copy the existing div afer set up just doesn't work for poo so create a new one
      commit('setMap', new google.maps.Map(ref))
      // assign the passed in value or fall back to default
      const { latitude, longitude } = {
        latitude: 37.0902, longitude: -95.7129, ...centroid
      }
      // setup map options
      state.map.setOptions({
        center: new google.maps.LatLng(latitude, longitude),
        // defaults are up top
        zoom: defaultZoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        panControl: true,
        streetViewControl: true,
        zoomControl: true
      })

      // set marker for the map
      state.marker.setMap(state.map)
      state.marker.setOptions({ draggable: true })

      // listen for leaving the map completely
      state.map.addListener('mouseout', () => dispatch('toggleHover'))

      // pass the feature(s) to the styling function for application
      state.map.data.setStyle(dynamicFeatureStyling)

      // listen for features being removed so we can log them just in case we need them (aka filtering)
      state.map.data.addListener('removefeature', ({ feature }) => removedFeatures.push(feature))

      // listen for hovers directly on the map features (does not work for polygons)
      state.map.data.addListener('mouseover', e => {
        // if hover styling is not found in the properties just move on
        if (isEmpty(e.feature.getProperty('styles'))) return
        // mousemove represents a state in which another feature is actively hovered
        // so clear the last hover before activating the new
        if (path(['wa', 'type'], e) && e.wa.type === 'mousemove') dispatch('toggleHover')
        dispatch('toggleHover', e.feature.getId())
      })

      // listen for zoom events
      // we need to toggle the colors off for close up zooms (for overhead views of property)
      google.maps.event.addListener(state.map, 'zoom_changed', () => dispatch('handleZoomOpacity'))
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name setCentroid
     * @description set the center of the visible map based on our 'centroid' from map api
     * @param {object} state
     * @param {object} obj.latitude
     * @param {object} obj.longitude
     * @returns {void}
     */
    setCentroid: ({ state }, { latitude, longitude }) => {
      const position = new google.maps.LatLng(latitude, longitude)
      // center the map
      state.map.setCenter(position)
      // drop the pin
      state.marker.setPosition(position)
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name enableStreetView
     * @description get the streetview panorama, set click listener, notify user of streetview
     * @param {object} context.state
     * @returns {void}
     */
    enableStreetView: ({ state }) => {
      const streetViewService = new google.maps.StreetViewService()
      streetViewService.getPanoramaByLocation(state.marker.getPosition(), 50, (data, status) => {
        if (status === 'OK') {
          // calculate the heading from the pano pov to the location
          const heading = google.maps.geometry.spherical.computeHeading(
            data.location.latLng,
            state.marker.getPosition()
          )
          // set up street view for this position
          const streetViewPanorama = state.map.getStreetView()
          // make sure users can see how old the image is (if available)
          streetViewPanorama.setOptions({
            imageDateControl: true
          })
          // set the position to the marker
          streetViewPanorama.setPosition(state.marker.getPosition())
          // set the pov based on the calculated heading
          streetViewPanorama.setPov({
            heading,
            zoom: 1,
            pitch: 0
          })
          // attach listener for pin click
          state.marker.addListener('click', () => streetViewPanorama.setVisible(true))
          // update label to indicate street view
          state.marker.setLabel('SV')
        }
      })
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name disableStreetView
     * @description reset the streetview panorama to original binding and removes marker changes
     * @param {object} context.state
     * @returns {void}
     */
    disableStreetView: ({ state }) => {
      // get the bound street view
      const streetViewPanorama = state.map.getStreetView()
      // toggle off visibility
      streetViewPanorama.setVisible(false)
      // clear it
      state.map.setStreetView(null)
      // this may revert the label to the original??
      state.marker.setLabel(null)
      // remove the click listener for street view
      google.maps.event.clearListeners(state.marker, 'click')
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name handleZoomOpacity
     * @description will set the layer opacity based on the current zoom level
     * @param {object} context.state
     * @param {object} context.commit
     * @returns {void}
     */
    handleZoomOpacity: ({ state, commit, dispatch }) => {
      const zoom = state.map.getZoom()
      // arbiratry pick of 18
      if (zoom >= 18 && state.colorsVisible) {
        commit('toggleColorsVisible')
        state.map.data.forEach(feature => {
          dispatch('toggleFeatureVisibility', feature.getId())
        })
      }
      if (zoom <= 18 && !state.colorsVisible) {
        commit('toggleColorsVisible')
        state.map.data.forEach(feature => {
          dispatch('toggleFeatureVisibility', feature.getId())
        })
      }
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name resetZoomLevel
     * @description reset the map instance zoom level to default
     * @param {object} conext.state
     * @returns {void}
     * NOTE - the default values are up top
     */
    resetZoomLevel: ({ state }) => state.map.setZoom(defaultZoom)
  }
}

export default store
