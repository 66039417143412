/* eslint no-shadow: 0 */
import * as Sentry from '@sentry/browser'
import { intersection, isEmpty, deepEq, find } from 'kyanite'
import { AllHtmlEntities } from 'html-entities'
import { geocode } from '../../services/googleMapsQuery'
import { defaultLayerStyles, colors } from '../../config/colors'
import map from './map'
import time from './time'
import geometry from './geometry'
import events from './events'
import { apiHttp } from '../../utils/apiHttp'
import { convertCollectionToMultiPolygon } from '../../utils/maps'

/**
 * @author Justin Voelkel <justin@budgetdumpster.com>
 * @name generateSearchSessionId
 * @description Creates a unique string to identify the search session in logs
 * @returns {string} the id string
 */
const generateSearchSessionId = () => {
  const rando = () => Math.random().toString(36).substring(2, 15)
  return `${rando()}${rando()}`
}

/**
 * @author Justin Voelkel <justin@budgetdumpster.com>
 * @name filterCityStateCountyZip
 * @description take in all of the address components and return an object
 * of only the necessary long names keyed by their type
 * @param {array} components The places api response address components
 * @param {string} type the type of data you want back [city, state, county, streetNumber, zipcode]
 * @returns {object}
 */
const filterComponentsByType = (components, type) => {
  // the anticipated pieces and their gmaps type(s)
  const accepted = {
    // locality covers townships (EX olmsted twp is ONLY a locality)
    number: ['street_number'],
    street: ['route'],
    city: ['locality', 'administrative_area_level_3'],
    state: ['administrative_area_level_1'],
    county: ['administrative_area_level_2'],
    zipcode: ['postal_code']
  }
  if (!Object.keys(accepted).includes(type)) throw new Error(`${type} is not an accepted type`)
  const component = find(c => intersection(accepted[type], c.types).length, components)
  return component ? component.long_name : ''
}

// what place types should offer street view?
const streetViewTypes = ['premise', 'street_address']

// we'll be resetting the bias at certain points
// so it's nice to have this for quick resets
// NOTE: google likes the lat/lng property names - not my choice
const initialBias = {
  lat: 0,
  lng: 0
}

const initialState = () => ({
  // the user inputted query string
  query: '',
  // places api normalized response properties
  placeId: '',
  placeFormattedAddress: '',
  placeAddressComponents: [],
  placeGeometry: {
    latitude: 0,
    longitude: 0
  },
  placeTypes: [],
  // any applicable search bias
  bias: { ...initialBias },
  // the market (MSA) the map api search falls into
  searchMarket: {},
  // if the map api search is a city, zip, or county the geo bounds of it
  searchLocale: {},
  // search results from the map api
  searchResults: [],
  // any search result service areas marked as 'special use'
  specialUseResults: [],
  // an array of geo notes associated to the search area
  searchGeoNotes: [],
  // optional
  buffer: 0,
  // unique id that will be applied to each search session - for logging
  searchSessionId: '',
  searchId: ''
})

const search = {
  namespaced: true,
  modules: {
    map,
    time,
    geometry,
    events
  },
  state: initialState(),
  mutations: {
    resetSearch: state => {
      Object.assign(state, initialState())
    },
    setBias: (state, bias) => { state.bias = bias },
    setBuffer: (state, buffer) => { state.buffer = buffer },
    setPlaceId: (state, id) => { state.placeId = id },
    setPlaceFormattedAddress: (state, address) => { state.placeFormattedAddress = address },
    setPlaceAddressComponents: (state, arr) => { state.placeAddressComponents = arr },
    setPlaceGeometry: (state, { lat, lng }) => {
      state.placeGeometry.latitude = lat()
      state.placeGeometry.longitude = lng()
    },
    setPlaceTypes: (state, types) => { state.placeTypes = types },
    // these are from our API results
    setSearchResults: (state, data) => { state.searchResults = data },
    setSearchMarket: (state, data) => { state.searchMarket = data },
    setSearchLocale: (state, data) => { state.searchLocale = data },
    setSpecialUseResults: (state, data) => { state.specialUseResults = data },
    setSearchGeoNotes: (state, arr) => { state.searchGeoNotes = arr },
    setSearchSessionId: state => { state.searchSessionId = generateSearchSessionId() },
    setSearchId: (state, data) => { state.searchId = data }
  },
  getters: {
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name encodeURIComponent
     * @description return a URI encoded version of the formatted address to use in the
     * external links to gmaps and bing maps
     * @param {object} state
     * @returns {string}
     */
    encodedSearchString: state => encodeURIComponent(state.placeFormattedAddress),
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchLocaleBoundaries
     * @description return the parsed geojson for the locale boundaries
     * @param {object} state
     * @returns {object}
     */
    searchLocaleBoundaries: state => (isEmpty(state.searchLocale) ? {} : JSON.parse(state.searchLocale.the_geom)),
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchResultsBoundaries
     * @description return an array of the parsed geojson for all service areas that
     * are NOT special use
     * @param {object} state
     * @returns {array}
     */
    searchResultsBoundaries: state => state.searchResults
      .filter(r => r.hauler.type !== 'outOfNetwork')
      .map(r => ({ geometry: JSON.parse(r.boundaries), id: r.id })) || [],
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchResultsIds
     * @description returns an array of the search result service area ids
     * @param {object} state
     * @returns {array}
     */
    searchResultsIds: state => state.searchResults.map(n => n.id),
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchMarketBoundaries
     * @description return the parsed geojson for the market boundaries
     * @param {object} state
     * @returns {object}
     */
    searchMarketBoundaries: state => (isEmpty(state.searchMarket) ? {} : JSON.parse(state.searchMarket.boundaries)),
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchGeoNoteboundaries
     * @description return the parse geojson for any geo note boundaries - this must also handle the potential for a geometry collection to be passed into it
     * @param {object} state
     * @returns {array}
     */
    searchGeoNoteBoundaries: state => state.searchGeoNotes.map(n => ({
      geometry: isEmpty(n.boundaries) ? {} : JSON.parse(n.boundaries),
      id: n.id,
      color: n.color
    })),
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchGeoNoteIds
     * @description returns an array of the geo note service area ids
     * @param {object} state
     * @returns {array}
     */
    searchGeoNoteIds: state => state.searchGeoNotes.map(n => n.id),
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchSpecialUseBoundaries
     * @description any service areas that ARE special use
     * @param {object} state
     * @returns {array}
     */
    searchSpecialUseBoundaries: state => state.specialUseResults
      .map(r => ({ geometry: JSON.parse(r.boundaries), id: r.id })),
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchSpecialUseIds
     * @description returns an array of the special use service area ids
     * @param {object} state
     * @returns {array}
     */
    searchSpecialUseIds: state => state.specialUseResults.map(r => r.id),
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name searchIsBiased
     * @description if the value is different from the init unbiased value return true
     * @param {object} state
     * @returns {boolean}
     */
    searchIsBiased: state => !deepEq(state.bias, initialBias),
    // does this search have geo notes
    hasNotes: state => !isEmpty(state.searchGeoNotes),
    mergedNoteables: state => (Array.isArray(state.searchGeoNotes)
      ? state.searchGeoNotes.concat(state.specialUseResults) : state.specialUseResults),
    searchId: state => state.searchId
  },
  actions: {
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name performGooglePlacesLookup
     * @description accepts a valid gmaps places id and performs a geocode lookup
     * will set/reset bias based on conditions
     * @param {function} context.commit commit a mutation
     * @param {string} obj.format what format are you searching geocode for (placeId, location, etc.)
     * @param {*} obj.val what value are you searching for (placeId string, lat/lng instance, etc.)
     * @returns {void}
     */
    performGooglePlacesLookup ({ commit }, { format, val }) {
      return geocode(format, val)
        .then(response => {
          const [result] = response
          commit('setPlaceId', result.place_id)
          commit('setPlaceFormattedAddress', result.formatted_address)
          commit('setPlaceAddressComponents', result.address_components)
          commit('setPlaceTypes', result.types)
          commit('setPlaceGeometry', result.geometry.location)
          // if search was a zip code set biasing for next search
          if (result.types.includes('postal_code')) {
          // this is dumb but I didn't decide on lat/lng vs latitude/longitude early enough
            const { lat, lng } = result.geometry.location
            commit('setBias', { lat: lat(), lng: lng() })
          } else {
          // if it was not a zip reset the bias
            commit('setBias', { ...initialBias })
          }
        })
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name performMapApiSearch
     * @description make a call to our map api and perform a search based on the
     * google places response
     * @param {object} context.commit
     * @param {object} context.state
     * @returns {void}
     */
    performMapApiSearch ({ commit, state, dispatch }) {
      // attach a unique id to this search for logging
      commit('setSearchSessionId')
      // set up local clock and run it
      dispatch('time/runLocalTimeClock', {
        lat: state.placeGeometry.latitude,
        lng: state.placeGeometry.longitude
      })

      return apiHttp.post('/search', {
        address: filterComponentsByType(state.placeAddressComponents, 'number') + ' ' + filterComponentsByType(state.placeAddressComponents, 'street'),
        city: filterComponentsByType(state.placeAddressComponents, 'city'),
        state: filterComponentsByType(state.placeAddressComponents, 'state'),
        latitude: state.placeGeometry.latitude,
        longitude: state.placeGeometry.longitude,
        zip_code: filterComponentsByType(state.placeAddressComponents, 'zipcode'),
        buffer: state.buffer,
        type: state.placeTypes[0],
        place_id: state.placeId
      })
        .then(({ data }) => {
          // TODO - this is due to a bug in the api
          // when the api search endpoint excludes inactive results this can be removed
          const active = data.search_results.filter(r => r.active)
          commit('setSearchResults', active.filter(r => !r.special_use))
          commit('setSearchMarket', data.market || {})
          commit('setSearchLocale', data.locale || {})
          commit('setSearchId', data.id || '')
          commit('setSpecialUseResults', active.filter(r => r.special_use) || [])
          return dispatch('geometry/fetchNotes', data.geo_notes)
        })
        .then(notes => {
          // make sure this is valid before we assign it to state
          // also initial data import includes random html entities so we have to decode
          const val = isEmpty(notes) ? [] : notes.map(note => {
            note.description = AllHtmlEntities.decode(note.description)
            return note
          })
          commit('setSearchGeoNotes', val)
        })
        .catch(e => {
          // clear the search data to avoid confusion
          commit('resetSearch')
          Sentry.captureException(e)
        })
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name renderSearchMap
     * @description requires a quote search to have been run. Will use the search state to render the appropriate map
     * @returns {void}
     */
    renderSearchMap: ({ dispatch, state, getters, commit }) => {
      // in case the map has been zoomed
      dispatch('map/resetZoomLevel')
      // clear out any existing features
      dispatch('map/removeAllFeaturesFromCollection')
      // maps is going to log all the removals so we have to clear them
      // before proceeding
      // I don't like this but it can only be done by listening to the event
      commit('map/clearRemovedFeatures')
      // re-center the map on the given area and drop pin
      dispatch('map/setCentroid', state.placeGeometry)
      // clear the previous street view settings
      dispatch('map/disableStreetView')
      // if the type is an address enable street view
      if (intersection(streetViewTypes, state.placeTypes).length) dispatch('map/enableStreetView')
      // MSA markets should appear as the bottom layer
      if (!isEmpty(getters.searchMarketBoundaries)) {
        dispatch('map/addFeatureToCollection', {
          geometry: getters.searchMarketBoundaries,
          properties: {
            // there will only be one msa
            id: 'msa',
            styles: defaultLayerStyles.msa
          }
        })
      }

      // then service areas
      getters.searchResultsBoundaries.forEach(({ geometry, id }, key) => {
        // if the geometry is not a polygon or multi-poly it has to be cleaned
        const validatedGeometry = convertCollectionToMultiPolygon(geometry)
        dispatch('map/addFeatureToCollection', {
          geometry: validatedGeometry,
          properties: {
            id,
            styles: defaultLayerStyles.serviceArea(colors[key], key)
          }
        })
      })
      // // then special use
      getters.searchSpecialUseBoundaries.forEach(({ geometry, id }) => {
        // if the geometry is not a polygon or multi-poly it has to be cleaned
        const validatedGeometry = convertCollectionToMultiPolygon(geometry)
        // this adds the 'dashed' outline areas but doesn't count as feature or filled poly
        dispatch('map/addPolylineToMap', {
          coordinates: validatedGeometry.coordinates,
          id,
          styles: defaultLayerStyles.specialUse.polyline
        })

        // we also have to add a feature to handle the hover states
        dispatch('map/addFeatureToCollection', {
          geometry,
          properties: {
            id,
            styles: defaultLayerStyles.specialUse
          }
        })
      })

      // then geo notes
      getters.searchGeoNoteBoundaries.forEach(({ geometry, id, color }) => {
        // if the geometry is not a polygon or multi-poly it has to be cleaned
        const validatedGeometry = convertCollectionToMultiPolygon(geometry)
        // adds the dashed lines
        dispatch('map/addPolylineToMap', {
          coordinates: validatedGeometry.coordinates,
          id,
          styles: defaultLayerStyles.geoNote(color).polyline
        })

        // we also have to add a feature to handle the hover states
        dispatch('map/addFeatureToCollection', {
          geometry,
          properties: {
            id,
            styles: defaultLayerStyles.geoNote(color)
          }
        })
      })

      // top layer should be locale to be noticeable
      if (!isEmpty(getters.searchLocaleBoundaries)) {
        dispatch('map/addFeatureToCollection', {
          geometry: getters.searchLocaleBoundaries,
          properties: {
            // there will be only one locale
            id: 'locale',
            styles: defaultLayerStyles.locale
          }
        })
      }
    }
  }
}

export default search
