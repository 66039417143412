<template>
    <div :class="'alert-box '+severity">
        <div class="row flex no-margin-bottom">
            <div class="valign-wrapper col s1">
                <i :class="'material-icons '+severityTextColor">{{icon}}</i>
            </div>
            <div :class="'message-container '+messageContainer">
               <span v-html="message" class="message-content"></span>
               <slot name="subtitle"></slot>
            </div>
            <div v-if="dismissable" class="valign-wrapper col s1">
                <i
                @click="dismiss"
                :class="'close material-icons medium '+severityTextColor">close</i>
            </div>
            <div class="valign-wrapper col s1">
               <i :class="'material-icons '+severityTextColor">{{icon}}</i>
            </div>
        </div>
    </div>
</template>

<script>
const acceptedSeverity = ['warning', 'error', 'success', 'info', 'note']
/**
 * A re-usable component to display a variety of types of alerts
 *  ranging in severity - available types are __info, note, warning, error, success__
 */
export default {
  name: 'AlertBox',
  props: {
    /**
     * The message to display
     * @type {String}
     */
    message: {
      required: true,
      type: String
    },

    subtitle: {
      required: false,
      type: String
    },
    /**
     * The level of severity of the alertbox
     * @type {String}
     */
    severity: {
      required: false,
      type: String,
      default: 'warning',
      validation: severity => acceptedSeverity.includes(severity)
    },
    /**
     * Set whether the alertbox be dismissable
     * @type {Boolean}
     */
    dismissable: {
      required: false,
      type: Boolean,
      default: false
    },
    /**
     * If the alertbox is dismissable allow for an optional
     * callback with a default emission of 'dismissed'
     * @type {Function}
     */
    dismissCallback: {
      required: false,
      type: Function,
      default () { this.$emit('dismissed', true) }
    }
  },
  methods: {
    /**
     * Dismiss the alertbox
     * @return {undefined} calls the dismissable callback prop
     * @public
     */
    dismiss () {
      this.dismissCallback()
    }
  },
  computed: {
    severityTextColor () {
      return `${this.severity}-text`
    },
    icon () {
      switch (this.severity) {
        case 'warning':
          return 'warning'
        case 'error':
          return 'error'
        case 'success':
          return 'check_circle'
        case 'info':
          return 'info'
        case 'note':
          return 'event_note'
        default:
          return 'warning'
      }
    },
    messageContainer () {
      const width = this.dismissable ? 's9' : 's10'
      return `col ${width}`
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/base/_variables.scss";

    .alert-box{
        color:$white;
        font-size: 1.2rem;
        width:100%;
        padding:5px 0;

        span.message-content{
            margin:0 auto;
        }

        .row{
            height:100%;
        }

        i.material-icons{
            font-size:1.6rem;
            margin:0 auto;
        }

        &.warning{
            background-color:$alert;
            .message-container{
                text-shadow: 2px 2px $alert-dark;
            }

        }

        &.error{
            background-color:$danger;
            .message-container{
                text-shadow: 2px 2px $danger-dark;
            }

        }

        &.success{
            background-color:$success;
        }
        &.info{
            background-color:$primary;
            font-size:1rem;
        }
        &.note{
            background-color:darken($dark-gray, 20%);
            font-size:1rem;
        }
    }
</style>
