<template>
<div>
    <base-button
        v-show="formLocked && editable"
        class="edit-button"
        :callback="unlock"
        key="edit">Edit</base-button>
    <base-button
      class="save save-button"
      v-show="!formLocked && formChanged && formValid"
      :callback="save"
      key="save">Save</base-button>
    <base-button
      v-show="!formLocked && editing && editable"
      class="discard-button"
      :callback="discard"
      key="discard">{{discardName}}</base-button>
    <delete-button @delete="destroy" v-show="deleteable"/>
</div>

</template>

<script>
import BaseButton from '../ui/BaseButton.vue'
import DeleteButton from '../ui/DeleteButton.vue'

export default {
  name: 'FormButtons',
  components: {
    'base-button': BaseButton,
    'delete-button': DeleteButton
  },
  props: {
    editable: {
      required: false,
      type: Boolean,
      default: () => true
    },
    /**
     * Should the buttons include a delete option
     */
    deleteable: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
     * Change state of buttons based on the edit state of the form
     */
    formChanged: {
      required: false,
      type: Boolean,
      default: () => true
    },
    /**
     * Determine if the form is editable or locked to start
     */
    formLocked: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
     * Change the state of the buttons based on the validation state of the parent
     */
    formValid: {
      required: false,
      type: Boolean,
      default: () => true
    },
    /**
     * Delete button text
     * Moving forward -
     * "cancel" means not saving any changes and sends the user back to the original page.
     * "discard" means erasing any changes the user has made but keeps them on that page
     */
    discardName: {
      required: false,
      type: String,
      default: () => 'Discard'
    }
  },
  data () {
    return {
      editing: !this.formEditable
    }
  },
  methods: {
    /**
     * Callback for after a user has confirmed deletion
     * @public
     * @event delete
     */
    destroy () {
      this.$emit('delete', true)
    },
    /**
     * Discard the changes and lock the form (prompt for edits)
     * Emit a discard to the parent
     * @public
     * @event discard
     */
    discard () {
      this.$emit('discard', true)
    },
    /**
     * Emit a save action request to the parent
     * @public
     * @event save
     */
    save () {
      this.$emit('save', true)
    },
    /**
     * Emit to the parent that the user is requesting an unlocked state
     * @public
     * @event lock
     */
    unlock () {
      this.$emit('lock', false)
    }
  },
  watch: {
    formEditable () {
      this.editing = !this.formEditable
    }
  }
}
</script>

<style lang="scss">
  div button {
    margin: 5px;
  }
</style>
