/* eslint no-param-reassign: 0 */

const chip = {
  state: {
    chips: []
  },
  mutations: {
    addChip: (state, chips) => { state.chips.push(...chips) },
    removeChip: (state, index) => { state.chips.splice(index, 1) },
    clearChips: state => { state.chips = [] }
  },
  getters: {
    getChips: state => state.chips
  },
  actions: {
    /**
         * Action to commit chips to state
         * @param {object} context - exposes all store functionality
         * @param {array} chips - array of values to add as chips
         */
    addChip (context, chips) { context.commit('addChip', chips) },
    /**
         * Action to remove chips from state
         * @param {object} context - exposes all store functionality
         * @param {number} index  - the numeric array index to remove
         */
    removeChip (context, index) { context.commit('removeChip', index) },
    /**
         * Action to remove all chips from state
         * @param {object} context - exposes all store functionality
         */
    clearChips (context) { context.commit('clearChips') }
  }
}

export default chip
