import { isEmpty, omit, prop, reduce } from 'kyanite'
import * as Sentry from '@sentry/browser'
import events from './events'
import { apiHttp } from '../../utils/apiHttp'

const inititalAvailabilityTypes = () => ({
  'Same Day': {
    id: 'f42d255134bedcb',
    name: 'Same Day',
    cutoff_time: '',
    notes: '',
    must_call: false,
    verified: false
  },
  'Next Day': {
    id: '12b48d21b4f8e11',
    name: 'Next Day',
    cutoff_time: '',
    notes: '',
    must_call: false,
    verified: false
  },
  Saturday: {
    id: '429f2ea2dd65e5f',
    name: 'Saturday',
    cutoff_time: '',
    notes: '',
    must_call: false,
    verified: false
  },
  Sunday: {
    id: '63b2c60ab63fdf0',
    name: 'Sunday',
    cutoff_time: '',
    notes: '',
    must_call: false,
    verified: false
  },
  Window: {
    id: 'c762e308307e56c',
    name: 'Window',
    cutoff_time: '',
    notes: '',
    must_call: false,
    window_days_start: 1,
    window_days_end: 7
  }
})

export default {
  namespaced: true,
  state: {
    ...inititalAvailabilityTypes(),
    selected: [],
    alertExpires: '',
    genAlert: false,
    completedForms: {}
  },
  modules: {
    events
  },
  getters: {
    /**
     * @name filterableAvailabilityNames
     * @description return an array of the prettified names of the availabilities
     * @property {object} state the current state
     * @returns {array} array of the pretty names only
     */
    filterableAvailabilityNames: state =>
      Object.keys(omit(['Window'], state))
        .filter(k => prop('name', state[k])).map(e => state[e].name),

    // Since the only thing required without a default is cutoff_time
    // It's the only thing really needed to make the form valid
    isValid: state => Boolean(state.selected.length) && state.selected.every(form => state[form].cutoff_time)
  },
  mutations: {
    updateAvail (state, { type, data }) {
      state[type] = { ...state[type], ...data }
    },

    setGenAlert (state, val) {
      state.genAlert = val
    },

    setAlertExpire (state, val) {
      state.alertExpires = `${val} 00:00:00`
    },

    updateSelected (state, val) {
      state.selected = val
    },

    clearCompleted (state) {
      state.completedForms = {}
    },

    nextStep (state, name) {
      const completed = state.completedForms[name]

      if (completed) {
        completed.selected.forEach(val => {
          if (state[val.name]) {
            Object.assign(state, { [val.name]: val })
          }
        })
        state.selected = completed.selected.map(x => x.name)
      }
    },

    addCompleted (state, data) {
      state.completedForms[data.uglyName] = {
        name: data.uglyName,
        selected: state.selected.reduce((acc, name) => acc.concat({
          name,
          ...state[name]
        }), [])
      }
    },

    addToAll (state) {
      const types = ['newDeliveries', 'emptyReturn', 'swap', 'pickUp']

      types.forEach(k => {
        Object.assign(state.completedForms, {
          [k]: {
            name: k,
            selected: state.selected.map(x => state[x])
          }
        })
      })
    },

    setAvailability (state, data) {
      if (!isEmpty(data)) {
        // for each service type [newDeliveries, swap, pickUp, emptyReturn]
        Object.keys(data).forEach(name => {
          state.completedForms[name] = {
            name,
            selected: data[name]
          }
        })
      }
    },

    resetAllAvail (state) {
      Object.assign(state, { ...inititalAvailabilityTypes(), selected: [] })
    }
  },
  actions: {
    fetchAvailability ({ commit }, id) {
      return apiHttp.get(`region/${id}/availability`)
        .then(({ data }) => commit('setAvailability', data.collection))
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error fetching region availability',
            severity: 'error',
            timeout: -1
          })
        })
    },

    saveAvailability ({ commit, state }, region) {
      commit('events/setLoading', true)
      return apiHttp.put(`/region/${region.id}`, {
        ...omit(['current_availability'], region),
        availability: reduce((k, acc) => {
          const { name, selected } = state.completedForms[k]
          const reformattedPayload = selected.map(avail => {
            const { id } = avail
            // I guess it doesn't like any of these?
            return {
              id,
              data: omit([
                '_links',
                'name',
                'relationship_created_at',
                'relationship_updated_at',
                'relationship_id'
              ], avail)
            }
          })

          return { ...acc, [name]: reformattedPayload }
        }, {}, Object.keys(state.completedForms))
      })
        .then(({ data }) => {
          commit('events/setLoading', false)
          commit('setAvailability', data.availability)
          commit('events/runFlash', {
            message: 'Saved Availability Successfully',
            severity: 'success',
            timeout: 2500
          })

          return data
        })
        .catch(err => {
          Sentry.captureException(err)
          commit('events/runFlash', {
            message: 'Something went wrong trying to save availability',
            severity: 'error',
            timeout: 2500
          })

          throw err
        })
        .finally(() => commit('events/setLoading', false))
    }
  }
}
