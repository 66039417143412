<template>
<section>
    <div v-if="prefills" class="row">
        <!-- show prefill options -->
        <div v-for="(prefill, key) of prefills" :key="key">
          <input
          type="radio"
          :id="`prefill-${key}`"
          :value="prefill.value"
          v-model="alert.text">
          <label :for="`prefill-${key}`">{{prefill.name}}</label>
        </div>
    </div>
    <div class="row">
        <div class="input-field col s12">
            <textarea
            :disabled="lock"
            v-validate="'required'"
            v-model="alert.text"
            id="alert_text"
            name="alert_text"
            class="materialize-textarea"
            wrap="true"></textarea>
            <form-error field="alert_text" :active-errors="errors"/>
            <label for="alert_text">Alert Text</label>
        </div>
    </div><!-- row -->
    <div v-show="!lock" class="row">
      <div class="col s12">
        <input type="radio" :id="`expires-date-${key}`" value="expires" v-model="timed">
        <label :for="`expires-date-${key}`">Set Expiration Date</label>
        <input type="radio" :id="`check-back-date-${key}`" value="check_back" v-model="timed">
        <label :for="`check-back-date-${key}`">Set Check Back Date</label>
      </div>
    </div>
    <div v-show="timed && !lock" class="row">
      <div class="col s6">
        <input id="pick-expires-date" type="date" class="datepicker" :min="today" v-model="setDate" required>
        <label for="pick-expires-date">Select Date</label>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <div class="right">
            <small v-if="alert.created_by" class="alert-meta success-text">
              <b>Created By:</b> <user-name :userId="alert.created_by" />
            </small>
            <small v-if="alert.created_at" class="alert-meta success-text">
              <b>Created At:</b> {{alert.created_at}}
            </small>
            <small v-if="alert.updated_by" class="alert-meta warning-text">
              <b>Last Updated By:</b> <user-name :userId="alert.updated_by" />
            </small>
            <small v-if="alert.updated_at" class="alert-meta warning-text">
              <b>Last Updated At:</b> {{alert.updated_at}}
            </small>
        </div>
      </div>
    </div><!-- row -->
    <div class="row">
      <div class="col s12">
        <div class="right">
          <small v-if="alert.expires" class="alert-meta error-text">
            <b>expires at:</b> {{alert.expires}}
          </small>
          <small v-if="alert.check_back" class="alert-meta error-text">
            <b>check back on:</b> {{alert.check_back}}
          </small>
        </div>
      </div>
    </div><!-- row -->
    <div class="row">
      <div class="col s12">
          <form-buttons
          @discard="discard"
          @lock="lockState"
          @save="submitListener"
          @delete="deleteListener"
          :deleteable="deleteable"
          :editable="editable"
          :formChanged="changed"
          :formLocked="lock"
          :formValid="valid"
          discardName="Cancel"
          />
      </div>
    </div><!--row-->
</section>
</template>

<script>
/* global Materialize */
import { deepEq, isEmpty } from 'kyanite'
import { format } from '../utils/time'
import FormButtons from './FormButtons.vue'
import UserName from './UserName'
import FormError from '../ui/FormError.vue'

const fmt = d => (d ? format(d, 'YYYY-MM-DD') : null)

/**
 * The form that is used to create and update alert information
 */
export default {
  name: 'AlertForm',
  components: {
    'form-buttons': FormButtons,
    'form-error': FormError,
    UserName
  },
  props: {
    saveCallback: {
      required: false,
      type: Function,
      default (alert) {
        this.$emit('save', alert)
      }
    },
    /**
     * Optional callback to be ran when data is deleted
     */
    deleteCallback: {
      required: false,
      type: Function,
      default (id) {
        this.$emit('delete', id)
      }
    },
    /**
     * Optional callback to be ran when data is discarded
     */
    discardCallback: {
      required: false,
      type: Function,
      default () { this.alert = { ...this.record } }
    },
    /**
     * Should the alert record be able to set an expires or checkback date
     */
    expireable: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
    * The record the form should display
    */
    record: {
      required: false,
      type: Object,
      default () {
        return {}
      }
    },
    /**
     * A manual reset can be requested
     */
    reset: {
      required: false,
      type: Boolean
    },
    /**
     * A way of setting the form locked from any edits
     */
    editable: {
      required: false,
      type: Boolean,
      default: () => true
    },
    /**
     * Given the scope of form responsiblitiy from parent
     * should the form offer the option to delete?
     * By default assume delete is not offered
     */
    deleteable: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
     * Should the form be locked by default or open for additions
     * Default to locked state
     */
    locked: {
      required: false,
      type: Boolean,
      default: () => true
    },
    /**
     * Offer shorthand way of prefilling the specified content
     */
    prefills: {
      required: false,
      type: Array,
      validation (arr) {
        const req = ['name', 'value']
        return arr.every(obj => Object.keys(obj).every(key => req.includes(key)))
      }
    }
  },
  data () {
    // I am not proud of this
    // May I be forgiven for the heresy I have committed this day
    // The terrible that this has become will haunt me till the end of my days
    // I can only tell my family I am sorry...
    let timed = null
    if (this.record.expires) {
      timed = 'expires'
    } else if (this.record.check_back) {
      timed = 'check_back'
    }
    return {
      alert: { ...this.record },
      key: Math.floor(Math.random() * 500),
      lock: this.locked,
      setDate: fmt(this.record.expires) ||
        fmt(this.record.check_back) ||
        '',
      timed
    }
  },
  /**
   * @namespace
   */
  watch: {
    /**
     * @member
     * @property {Function} - A record watcher to keep the local alert up to date
     * @param {Object} val The value given by the watcher to update the local alert
     * @return {Void}
     */
    record (val) {
      this.alert = { ...this.alert, ...val }
    },
    setDate (val) {
      this.alert[this.timed] = !isEmpty(val) ? `${val} 00:00:00` : null
    },
    timed (val) {
      if (val === 'expires') this.alert.check_back = null
      if (val === 'check_back') this.alert.expires = null
    }
  },
  methods: {
    /**
     * Callback for the delete
     * @public
     */
    deleteListener () {
      this.deleteCallback(this.alert.id)
    },
    /**
     * Discard any changes by re-assigning the
     * initial state to the form
     * @public
     */
    discard () {
      this.discardCallback(this.alert)
      this.lockState(this.locked)
      this.timed = null
    },
    /**
     * Callback for the button click on the form
     * @public
     */
    submitListener () {
      // lock the form
      this.lockState(true)
      // call for save
      this.saveCallback(this.alert)
      // discard it's contents
      // this.discardCallback(this.alert)
    },
    /**
     * Set lock state based on form button selection
     * @public
     * @param {Boolean} state The state to change the lock to
     * @return {Void}
     */
    lockState (state) {
      this.lock = state
    }
  },
  /**
   * @vue-computed {Boolean} changed - Boolean to check if the form has changed or not
   * @public
   */
  computed: {
    today () {
      return fmt(new Date())
    },
    changed () {
      return !deepEq(this.record, this.alert)
    },
    valid () {
      return true
    }
  },
  updated () {
    this.$nextTick(() => {
      $(document).ready(() => {
        Materialize.updateTextFields()
      })
    })
  }
}
</script>

<style lang="scss">
@import 'src/styles/base/_variables.scss';
  .alert-meta{
    margin-left:15px;
  }
</style>
