<template>
    <div>
        <head-preloader/>
        <flash-box/>
        <the-navigation />
        <version-alert id="sticky-notice"/>
        <slot>
            <router-view></router-view>
        </slot>
        <base-modal/>
    </div>
</template>

<script>
import TheNavigation from '../components/TheNavigation.vue'
import HeadPreloader from '../ui/HeadPreloader.vue'
import FlashBox from '../ui/FlashBox.vue'
import VersionAlert from '../ui/VersionAlert.vue'
import BaseModal from '../ui/BaseModal.vue'

export default {
  name: 'MasterLayout',
  components: {
    'the-navigation': TheNavigation,
    'head-preloader': HeadPreloader,
    'flash-box': FlashBox,
    'version-alert': VersionAlert,
    'base-modal': BaseModal
  }
}
</script>

<style lang="scss">
.loading-bar {
  position: fixed;
  &.progress {
    height:6px;
    margin:0;
    z-index:999;
  }
}
#sticky-notice {
  position: fixed;
  top: 2em;
  left: 2em;
}
</style>
