export const geocode = (prop, val) =>
  new Promise((resolve, reject) => {
    const searchObj = {}
    searchObj[prop] = val
    // eslint-disable-next-line no-undef
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode(searchObj, (data, status) => {
      if (status === 'OK') {
        return resolve(data)
      }

      if (status === 'OVER_QUERY_LIMIT') {
        // we have hit rate limiting and need abort or re-run
        return reject(status)
      }

      return reject(status)
    })
  })

// utilizing manually included gmaps places api client lib on index.html
// eslint-disable-next-line no-undef
export const placesAutocompleteService = new google.maps.places.AutocompleteService()

// eslint-disable-next-line no-undef
const sessionToken = new google.maps.places.AutocompleteSessionToken()

export const placesAutocomplete = (val, callback, location, options) =>
  placesAutocompleteService.getPlacePredictions({
    input: val,
    componentRestrictions: { country: 'us' },
    types: (options && options.types) || ['geocode'],
    location,
    radius: 10000,
    sessionToken
  }, callback)

// expose good stuff
// export { geocode, placesAutocomplete };
