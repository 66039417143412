<template>
    <div class="mt container">
      <div class="row input-field">
        <div class="right col s4">
          <input
          type="text"
          v-model="search"
          @input="searchHauler"
          placeholder="Search Alerts By Hauler"
          ref="search"
        />
        </div>
      </div>
      <div class="row">
        <div class="filter-dropdown">
          Filter By Territory:
          <base-dropdown
          @selected="territoryFilter"
          :items="territories"
          :options="{value:'value', display:'name'}"
          id="territory"
          name="territory"
          />
         </div>
      </div>

      <div class="row collection">
        <table class="highlight bordered" v-if="alerts.length">
        <thead>
          <tr>
            <th @click="sorting('hauler_name')">Hauler
              <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="currField === 'hauler_name'">
                arrow_drop_up
              </i>
            </th>
            <th>Region/Service Area</th>
            <th>Text</th>
            <th>Territory</th>
            <th @click="sorting('created_at')">Created At
              <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="currField === 'created_at'">
                arrow_drop_up
              </i>
            </th>
            <th @click="sorting('updated_at')">Updated At
              <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="currField === 'updated_at'">
                arrow_drop_up
              </i>
            </th>
            <th @click="sorting('created_by')">Created By
              <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="currField === 'created_by'">
                arrow_drop_up
              </i>
            </th>
          </tr>
        </thead>
        <tbody>
          <router-link
          v-for="rec in filtered"
          :key="rec.id"
          tag="tr"
          :to="`/alerts/${rec.id}?hauler=${rec.hauler_id || ''}`">
            <td>{{rec.hauler_name || ''}}</td>
            <td>{{generateTooltip(rec)}}</td>
            <td>{{rec.text | truncat}}</td>
            <td>{{rec.territory || 'None'}}</td>
            <td>{{rec.created_at || ''}}</td>
            <td>{{rec.updated_at || ''}}</td>
            <td><user-name :userId="rec.created_by" /></td>
          </router-link>
        </tbody>
      </table>
      </div>
    </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapMutations } from 'vuex'
import { isEmpty } from 'kyanite'
import { apiCRUD } from '../utils/apiHttp'
import CollectionManagerSort from '../utils/CollectionManager'
import truncat from '../filters/truncat'
import UserName from '../components/UserName'
import BaseDropdown from '../ui/BaseDropdown.vue'

export default {
  name: 'AlertCollection',
  filters: {
    truncat: truncat(60)
  },
  components: {
    BaseDropdown,
    UserName
  },
  data () {
    return {
      // all the alerts
      alerts: [],
      // the current position of the inf loading given buffers applied
      loadingPosition: 0,
      infiniteId: +new Date(),
      // for sorting
      currField: '',
      // for sorting
      sortDir: '',
      search: '',
      territory: '',
      filtered: [],
      territories: [
        {
          name: 'Midwest',
          value: 'Midwest'
        }, {
          name: 'Southeast',
          value: 'Southeast'
        }, {
          name: 'Northeast',
          value: 'Northeast'
        }, {
          name: 'West',
          value: 'West'
        }
      ]
    }
  },
  methods: {
    ...mapMutations('events', ['toggleSaving']),
    sorting (field) {
      this.currField = field
      this.filtered.sort(CollectionManagerSort.sort(field))

      this.sortDir = CollectionManagerSort.getSortDirection()
    },
    territoryFilter (val) {
      this.haulerSearch(this.$refs.search.value)
      if (val !== 'all' && val !== '') {
        this.filtered = this.filtered.filter(alert => alert.territory === val)
      }
    },
    searchHauler () {
      this.territoryFilter('')
    },
    haulerSearch (input) {
      this.filtered = this.alerts.filter(alert => alert.hauler_name && alert.hauler_name.toLowerCase()
        .includes(input.toLowerCase()))
    },
    generateTooltip (record) {
      let tooltip = ''
      if (!isEmpty(record.region_name)) tooltip += `${record.region_name}`
      if (!isEmpty(record.service_area_name)) {
        tooltip += ' - '
        tooltip += `${record.service_area_name}`
      }
      return tooltip
    }
  },
  created () {
    this.toggleSaving()
    apiCRUD.collection('alerts', { per_page: -1 })
      .then(({ data }) => {
        this.alerts = data.data.slice()
        this.filtered = data.data.slice()
      }).finally(() => this.toggleSaving())
  }
}
</script>

<style scoped lang="scss">

tbody tr {
  color: #FA8D29;
}

tbody tr td {
  cursor: pointer;
}

table tbody tr td:first-child,
table thead tr th:first-child {
  text-align: left;
  padding-left: 1.5rem;
}

.btn {
  transition: all 0.4s;
}

th .material-icons {
  vertical-align: middle;
}

.material-icons.down {
  transform: rotate(180deg);
}

.material-icons {
  transition: transform 0.3s;
}
.filter-dropdown {
  width: 200px;
}
</style>
