<template>
  <div>
    <section class="title-bar">
      <section class="hauler-name" @click="reset">
        <div class="title" v-if="hauler.name">
          <i class="material-icons">chevron_left</i><h6>{{ hauler.name }}</h6>
        </div>
        <div v-else class="placeholders placeholders--animated">
          <div class="placeholders-heading__title"></div>
        </div>
      </section>
      <section class="territory">
        <div class="territory__menu" @click="terrOpen = !terrOpen">
          <p>{{ translateTerr }}</p> <i class="material-icons">arrow_drop_down</i>
        </div>
        <transition name="dropdown">
          <div class="dropdown__menu" :class="{ active: terrOpen }" v-show="terrOpen">
            <ul>
              <li class="dropdown__menu-item" @click="setTerr('all')">
                <span class="dropdown__menu-link" title="all territories">
                  <div class="dropdown__menu-text">All Territories</div>
                </span>
              </li>
              <li class="dropdown__menu-item" @click="setTerr('ne')">
                <span class="dropdown__menu-link" title="north east">
                  <div class="dropdown__menu-text">Northeast</div>
                </span>
              </li>
              <li class="dropdown__menu-item" @click="setTerr('se')">
                <span class="dropdown__menu-link" title="south">
                  <div class="dropdown__menu-text">Southeast</div>
                </span>
              </li>
              <li class="dropdown__menu-item" @click="setTerr('mw')">
                <span class="dropdown__menu-link" title="midwest">
                  <div class="dropdown__menu-text">Midwest</div>
                </span>
              </li>
              <li class="dropdown__menu-item" @click="setTerr('nw')">
                <span class="dropdown__menu-link" title="west">
                  <div class="dropdown__menu-text">West</div>
                </span>
              </li>
            </ul>
          </div>
        </transition>
      </section>
    </section>
    <details
      v-for="area in filteredRegions"
      :id="area.id"
      :key="area.id"
      :ref="`detail`"
    >
      <summary
        @click="setOpen($event, area.id)"
        :class="['grid', selectedRegion === area.id ? 'selected' : '']"
      >
          <span class="break">{{ area.name }}</span>
          <span>
            <i class="material-icons">{{ selectedRegion === area.id ? 'arrow_drop_up': 'arrow_drop_down' }}</i>
          </span>
      </summary>
      <p
        v-for="zone in getAreasByRegion(area.id)"
        @click="setZone(zone)"
        :key="zone.id"
        :class="['pl', { selected: selectedZone === zone.id }]"
      >
        {{ zone.name }}
      </p>
      <p class="add-zone" @click="$emit('addZone')">+ Add Zone</p>
    </details>
    <section class="add-region" @click="$emit('addRegion')">
      <p>+ Add Region</p>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { isNil, findIndex, remove } from 'kyanite'

export default {
  data () {
    return {
      selectedZone: null,
      selectedRegion: null,
      terrOpen: false,
      territory: 'all'
    }
  },

  computed: {
    ...mapState('regions', { regions: 'regions', chosenRegion: 'selectedRegion', serviceAreas: 'serviceAreas', selectedArea: 'selectedArea' }),
    ...mapState('hauler', ['hauler']),
    ...mapGetters('regions', ['getAreasByRegion']),

    translateTerr () {
      const territories = {
        all: 'All Territories',
        ne: 'Northeast',
        se: 'Southeast',
        mw: 'Midwest',
        nw: 'West'
      }

      return territories[this.territory]
    },
    filteredRegions () {
      if (this.territory === 'all') {
        return this.regions
      }
      return this.regions.filter(r => r.territory === this.translateTerr)
    }
  },

  methods: {
    ...mapMutations('regions', ['selectRegion', 'startCreating', 'selectArea']),
    // ...mapMutations('serviceAreas', ['selectArea']),
    ...mapMutations('pricing', ['setPricingRecords']),
    ...mapMutations('geometry', ['setBoundaries']),
    setTerr (t) {
      this.territory = t
      this.terrOpen = false
    },

    setOpen (e, index) {
      if (isNil(this.selectedRegion)) {
        this.selectedRegion = index
        this.setRegion(index)
      } else if (!isNil(this.selectedZone) && this.selectedZone === index) {
        this.selectedZone = null
        this.selectedRegion = index
        e.target.open = true
      } else {
        const i = findIndex(({ id }) => id === index, this.$refs.detail)

        this.selectedRegion = index
        this.setRegion(index)
        this.selectedZone = null
        this.selectArea(null)
        this.closeAll(remove(i, this.$refs.detail))
      }
    },

    setRegion (id) {
      this.selectRegion(id)
      this.$emit('changeView', 'RegionView')
      this.setBoundaries(this.getAreasByRegion(this.chosenRegion.id))
    },

    setZone (zone) {
      this.selectedRegion = null
      this.selectedZone = zone.id

      this.selectArea(zone.id)
      this.setBoundaries(this.selectedArea)
      this.setPricingRecords(zone.rolloff_pricing)
      this.$emit('changeView', 'ZoneView')
    },

    closeAll (els) {
      els.forEach(e => {
        e.open = false
      })
    },

    reset () {
      this.closeAll(this.$refs.detail)

      this.selectedRegion = null
      this.selectedZone = null

      this.selectRegion(-1)
      this.selectArea(-1)

      this.$emit('changeView', 'HaulerForm')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/base/_variables.scss';

  .zones {
    margin: 0;
    .collapsible-body {
      padding: 0 !important;
    }
  }

  .placeholders .placeholders-heading__title {
    width: 100%;
  }

  .pl {
    padding-left: 1.5rem;
  }

  .break {
    word-break: break-all;
  }

  details > summary {
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }
  }

  .dropdown__menu {
    margin-right: 0.5rem;
  }

  .territory {
    position: relative;
    width: 80%;
    font-size: 12px;
    margin: auto;
    padding: 0;
    color: $white;
    cursor: pointer;
    &__menu {
      display: flex;
      place-content: center;
      background-color: lighten($tertiary, 10%);
      border: 1px solid lighten($tertiary, 10%);
      border-radius: 15px;
      padding-left: 0.3rem;
      p {
        padding-top: 0.2rem;
        margin: 0;
      }
    }
  }

  .title {
    display: flex;
    cursor: pointer;
    place-content: center;
    flex-direction: row;
  }

  .title .material-icons {
    place-self: center;
  }

  .grid {
    display: grid;
    grid-template-columns: 90% 10%;
  }

  .add-region {
    margin-top: 0.5rem;
    border: 1px dashed #e1e1e1;
    color: #e1e1e1;
    cursor: pointer;
    p {
      padding: 0.75rem;
      margin: 0;
    }
  }

  details {
    border: 1px solid #e1e1e1;
  }

  summary {
    padding: 0.5rem;
  }

  // .collapsible-header.active,
  .selected {
    background-color: #e1e1e1;
  }

  details p {
    cursor: pointer;
    padding: 0.75rem;
    margin: 0;
    &:hover {
      background-color: #e1e1e1;
      transition: all 0.2s ease;
    }
  }

  .add-zone {
    color: #a0a0a0;
  }

  .title-bar {
    padding: 1rem;
    text-align: center;
    background-color: #1e2f3e;
    color: #FFF;
  }
</style>
