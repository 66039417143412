<template>
  <section class="dumpster-pricing-form">
    <p class="item-name"> {{ record.netsuite_item_name }} </p>
    <div class="row">
      <div class="col s3">
        <input
          type="checkbox"
          v-model="record.mako_preferred"
          id="mako-preferred"
          @change="setPreferred($event.target.checked, 'mako_preferred')">
        <label for="mako-preferred">Mako Preferred</label>
      </div>
      <div class="col s3">
        <input
          type="checkbox"
          v-model="record.quote_preferred"
          id="quote-preferred"
          @change="setPreferred($event.target.checked, 'quote_preferred')">
        <label for="quote-preferred">Quote Preferred</label>
      </div>
      <div class="col s6">
        <input
          type="checkbox"
          v-model="record.preferred"
          id="ecommerce-preferred"
          @change="setPreferred($event.target.checked, 'preferred')">
        <label for="ecommerce-preferred">E-Commerce Preferred</label>
      </div>
    </div>
    <div class="row">
      <div class="col s4">
        <label>Brand</label>
        <smart-dropdown
        query="brands"
        :selected="record.brand_id"
        :disabled="edit"
        />
      </div>
      <div class="col s4">
        <label>Product</label>
        <smart-dropdown
        query="products"
        :selected="record.product_id"
        :disabled="edit"
        />
      </div>
      <div class="col s2">
        <label>Service Type</label>
        <smart-dropdown
          query="service-types"
          :selected="record.service_type_id"
          :disabled="edit"
        />
      </div>
      <div class="col s2">
        <label>Cost Structure</label>
        <smart-dropdown
          query="cost-structures"
          :selected="record.cost_structure_id"
          :disabled="edit"
        />
      </div>
    </div>
    <div class="row shaded">
      <div class="col s2">
        <label>Size</label>
        <smart-dropdown
        query="sizes"
        :selected="record.size_id"
        :disabled="edit"
        />
      </div>
      <div class="col s2">
        <label>Waste Type</label>
        <smart-dropdown
        query="waste-types"
        :selected="record.waste_type_id"
        :disabled="edit"
        />
      </div>
      <div class="col s4">
        <div class="input-field">
          <i class="warning-text small material-icons prefix">monetization_on</i>
          <input
          v-model="record.cost"
          :disabled="edit"
          id="cost"
          name="cost"
          type="text">
          <label for="cost">Our Cost</label>
        </div>
      </div>

      <div class="col s4">
        <div class="input-field">
          <i class="success-text small material-icons prefix">attach_money</i>
          <input
          v-model="record.price"
          :disabled="edit"
          id="price"
          name="price"
          type="text">
          <label for="price">Customer Price</label>
        </div>
      </div>

    </div><!-- row -->

    <div class="row">
      <div class="col s4">
        <div class="input-field">
          <i class="small material-icons prefix">title</i>
          <input
          v-model="record.tonnage"
          id="tonnage"
          name="tonnage"
          :disabled="edit"
          type="text">
          <label for="tonnage">Tonnage</label>
        </div>
      </div>

      <div class="col s4">
        <div class="input-field">
          <i class="warning-text small material-icons prefix">monetization_on</i>
          <input
          v-model="record.cost_per_ton"
          :disabled="edit"
          id="cost-per-ton"
          name="cost-per-ton"
          type="text">
          <label for="cost-per-ton">Our Cost Per Ton Over</label>
        </div>
      </div>

      <div class="col s4">
        <div class="input-field">
          <i class="success-text small material-icons prefix">attach_money</i>
          <input
          v-model="record.price_per_ton"
          :disabled="edit"
          id="price-per-ton"
          name="price-per-ton"
          type="text">
          <label for="price-per-ton">Customer Price Per Ton Over</label>
        </div>
      </div>
    </div><!-- row -->

    <div class="row shaded">
      <div class="col s4">
        <div class="input-field">
          <i class="small material-icons prefix">date_range</i>
          <input
          v-model="record.rental_period"
          :disabled="edit"
          id="rental-days"
          name="rental-days"
          type="text">
          <label for="rental-days">Rental Length</label>
        </div>
      </div>

      <div class="col s4">
        <div class="input-field">
          <i class="warning-text small material-icons prefix">monetization_on</i>
          <input
          v-model="record.extra_day_cost"
          :disabled="edit"
          id="cost-per-day"
          name="cost-per-day"
          type="text">
          <label for="cost-per-day">Our Cost Per Day Over</label>
        </div>
      </div>

      <div class="col s4">
        <div class="input-field">
          <i class="success-text small material-icons prefix">attach_money</i>
          <input
          v-validate="'required'"
          v-model="record.extra_day_price"
          :disabled="edit"
          id="price-per-day"
          name="price-per-day"
          type="text">
          <label for="price-per-day">
            Customer Price Per Day Over
          </label>
        </div>
      </div>
    </div><!-- row -->
    <div class="row">
      <div class="input-field col s12">
        <input
        v-model="record.cost_structure_note"
        :disabled="edit"
        :id="`${record.id}-cost-structure-explained`"
        type="text">
        <label :for="`${record.id}-cost-structure-explained`">Cost Structure Notes</label>
      </div>
      <div class="input-field col s12">
        <input
        v-model="record.extra_day_cost_note"
        :disabled="edit"
        :id="`${record.id}-rental-explained`"
        type="text">
        <label :for="`${record.id}-rental-explained`">Extra Day Cost Explained</label>
      </div>
      <div class="input-field col s12">
        <input
        v-model="record.product_notes"
        :disabled="edit"
        :id="`${record.id}-product-notes`"
        type="text">
        <label :for="`${record.id}-product-notes`">Product Notes</label>
      </div>
    </div>
    <div class="row" :class="customized">
      <h5>Customizations</h5>
      <div class="col s2">
        <label>Sell as size:</label>
        <smart-dropdown
        query="sizes"
        :selected="record.sell_as"
        :disabled="edit"
        />
      </div>
      <div class="input-field col s3">
        <input
        :id="`custom-length-${record.id}`"
        v-model="record.length"
        :disabled="edit"
        type="text" >
        <label :for="`custom-length-${record.id}`">Custom Length</label>
      </div>
      <div class="input-field col s3">
        <input
        type="text"
        v-model="record.width"
        :disabled="edit"
        :id="`custom-width-${record.id}`">
        <label :for="`custom-width-${record.id}`">Custom Width</label>
      </div>
      <div class="input-field col s3">
        <input
        type="text"
        v-model="record.height"
        :disabled="edit"
        :id="`custom-height-${record.id}`">
        <label :for="`custom-height-${record.id}`">Custom Height</label>
      </div>
    </div>

  <div class="row animated">
    <h5>Fill Types</h5>
    <debris-type
    :wasteType="wasteTypes.find(t => t.id === record.waste_type_id)"
    @save="saveRollOffPricing"
    :recordId="record.id"
    :key="record.id"/>
  </div>
    <base-button
      class="save save-button"
      :callback="saveRollOffPricing"
      key="save">Save</base-button>
    <base-button
      class="discard-button"
      :callback="discard">
      Cancel
    </base-button>

  </section>
</template>

<script>
/* global Materialize */
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex'
import { isEmpty, path } from 'kyanite'
import BaseButton from '../ui/BaseButton.vue'
import SmartDropdown from './SmartDropdown.vue'
import DebrisType from '../components/DebrisType'

export default {
  name: 'DumpsterPricingForm',
  components: {
    'base-button': BaseButton,
    'smart-dropdown': SmartDropdown,
    DebrisType
  },
  props: {
    /**
     * The pricing record to display
     * @type {object}
     */
    pricing: {
      type: Object,
      default: () => ({})
    },
    serviceAreaId: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      record: { ...this.pricing },
      note: '',
      edit: true,
      activeNoteType: '',
      noteHeader: '',
      defaultRecord: {
        // sets brand to BD (this should be relatively static)
        brand_id: '6c215fc8c846bb4',
        // sets product to roll off (this should be relatively static)
        product_id: '87d48a11d3c9d83',
        new: true,
        yard_waste: 0,
        appliances: 0,
        stumps: 0,
        electronics: 0,
        other: 0,
        rank: 0,
        mattress: 0,
        service_area_id: null,
        alerts: []
      }
    }
  },
  methods: {
    ...mapMutations('note', ['setNote', 'updateNoteDescription']),
    ...mapMutations('wasteType/debris', ['updateSelectedTypes', 'setDebrisNote', 'resetDebris']),
    ...mapMutations('pricing', ['updatePricingRecord', 'updatePricingRecord']),
    ...mapActions('alerts', ['addAlert', 'createAlert', 'removeAlert', 'updateSingleAlert']),
    ...mapActions('note', ['createNote', 'updateNote', 'removeNote']),
    ...mapActions('wasteType', ['fetchWasteTypes']),
    ...mapMutations('serviceAreas', ['updateSelectedAreaPricing', 'removeArea']),
    ...mapActions('pricing', ['updatePricing']),
    path,
    updateDebrisStore () {
      const keys = this.getDebrisTypeKeys.filter(k => this.pricing[k])

      this.updateSelectedTypes(keys)

      keys.forEach(x => {
        const noteKey = `${x}_note`

        this.setDebrisNote({ type: x, note: this.pricing[noteKey] })
      })
    },
    /**
     * Discard the changes made to the form without a save
     */
    discard () {
      this.$emit('discard')
    },
    hasNote (name) {
      return !!this.record[name] && !!this.record[name] !== ''
    },
    /**
     * Save this pricing record
     */
    saveRollOffPricing () {
      // shallow copy of record
      const copy = { ...this.record }
      const debrisArray = Object.entries(this.getDebris)

      // Check and see if the waste type was changed
      // If so then go ahead and reset the rank to 0
      if (this.pricing.waste_type_id !== this.record.waste_type_id) {
        copy.rank = 99
      }
      // if this is a new price form it won't have the related service area
      if (!copy.service_area_id) copy.service_area_id = this.serviceAreaId
      const debrisTypesState = debrisArray.reduce((acc, e) => {
        const [type, val] = e
        const noteKey = `${type}_note`

        return {
          ...acc,
          [noteKey]: val.note,
          [type]: this.getSelectedDebrisTypes.includes(type)
        }
      }, {})
      // merge the debris types state into the current record
      const data = { ...copy, ...debrisTypesState }
      // set up the payload
      const payload = { data, userId: this.user.id }
      if (this.record.new) payload.rank = 99
      this.updatePricing(payload).then(d => {
        this.updateSelectedAreaPricing(d)
        this.$emit('saved')
        // Reset our debris store for the next one
        this.resetDebris()
      })
    }
  },
  computed: {
    ...mapGetters('note', ['productNotes']),
    ...mapGetters('wasteType/debris', ['getDebrisTypeKeys', 'getSelectedDebrisTypes', 'getDebris']),
    ...mapState('pricing', ['selectedPricing']),
    ...mapState('session', ['user']),
    ...mapState('wasteType/debris', ['selectedDebrisTypes']),
    ...mapState('wasteType', ['wasteTypes']),
    ...mapState('size', ['sizes']),
    ...mapState('regions', ['selectedArea']),
    alert () {
      return path(['alerts'], this.record) && this.record.alerts.length > 0 ? this.record.alerts[0] : {}
    },

    customized () {
      if (this.record) {
        return { 'customized-data': !!this.record.length || !!this.record.sell_as }
      }
      return false
    }
  },
  created () {
    if (path(['alerts'], this.record) && this.record.alerts.length > 0) {
      this.record.alerts.forEach(alert => {
        this.addAlert({ id: alert.id, type: 'product' })
      })
    }
    if (this.record.notes) {
      this.record.notes.forEach(note => {
        this.setNote({
          data: note, type: 'product', relId: this.record.id
        })
      })
    }
  },
  mounted () {
    this.fetchWasteTypes()
    this.updateDebrisStore()
    this.$nextTick(() => {
      Materialize.updateTextFields()
    })

    if (isEmpty(this.pricing)) {
      this.record = this.defaultRecord
    }
  }
}
</script>

<style lang="scss">
@import 'src/styles/base/_variables.scss';
  .shaded{
    background-color:#efefef;
  }
  .customized-data{
    background-color:$alert-light;
    color:black;
  }

  .row .dumpster-pricing-form.col {
    padding-top: 1rem;
  }

  .dumpster-pricing-form{
    margin-bottom:40px;
    p.item-name {
      font-size: 24px;
      font-weight: 350;
    }
  }
  .price-header{
    padding:5px;
    text-align:center;
  }
</style>
