<template>
  <div class="zone-form">
    <form @submit.prevent="save">
      <div class="row">
        <div class="col s12">
          <base-switch
            v-model="active"
            :disabled="lock"
            label="Active"
            :options="[false, true]"
          />
        </div>
      </div>
      <div class="row">
        <div class="input-field col s6">
          <input
            :disabled="lock || (!lock && !creating && active)"
            v-model="name"
            id="name"
            type="text"
            name="name"
            required
          >
          <label for="name" :class="name ? 'active' : ''">Zone Name</label>
        </div>
        <div class="input-field col s6">
          <input
            :disabled="lock"
            v-model="netsuiteName"
            id="netsuiteName"
            type="text"
            name="netsuiteName"
            required
          >
          <label for="netsuiteName" :class="selectedArea.netsuite_name ? 'active' : ''">Netsuite Name</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <textarea
            :disabled="lock"
            v-model="searchText"
            id="searchText"
            type="text"
            name="searchText"
            class="materialize-textarea"
            wrap="true"
            required
          ></textarea>
          <label for="searchText" :class="searchText ? 'active' : ''">Search Text</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <textarea
            :disabled="lock"
            v-model="permitNotes"
            id="permitNotes"
            type="text"
            name="permitNotes"
            class="materialize-textarea"
            wrap="true"
          ></textarea>
          <label for="permitNotes" :class="permitNotes ? 'active' : ''">Permit Notes</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <textarea
            :disabled="lock"
            v-model="serviceAreaDefined"
            id="serviceAreaDefined"
            type="text"
            name="serviceAreaDefined"
            class="materialize-textarea"
            wrap="true"
            required
          ></textarea>
          <label for="serviceAreaDefined" :class="serviceAreaDefined ? 'active' : ''">Service Area Defined</label>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <transition-group name="fade">
            <button v-if="editMode || creating" class="mako-btn" type="submit" key="save">Save</button>
          <button v-else type="button" class="mako-btn" @click="setEdit" key="edit">Edit</button>
            <button class="mako-btn" type="button" @click="discard" v-if="editMode || creating" key="discard">
              Discard
            </button>
            <button class="hollow-btn hollow-btn--danger" type="button" v-if="!creating" @click="runDel" key="delete">
              {{ confirmDel ? 'Confirm Delete' : 'Delete Zone' }}
            </button>
            <button class="mako-btn" type="button" v-if="confirmDel" @click="confirmDel = false" key="cancel">
              Cancel
            </button>
          </transition-group>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseSwitch from '../ui/BaseSwitch.vue'

export default {
  props: {
    creating: {
      type: Boolean,
      required: false
    }
  },

  components: {
    'base-switch': BaseSwitch
  },

  data () {
    return {
      lock: !this.creating,
      editMode: false,
      confirmDel: false
    }
  },

  computed: {
    ...mapState('regions', ['selectedRegion', 'selectedArea']),

    name: {
      get () {
        return this.selectedArea.name
      },

      set (value) {
        this.updateAreaZone({ key: 'name', value })
      }
    },

    netsuiteName: {
      get () {
        return this.selectedArea.netsuite_name
      },

      set (value) {
        this.updateAreaZone({ key: 'netsuite_name', value })
      }
    },

    searchText: {
      get () {
        return this.selectedArea.search_text
      },

      set (value) {
        this.updateAreaZone({ key: 'search_text', value })
      }
    },

    permitNotes: {
      get () {
        return this.selectedArea.permit_info
      },

      set (value) {
        this.updateAreaZone({ key: 'permit_info', value })
      }
    },

    serviceAreaDefined: {
      get () {
        return this.selectedArea.service_area_defined
      },

      set (value) {
        this.updateAreaZone({ key: 'service_area_defined', value })
      }
    },

    active: {
      get () {
        return this.selectedArea.active
      },

      set (value) {
        this.updateAreaZone({ key: 'active', value })
      }
    }
  },

  methods: {
    ...mapActions('regions', ['saveArea', 'createArea', 'deleteArea']),
    ...mapMutations('regions', ['updateArea', 'updateAreaZone', 'selectArea']),

    save () {
      if (this.creating) {
        this.updateAreaZone({ key: 'hauler_id', value: this.$route.params.id })
        this.updateAreaZone({ key: 'region_id', value: this.selectedRegion.id })

        this.createArea()

        return this.$emit('finished', 'zone')
      }

      const data = Object.assign({}, this.selectedArea)
      const payload = { data }
      this.lock = true
      this.editMode = false

      return this.saveArea(payload)
    },

    setEdit () {
      if (this.editMode) {
        this.save()
      } else {
        this.editMode = true
        this.lock = false
      }
    },

    discard () {
      this.lock = true
      this.editMode = false
      if (this.creating) {
        this.$emit('finished', 'zone')
      }

      this.selectArea(this.selectedArea.id)
    },

    runDel () {
      if (this.confirmDel) {
        this.deleteArea(this.selectedArea.id)
        this.$emit('finished', 'zone')
      } else {
        this.confirmDel = true
      }
    }
  },

  beforeDestroy () {
    this.editMode = false
    this.lock = true
  }
}
</script>

<style lang="scss" scoped>
.mako-btn,
.hollow-btn {
  margin-right: 1rem;
}
</style>
