<template>
  <div class="container">
    <div class="exceptionView" v-if="!editing && getExceptionCount > 0">
      <div class="topBar">
        <button type="button" class="mako-btn" @click="setEditMode">Add Exception</button>
      </div>
      <div v-if="loading" class="stateful">
        <span class="stateful__spinner stateful__spinner--huge stateful__spinner--block"></span>
        <span class="stateful__msg">Loading...</span>
      </div>
      <div v-if="!loading">
        <div class="cards" v-for="exception in exceptions" :key="exception.id">
          <ExceptionCard
            :exception="exception"
            @edit="handleEdit"
            @deleted="reload"
          />
        </div>
      </div>
    </div>
    <div class="form" v-if="!loading && (getExceptionCount < 1 || editing)">
      <ExceptionForm
        @saved="edited"
        @cancel="setViewMode"
        :record="exceptionRecord"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import ExceptionForm from './ExceptionForm'
import ExceptionCard from './ExceptionCard'

export default {
  name: 'ExceptionView',
  components: {
    ExceptionForm,
    ExceptionCard
  },
  data () {
    return {
      editing: false,
      loading: true
    }
  },
  methods: {
    ...mapActions('exceptions', ['getExceptions', 'getRegionPricing']),
    ...mapMutations('exceptions', ['setSelectedException']),
    edited () {
      this.setViewMode()
      this.reload()
    },
    handleEdit (payload) {
      this.editing = payload.value
      this.setSelectedException(payload.data)
    },
    setEditMode () {
      this.editing = true
      this.setSelectedException({})
    },
    setViewMode () {
      this.editing = false
      this.setSelectedException({})
    },
    reload () {
      this.loading = true
      this.getExceptions(this.selectedRegion.id)
        .then(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters('exceptions', ['getExceptionCount']),
    ...mapState('regions', ['selectedRegion']),
    ...mapState('exceptions', ['exceptions', 'selectedException']),
    exceptionRecord () {
      return Object.assign({}, this.selectedException)
    }
  },
  mounted () {
    this.reload()
    this.getRegionPricing(this.selectedRegion.id)
  }
}
</script>
<style lang="scss">
  div.topBar {
    display: flex;
    margin: 10px 0;
  }
</style>
