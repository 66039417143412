<template>
  <div v-if="isVisible">
    <i @click="close" class="material-icons">close</i>
    <p>A new update to MAKO is available</p>
    <button
      type="button"
      class="btn"
      @click="update"
    >
      {{buttonMessage}}
    </button>
  </div>
</template>

<script>
import { onChange, offChange, update } from '../services/versionChecker'

export default {
  name: 'VersionAlert',
  data () {
    return {
      state: 0,
      states: {
        hidden: 0,
        visible: 1,
        reloading: 2
      }
    }
  },
  computed: {
    buttonMessage () {
      return (this.state & this.states.reloading) ? 'Reloading…' : 'Refresh'
    },
    isVisible () {
      return this.state & this.states.visible
    }
  },
  methods: {
    close () {
      this.state = this.states.hidden
    },
    show () {
      this.state |= this.states.visible
    },
    update () {
      this.state = this.states.reloading | this.states.visible
      update()
    }
  },
  mounted () {
    onChange(this.show)
  },
  destroyed () {
    offChange(this.show)
  }
}
</script>

<style scoped lang="scss">
  div {
    background-color: #fff;
    padding: 1em;
    border-radius: 0.2rem;
    box-shadow: 0px 3px 5px grey;
    z-index: 9999;
  }
  p {
    display: inline;
    margin: 0 1.5em;
  }
  i {
    cursor: pointer;
    vertical-align: middle;
    font-size: large;
  }
</style>
