<template>
  <div>
    <gmap
      id="map-area"
      @custom_area_merged="handleCustomDrawing"
      @custom_area_cut="handleCustomDrawing"
      :locationGeometry="locationGeometry"
      :haulerGeometry="boundaries"
      :hiddenAreas="hiddenAreas"
      :drawing="drawingControl"
      :drawingType="drawingType"
      :zoom="zoom"/>
    <transition name="fade">
      <div
        v-if="enable"
        :class="['editing-container',  modeClasses.background]">
        <div class="container">
          <div class="row flex">
            <div class="col s2 valign-wrapper">
              <base-switch
                id="mode-switch"
                @change="setMode"
                :options="['Merge', 'Cut']"
                :selected="mode"
                :disabled="inputBlocked"
              />
            </div>
            <div class="col s5 valign-wrapper">
              <h5>{{editActionTitle}}</h5>
            </div>
            <div class="col s5 valign-wrapper">
              <base-button
              :callback="setDrawingOptions"
              :class="`right ${modeClasses.button} ${modeClasses.drawing}`"
              :key="`${mode}draw-button`"
              :disabled="inputBlocked">
                {{ drawText }}
                <i class="material-icons right">{{ modeClasses.icon }}</i>
              </base-button>
            </div>
          </div> <!-- row -->
          <div class="row">
            <div class="col s4">
              <chip-collection
                :chipValues="chipValues"
                :class="modeClasses.chips"
                @chip_removed="chipRemoved" />
            </div>
            <div class="col s4">
              <base-search
                  label="Single Zip Code/City/County/State"
                  class="col s12"
                  :id="'searchbox_add'"
                  :clearAfter="true"
                  :autocomplete="true"
                  :autocompleteConfig="autocompleteConfiguration"
                  :callback="handleAutoComplete"
                  :enterLocked="true"
                  :disabled="inputBlocked">
              </base-search>
            </div>

            <div class="col s4">
              <div class="input-field col s12">
                <input type="text"
                  name="addMultiple"
                  ref="multipleZips"
                  placeholder="44145, 44146, 44147..."
                  v-model="multiZips"
                  @keyup.enter="parseMultipleZips(multiZips)"
                  :disabled="inputBlocked"/>
                <label class="active" for="addMultiple">Multiple Zip Codes</label>
              </div>
            </div>

            <div class="row">
              <div class="col s4"></div>
              <div class="col s4">
                <base-search
                  label="Service Area"
                  class="col s12"
                  :id="'service_area_cut_merge'"
                  :clearAfter="true"
                  :autocomplete="true"
                  :autocompleteConfig="serviceAreaAutoCompleteConfig"
                  :callback="handleAutoComplete"
                  :enterLocked="true"
                  :disabled="inputBlocked">
                </base-search>
              </div>
              <div class="col s4">
                <base-search
                  label="Geo Note"
                  class="col s12"
                  :id="'geo_note_cut_merge'"
                  :clearAfter="true"
                  :autocomplete="true"
                  :autocompleteConfig="geoNoteAutoCompleteConfig"
                  :callback="handleGeoNoteAutoComplete"
                  :enterLocked="true"
                  :disabled="inputBlocked">
                </base-search>
              </div>
            </div>

            <div class="col s12">
              <base-button
                v-show="mapChanged"
                style="margin-bottom:20px"
                class="right save"
                :callback="updateServiceAreaInfo"
                :disabled="inputBlocked">Save Map</base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* global google */
/* eslint-disable camelcase */
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import * as Sentry from '@sentry/browser'
import { uniq } from 'kyanite'
import BaseSearch from '../ui/BaseSearch.vue'
import BaseSwitch from '../ui/BaseSwitch.vue'
import BaseButton from '../ui/BaseButton.vue'
import ChipCollection from './ChipCollection.vue'
import gmap from './TheMap.vue'
import { serviceAreaSearchStream, geoNoteSearchStream } from '../utils/typeahead'
import { placesAutocomplete } from '../services/googleMapsQuery'
import { objectsSort } from '../utils/sort'
import { apiHttp } from '../utils/apiHttp'

export default {
  props: {
    enable: {
      type: Boolean,
      default: false
    },
    selectedArea: {
      type: Object,
      required: true
    },
    isGeoNote: {
      type: Boolean,
      default: false
    },
    hiddenAreas: {
      type: Array,
      default: () => [],
      required: false
    },
    resourceType: {
      type: String,
      default: 'servicearea',
      required: false
    },
    resourceFieldName: {
      type: String,
      default: 'hauler_id',
      required: false
    },
    mapCb: {
      type: Function,
      required: false
    }
  },
  data () {
    return {
      customMerges: 0,
      mode: 'Merge',
      mergeErrors: [],
      zoom: 10,
      locationGeometry: { lat: 37.0902, lng: -95.7129 },
      drawingType: '',
      drawingControl: false,
      autocompleteConfiguration: {
        data: {},
        type: 'category',
        source: this.typeAhead
      },
      serviceAreaAutoCompleteConfig: {
        data: {},
        type: 'category',
        source: serviceAreaSearchStream
      },
      geoNoteAutoCompleteConfig: {
        data: {},
        type: 'category',
        source: geoNoteSearchStream
      },
      multiZips: '',
      mapChanged: false,
      serviceAreaStream: null,
      inputBlocked: false
    }
  },
  components: {
    BaseButton,
    BaseSearch,
    BaseSwitch,
    ChipCollection,
    gmap
  },
  watch: {
    boundaries () {
      this.mapChanged = true
    },

    // This is done here because the map is so tightly coupled to the editing bar it has to be for now
    selectedRegion ({ id }) {
      // Move the centroid so that it focuses the map on the region they choose
      apiHttp.post('geometry/centroid', {
        resource_id: id || this.$route.params.id,
        resource_type: this.resourceType,
        field_name: id ? 'region_id' : this.resourceFieldName
      })
        .then(({ data }) => {
          this.$nextTick(() => {
            if (data) this.locationGeometry = data
          })
        })
        .catch(e => Sentry.captureException(e))
    },

    selectedArea ({ id }) {
      if (!id) {
        return
      }

      // Move the centroid so that it focuses the map on the region they choose
      apiHttp.post('geometry/centroid', {
        resource_id: id || this.selectedRegion.id || this.$route.params.id,
        resource_type: this.resourceType,
        field_name: 'id'
      })
        .then(({ data }) => {
          this.$nextTick(() => {
            if (data) this.locationGeometry = data
          })
        })
        .catch(e => Sentry.captureException(e))
    }
  },
  computed: {
    ...mapGetters(['getChips']),
    ...mapGetters('regions', ['getZoneBoundaries']),
    ...mapState('hauler', ['hauler']),
    ...mapState('session', ['user']),
    ...mapState('geometry', ['boundaries', 'selectedGeoNote']),
    ...mapState('regions', ['selectedRegion', 'serviceAreas']),
    drawText () {
      if (this.drawingControl) {
        return `Done Drawing ${this.mode}`
      }

      return `Draw Custom ${this.mode}`
    },
    modeClasses () {
      const drawing = this.drawing ? 'draw-active' : 'draw-inactive'
      const classObj = {
        Merge: {
          button: 'save',
          icon: 'add_circle',
          background: 'merge-background',
          chips: 'merge',
          drawing
        },
        Cut: {
          button: 'discard',
          icon: 'remove_circle',
          background: 'cut-background',
          chips: 'cut',
          drawing
        }
      }

      return classObj[this.mode]
    },
    editActionTitle () {
      return `${this.mode === 'Merge' ? 'Merge Into' : 'Cut From'} ${this.selectedArea.name}`
    },
    chipValues () {
      return this.getChips.filter(chip =>
        chip.mode === this.mode && chip.service_area_id === this.selectedArea.id)
    }
  },
  methods: {
    ...mapActions(['addChip', 'clearChips', 'removeChip']),
    ...mapActions('hauler', ['setHauler', 'updateServiceArea']),
    ...mapActions('regions', ['saveArea']),
    ...mapMutations('geometry', ['updateBoundaries', 'setBoundaries']),
    ...mapMutations('regions', ['updateArea']),
    ...mapMutations('events', ['setLoading', 'runFlash']),
    ...mapMutations('market', ['updateMarketVal']),
    typeAhead (request, response) {
      const location = new google.maps.LatLng(this.locationGeometry.lat, this.locationGeometry.lng)
      return placesAutocomplete(request.term, this.typeAheadCallback(request, response), location)
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name typeAheadCallback
     * @description passed to places autocomplete and executed with response
     * @param {object} request
     * @param {object} response
     * @return {function}
     */
    typeAheadCallback (request, response) {
      return autoCompletePredictions => {
        const autoComplete = this.formatPredictions(autoCompletePredictions)
        // Place service for zip code look up fallback
        const service = new google.maps.places.PlacesService(document.getElementById('placeholder'))
        if (request.term.length === 5 &&
        !Number.isNaN(request.term) &&
        autoComplete.every(res => res.category !== 'Zipcode')) {
        // fallback for zips not found by autocomplete
          service.textSearch({ query: request.term }, predictions => {
            if (predictions.length !== 0) {
              const zipResult = this.formatPredictions(predictions)[0]
              autoComplete.unshift(zipResult)
              return response(objectsSort.ascendingByAlpha(autoComplete, 'category'))
            }
            return false
          })
        }
        return response(objectsSort.ascendingByAlpha(autoComplete, 'category'))
      }
    },
    /**
     * Format the predictions from the gmap api response
     * into jquery ui friendly objects
     * @param {array} predictions - an array of prediction objects
     * @return {mixed}
     */
    formatPredictions (predictions) {
      const accepted = ['locality', 'postal_code', 'administrative_area_level_2', 'administrative_area_level_1']
      if (predictions) {
        return predictions
          .filter(p => p.types.some(type => accepted.includes(type)))
          .map(({ description, place_id, structured_formatting, types }) => {
            const formatted = {
              label: description,
              category: '',
              place_id,
              search_term: description
            }
            // zips need a different value for search term to satisfy merge call
            if (types.includes('postal_code')) {
              formatted.category = 'Zipcode'
              formatted.search_term = structured_formatting.main_text
            }
            if (types.includes('locality')) formatted.category = 'Cities'
            if (types.includes('administrative_area_level_2')) {
              formatted.category = 'Counties'
              // 1000% a hack to get this to work
              // TODO - Counties and their equivs are not stored in DB with the
              // correct wording IE (Lake County is Lake) in the database
              // fix this on both ends
              formatted.value = description.replace('County', '')
            }
            if (types.includes('administrative_area_level_1')) {
              formatted.category = 'State'
            }

            return formatted
          })
      }
      return false
    },
    setMode (val) {
      this.mode = val
    },
    parseMultipleZips (zips) {
      // Might be a good idea to also filter out based on length
      // That way we can strip out any mistypes and invalid zip codes
      const cleanZips = uniq(zips.split(',')).map(zip => ({
        service_area_id: this.selectedArea.id,
        mode: this.mode,
        category: 'Zipcode',
        value: zip.replace(' ', ''),
        place_id: '',
        search_term: zip.replace(' ', ''),
        label: zip.replace(' ', '')
      }))

      this.multiZips = ''
      this.addChip(cleanZips)
      this.createServiceArea()
    },
    setDrawingOptions () {
      if (this.drawingControl) {
        this.drawingType = ''
        this.drawingControl = false
      } else {
        this.drawingType = this.mode.toLowerCase()
        this.drawingControl = true
      }
    },
    resetDrawingMethod () {
      this.drawingType = ''
      this.drawingControl = false
    },
    /**
     * Received the api response from the merge endpoint and handles
     * adding the new geometry to the feature collection
     * @param {object} response - api data response
     * @param {object} response.data - the data response (destructured)
     */
    updateAreaBoundaryCollection ({ data }) {
      this.mergeErrors = data.errors
      const geometry = JSON.parse(data.feature)
      const properties = this.isGeoNote ? { color: this.selectedGeoNote.color } : {}
      const idx = this.isGeoNote ? this.selectedGeoNote.id : this.selectedArea.id
      const featureCollection = {
        type: 'FeatureCollection',
        id: idx,
        features: [
          {
            type: 'Feature',
            geometry,
            id: idx,
            properties
          }
        ]
      }

      this.updateBoundaries({ data: featureCollection, currId: idx })

      if (!this.isGeoNote) {
        this.updateArea({ key: 'boundaries', value: featureCollection })
      }

      if (this.resourceType === 'market') {
        this.updateMarketVal({ boundaries: geometry })
      }

      if (data.centroid !== null) {
        this.locationGeometry = data.centroid
      }

      this.resetDrawingMethod()
      this.setLoading(false)
      this.setWarnings()
    },
    updateMap () {
      this.inputBlocked = true
      apiHttp.post('/geometry/merge', {
        resource_id: this.selectedArea.id,
        resource_type: this.resourceType,
        geometries: this.getChips.filter(({ mode }) => mode === 'Merge'),
        excludeGeometries: this.getChips.filter(({ mode }) => mode === 'Cut')
      }).then((response) => {
        this.inputBlocked = false
        this.updateAreaBoundaryCollection(response)
      }).catch(e => {
        this.inputBlocked = false
        this.setLoading(false)
        this.runFlash({
          message: 'Unable to update map',
          severity: 'error',
          timeout: 2000
        })

        Sentry.captureException(e)
      })
    },
    createServiceArea () {
      this.setLoading(true)
      this.updateMap()
    },
    chipRemoved (payload) {
      const index = this.getChips.findIndex(({ search_term, service_area_id, mode }) =>
        search_term === payload.value &&
        service_area_id === this.selectedArea.id &&
        mode === this.mode)
      this.removeChip(index)
      this.setLoading(true)
      this.updateMap()
    },
    setWarnings () {
      let mergeError
      let message
      let errorTerms

      if (this.mergeErrors.length > 0) {
        errorTerms = this.mergeErrors.map(element => element.search_term)
        mergeError = errorTerms.join(' ')
        message = 'The following terms were unable to be found and therefore are not reflected on the map: <br>'
        // remove bad chips
        this.getChips.forEach((chip, key) => {
          const termMatch = errorTerms.includes(chip.search_term)
          const modeMatch = chip.mode === this.mode
          if (termMatch && modeMatch) this.removeChip(key)
        })
        this.runFlash({
          message: message + mergeError,
          severity: 'warning',
          timeout: -1
        })
      }
    },
    handleCustomDrawing (item) {
      const newItem = { ...item }
      this.customMerges += 1
      newItem.search_term += ` ${this.customMerges}`
      newItem.mode = this.mode
      newItem.service_area_id = this.selectedArea.id
      this.addChip([newItem])
      this.updateMap()
    },
    handleAutoComplete (area) {
      const newArea = { ...area }
      // TODO - This is another work around to differentiate service area completes
      // need to make this more standard for the api interactions
      if (newArea.type === 'service area') newArea.category = newArea.type
      if (this.getChips.every((x = {}) => x.label !== newArea.label && x.value !== newArea.value)) {
        newArea.service_area_id = this.selectedArea.id
        newArea.mode = this.mode
        this.addChip([newArea])
        this.createServiceArea()
      }
    },
    handleGeoNoteAutoComplete (note) {
      const newNote = { ...note }
      newNote.service_area_id = this.selectedArea.id
      newNote.mode = this.mode
      this.addChip([newNote])
      this.createServiceArea()
    },
    updateServiceAreaInfo () {
      const {
        id, name, market_id, search_text, netsuite_name, boundaries
      } = { ...this.selectedArea }

      const boundariesGeometry = Object.hasOwnProperty.call(boundaries, 'features')
        ? boundaries.features[0].geometry
        : null

      const payload = {
        data: {
          id,
          name,
          market_id,
          search_text,
          hauler_id: this.$route.params.id,
          region_id: this.selectedRegion.id,
          netsuite_name,
          boundaries: JSON.stringify(boundariesGeometry)
        },
        userId: this.user.id
      }

      if (!this.mapCb) {
        this.saveArea(payload)
      } else {
        this.mapCb(boundariesGeometry)
      }

      this.$emit('finishedUpdate')
      this.clearChips()
    }
  },
  beforeMount () {
    // remove what is currently on the map before adding more
    // this.setBoundaries(null)
    // add new stuff
    // this.setBoundaries(this.getZoneBoundaries)

    // This goes and fetches the centroid based around hauler id
    // Then setups the locationGeometry based on that feedback
    if (this.$route.params.id) {
      apiHttp.post('geometry/centroid', {
        resource_id: this.$route.params.id,
        resource_type: this.resourceType,
        field_name: this.resourceFieldName
      })
        .then(({ data }) => {
          this.$nextTick(() => {
            if (data) this.locationGeometry = data
          })
        })
        .catch(e => Sentry.captureException(e))
    }
  }
}
</script>

<style lang="scss">
@import 'src/styles/base/_variables.scss';

.chips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: none;
    padding: 0;
    height: auto;
    min-height: 16px;
    margin: 0;
    .input {
        display: none;
    }
    .chip {
        padding: 0 6px;
        font-size: 0.750rem;
        line-height: 26px;
        height: 26px;
        border-radius: 5px;
        .close {
            line-height: 25px;
            font-size: 0.750rem;
        }
    }
}

.editing-container {
  padding:20px 15px 0px;
  .row.flex{
    height:100%;
    .col{
      width:auto;
      justify-items:center;
      text-align:center;

    }
  }
}
.merge-background {
  background-color: fade-out($primary, 0.95);
}

.cut-background {
  background-color: fade-out($alert, 0.95);
}
</style>
