/* eslint-disable no-param-reassign */
// Eslint doesn't like that my mutations are... Mutating...

export default {
  state: {
    hoverStatus: 'off',
    hoverId: null,
    prevId: null
  },

  // We need to setup semi-pointless getters so that our
  // Watches can use these, since you can't "watch" store state
  // When the store is not namespaced
  getters: {
    hoverState: state => state.hoverStatus,
    getHoverId: state => state.hoverId,
    getPrevId: state => state.prevId
  },

  mutations: {
    setHoverStatus (state, payload) {
      state.hoverStatus = payload
    },

    setHoverId (state, id) {
      state.hoverId = id

      if (id) {
        state.prevId = id
      }
    }
  }
}
