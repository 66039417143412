import modelValidator from '../utils/modelValidator'

const invalidTypeError = type => new Error(`Invalid model type: ${type}`)
const crud = http => ({
  /**
     * @param  {String} type - corresponds to an api endpoint (model)
     * @param  {Object} data - the payload data
     * @return {Promise}
     */
  create (type, data) {
    return modelValidator(type) ? http.post(`/${type}`, data) : invalidTypeError(type)
  },
  /**
     * @param  {String} type - corresponds to an api endpoint (model)
     * @param  {Object} params - optional request parameters
     * @return {Promise}
     */
  collection (type, params) {
    const payload = (typeof params === 'object') ? params : {}
    return modelValidator(type) ? http.get(`/${type}`, { params: payload }) : invalidTypeError(type)
  },
  /**
     * @param  {String} type - corresponds to an api endpoint (model)
     * @param  {String} id - the id of the model record you want
     * @return {Promise}
     */
  record (type, id) {
    return modelValidator(type) ? http.get(`/${type}/${id}`) : invalidTypeError(type)
  },
  /**
     * @param  {String} type - corresponds to an api endpoint (model)
     * @param  {String} id - the id of the model record to be updated
     * @param  {Object} data - the payload data
     * @return {Promise}
     */
  update (type, id, data) {
    return modelValidator(type) ? http.put(`/${type}/${id}`, data) : invalidTypeError(type)
  },
  /**
     * @param  {String} type - corresponds to an api endpoint (model)
     * @param  {String} id - the id of the model record to be deleted
     * @return {Promise}
     */
  delete (type, id) {
    return modelValidator(type) ? http.delete(`/${type}/${id}`) : invalidTypeError(type)
  }
})

export default crud
