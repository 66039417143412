<template>
  <form class="row" @submit.prevent="saveNote">
    <div class="input-field col s6">
      <input
        type="text"
        id="name"
        name="name"
        required
        maxlength="100"
        minLenght="1"
        v-model="name"
      />
      <label for="name" :class="{ active: name }">
        Name
      </label>
    </div>
    <div class="col s6">
      <label for="color">Color</label>
      <select class="browser-default" id="color" required v-model="color">
        <option value="" disabled selected>Select a Color</option>
        <option value="#ff0000">Red</option>
        <option value="#fff000">Yellow</option>
        <option value="#ffa500">Orange</option>
        <option value="#00ff00">Green</option>
      </select>
    </div>
    <div class="input-field col s12">
      <textarea
        id="description"
        name="description"
        class="materialize-textarea"
        required
        v-model="description">
      </textarea>
      <label for="description" :class="{ active: description }">Description</label>
    </div>
    <div class="col s12">
      <input
        id="active"
        type="radio"
        :value="true"
        v-model="active">
        <label for="active" :class="{ active }">
          Active
        </label>
        <input
        id="inactive"
        type="radio"
        :value="false"
        v-model="active">
        <label for="inactive" :class="{ active }">
          Inactive
        </label>
    </div>
    <div class="col s12">
      <button
        class="btn"
        type="submit">
        {{ btnTxt }}
      </button>
      <button
        v-if="editing"
        class="btn discard delete-button"
        @click="checkDel(selectedGeoNote.id)"
        type="button">
        {{ delBtnTxt }}
      </button>
      <button
        v-if="delMode"
        class="btn"
        @click="delMode = false"
        type="button">
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      delMode: false
    }
  },
  computed: {
    ...mapState('session', ['user']),
    ...mapState('geometry', ['selectedGeoNote']),
    // Two way computed properties allowing us to use state as v-models
    name: {
      get () {
        return this.selectedGeoNote.name
      },
      set (val) {
        this.updateSelectedGeoNote({ name: val })
      }
    },
    description: {
      get () {
        return this.selectedGeoNote.description
      },
      set (val) {
        this.updateSelectedGeoNote({ description: val })
      }
    },
    active: {
      get () {
        return this.selectedGeoNote.active
      },
      set (val) {
        this.updateSelectedGeoNote({ active: val })
      }
    },
    color: {
      get () {
        return this.selectedGeoNote.color
      },
      set (val) {
        this.updateSelectedGeoNote({ color: val })
      }
    },
    btnTxt () {
      return !this.editing ? 'Create' : 'Save'
    },
    delBtnTxt () {
      return this.delMode ? 'Are you sure?' : 'Delete'
    }
  },
  methods: {
    ...mapMutations('geometry', ['updateSelectedGeoNote']),
    ...mapActions('geometry', ['createNote', 'deleteNote', 'updateNote']),
    saveNote () {
      const payload = {
        id: this.$route.params.id,
        name: this.name,
        description: this.description,
        active: this.active,
        color: this.color,
        boundaries: this.selectedGeoNote.boundaries,
        created_by: this.selectedGeoNote.created_by,
        updated_by: this.user.id
      }

      if (this.editing) {
        return this.updateNote(payload)
      }

      payload.created_by = this.user.id

      return this.createNote(payload)
        .then(id => {
          this.$router.replace({ path: `/geo-notes/${id}` })
        })
    },
    checkDel (id) {
      if (this.delMode) {
        this.deleteNote(id)
          .then(() => this.$router.replace({ path: '/geo-notes/collection' }))
      } else {
        this.delMode = true
      }
    }
  }
}
</script>
