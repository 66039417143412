/**
 * @name truncat
 * @description Takes a string and truncates it at a desired point
 * @param {Number} len The Maximum length the string can be before it's truncated
 * @param {String} val The string to truncat
 * @return {String} Either the string truncated or the string itself
 */
export const truncat = len => val => {
  // Since the linter keeps yelling at me for ternaries...
  if (val.length > len) {
    return `${val.substring(0, len)}...`
  }

  return val
}

export default truncat
