<template>
  <form @submit.prevent="save" class="row mt">
    <div class="pad-bottom">
      <base-switch
        v-model="active"
        :disabled="lock"
        label="Active"
        :options="[false, true]"
      />
    </div>
    <div class="input-field col s12">
      <input
        :disabled="lock"
        v-model="name"
        id="name"
        type="text"
        name="name"
        required
      >
      <label for="name" :class="name ? 'active' : ''">Region Name</label>
    </div>
    <div class="input-field col s4">
      <input
        :disabled="lock || (!lock && !creating && active)"
        v-model="netsuiteId"
        id="netsuiteId"
        type="text"
        name="netsuiteId"
        required
      >
      <label for="netsuiteId" :class="netsuiteId ? 'active' : ''">Netsuite ID</label>
    </div>
    <div class="input-field col s4">
      <input
        :disabled="lock"
        v-model="phone"
        id="phone"
        type="text"
        name="phone"
      >
      <label for="phone" :class="phone ? 'active' : ''">Phone</label>
    </div>
    <div class="col s4">
      <label for="territory">Territory</label>
      <select class="browser-default" id="territory" :disabled="lock" v-model="territory">
        <option value="" selected>None</option>
        <option v-for="territory in territories" :key="territory.value" :value="territory.name">
          {{ territory.name }}
        </option>
      </select>
    </div>
    <div class="input-field col s12">
      <input
        :disabled="lock"
        v-model="street_address"
        id="street_address"
        type="text"
        name="street_address"
      >
      <label for="street_address" :class="street_address ? 'active' : ''">Street Address</label>
    </div>
    <div class="input-field col s4">
      <input
        :disabled="lock"
        v-model="city"
        id="city"
        type="text"
        name="city"
      >
      <label for="city" :class="city ? 'active' : ''">City</label>
    </div>
    <div class="col s4">
      <label for="state">State</label>
      <select class="browser-default" id="state" v-model="state" :disabled="lock">
        <option value="" disabled selected>Select State</option>
        <option v-for="state in states" :key="state.name" :value="state.abbreviation">
          {{ state.abbreviation }}
        </option>
      </select>
    </div>
    <div class="input-field col s4">
      <input
        :disabled="lock"
        v-model="zip_code"
        id="zip_code"
        type="text"
        name="zip_code"
      >
      <label for="zip_code" :class="zip_code ? 'active' : ''">Zip Code</label>
    </div>
    <div class="input-field col s12">
      <textarea
        :disabled="lock"
        v-model="notes"
        class="materialize-textarea"
        id="notes"
        type="text"
        name="notes"
      ></textarea>
      <label for="notes" :class="notes ? 'active' : ''">General Notes</label>
    </div>
    <div class="col s12">
      <div class="pad-bottom">
        <base-switch
          v-model="portal_active"
          :disabled="lock"
          label="Dispatcher Active"
          :options="[false, true]"
        />
      </div>
    </div>

    <div class="col s12">
      <transition-group tag="section" name="fade">
        <button v-if="editMode || creating" class="mako-btn" type="submit" key="save">Save</button>
        <button v-else type="button" class="mako-btn" @click="setEdit" key="edit">Edit</button>
        <button class="mako-btn" type="button" @click="discard" v-if="editMode" key="discard">Discard</button>
        <button class="hollow-btn hollow-btn--danger" type="button" v-if="!creating" @click="runDel" key="delete">
          {{ confirmDel ? 'Are you Sure?' : 'Delete Region' }}
        </button>
        <button class="mako-btn" type="button" v-if="confirmDel" @click="confirmDel = false" key="cancel">
          Cancel
        </button>
      </transition-group>
    </div>
  </form>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import states from '../config/states'
import BaseSwitch from '../ui/BaseSwitch.vue'

export default {
  props: {
    creating: {
      type: Boolean,
      required: false
    }
  },

  components: {
    'base-switch': BaseSwitch
  },

  data () {
    return {
      states,
      editMode: false,
      confirmDel: false,
      lock: !this.creating,
      territories: [
        {
          name: 'Midwest',
          value: 'Midwest'
        }, {
          name: 'Southeast',
          value: 'Southeast'
        }, {
          name: 'Northeast',
          value: 'Northeast'
        }, {
          name: 'West',
          value: 'West'
        }
      ]
    }
  },

  computed: {
    ...mapState('regions', ['selectedRegion']),
    name: {
      get () {
        return this.selectedRegion.name
      },

      set (value) {
        this.updateRegion({ key: 'name', value })
      }
    },

    netsuiteId: {
      get () {
        return this.selectedRegion.netsuite_id
      },

      set (value) {
        this.updateRegion({ key: 'netsuite_id', value })
      }
    },

    territory: {
      get () {
        return this.selectedRegion.territory
      },

      set (value) {
        this.updateRegion({ key: 'territory', value })
      }
    },

    street_address: {
      get () {
        return this.selectedRegion.street_address
      },

      set (value) {
        this.updateRegion({ key: 'street_address', value })
      }
    },

    city: {
      get () {
        return this.selectedRegion.city
      },

      set (value) {
        this.updateRegion({ key: 'city', value })
      }
    },

    state: {
      get () {
        return this.selectedRegion.state
      },

      set (value) {
        this.updateRegion({ key: 'state', value })
      }
    },

    zip_code: {
      get () {
        return this.selectedRegion.zip_code
      },

      set (value) {
        this.updateRegion({ key: 'zip_code', value })
      }
    },

    notes: {
      get () {
        return this.selectedRegion.notes
      },

      set (value) {
        this.updateRegion({ key: 'notes', value })
      }
    },

    active: {
      get () {
        return this.selectedRegion.active
      },

      set (value) {
        this.updateRegion({ key: 'active', value })
      }
    },
    phone: {
      get () {
        return this.selectedRegion.phone
      },

      set (value) {
        this.updateRegion({ key: 'phone', value })
      }
    },
    portal_active: {
      get () {
        return this.selectedRegion.portal_active
      },

      set (value) {
        this.updateRegion({ key: 'portal_active', value })
      }
    }
  },

  methods: {
    ...mapMutations('regions', ['updateRegion', 'selectRegion']),
    ...mapActions('regions', ['saveRegion', 'createRegion', 'deleteRegion']),
    setLock (bool) {
      this.lock = bool
    },
    setEdit () {
      if (this.editMode) {
        this.save()
      } else {
        this.editMode = true
        this.lock = false
      }
    },

    runDel () {
      if (this.confirmDel) {
        this.deleteRegion(this.selectedRegion.id)
        this.$emit('finished', 'region')
      } else {
        this.confirmDel = true
      }
    },

    discard () {
      this.lock = true
      this.editMode = false
      this.selectRegion(this.selectedRegion.id)
    },

    save () {
      if (this.creating) {
        this.updateRegion({ key: 'hauler_id', value: this.$route.params.id })

        this.createRegion()

        return this.$emit('finished', 'region')
      }

      this.lock = true
      this.editMode = false

      return this.saveRegion()
    }
  },

  beforeDestroy () {
    this.editMode = false
    this.lock = true
  }
}
</script>

<style lang="scss" scoped>
.mako-btn,
.hollow-btn {
  margin-right: 1rem;
}
.pad-bottom {
  padding-bottom: 32px;
}
</style>
