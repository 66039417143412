<template>
  <section :key="`${recordId}-checkboxes`">
    <div
    class="fill-types-grid"
    v-for="(box, prop) in getDebris"
    :key="`${prop}-${recordId}`"
    v-show="shouldShow(box, prop)"
    >
      <div>
        <input
        type="checkbox"
        v-model="getSelectedDebrisTypes"
        disabled="true"
        :value="prop"
        :id="`${prop}-${recordId}`">
        <label :for="`${prop}-${recordId}`">{{ prop | cap }}</label>
      </div>
      <div>
        <input v-model="getDebris[prop].note"
          class="debris-type-note"
          type="text"
          :id="`note-${prop}`"
          disabled="true"
          />
        <label class="hide" :for="`note-${prop}`">{{ prop | cap }} Note</label>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { and, capitalize, either, eq, identity, or, some } from 'kyanite'

export default {
  name: 'DebrisType',
  props: {
    wasteType: {
      type: Object,
      default: () => ({})
    },
    recordId: {
      type: String,
      default: ''
    }
  },

  filters: {
    cap (val) {
      if (val === 'yard_waste') {
        return 'Yard Waste'
      }

      return capitalize(val)
    }
  },

  computed: {
    ...mapGetters('wasteType/debris', ['getDebris', 'getSelectedDebrisTypes'])
  },

  methods: {
    // Might be worth re approaching this at a later date to see
    // If we can simplify the amount of branching that happens here
    shouldShow (val, name) {
      // Logic that accounts for certain attributes and determines if a debris fill should show or not
      return some(identity, [
        // Always show other no matter what
        eq('other', name),
        // If the fill type is stumps or yard waste, AND special or household are checked then show them in the list
        and(either(eq('yard_waste'), eq('stumps'), name), this.wasteType.household_debris),
        or(
          // If it's special and the special classification is checked
          and(
            val.special,
            this.wasteType.special_debris
          ),
          // If it's NOT special and either the construction or household classifications are checked
          and(
            !val.special,
            or(
              this.wasteType.construction_debris,
              this.wasteType.household_debris
            )
          )
        )
      ])
    }
  }
}
</script>

<style lang="scss">
.btn-small {
  padding: 0.1rem;
  font-size: 12px;
  margin-right: 0;
  line-height: 1rem;
  height: 100%;
}

.fill-types-grid {
  display: grid;
  grid-template-columns: 15% 85%;
  align-items: center;
}

.debris-type-note {
  margin: 0 0 5px 0 !important;
}
</style>
