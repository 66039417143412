<template>
    <fieldset>
        <legend v-if="legend">{{legend | toNormalCase}}</legend>
            <div class="check-container" v-for="(option, key) in options" :key="key">
                <input
                v-model="checked"
                :id="`${option.name}-${key}`"
                type="checkbox"
                :name="option.name"
                :value="option"
                :class="checkboxClass">
                <label :for="`${option.name}-${key}`" :class="labelClass" style="padding-left:30px;">{{option.label}}</label>
            </div>
    </fieldset>
</template>

<script>
import toNormalCase from '../filters/toNormalCase'
/**
 * The basic checkbox grouping that will accept a
 * legend and display list of grouped checkboxes
 */
export default {
  name: 'BaseCheckboxes',
  props: {
    /**
     * The legend to display around the checkbox grouping
     * @type {String}
     */
    legend: {
      required: false,
      type: String
    },
    checkboxClass: {
      required: false,
      type: String,
      default: 'checkbox'
    },
    labelClass: {
      required: false,
      type: String,
      default: 'checkbox-label'
    },
    /**
     * The checkbox options - each option element must have
     * a _*name*_ property and a _*label*_ property
     * @type {Array}
     */
    options: {
      required: true,
      type: Array,
      validate: options => {
        const valid = options.every(o =>
          Object.prototype.hasOwnProperty.call(o, 'name') &&
          Object.prototype.hasOwnProperty.call(o, 'label'))
        return valid
      }
    }
  },
  filters: {
    toNormalCase
  },
  data () {
    return {
      checked: []
    }
  },
  watch: {
    checked () {
      const payload = {}
      if (this.legend) payload.legend = this.legend
      payload.checked = this.checked
      this.$emit('checked', payload)
    }
  },
  mounted () {
    this.options.forEach((option) => {
      if (option.checked) {
        this.checked.push(option)
      }
    })
  }
}
</script>
