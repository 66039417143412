<template>
  <div v-show="authenticated">
    <nav class="main-nav">
      <div class="nav-wrapper">
        <a href="/quote" class="brand-logo">
          <img alt='MAKO logo' src='../assets/images/Logo_white.png' width="130">
        </a>
        <ul id="nav-mobile" class="right hide-on-med-and-down">
          <li><router-link v-show="authenticated" to="/quote">Quote</router-link></li>
          <li><router-link v-show="routeVisible('alerts')" to="/alerts">Alerts</router-link></li>
          <li><router-link v-show="routeVisible('exception')" to="/exception-report">Exception Report</router-link></li>
          <li><router-link v-show="routeVisible('availability')" to="/availability-report">
            Availability Report
          </router-link></li>
          <li v-for="model of collections.meta.models" :key="model">
            <router-link
            v-show="routeVisible(model)"
            :to="`/${model}/collection`">{{model | normalize}}</router-link>
          </li>
          <li><a v-show="authenticated" class="dropdown-button" data-activates="history">Search History</a></li>
          <li v-if="authenticated">
            {{user.firstName || user.username}}
          </li>
          <li v-else>
            <router-link to="/login">Login</router-link>
          </li>
          <li>
            <a class="dropdown-button" data-activates="settings" href="#">
              <i class="material-icons right">settings</i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <ul v-if="searchHistory.length" id="history" class="dropdown-content">
      <li v-for="(search, key) of searchHistory" :key="key">
        <router-link
        :to="'/quote/result/'+ search.place_id "
        class="truncate">{{search.term}}</router-link>
      </li>
    </ul>
    <ul id="settings" class="dropdown-content">
      <li><a href="/docs/" target="_blank">Help</a></li>
      <li><router-link to="/feedback">Feedback</router-link></li>
      <li><a @click="doLogout">Logout</a></li>
    </ul>
  </div>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import { compose, eq, find, includes, intersection, length, pathOr, pipe, prop } from 'kyanite'
import routes from '../router/routes'
import { normalize } from '../utils/stringManipulation'

export default {
  name: 'TheNavigation',
  data () {
    return {
      routes
    }
  },
  filters: {
    normalize
  },
  methods: {
    ...mapActions('session', ['logout']),
    doLogout () {
      this.logout().then(() => this.$router.replace('/login'))
    },
    routeVisible (model) {
      return pipe([
        find(compose(includes(model), prop('path'))),
        pathOr([], ['meta', 'roles']),
        intersection(this.user.roles),
        length
      ], this.routes)
    }
  },
  computed: {
    ...mapState('session', ['searchHistory', 'user', 'authenticated']),
    collections () {
      // return the collections routes
      return find(compose(eq('Collections'), prop('name')), this.routes)
    },
    alerts () {
      return find(compose(eq('Alerts'), prop('name')), this.routes)
    }
  }
}
</script>

<style>
.brand-logo{
  margin-left: 5%
}
</style>
