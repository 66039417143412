import Vue from 'vue'
import VueRouter from 'vue-router'
import { and, compose, eq, identity, intersection, length, or, some } from 'kyanite'
import routes from './routes'
import store from '../state/store'
import { apiHttp, authHttp } from '../utils/apiHttp'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

apiHttp.interceptors.response.use(identity, err => {
  const { response } = err
  const statusEq = eq(response && response.status)

  // If it's no bueno with the 401, drop the cookie and send em to login
  if (or(statusEq(401), statusEq(403))) {
    // This is in case the 401 happened during a loading process
    // Un comment once my branch get's merged in
    // store.commit('events/setLoading', false)
    store.dispatch('session/logout')
      .then(() => {
        router.replace({ path: '/login' })
      })
  }

  return Promise.reject(err)
})

const denied = next => {
  store.commit('events/runFlash', {
    message: 'You do not have permission to access this page',
    severity: 'warning',
    timeout: 1500
  })

  return next({ path: '/login', replace: true })
}

router.onError(denied)

const getToken = () => store.getters['session/getToken']

const setAuthHeaders = () => {
  const auth = authHttp.defaults.headers.common.Authorization
  const api = apiHttp.defaults.headers.common.Authorization
  const token = getToken()

  if (!auth || !api) {
    authHttp.defaults.headers.common.Authorization = token
    apiHttp.defaults.headers.common.Authorization = `Bearer: ${token}`
  }
}

/**
 * Global route protection
 * TODO: In need of some handy dandy cleanup ASAP!
 * Really could do with some love, I need to figure out a way to make this
 * Less complex than it currently is...
 */
router.beforeEach((to, _, next) => {
  const loggedIn = store.state.session.authenticated
  const hasPerms = roles => {
    if (!to.meta.permissions && !to.meta.roles) {
      return true
    }

    return compose(length, intersection(to.meta.roles), roles)
  }

  // This shouldn't be in the router
  setAuthHeaders()

  // If the route isn't protected then we don't care about login
  // Just move on
  if (to.meta.protected === false) {
    return next()
  }

  // Attempt to see if the user IS logged in but state just reset
  if (!loggedIn) {
    if (!getToken()) {
      // if we don't have a token, no point trying to fetch anything
      return next({ path: '/login', replace: true })
    }

    return store.dispatch('session/fetchSession')
      .then(({ valid, roles, expired = false }) => {
        if (expired) {
          return next({ path: '/login', replace: true })
        }

        if (some(identity, [
          !valid,
          and(valid, !hasPerms(roles))
        ])) {
          return denied(next)
        }

        return next()
      })
  }

  // Lockout for users without sufficient role
  if (!hasPerms(store.state.session.user.roles)) {
    return denied(next)
  }

  return next()
})

export default router
