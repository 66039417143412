import {
  ascendBy,
  descendBy,
  prop,
  sort
  // sortWith
} from 'kyanite'

// sort an array of objects by property descending alpha
export const objectsSort = {
  descendingByDate (obj, dateKey) {
    return sort((a, b) =>
      new Date(b[dateKey]).getTime() - new Date(a[dateKey]).getTime(), obj)
  },

  ascendingByDate (obj, dateKey) {
    return sort((a, b) =>
      new Date(b[dateKey]).getTime() - new Date(a[dateKey]).getTime(), obj)
  },

  descendingByAlpha (list, p) {
    return sort(descendBy(prop(p)), list)
  },
  ascendingByAlpha (list, p) {
    return sort(ascendBy(prop(p)), list)
  }
}

/**
 * @name sortProductList
 * @description Sorting algorithm for products in the following flow:
 * Size ASC -> Profitability DESC
 * It is also capable of handling fields that don't exist that are used in the sort
 * DOES NOT MUTATE the original array
 * @param {Array} list The array of products that should be sorted
 * @return {Array} A new and sorted array of products
 */
export const sortProductList = (list = []) => {
  // This creates a safe function set to traverse an object just in case the data doesn't exist
  const groupBy = (data) => {
    return data.reduce((acc, obj) => {
      const size = obj.size || {}
      const val = size.name || ''

      acc[val] = acc[val] || []
      acc[val].push(obj)
      return acc
    }, {})
  }

  const results = groupBy(list)
  const keys = Object.keys(results).sort()
  const sortedResults = keys.reduce((acc, group) => {
    const data = results[group].sort((a, b) => {
      // sort descending
      return parseFloat(b.profitability) - parseFloat(a.profitability)
    })
    return [...acc, ...data]
  }, [])
  return sortedResults
}
