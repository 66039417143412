<template>
    <section>
        <div class="row">
            <div class="input-field col s12">
              <input v-validate="'required'"
                  v-model="serviceArea.name"
                  :disabled="lock"
                  id="name"
                  type="text"
                  name="name">
              <label for="name">Service Area Name</label>
              <form-error :activeErrors="errors" field="name"/>
            </div>

            <div class="input-field col s12">
              <input v-model="serviceArea.search_text"
                  :disabled="lock"
                  id="search_text"
                  type="text"
                  name="search_text">
              <label for="search_text">Search Text</label>
            </div>
            <div class="input-field col s12">
              <input
              v-model="serviceArea.netsuite_name"
              :disabled="lock"
              name="netsuite"
              type="text">
              <label for="netsuite">Netsuite Name</label>
            </div>
            <div class="col s12">
              <label>Service Area Market</label>
              <smart-dropdown
                query="markets"
                :disabled="lock"
                :required="true"
                :selected="selectedMarket"
                :sort="sortMarketsAlpha"
                @selection="setMarket"
              />
              <br>
            </div>
            <div class="col s12">
              <div>
                <input
                  v-model="serviceArea.special_use"
                  id="special_use"
                  type="checkbox"
                  :disabled="lock"
                  name="special_use">
                <label for="special_use">Special Use</label>
              </div>
            </div>
            <div class="col s12">
              <input
              :disabled="lock"
              :id="`hauler-${serviceArea.id}-active`"
              type="radio" :value="1"
              v-model="serviceArea.active">
              <label :for="`hauler-${serviceArea.id}-active`">Active</label>
              <input
              :disabled="lock"
              :id="`hauler-${serviceArea.id}-inactive`"
              type="radio"
              :value="0"
              v-model="serviceArea.active">
              <label :for="`hauler-${serviceArea.id}-inactive`">Inactive</label>
            </div>
            <div class="col s12">
              <form-buttons
              @discard="discardCallback(serviceArea)"
              @lock="lockState"
              @save="saveCallback(serviceArea)"
              @delete="deleteCallback(serviceArea.id)"
              :deleteable="!!serviceArea.id"
              :editable="editable"
              :formChanged="changed"
              :formLocked="lock"
              :formValid="valid"
              />
            </div>
        </div>
    </section>
</template>

<script>
/* global Materialize */
import FormButtons from './FormButtons.vue'
import FormError from '../ui/FormError.vue'
import SmartDropdown from './SmartDropdown.vue'
import { apiCRUD } from '../utils/apiHttp'

export default {
  name: 'ServiceAreaForm',
  components: {
    'form-buttons': FormButtons,
    'form-error': FormError,
    'smart-dropdown': SmartDropdown
  },
  props: {
    /**
    * Service Area Record used to populate the form/map
    * @type {Object}
    */
    record: {
      required: false,
      type: Object
    },
    saveCallback: {
      required: false,
      type: Function,
      default (data) {
        this.$emit('save', data)
      }
    },
    deleteCallback: {
      required: false,
      type: Function,
      default (id) {
        this.$emit('delete', id)
      }
    },
    discardCallback: {
      required: false,
      type: Function,
      default () {
        this.serviceArea = { ...this.record }
        this.lockState(true)
      }
    },
    editable: {
      required: false,
      type: Boolean,
      default: () => true
    },
    locked: {
      required: false,
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      selectedMarket: this.record.market_id || null,
      serviceArea: { ...this.record },
      lock: this.locked
    }
  },
  methods: {
    lockState (state) {
      this.lock = state
    },
    saveNote (id) {
      apiCRUD.update('note', id)
    },
    setDescription (type, e) {
      this.calculations[type].description = e.target.value
    },
    setMarket (id) {
      this.selectedMarket = id
      this.serviceArea.market_id = id
    },
    sortMarketsAlpha (a, b) {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    }
  },
  computed: {
    changed () {
      const check = ['name', 'search_text', 'market_id', 'netsuite_name', 'active', 'special_use']
      return check.some(el => this.record[el] !== this.serviceArea[el])
    },
    valid () {
      return !!(this.errors.items.length === 0)
    }
  },
  updated () {
    this.$nextTick(() => {
      $(document).ready(() => {
        Materialize.updateTextFields()
      })
    })
  }
}
</script>
