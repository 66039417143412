<template>
  <section>
    <editing-bar
      :resourceType="'geonote'"
      :resourceFieldName="'id'"
      :selectedArea="selectedGeoNote"
      :isGeoNote="true"
      :enable="true"
      :map-cb="saveMap"
      class="row" />
    <div class="container">
      <div class="card">
        <div class="card-content">
          <span class="card-title">Edit Geo Note</span>
            <geo-note-form :editing="true"></geo-note-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import EditingBar from '../components/EditingBar'
import GeoNoteForm from '../components/GeoForm'

export default {
  components: {
    EditingBar,
    GeoNoteForm
  },
  computed: {
    ...mapState('geometry', ['selectedGeoNote']),
    ...mapState('session', ['user'])
  },
  methods: {
    ...mapActions('geometry', ['updateNote', 'fetchNote']),
    ...mapMutations('geometry', ['resetGeometryStore']),
    saveMap (boundaries) {
      const payload = {
        id: this.$route.params.id,
        name: this.selectedGeoNote.name,
        description: this.selectedGeoNote.description,
        active: this.selectedGeoNote.active,
        color: this.selectedGeoNote.color,
        boundaries: JSON.stringify(boundaries),
        created_by: this.selectedGeoNote.created_by,
        updated_by: this.user.id
      }

      this.updateNote(payload)
    }
  },
  beforeMount () {
    this.resetGeometryStore()
  },
  mounted () {
    this.fetchNote(this.$route.params.id)
  },
  beforeDestroy () {
    this.resetGeometryStore()
  }
}
</script>

<style scoped>
.card form.row {
  margin-bottom: 0;
}
.card .btn {
  margin-top: 0.5rem;
}
</style>
