<template>
  <form @submit.prevent="submit">
    <div class="input-field col s8">
        <input
        :disabled="lock"
        v-model="city"
        id="city"
        :required="!editing"
        name="city"
        type="text">
        <label for="name">City</label>
        <form-error field="city" :active-errors="errors"/>
    </div>

    <div class="col s4">
      <label>State</label>
      <base-dropdown
      @selected="setState"
      :required="!editing"
      :value="getMarket.state"
      :items="states"
      :options="{value:'abbreviation', display:'name'}"
      id="state"
      name="state"
      />
      <form-error field="state" :active-errors="errors"/>
    </div>

    <div class="input-field col s12">
        <input
        :disabled="lock"
        :value="getMarket.our_phone"
        @input="updateMarketVal({ our_phone: $event.target.value })"
        maxlength="10"
        minlength="10"
        id="our-phone"
        name="our phone"
        type="tel">
        <label for="our-phone" :class="{ active: getMarket.our_phone }">
          Our Phone <small>(optional)</small>
        </label>
        <form-error field="our phone" :active-errors="errors"/>
    </div>

    <div class="input-field col s12">
        <input
        :disabled="lock"
        :value="getMarket.our_address"
        @input="updateMarketVal({ our_address: $event.target.value })"
        id="our-address"
        name="our address"
        type="text">
        <label for="our address" :class="{ active: getMarket.our_address }">
          Our Address <small>(optional)</small>
        </label>
        <form-error field="our address" :active-errors="errors"/>
    </div>

    <div class="input-field col s12">
        <input
        :disabled="lock"
        :value="getMarket.alt_phone"
        @input="updateMarketVal({ alt_phone: $event.target.value })"
        id="alt-phone"
        maxlength="10"
        minlength="10"
        name="alt phone"
        type="tel">
        <label for="alt-phone" :class="{ active: getMarket.alt_phone }">
          Alternative Phone <small>(optional)</small>
        </label>
        <form-error field="alt phone" :active-errors="errors"/>
    </div>

    <div class="input-field col s12">
        <input
        :disabled="lock"
        :value="getMarket.alt_address"
        @input="updateMarketVal({ alt_address: $event.target.value })"
        id="alt-address"
        name="alt address"
        type="text">
        <label for="alt-address" :class="{ active: getMarket.alt_address }">
          Alternative Address <small>(optional)</small>
        </label>
        <form-error field="alt address" :active-errors="errors"/>
    </div>

    <div class="col s12 mt-1">
      <button class="btn save" type="submit">Save</button>
      <button class="btn" type="button">Discard</button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { omit } from 'kyanite'
import * as Sentry from '@sentry/browser'
import FormError from '../ui/FormError.vue'
import BaseDropdown from '../ui/BaseDropdown.vue'
import market from '../models/market'
import states from '../config/states'
import requestFormatter from '../utils/requestFormatter'
import { apiCRUD } from '../utils/apiHttp'

/**
 * The form to be used for adding or editing a Market
 */
export default {
  name: 'MarketForm',
  components: {
    BaseDropdown,
    FormError
  },
  props: {
    editing: {
      required: false,
      type: Boolean
    },
    /**
     * The record the form should display
     * By default just display a blank model
     */
    record: {
      required: false,
      type: Object,
      default () {
        return { ...market }
      }
    },
    /**
     * Given the scope of form responsiblitiy from parent
     * should the form offer the option to delete?
     * By default assume delete is not offered
     */
    deleteable: {
      required: false,
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      city: null,
      state: null,
      lock: false,
      states
    }
  },
  methods: {
    ...mapActions('market', ['updateMarket', 'setMarket']),
    ...mapMutations('market', ['updateMarketVal']),
    ...mapMutations('events', ['setLoading', 'runFlash']),
    setState (val) {
      this.state = val
    },
    /**
     * Event handler for delete click.
     * @public
     * @event delete
     * @type {string}
     */
    remove () {
      this.$emit('delete', this.getMarket.id)
    },
    /**
     * Event handler for discard changes click.
     * Reset the validator to clear errors
     * @public
     */
    discard () {
      this.city = null
      this.state = null
      this.$nextTick(() => this.$validator.reset())
    },
    /**
     * Event handler for save click.
     * @public
     * @event save
     * @type {object}
     */
    submit () {
      const payload = { ...this.getMarket, name: `${this.city} (${this.state})` }

      if (!this.editing) {
        this.setLoading(true)
        apiCRUD.create('markets', requestFormatter.market(payload, this.user.id))
          .then(({ data }) => {
            this.runFlash({
              message: 'Market Added Successfully',
              severity: 'success',
              timeout: 2000
            })
            this.$router.replace({ path: `/markets/${data.id}` })
            this.$emit('saved')
          })
          .catch(e => Sentry.captureException(e))
          .finally(() => this.setLoading(false))
      } else {
        this.updateMarket(omit(['_links', 'haulers', 'rolloff_pricing'], this.getMarket))
        this.$emit('saved')
      }
    },
    /**
     * Toggle locked state of the form
     * @public
     * @param {boolean} state - the locked state of the form
     */
    lockState (state) {
      this.lock = state
    }
  },
  computed: {
    ...mapGetters('market', ['getMarket']),
    ...mapState('session', ['user']),
    valid () {
      return !!(this.errors.items.length === 0)
    }
  },
  beforeMount () {
    this.setMarket(this.$route.params.id)
  }
}
</script>

<style scoped>
.mt-1 {
  margin-top: 0.3rem;
}
</style>
