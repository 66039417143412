<template>
  <div class="mt container">
    <section class="tabs-wrapper">
      <h4 class="row">Availability Report</h4>
      <div class="tabs">
        <p :class="['tab', { active: activeView === true }]" @click="activeView = true">Active Haulers</p>
        <p :class="['tab', { active: activeView === false }]" @click="activeView = false">Inactive Haulers</p>
      </div>
    </section>
    <section>
      <div class="instructions">
        Please note: The No Service filter pulls from Contract Availability while the Must Call, Window, and Days Out filters show haulers with those set on at least one size delivery in Current Availability.
      </div>
      <div class="row search-filter">
        <div class="col sm 2 filter-dropdown">
          <label for="territory">Filter By Territory</label>
          <select class="browser-default"
            id="territory"
            v-model="territoryValue"
            @change="territoryFilter($event.target.value)"
          >
            <option disabled value='' selected>pick one</option>
            <option value='null' selected>None</option>
            <option v-for="territory in territories" :key="territory.value" :value="territory.name">
              {{ territory.name }}
            </option>
          </select>
        </div>
        <div class="col sm 4 service-filters">
          <div id="no_service" :class="noService" @click="setFilters">
            <i class="material-icons">location_off</i>
            No Service
          </div>
          <div id="must_call" :class="mustCall" @click="setFilters">
            <i class="material-icons">phone</i>
            Must Call
          </div>
          <div id="window_active" :class="window" @click="setFilters">
            <i class="material-icons">event_note</i>
            Window
          </div>
          <div id="days_out" :class="daysOut" @click="setFilters">
            <i class="material-icons">filter_3</i>
            Days Out
          </div>
        </div>
        <div class="col sm 6 search">
          <input
            type="text"
            v-model="searchValue"
            v-debounce:400ms="haulerSearch"
            placeholder="Search By Hauler"
          />
        </div>
      </div>
      <availability-collection-table
        :regions="availabilityRegions"
        :loading="loading"
        @sorted='(value) => updateSort(value.field, value.sortDir)'
      />
      <transition name="fade" mode="out-in">
        <div class="row">
          <paginate
            v-show="totalPages"
            :model='""'
            :totalPages="totalPages"
            @pageChange="getAvailabilityRegions" />
        </div>
      </transition>
    </section>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import AvailabilityCollectionTable from '../components/AvailabilityCollectionTable'
import Paginate from '../components/PaginateCollection'

export default {
  name: 'AvailabilityCollection',
  components: {
    AvailabilityCollectionTable,
    Paginate
  },
  data () {
    return {
      activeView: true,
      searchPayload: {
        searchType: 'inclusive',
        sort: 'last_saved_at',
        sort_order: 'desc',
        page: 1,
        per_page: 25,
        field: null,
        operator: null,
        value: null,
        has: 'current_availability',
        conditions: []
      },
      filtersSet: [],
      searchType: '',
      searchValue: '',
      territory: '',
      territoryValue: '',
      totalPages: 0,
      currentPage: 1,
      sortField: 'last_saved_at',
      sortDir: 'desc',
      territories: [
        {
          name: 'Midwest',
          value: 'Midwest'
        }, {
          name: 'Southeast',
          value: 'Southeast'
        }, {
          name: 'Northeast',
          value: 'Northeast'
        }, {
          name: 'West',
          value: 'West'
        }
      ]
    }
  },
  computed: {
    ...mapState('regions', ['availabilityRegions', 'availabilityRegionPages']),
    ...mapState('events', ['loading']),
    noService () {
      const base = 'filter'
      if (this.filtersSet.includes('no_service')) {
        return `${base} selected`
      }
      return `${base} unselected`
    },
    mustCall () {
      const base = 'filter'
      if (this.filtersSet.includes('must_call')) {
        return `${base} selected`
      }
      return `${base} unselected`
    },
    window () {
      const base = 'filter'
      if (this.filtersSet.includes('window_active')) {
        return `${base} selected`
      }
      return `${base} unselected`
    },
    daysOut () {
      const base = 'filter'
      if (this.filtersSet.includes('days_out')) {
        return `${base} selected`
      }
      return `${base} unselected`
    }
  },
  methods: {
    ...mapMutations('events', ['setLoading']),
    ...mapMutations('regions', ['setAvailabilityRegions']),
    ...mapActions('regions', ['fetchAvailabilityRegions', 'searchRegions']),
    territoryFilter (val) {
      if (val !== 'null') {
        this.searchType = 'territory'
        this.searchPayload.field = this.searchType
        this.territoryValue = val
        this.searchPayload.value = val
        this.searchPayload.operator = '='
      } else {
        this.searchType = ''
        this.searchPayload.field = null
        this.searchPayload.operator = null
        this.searchPayload.value = null
        this.territoryValue = val
      }

      this.getAvailabilityRegions(1)
    },
    updateSort (field, sortDir) {
      this.sortField = field
      this.sortDir = sortDir
      this.searchPayload.sort = this.sortField
      this.searchPayload.sort_order = this.sortDir
      this.getAvailabilityRegions(this.currentPage)
    },
    haulerSearch () {
      if (this.searchValue.length && this.searchValue.length < 3) {
        return
      }
      this.filtersSet = []
      this.searchType = 'hauler_name'
      this.searchPayload.field = 'hauler_name'
      this.searchPayload.operator = 'ilike'
      this.searchPayload.value = this.searchValue
      this.territoryValue = ''
      this.searchPayload.conditions = []
      this.getAvailabilityRegions(1)
    },
    getAvailabilityRegions (page) {
      this.currentPage = page
      this.setLoading(true)
      this.setAvailabilityRegions([])
      this.searchPayload.page = page
      this.searchPayload.active = this.activeView

      this.searchRegions(this.searchPayload)
        .then((data) => {
          this.totalPages = this.availabilityRegionPages
        })
        .finally(() => this.setLoading(false))
    },
    setFilters (e) {
      if (this.searchType === 'hauler_name') {
        this.searchPayload.field = null
        this.searchPayload.operator = null
        this.searchPayload.value = null
        this.searchType = ''
        this.searchValue = ''
      }

      const filterName = e.target.id
      const filter = { field: filterName, value: true }

      const exists = this.filtersSet.indexOf(filterName)
      if (exists < 0) {
        this.searchPayload.conditions.push(filter)
        this.filtersSet.push(filterName)
      } else {
        this.searchPayload.conditions.splice(exists, 1)
        this.filtersSet.splice(exists, 1)
      }

      this.getAvailabilityRegions(this.currentPage)
    }
  },
  watch: {
    activeView () {
      this.getAvailabilityRegions(1)
    }
  },
  mounted () {
    this.updateSort(this.sortField, this.sortDir)
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

.search-filter {
  position: relative;
  margin-bottom: 40px;
}

.search {
  position: absolute;
  width: 338px;
  bottom: 0;
  right: 0;
  input {
    margin-bottom: 0 !important;
  }
}

.service-filters {
  display: flex;
  margin-top: 21px;
  .material-icons {
    font-size: 16px;
  }
  .filter {
    cursor: pointer;
    border: 1px solid #ededed;
    padding: 10px 12px;
    border-radius: 15px;
    margin-right: 15px;
  }
  .selected {
    background-color: #007e80;
    color: #fff;
  }
}
.filter-dropdown {
  width: 200px;
}
.instructions {
  font-size: 0.8rem;
  color: #9e9e9e;
  font-style: italic;
}

section {
  margin-top: 2em;
}

h4 {
  display: inline-block;
}

.tabs {
  display: inline;
  margin-left: 2em;

  p {
    display: inherit;
    margin: 1em;
    padding-bottom: .5em;
  }
}
</style>
