import * as Sentry from '@sentry/browser'
import { compose, includes, not, omit, pathOr, prepend, take } from 'kyanite'
import { decode } from 'jsonwebtoken'
import { removeCookie } from 'tiny-cookie'
import { authHttp, apiHttp } from '../../utils/apiHttp'
import events from './events'

const store = {
  namespaced: true,

  state: {
    authenticated: false,
    preferences: {
      showPip: true,
      searchType: 'product'
    },
    searchHistory: [],
    token: '',
    idToken: '',
    refreshToken: '',
    user: {
      id: '',
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      permissions: [],
      roles: []
    },
    loginErr: false
  },

  getters: {
    // This is sort of required because something somewhere
    // Whether it's Vuex, or the Persisted State module change the token
    // Into an object of some kind, this getter turns it back into a string
    getToken ({ token }) {
      return token
    },
    getFullName ({ firstName, lastName }) {
      return `${firstName} ${lastName}`
    }
  },

  modules: {
    events
  },

  mutations: {
    addSearchToHistory (state, search) {
      state.searchHistory = compose(take(5), prepend(search), state.searchHistory)
    },

    setUser (state, user) {
      state.user = user
    },

    setToken (state, token) {
      state.token = token
    },

    togglePip (state) {
      state.preferences.showPip = not(state.preferences.showPip)
    },

    setLoginErr (state, status) {
      state.loginErr = status
    },

    setSearchType (state, val) {
      state.preferences.searchType = val
      Sentry.configureScope(scope => scope.setExtra('search_mode', val))
    },

    setAuthenticated (state, isAuthed) {
      state.authenticated = isAuthed
    }
  },

  actions: {
    login ({ state, commit }, userInfo) {
      return authHttp.post('/oauth/token', {
        ...userInfo,
        client_secret: 'GUiSZ4qV60-JE7pGxY-t_CziTxX3ZEnMln0xmy9FRlTk4W2Lj47VVys3gAv3rlc1',
        client_id: 'b70J6YFIBcGTPFEWp0ph8DFLEyvWWZ7E',
        audience: 'inquisitor',
        grant_type: 'password',
        scope: 'openid offline_access read:users '
      })
        .then(({ data, headers }) => {
          const idToken = decode(data.id_token)
          const userInfo = idToken['https://budgetdumpster.com/user']
          const authInfo = idToken['https://budgetdumpster.com/auth']

          const user = {
            id: idToken.sub,
            email: idToken.email,
            firstName: userInfo.first_name,
            lastName: userInfo.last_name,
            username: idToken.email,
            permissions: authInfo.permissions,
            roles: authInfo.roles
          }

          commit('setUser', user)

          // set user context in Sentry so we know who is generating errors
          Sentry.configureScope(scope => {
            // identify the user
            scope.setUser({
              email: idToken.email,
              username: idToken.nickname,
              id: idToken.sub
            })
            // tag the chosen search type
            scope.setExtra('search_mode', state.preferences.searchType)
          })

          commit('setAuthenticated', true)
          const token = data.access_token

          // Add the Authorization header to our axios instance
          authHttp.defaults.headers.common.Authorization = `Bearer: ${token}`
          apiHttp.defaults.headers.common.Authorization = `Bearer: ${token}`

          commit('setToken', token)

          // Reset the error status
          return commit('setLoginErr', false)
        })
        .catch(err => {
          commit('setUser', {
            id: '',
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            username: '',
            permissions: [],
            roles: []
          })
          commit('setToken', '')

          // Set the Error status
          commit('setLoginErr', true)
          Sentry.captureException(err)

          return true
        })
    },

    logout ({ commit }) {
      Sentry.configureScope(scope => scope.setUser({}))
      const omitHeaders = omit(['Authorization'])

      removeCookie('vuex')
      // This needs to happen regardless if something went wrong or not
      commit('setUser', {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        username: '',
        permissions: [],
        roles: []
      })
      commit('setToken', '')

      commit('setAuthenticated', false)

      // Remove the Auth header from the axios instance
      authHttp.defaults.headers.common = omitHeaders(authHttp.defaults.headers.common)
      apiHttp.defaults.headers.common = omitHeaders(apiHttp.defaults.headers.common)
      return true
    },

    /**
     * Used to renew a users token with the auth API.
     * @param {Function} commit The Vuex commit function
     */
    renewSession ({ commit }) {
      return authHttp.put('/renew', { for: 'maps' })
        .then(({ data, headers }) => {
          authHttp.defaults.headers.common.Authorization = headers.authorization
          apiHttp.defaults.headers.common.Authorization = `Bearer: ${headers.authorization}`
          const permissions = pathOr([], ['auth', 'permissions'], data)
          const roles = pathOr([], ['auth', 'roles'], data)

          commit('setUser', {
            id: data.userId,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            username: data.nickname,
            permissions,
            roles
          })
          commit('setAuthenticated', true)

          return {
            valid: true, roles, permissions
          }
        })
        .catch(err => {
          commit('events/runFlash', {
            message: 'Token renewal failed, please relogin',
            severity: 'error',
            timeout: 2000
          })
          if (includes('401', err.message)) {
            return {
              valid: false, roles: [], permissions: []
            }
          }

          return Sentry.captureException(err)
        })
    },

    /**
     * This actions job is to basically refresh the store and axios with the token we have stored in a cookie
     * @param {Function} commit The Vuex commit function
     * @param {String} token The access_token stored in the users cookie
     */
    fetchSession ({ commit, dispatch }) {
      return authHttp.get('/check')
        .then(({ data, headers }) => {
          if (data !== 'Unauthorized') {
            const permissions = pathOr([], ['auth', 'permissions'], data)
            const roles = pathOr([], ['auth', 'roles'], data)

            commit('setUser', {
              id: data.userId,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone,
              username: data.nickname,
              permissions,
              roles
            })
            commit('setAuthenticated', true)

            const token = headers['x-token']

            // Add the Authorization header to our axios instance
            authHttp.defaults.headers.common.Authorization = token
            apiHttp.defaults.headers.common.Authorization = `Bearer: ${token}`

            commit('setToken', token)

            return {
              valid: true, roles, permissions
            }
          }

          return {
            valid: false, roles: [], permissions: []
          }
        })
        .catch(err => {
          // If the response is a 401, then we want to properly log out their session
          // No need to record this with sentry
          if (includes('401', err.message)) {
            commit('events/runFlash', {
              message: 'Session expired, please relogin',
              severity: 'error',
              timeout: 2000
            })

            dispatch('logout')

            return { expired: true }
          }
          commit('events/runFlash', {
            message: 'Unable to fetch session',
            severity: 'error',
            timeout: 2000
          })

          return Sentry.captureException(err)
        })
    }
  }
}

export default store
