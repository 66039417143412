<template>
    <div class="row collection">
      <table class="highlight bordered">
      <thead>
        <tr>
          <th @click="sorting('type')"> Type
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'type'">
              arrow_drop_up
            </i>
          </th>
          <th>Haulers Excluded</th>
          <th>Regions Excluded</th>
          <th @click="sorting('updated_at')">Last Saved
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'updated_at'">arrow_drop_up</i>
          </th>
          <th> Saved By </th>
          <th @click="sorting('action_date')"> End Date
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'action_date'">arrow_drop_up</i>
          </th>
          <th @click="sorting('reason')"> Reason
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'reason'">arrow_drop_up</i>
          </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody v-if="loading" class="stateful centered">
        <span class="stateful__spinner stateful__spinner--huge stateful__spinner--block"></span>
        <span class="stateful__msg">Loading Results...</span>
      </tbody>
      <bulk-entry
        v-else
        v-for="exception in exceptions"
        :key="exception.id"
        :exception="exception"
        @edit="edit(exception)"
        @deleted="reload"
      />
    </table>
    </div>
</template>

<script>
import BulkEntry from '../components/ExceptionTableBulkEntry'

export default {
  name: 'ExceptionTableBulk',
  components: {
    BulkEntry
  },
  props: {
    exceptions: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      sortField: 'action_date',
      sortDir: 'asc'
    }
  },
  methods: {
    sorting (field) {
      this.sortField = field

      if (!this.sortDir || this.sortDir === 'desc') {
        this.sortDir = 'asc'
      } else {
        this.sortDir = 'desc'
      }

      this.$emit('sorted', { field, sortDir: this.sortDir })
    },
    edit (exception) {
      this.$emit('edit', exception)
    },
    reload () {
      this.$emit('reload')
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

.centered {
  height: 100px;
  display: table-row;
  position: relative;

  span {
    position: absolute;
  }

  .stateful__spinner {
    bottom: 20px;
    left: 45%;
  }

  .stateful__msg {
    bottom: 0px;
    left: 43%;
  }
}

th .material-icons {
  vertical-align: middle;
}

.material-icons.down {
  transform: rotate(180deg);
}

.material-icons {
  transition: transform 0.3s;
  font-size: 24px;
  margin: 0 2px;
}
</style>
