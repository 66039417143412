<template>
    <transition
    enter-active-class="animated animated--slow animated--fade-in"
    leave-active-class="animated animated--slow animated--fade-out">
        <div id="flash" v-if="flash.active" :class="`flex row center-align ${flash.severity}`">
            <alert-box
            style="padding:20px;"
            :message="flash.message"
            :severity="flash.severity"
            :dismissable="flash.prompt"
            :dismissCallback="closeFlash"></alert-box>
        </div>
    </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import AlertBox from './AlertBox.vue'

export default {
  name: 'FlashBox',
  components: {
    'alert-box': AlertBox
  },
  computed: mapState('events', ['flash']),
  methods: mapMutations('events', ['closeFlash'])
}
</script>

<style lang="scss">
@import 'src/styles/base/_variables.scss';
  #flash {
    left: 0;
    max-height: 200px;
    min-height: 100px;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 99999;
    .close {
      cursor: pointer;
    }
  }
</style>
