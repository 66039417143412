
const store = {
  namespaced: true,

  state: {
    saving: false,
    loading: false,
    modal: false,
    flash: {
      active: false,
      message: '',
      prompt: false,
      severity: 'warning',
      timeout: 1000
    }
  },

  mutations: {
    toggleSaving (state) {
      state.saving = !state.saving
    },
    setLoading (state, val) {
      state.loading = val
    },

    toggleLoading (state) {
      state.loading = !state.loading
    },

    // Oh the PAIINNNN Why modals WHHHYYYY
    setModal (state, payload) {
      state.modal = payload
    },

    closeModal (state) {
      state.modal = false
    },

    runFlash (state, opts) {
      if (opts.timeout > 0) {
        Object.assign(state.flash, opts, { active: true })
        setTimeout(() => {
          Object.assign(state.flash, { active: false })
        }, opts.timeout)
      } else {
        Object.assign(state.flash, opts, { active: true, prompt: true })
      }
    },

    closeFlash (state) {
      Object.assign(state.flash, { active: false })
    }
  }
}

export default store
