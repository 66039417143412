<template>
  <div>
    <div class="topContainer">
      <div class="tableTitle">{{ tableTitle }}</div>
      <div class="noServiceDropDown" v-if="availability.no_service">
        <label :for="`noService-${availability.id}`">Reason</label>
        <select
          class="browser-default"
           id="noService"
           :name="`noService-${availability.id}`"
           v-model="availability.no_service_reason"
           v-validate.disabled="'required'"
           @change="changeEvent('no_service_reason', $event.target.value)"
         >
           <option disabled selected :value="null">pick one</option>
           <option v-for="opt in serviceOpts" :key="opt.value" :value="opt.name">
             {{ opt.name }}
           </option>
         </select>
         <form-error :activeErrors="errors" :field="`noService-${availability.id}`"/>
        </div>
        <div class="switch noServiceSwitch">
          <label>
             {{ availability.no_service ? "No Service" : "Service"}}
            <input
              type="checkbox"
              :checked="!availability.no_service"
              @input="changeEvent('no_service', !$event.target.checked, $event)"
              >
            <span class="lever"></span>
          </label>
        </div>
      </div>
      <table class='table' :class="{tableDisabled: availability.no_service}">
        <thead class='table-head'>
          <tr class='table-row'>
            <th class='table-header'></th>
            <th class='table-header'>Available</th>
            <th class='table-header'>Must Call</th>
            <th class='table-header'>Cutoff Time</th>
            <th class='table-header'>Notes</th>
          </tr>
          </thead>
        <tbody class='table-body'>
          <tr
            v-for="(option, key) in options"
            :key="option"
            :class="[(key === 'saturday' || key ==='sunday') ? 'bg-gray table-row' : 'table-row']"
          >
            <td class='table-data table-row-header'>{{ option }}</td>
            <td class='table-data'>
              <input
                :id='`${key}-${availability.id}`'
                name='active'
                type='checkbox'
                class="filled-in"
                :checked="checkProp(key, 'active')"
                :disabled="availability.no_service"
                @input="changeEvent(`${key}_${$event.target.name}`, $event.target.checked)"
              />
              <label :for="`${key}-${availability.id}`" class='checkbox'></label>
            </td>
            <td class='table-data'>
              <input
                :id='`${key}_must_call-${availability.id}`'
                name='must_call'
                type='checkbox'
                class="filled-in"
                :checked="checkProp(key, 'must_call')"
                :disabled="checkMustCall(key) || availability.no_service"
                @input="changeEvent(`${key}_${$event.target.name}`, $event.target.checked)"/>
              <label :for='`${key}_must_call-${availability.id}`' class='checkbox'></label>
            </td>
            <td class='table-data flex'>
              <div v-if="key === 'saturday' || key === 'sunday'">
                <select
                  :id='`${key}_cutoff_day-${availability.id}`'
                  name='cutoff_day'
                  class="browser-default dark-field"
                  :value="checkProp(key, 'cutoff_day')"
                  :disabled="availability.no_service"
                  @change="changeEvent(`${key}_${$event.target.name}`, $event.target.value)">
                    <option value="">None</option>
                    <option v-for="day in days" :key="day.value" :value="day.value">
                      {{ day.value }}
                  </option>
                </select>
              </div>
              <div>
                <input
                  :id='`${key}-cutoff_time-${availability.id}`'
                  name='cutoff_time'
                  type='time'
                  :value="formatTime(checkProp(key, 'cutoff_time'))"
                  :disabled="availability.no_service"
                  @input="changeEvent(`${key}_${$event.target.name}`, $event.target.value)"
                />
              </div>
            </td>
            <td class='table-data'>
              <textarea
                :value="checkProp(key, 'notes')"
                :disabled="availability.no_service"
                name="notes"
                :id='`${key}-notes-${availability.id}`'
                @input="changeEvent(`${key}_${$event.target.name}`, $event.target.value)">
              </textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="bottom-options">
        <table class="table window-options">
          <tbody class="table-body">
            <tr class="bg-grey table-row">
              <td class="table-data label-adjust">
                Window
              </td>
              <td class="table-data">
                <input
                  v-model="availability.window_active"
                  :id='`window_active_${availability.delivery_type}`'
                  name='window_active'
                  :disabled="availability.no_service"
                  type='checkbox'
                  class="filled-in"
                  @change="changeEvent($event.target.name, $event.target.checked)"
                />
                <label :for="`window_active_${availability.delivery_type}`" class='checkbox'></label>
              </td>
              <td class="table-data label-adjust">
                <span>1 to </span>
                <span>
                  <select
                    :id='`window_days_${availability.delivery_type}`'
                    v-model="availability.window_days"
                    name='window_days'
                    class="browser-default dark-field"
                    :disabled="availability.no_service"
                    @change="changeEvent($event.target.name, $event.target.value)">
                      <option value=""></option>
                      <option v-for="n in windowOptions" :value="n" :key="n">
                        {{ n }}
                    </option>
                  </select>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table mustCallCheck">
          <tbody class="table-body">
            <tr class="bg-grey table-row">
              <td class="table-data">
                Must call for ALL {{ tableTitle }}
              </td>
              <td class="table-data">
                <input
                  v-model="availability.must_call_all"
                  :id='`must_call_all_${availability.delivery_type}`'
                  :name='`must_call_all_${availability.delivery_type}`'
                  type='checkbox'
                  class="filled-in"
                  :disabled="availability.no_service"
                  @change="changeEvent('must_call_all', $event.target.checked)"
                />
                <label :for="`must_call_all_${availability.delivery_type}`" class='checkbox'></label>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>
</template>

<script>
import FormError from '../ui/FormError.vue'

export default {
  name: 'HaulerAvailabilityTable',
  inject: ['$validator'],
  components: {
    FormError
  },
  props: {
    availability: {
      type: Object,
      required: true
    },
    showColumnHeader: {
      type: Boolean,
      default: true
    },
    tableTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      windowOptions: [2, 3, 4, 5, 6, 7, 8, 9, 10],
      serviceOpts: [
        {
          name: 'Billing',
          value: 'Billing'
        }, {
          name: 'Internal',
          value: 'Internal'
        }, {
          name: 'Pricing',
          value: 'Pricing'
        }, {
          name: 'Service Area',
          value: 'Service Area'
        }
      ],
      options: {
        same_day: 'Same Day',
        next_day: 'Next Day',
        two_day_notice: '2 Day Notice',
        saturday: 'Saturday',
        sunday: 'Sunday'
      },
      days: [{
        value: 'Monday'
      }, {
        value: 'Tuesday'
      }, {
        value: 'Wednesday'
      }, {
        value: 'Thursday'
      }, {
        value: 'Friday'
      }, {
        value: 'Saturday'
      }, {
        value: 'Sunday'
      }]
    }
  },
  methods: {
    checkProp (type, prop) {
      const property = `${type}_${prop}`

      if (this.availability.must_call_all && !!this.availability[`${type}_active`]) {
        this.availability[`${type}_must_call`] = true
      }

      return this.availability[property]
    },
    formatTime (time) {
      if (time === null || time === '00:00:00') {
        return null
      }
      const timeParts = time.split(':')
      return `${timeParts[0]}:${timeParts[1]}`
    },
    checkMustCall (key) {
      return this.availability.must_call_all && this.availability[`${key}_active`]
    },
    changeEvent (name, value) {
      if (name === 'no_service' && value === false) {
        this.$emit('change', {
          id: this.availability.id,
          name: 'no_service_reason',
          value: null
        })
      }

      if (name === 'window_active' && value === false) {
        this.$emit('change', {
          id: this.availability.id,
          name: 'window_days',
          value: null
        })
      }

      if (name === 'window_days' && value === '') {
        this.$emit('change', {
          id: this.availability.id,
          name: 'window_active',
          value: false
        })
        value = null
      }

      if (name === 'window_days' && value !== '' && !this.availability.window_active) {
        this.$emit('change', {
          id: this.availability.id,
          name: 'window_active',
          value: true
        })
      }
      if (name === 'window_active' && value === true && this.availability.window_days === null) {
        this.$emit('change', {
          id: this.availability.id,
          name: 'window_days',
          value: 2
        })
      }

      // if we are setting a cutoff time but haven't set a day yet, set it to Friday
      if (name === 'saturday_cutoff_time' &&
        (this.availability.saturday_cutoff_day === null || this.availability.saturday_cutoff_day === '')) {
        this.$emit('change', {
          id: this.availability.id,
          name: 'saturday_cutoff_day',
          value: 'Friday'
        })
      } else if (name === 'sunday_cutoff_time' &&
      (this.availability.sunday_cutoff_day === null || this.availability.sunday_cutoff_day === '')) {
        this.$emit('change', {
          id: this.availability.id,
          name: 'sunday_cutoff_day',
          value: 'Friday'
        })
      }

      this.$emit('change', {
        id: this.availability.id,
        name,
        value
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import 'src/styles/base/_variables.scss';

.table {
  margin: 0 0 1.5rem 0;
  width: 100%;
  border-collapse: separate;

  &-head {
    font-weight: bold;
    border: 1px solid $lighter-gray;
  }

  &-header {
    font-size: 1.125rem;
    min-width: 105px;
    padding: 0.25rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }

  &-row {
    border-bottom: 1px solid $light-gray;

    &-header {
      font-size: 1.125rem;
    }
  }

  &-body {
    border: 1px solid $light-gray;
    box-shadow: 0px 3px 5px rgba(242, 242, 242, 0.5);
  }

  &-data {
    border-top: 1px solid $light-gray;
    min-width: 105px;
    padding: 0.25rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }
}

.tableDisabled {
  color: #0000006b
}

.bg-gray {
  background-color: #f8f8f8;
  textarea {
    background-color: $white;
  }
}

.cutoff-time {
  min-width: 150px;
}

.checkbox {
  margin-left: 15px;
  margin-top: 9px;
}
.flex {
  display: flex;
  flex-direction: row;
  div {
    flex-grow: 1;
    vertical-align: middle;
  }
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 76px;

  div.tableTitle {
    font-size:24px;
    text-align: left;
    margin: auto;
    flex-grow: 1;
  }

  div.noServiceDropDown {
    flex-basis: 18%;
    margin-right: 10px;
    select {
      height: auto;
    }
  }

  div.noServiceSwitch {
    margin: auto;
  }

}

.table-body > .table-row:last-child > .table-data:last-child {
  border-bottom-right-radius: 5px;
}

.table-body > .table-row:first-child > .table-data:last-child {
  border-top-right-radius: 5px;
}

.table-body > .table-row:first-child > .table-data:first-child {
  border-top-left-radius: 5px;
}
.table-body > .table-row:last-child > .table-data:first-child {
  border-bottom-left-radius: 5px;
}

.table-body > .table-row > .table-data:last-child {
  border-right: 1px solid $light-gray;
}

.table-body > .table-row:last-child > .table-data {
  border-bottom: 1px solid $light-gray;
}

.table-body > .table-row > .table-data:first-child {
  border-left: 1px solid $light-gray;
  border-right: 1px solid $light-gray;
}

.bottom-options {
  width: 100%;
  justify-content: space-between;
  display: flex;
  td {
    padding: 5px 15px;
  }
  .window-options, .mustCallCheck {
    display: flex;
    width: 100%;
    border: 1px solid #c5c5c5;
    margin: 0 20px 0 0;
    background-color: #e5e5e5;
    border-radius: 4px;
    select {
      display: inline-flex;
      width: auto;
      margin: 0 10px 0 0;
      padding: 0;
      height: auto;
    }
  }
  .mustCallCheck {
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
  }
  .label-adjust {
    padding-top: 5px;
  }
}

</style>
