<template>
    <tbody>
      <tr @click="showDetails = !showDetails">
        <td>{{exception.type | toNormalCase}}</td>
        <td>{{counts.haulers}}</td>
        <td>{{counts.regions}}</td>
        <td><span v-html="formatUpdatedAt(exception.updated_at)" /></td>
        <td><user-name :userId="exception.updated_by || ''" /></td>
        <td>
          <i class="material-icons checkBack" v-if="exception.action_type.toLowerCase() === 'checkback'">notifications</i>
          <i class="material-icons expire" v-if="exception.action_type.toLowerCase() === 'expirationdate'">hourglass_bottom</i>
          <i class="material-icons permanent" v-if="exception.action_type.toLowerCase() === 'permanent'">lock_clock</i>
          <span v-if="exception.action_type.toLowerCase() !== 'permanent'">{{formatDate(exception.action_date)}}</span>
          <span v-if="exception.action_type.toLowerCase() === 'permanent'">Permanent</span>
        </td>
        <td>{{exception.reason | toNormalCase}}</td>
        <td v-on:click.stop="">
          <div v-show="!showDelete" class="icons">
            <div class="icon">
              <i class="fa fa-pencil" @click="editException()"></i>
            </div>
            <div class="icon">
              <i class="fa fa-trash" @click="showDelete = true"></i>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="showDelete">
        <td colspan="8">
          <div class="right">
            <span class="confirmation">Are you sure you wish to delete this Exception?</span>
            <button class="mako-btn--danger-white-text" type="button" id="delete" @click="handleDelete">Yes, Delete</button>
            <button class="hollow-btn--danger" type="button" id="cancelDelete" @click="showDelete = false">No, Cancel</button>
          </div>
        </td>
      </tr>
      <tr v-if="showDetails" class="availability-row">
        <td>&nbsp;</td>
        <td colspan="2">
          <div v-if="exception.type.toLowerCase() === 'availabilitybydate'">
            <span class="text-bold">Unavailable Dates:</span> {{formatDateRange(exception.start_date, exception.end_date)}}
          </div>
          <div v-if="exception.type.toLowerCase() === 'closure'">
            <span class="text-bold">Closure:</span> {{formatDateRange(exception.start_date, exception.end_date)}}
          </div>
          <div v-if="exception.type.toLowerCase() === 'dayofweek'">
            <span class="text-bold">Schedule by Day of Week:</span> No {{daysOfWeek(exception.days_of_week)}}
          </div>
          <div v-if="exception.type.toLowerCase() === 'availabilitybyzone' && exception.availability_action === 'mustCall'">
            <span class="text-bold">Next Available Date:</span> <span class="warning">Must Call</span>
          </div>
          <div v-if="exception.type.toLowerCase() === 'availabilitybyzone' && exception.next_available_date !== null && exception.availability_action === 'nextAvailableDate'">
            <span class="text-bold">Next Available Date:</span> {{formatDate(exception.next_available_date)}}
          </div>
          <div v-if="exception.type.toLowerCase() === 'servicearea'">
            <span class="text-bold">Service Area:</span> {{exception.boundaries_description}}
          </div>
          <div v-if="exception.type.toLowerCase() === 'noservice'">
            <span class="text-bold">No Service</span>
          </div>
        </td>
        <td colspan="5">
          <div v-show="!['closure', 'dayofweek'].includes(exception.type.toLowerCase())">
            <span class="text-bold">Service Types: </span> {{exception.delivery_type| servicesNormalCase}}
          </div>
          <div v-show="exception.type.toLowerCase() !== 'closure'">
            <span class="text-bold">Sizes: </span> {{exception.sizes_text || 'All'}}
          </div>
        </td>
      </tr>
    </tbody>
</template>

<script>
import { mapActions } from 'vuex'
import { daysOfWeek, format, formatRange } from '../utils/time'
import UserName from '../components/UserName'
import toNormalCase from '../filters/toNormalCase'
import servicesNormalCase from '../filters/servicesNormalCase'

export default {
  name: 'ExceptionTableBulkEntry',
  components: {
    UserName
  },
  props: {
    exception: {
      type: Object,
      required: true
    }
  },
  filters: {
    toNormalCase,
    servicesNormalCase
  },
  data () {
    return {
      showDelete: false,
      showDetails: false
    }
  },
  computed: {
    counts () {
      return this.exception.bulk_counts.excluded || {}
    }
  },
  methods: {
    ...mapActions('exceptions', ['deleteException']),
    editException () {
      this.$emit('edit', this.exception)
    },
    handleDelete () {
      const id = this.exception.id

      this.deleteException(id)
        .then((success) => {
          this.showDelete = false

          if (success) {
            this.$emit('deleted', id)
          }
        })
    },
    daysOfWeek (days) {
      return daysOfWeek(days).map((day) => {
        return day.name
      }).join(', ')
    },
    formatDate (date) {
      return format(date, 'M/DD/YYYY')
    },
    formatDateRange (start, end) {
      return formatRange(start, end)
    },
    formatUpdatedAt (date) {
      if (Number.isNaN(Date.parse(date))) {
        return ''
      }

      const updatedDate = new Date(`${date}.400Z`)

      return `${format(updatedDate, 'M/DD/YYYY')} ${format(date, 'h:mm a')}`
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

.availability-row {

  .table-title {
    position: relative;
    color: $dark-gray;
    text-align: left;
    div {
       margin-left: 20px;
    }
  }

  .table-title:after {
    bottom: 10px;
    top: 20px;
    right: 24px;
    height: 60%;
  }

  .divider-header, .divider-td  {
    position: relative;
  }

  .table-title:after, .divider-header:before,
  .divider-header:after, .divider-td:after, .divider-td:before {
    content: "";
    position: absolute;
    width: 1px;
    background: $dark-gray;
  }

   .divider-header:after,  .divider-header:before {
     top: 3px;
     height: 120%
   }

  .divider-header:before {
    left: -20px;
  }

  .divider-header:after {
    right: 20px;
  }

  .divider-td:after, .divider-td:before {
    bottom: 12px;
    height: 120%;
  }

  .divider-td:after {
    right: 20px;
  }

  .divider-td:before {
    left: -20px;
  }

  .availability-details {
    thead {
      border-bottom: none;
      th {
        padding-bottom: 0px;
      }
    }
  }
}

.material-icons {
  transition: transform 0.3s;
  font-size: 24px;
  margin: 0 2px;
}
.delivery-window {
    text-align: center;
}
.text-bold {
  font-weight: bold;
}
span.warning {
  color: red;
}

div.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  div.icon {
    border: 1px solid $light-gray;
    background-color: $lighter-gray;
    padding: .2em;
    cursor: pointer;
    font-size: 110%;
  }
}
</style>
