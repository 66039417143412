<template>
    <div class="row">
      <geo-search
        class="col s12"
        :callback="initSearch"
      />
      <base-radio-input v-show="preferenceVisible && preferences.searchType"
          :radios="prefOpts"
          :selected="preferences.searchType"
          @checked="setSearchType"/>
          <!-- dom placeholder for google text search-->
        <div id="placeholder"></div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import GeoSearch from '../components/GeoSearch.vue'
import BaseRadioInput from '../ui/BaseRadioInput.vue'

/**
 * Component composed of the base search w/ typeahead
 * _(using Google Maps places autocomplete)_ and radio buttons to control the user search preference
 */
export default {
  name: 'QuoteSearch',
  props: {
    preferenceVisible: {
      required: false,
      type: Boolean,
      default: () => false
    }
  },
  components: {
    'geo-search': GeoSearch,
    'base-radio-input': BaseRadioInput
  },
  data () {
    return {
      prefOpts: [
        {
          id: 'hauler',
          label: 'Haulers'
        },
        {
          id: 'product',
          label: 'Dumpsters'
        }
      ]
    }
  },
  computed: {
    ...mapState('session', ['preferences'])
  },
  methods: {
    ...mapMutations('session', ['setSearchType']),
    /**
     * Transition to the quote results page with the result of the places autocomplete place_id
     * @param  {object} item - the selected result from the autocomplete dropdown
     * @return {undefined} Redirect to the quote results page
     * @public
     */
    initSearch (item = {}) {
      this.$router.push(`/quote/result/${item.place_id}`)
    }
  }
}
</script>
