<template>
  <base-search
    ref="search"
    :label=label
    :placeholder=placeholder
    :autocomplete="true"
    :autocompleteConfig="autocompleteConfiguration"
    :callback=onSubmit
    :enterLocked="true">
  </base-search>
</template>

<script>
/* global google */
import { mapGetters, mapState, mapMutations } from 'vuex'
import BaseSearch from '../ui/BaseSearch.vue'
import { placesAutocomplete } from '../services/googleMapsQuery'
import { objectsSort } from '../utils/sort'

/**
 * Component composed of the base search w/ typeahead
 */
export default {
  name: 'GeoSearch',
  props: {
    callback: {
      required: true,
      type: Function
    },
    label: {
      required: false,
      type: String,
      default: () => 'Search Zip or Address'
    },
    placeholder: {
      required: false,
      type: String,
      default: ''
    },
    searchTypes: {
      required: false,
      type: Array,
      default: () => ['geocode']
    },
    useHistory: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  components: {
    'base-search': BaseSearch
  },
  data () {
    const history = this.useHistory ? this.searchHistory : []
    return {
      autocompleteConfiguration: {
        data: { ...history },
        type: 'category',
        source: this.typeAhead
      }
    }
  },
  computed: {
    ...mapState('session', ['searchHistory']),
    autoCompleteHistory () {
      return this.searchHistory.map(e => ({
        label: e.term, category: 'History', place_id: e.place_id
      }))
    },
    // bias specifics are kept in the search module (not persisted)
    ...mapGetters('search', ['searchIsBiased']),
    ...mapState('search', ['bias', 'searchSessionId'])
  },
  methods: {
    ...mapMutations('session', ['addSearchToHistory', 'setSearchType']),
    clear () {
      this.$refs.search.clear()
    },
    onSubmit (item = {}) {
      if (this.useHistory) {
        this.addSearchToHistory({ term: item.label, place_id: item.place_id })
      }

      this.callback(item)
    },
    /**
     * Format the predictions from the gmap api response
     * into jquery ui friendly objects
     * @param {array} predictions - an array of prediction objects
     * @return {mixed}
     */
    formatPredictions (predictions) {
      const accepted = ['locality', 'premise', 'subpremise', 'postal_code', 'route', 'street_address', 'administrative_area_level_2']
      if (predictions) {
        return predictions
          .filter(p => p.types.some(type => accepted.includes(type)))
          .map(p => {
            if (p.types.includes('postal_code')) {
              return {
                label: p.description || p.formatted_address, category: 'Zipcode', place_id: p.place_id
              }
            }

            if (p.types.includes('street_address') || p.types.includes('route') || p.types.includes('premise') || p.types.includes('subpremise')) {
              return {
                label: p.description, category: 'Addresses', place_id: p.place_id
              }
            }

            if (p.types.includes('locality')) {
              return {
                label: p.description, category: 'Cities', place_id: p.place_id
              }
            }

            if (p.types.includes('administrative_area_level_2')) {
              return {
                label: p.description, category: 'Counties', place_id: p.place_id
              }
            }
            return {
              label: 'NA',
              category: 'NA',
              place_id: 'NA'
            }
          })
      }
      return [{
        label: 'No Results',
        category: 'No Results',
        place_id: 'none'
      }]
    },
    /**
     * Callback to perform in jquery-ui implementation
     * @param  {object} request - the request object of the Google Maps API call
     * @param  {object} response - the response object containing any results of the API call
     * @return {mixed}
     * @public
     */
    typeAhead (request, response) {
      const callback = this.typeAheadCallback(request, response)
      if (typeof request.term !== 'undefined' && request.term !== '') {
        const location = new google.maps.LatLng(this.bias.lat, this.bias.lng)
        placesAutocomplete(request.term, callback, location, { types: this.searchTypes })
      }
      return this.useHistory ? response(this.autoCompleteHistory) : response
    },
    /**
     * The callback for the autocomplete src
     * @param {object} request - the request from the input
     * @param {object} response - the response used to return data to typeahead
     * @return {function} - curried function that will have the request and response already
     * @public
     */
    typeAheadCallback (request, response) {
      return autoCompletePredictions => {
        const autoComplete = this.formatPredictions(autoCompletePredictions)
        const history = this.useHistory ? this.autoCompleteHistory : []
        const isNum = /^\d+$/.test(request.term)

        // Place service for zip code look up fallback
        const service = new google.maps.places.PlacesService(this.$refs.search.$el.querySelector('input'))
        if (request.term.length === 5 &&
        isNum &&
        autoComplete.every(res => res.category !== 'Zipcode')) {
          // fallback for zips not found by autocomplete
          service.textSearch({ query: request.term }, predictions => {
            if (predictions.length !== 0) {
              const zipResult = this.formatPredictions(predictions)[0]
              autoComplete.unshift(zipResult)
              return response(objectsSort.ascendingByAlpha(autoComplete, 'category').concat(history))
            }
            return false
          })
        }
        return response(objectsSort.ascendingByAlpha(autoComplete, 'category').concat(history))
      }
    }
  }
}
</script>
