<template>
  <ul v-show="totalPages" class="pagination">
    <li :class="{ disabled: pageMin }">
      <a href="#" @click.prevent="pageChange(currentPage - 1)" :disabled="pageMin">
        <i class="material-icons">chevron_left</i>
      </a>
    </li>
      <li
        :class="['pagination-element', page === currentPage ? 'active' : '']"
        v-for="page in totalPages"
        :key="page">
          <a href="#" @click.prevent="pageChange(page)">{{ page }}</a>
      </li>
    <li :class="{ disabled: pageMax }">
      <a @click="pageChange(currentPage + 1)" :disabled="pageMax">
        <i class="material-icons">chevron_right</i>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PaginateCollection',
  props: {
    model: {
      type: String,
      required: true
    },
    totalPages: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  watch: {
    totalPages () {
      this.currentPage = 1
    }
  },
  computed: {
    pageMin () {
      return this.currentPage === 1
    },
    pageMax () {
      return this.currentPage === this.totalPages
    }
  },
  methods: {
    pageChange (page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page
        this.$emit('pageChange', page)
      }
    }
  }
}
</script>

<style scoped>
.pagination li:hover {
  cursor: pointer;
}

.pagination {
  display: flex;
}

.pagination .pagination-element.active {
  background-color: #FA8D29;
}
</style>
