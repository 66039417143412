<template>
  <div>
    <google-map />
    <div v-if="noteablesVisible" id="map-notification-container">
      <div
      @mouseover="toggleHover(note.id)"
      @mouseout="toggleHover()"
      class="map-notification"
      v-for="note in mergedNoteables"
      :key="note.id"
      :style="`background-color:${note.color}`">
        <i class="material-icons">notification_important</i>
        <b>{{note.name}}</b>
        <br>
        <span v-html="note.description"></span>
      </div>
    </div>
    <div id="body" class="container">
      <div class="row">
        <div class="map-links right">
          <a :href="'https://www.google.com/maps/search/?api=1&query='+encodedSearchString" target="_blank">
            View On Google Maps
          </a>
          <a :href="'https://www.bing.com/maps?where1='+encodedSearchString+'&sty=c'" target="_blank">
            View On Bing Maps
          </a>
        </div>
        <!-- these are important to hide for all users who do not have permission -->
        <div v-if="isAdmin" class="admin-feature-filters">
          <b>Hide Features:</b>&nbsp;
          <span v-for="filter in adminFilters" :key="filter">
            <input :id="filter" v-model="selectedAdminFilters" :name="filter" type="checkbox" :value="filter">
            <label :for="filter">{{filter | unKabob}}</label>&nbsp;
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <div class="card">
            <div class="card-content">
              <span class="card-title">
                {{ placeFormattedAddress}} <small>Market: {{searchMarket.name || ''}}</small>
              </span>
              <section>{{formattedTime}} ({{timezoneOffset.timeZoneName}})</section>
              <quote-search />
            </div>
          </div>
          <div class="card">
            <div class="card-content pss-card">
              <i class="fa-solid fa-toilets-portable fa-xl"></i>
              <span>Does the customer need a portable toilet?</span>
              <a href="https://docs.google.com/presentation/d/18HvwaelVuZnjS1G_deUkt-OTrBSST5Ki" target="_blank"><base-button :callback="noop" class="pss-button">View PSS Rate Card</base-button></a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showNoteRow" class="row">
        <div  v-if="!isEmpty(geoNotes)"
          :class="['col', isEmpty(getSpecialUseAreas) ? 's12' : 's6']">
          <div class="card">
            <div class="card-content">
              <span class="card-title">Geo Notes</span>
              <ul class="collapsible" data-collapsible="accordion">
                <li v-for="n in geoNotes" :key="n.id">
                  <div class="collapsible-header">
                    <div class="key-box left" :style="`background-color:${n.color}`">
                      &nbsp;
                    </div>
                    <span class="hauler-name"><b>{{ n.name }}</b></span>
                  </div>
                  <div class="collapsible-body">
                    <span>{{ n.description || 'No Description Given...' }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s2 pr-0">
          <div class="filter-wrapper">
              <div class="filter" v-if="filterable" :key="`filters-${$route.params.id}`">
                  <b>Filter By</b>
                  <filter-options
                    v-for="(options, key) in filterable"
                    @checked="filterBy"
                    :legend="key"
                    :options="options"
                    :title="key"
                    :key="key"
                    :collapsible="(collapsibleFilters.includes(key))"
                  />
              </div>
          </div>
        </div>
        <div class="col s10">
          <div class="top-filter-wrapper align-vertical">
            <div class="align-vertical">
              <span style="margin: 0 10px 0 0px"><b>View By</b></span>
              <base-switch
                  @change="setSearchType"
                  :options="['hauler', 'product']"
                  :selected="preferences.searchType"/>
            </div>
            <div class="align-vertical">
              <span style="margin: 0 10px 0 0"><b>Show Nearby</b></span>
              <base-button :callback="setNearby" id="show-nearby" style="margin: 0">Expand Search</base-button>
            </div>
            <div class="align-vertical">
              <span style="margin: 0 10px 0 0"><b>Hauler Type</b></span>
              <base-options
                @selected="setHaulerType"
                :options=getOptions()
                :text=getOptionsText()
              ></base-options>
            </div>
          </div>
          <div v-if="searchResults && pricing && pricing.length">
            <component
              :is="`${preferences.searchType}-results`"
              :haulers="sortedHaulerPricing"
              :pricing="pricing"/>
          </div>
          <div v-else>
            <div class="center-align">
              <h2>No Results</h2>
              <p>
                Unfortunately, we cannot service this area and we do not offer out of network products here
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as Sentry from '@sentry/browser'
import { XmlEntities } from 'html-entities'
import {
  difference,
  encase,
  isEmpty,
  isNil,
  includes,
  omit,
  prop,
  reverse,
  sortBy
} from 'kyanite'
import BaseButton from '../ui/BaseButton.vue'
import BaseSwitch from '../ui/BaseSwitch.vue'
import FilterOptions from '../components/FilterOptions.vue'
import GoogleMap from '../components/GoogleMap.vue'
import HaulerPricingResults from '../components/HaulerPricingResults.vue'
import ProductPricingResults from '../components/ProductPricingResults.vue'
import QuoteSearch from '../components/QuoteSearch.vue'
import { colors } from '../config/colors'
import { sortProductList } from '../utils/sort'
import actionLogger from '../services/actionLogging'
import BaseOptions from '@/ui/BaseOptions.vue'

/**
 * TODO - All of the sorting functions should be abstracted.
 * Too much repitiion currently. Or, filters should be
 * refactored to use presorted data from the price objects
 */
function uniqueMapHaulerRelation (array, property, filterable) {
  const copy = array.filter(x => x.rolloff_pricing.length > 0)
  return copy.map(area => area.rolloff_pricing.map(price => ({
    id: (price[property] !== null) ? price[property].id : '',
    filterable,
    label: (price[property] !== null) ? price[property].name : '',
    name: property
  })))
    .reduce((arr, el) => {
      arr.push(...el)
      return arr
    }, [])
    .reduce((arr, el) => {
      if (arr.every(e => e.id !== el.id)) arr.push(el)
      return arr
    }, [])
}

export default {
  name: 'QuoteResult',
  components: {
    BaseOptions,
    GoogleMap,
    'base-button': BaseButton,
    'filter-options': FilterOptions,
    'hauler-results': HaulerPricingResults,
    'product-results': ProductPricingResults,
    'base-switch': BaseSwitch,
    'quote-search': QuoteSearch
  },
  filters: {
    // just replace the skewers with spaces
    unKabob (val) {
      return val.replace('-', ' ')
    }
  },
  data () {
    return {
      buffer: 0,
      filtered: {
        serviceTypes: ['no_service'],
        sizes: [],
        wasteTypes: [],
        tonnage: [],
        availability: [],
        costStructures: [],
        serviceAreas: []
      },
      collapsibleFilters: ['wasteTypes'],
      filterable: {
        serviceTypes: [
          {
            filterable: 'serviceTypes',
            label: 'Hide No Service',
            checked: true,
            name: 'serviceType',
            id: 'no_service'
          },
          {
            filterable: 'serviceTypes',
            label: 'Hide "Must Call"',
            name: 'serviceType',
            id: 'must_call'
          }
        ],
        sizes: [],
        tonnage: [],
        wasteTypes: [
          {
            filterable: 'wasteTypes',
            label: 'C&D',
            name: 'wasteType',
            id: 'construction_debris'
          },
          {
            filterable: 'wasteTypes',
            label: 'Household',
            name: 'wasteType',
            id: 'household_debris'
          },
          {
            filterable: 'wasteTypes',
            label: 'Yard',
            name: 'wasteType',
            id: 'yard_waste'
          },
          {
            filterable: 'wasteTypes',
            label: 'Stumps',
            name: 'wasteType',
            id: 'stumps'
          },
          {
            filterable: 'wasteTypes',
            label: 'Appliances',
            name: 'wasteType',
            id: 'appliances'
          },
          {
            filterable: 'wasteTypes',
            label: 'Electronics',
            name: 'wasteType',
            id: 'electronics'
          },
          {
            filterable: 'wasteTypes',
            label: `Mattress
            /Boxsprings`,
            name: 'wasteType',
            id: 'mattress'
          },
          {
            filterable: 'wasteTypes',
            label: 'Concrete',
            name: 'wasteType',
            id: 'concrete'
          },
          {
            filterable: 'wasteTypes',
            label: 'Dirt',
            name: 'wasteType',
            id: 'dirt'
          },
          {
            filterable: 'wasteTypes',
            label: 'Brick',
            name: 'DebrisTypes',
            id: 'brick'
          },
          {
            filterable: 'wasteTypes',
            label: 'Block',
            name: 'wasteType',
            id: 'block'
          },
          {
            filterable: 'wasteTypes',
            label: 'Gravel',
            name: 'wasteType',
            id: 'gravel'
          },
          {
            filterable: 'wasteTypes',
            label: 'Asphalt',
            name: 'wasteType',
            id: 'asphalt'
          },
          {
            filterable: 'wasteTypes',
            label: 'LEED',
            name: 'wasteType',
            id: 'leed'
          },
          {
            filterable: 'wasteTypes',
            label: 'Other',
            name: 'wasteType',
            id: 'other'
          }
        ],
        availability: [
          {
            filterable: 'Availability',
            id: 'today',
            label: 'Today',
            name: 'availability'
          }, {
            filterable: 'Availability',
            id: 'tomorrow',
            label: 'Tomorrow',
            name: 'availability'
          }, {
            filterable: 'Availability',
            id: 'saturday',
            label: 'Saturday',
            name: 'availability'
          }, {
            filterable: 'Availability',
            id: 'sunday',
            label: 'Sunday',
            name: 'availability'
          }
        ],
        costStructures: [
          {
            filterable: 'Cost Structures',
            id: '0c12fe62530e86d',
            label: 'Flat Rate',
            name: 'costStructures'
          }, {
            filterable: 'Cost Structures',
            id: '388b6b4ce0681d6',
            label: 'Haul + Disposal',
            name: 'costStructures'
          }, {
            filterable: 'Cost Structures',
            id: 'a063c9fbcbfe24e',
            label: 'Commercial',
            name: 'costStructures'
          }
        ],
        serviceAreas: []
      },
      haulers: [],
      hiddenServiceAreas: [],
      location: {},
      pricing: [],
      // resultPreference: null,
      searchString: null,
      adminFilters: ['geo-note', 'special-use', 'locale', 'msa', 'marker'],
      // track if any of the admin map filters are checked
      selectedAdminFilters: [],
      geoNotes: [],
      colors,
      haulerType: 'inNetwork',
      hasInNetwork: false,
      hasOutOfNetwork: false
    }
  },
  methods: {
    isEmpty,
    ...mapActions('geometry', ['fetchNotes']),
    ...mapActions('time', ['initializeTimezone', 'startClock', 'killClock']),
    ...mapActions('search', [
      'performGooglePlacesLookup',
      'performMapApiSearch',
      'renderSearchMap']),
    ...mapActions('map', [
      'toggleHover',
      'removeFeatureFromCollection',
      'restoreFeatureToCollection',
      'hidePolygon',
      'showPolygon',
      'hideFeature',
      'showFeature',
      'toggleMarker'
    ]),
    ...mapMutations('map', ['setLastHover']),
    ...mapMutations('geometry', ['updateBoundaryCollection', 'setAreaList']),
    ...mapMutations('filter', [
      'setServiceType',
      'setProductList',
      'setServiceAreas',
      'setSize',
      'setTonnage',
      'setWasteType',
      'setAvailability',
      'resetFilters',
      'setCostStructures',
      'setServiceTypes'
    ]),
    ...mapMutations('session', ['setSearchType', 'setHaulerType']),
    ...mapMutations('search', ['resetSearch', 'setBuffer']),
    ...mapMutations('events', ['setLoading', 'runFlash', 'closeFlash']),
    noop () {
      // do nothing
      // temporary for the button callback
    },
    /**
     * Click handler for show nearby
     */
    setNearby () {
      // set the buffer for the next search
      this.setBuffer(40)
      // perform a search with the 40 mile buffer
      this.getPageData(this.placeId)
    },
    getOptions () {
      const options = []

      if (this.hasInNetwork) options.push({ title: 'In Network', active: this.haulerType === 'inNetwork', type: 'inNetwork', callback: this.setHaulerType })
      if (this.hasOutOfNetwork) options.push({ title: 'Out of Network', active: this.haulerType === 'outOfNetwork', type: 'outOfNetwork', callback: this.setHaulerType })
      if (this.hasInNetwork && this.hasOutOfNetwork) options.push({ title: 'All', active: this.haulerType === 'all', type: 'all', callback: this.setHaulerType })

      return options
    },
    getOptionsText () {
      if (!this.hasInNetwork && !this.hasOutOfNetwork) {
        return 'No Network Options'
      } else if (!this.hasInNetwork) {
        return 'No In Network Options'
      } else if (!this.hasOutOfNetwork) {
        return 'No Out Network Options'
      }

      return ''
    },
    // This method REALLY REALLY Needs love
    // TODO: Make this not suck: way to much looping, SUPER slow there IS a better way
    filterBy ({ legend, checked }) {
      const legendMethods = {
        serviceTypes: this.setServiceTypes,
        sizes: this.setSize,
        tonnage: this.setTonnage,
        wasteTypes: this.setWasteType,
        availability: this.setAvailability,
        costStructures: this.setCostStructures,
        serviceAreas: this.setServiceAreas
      }

      // Encase it so it softly fails if our data for some reason disappears
      // This way the rest of the page won't be stopped or prevented from working
      encase(legendMethods[legend], checked)

      // remove the real data heavy properties from the search results
      const logFilters = ['alerts', 'boundaries', 'created_at', 'region', 'service_area_defined']

      const logProducts = this.searchResults.map(item => omit(logFilters, item))

      // determine whether the action was a check action or uncheck action
      let filterCheckValue = 1
      let category = ''
      let selected = ''

      // filter to figure out which record was checked or unchecked so it can be logged
      const removed = this.filtered[legend].filter(value => !checked.includes(value))
      const added = checked.filter(value => !this.filtered[legend].includes(value))

      if (removed.length > 0) {
        filterCheckValue = 0
        category = removed[0].filterable
        selected = removed[0].label
      }

      if (added.length > 0) {
        filterCheckValue = 1
        category = added[0].filterable
        selected = added[0].label
      }

      // Update our filtered object
      this.filtered[legend] = checked

      const logData = {
        search_id: this.searchId,
        filter_category: category,
        filter_selected: selected,
        filter_value: filterCheckValue,
        products: JSON.stringify(logProducts)
      }

      actionLogger.logAzure('filters', { data: logData })

      // if all areas are unchecked show everything again
      if (!this.serviceAreas.length) return this.searchResultsIds.forEach(this.restoreFeatureToCollection)

      // hide the areas that aren't checked (diff total set vs checked)
      difference([this.serviceAreas, this.searchResultsIds]).forEach(this.removeFeatureFromCollection)
      // show the areas that are checked
      return this.serviceAreas.forEach(this.restoreFeatureToCollection)
    },
    setHaulerType (type) {
      this.haulerType = type
      this.setupPricing()
    },
    generateFilterValues () {
      // Set unique mapped arrays for checkboxes
      this.filterable.serviceAreas = this.searchResults.map(area => ({
        id: area.id, filterable: 'Service Areas', label: `${area.hauler.name} ${area.name}`, name: 'service_area'
      }))

      this.filterable.sizes = uniqueMapHaulerRelation(this.searchResults, 'size', 'Sizes').sort((a, b) => {
        if (isNil(b)) return 1
        if (isNil(a)) return -1
        return parseInt(a.label.split(' ')[0], 10) - parseInt(b.label.split(' ')[0], 10)
      })

      this.filterable.tonnage = this.searchResults.reduce((acc, item) => {
        return [...acc, ...item.rolloff_pricing]
      }, [])
        .reduce((a, price) => {
          const tonnage = parseFloat(price.tonnage)
          if (!a.includes(tonnage)) {
            a = [...a, tonnage]
          }
          return a
        }, [])
        .sort((a, b) => {
          return parseFloat(a, 10) - parseFloat(b, 10)
        })
        .map((p) => {
          return { id: `${p}`, filterable: 'Tonnage', label: `${p} T`, name: 'tonnage' }
        })
    },
    /**
     * @name getPageData
     * @description Takes the gmaps place id and converts it to geographic data
     * @param {*} val can be a placeId string or a lat/lng object
     * @param {string} format what are you geocoding with placeId (default) or location (lat/lng)
     * @returns {void}
     */
    getPageData (val, format = 'placeId') {
      this.pricing = []
      this.setLastHover('')
      this.resetFilters()
      this.closeFlash()
      this.setLoading(true)
      // find this place info on gmaps
      this.performGooglePlacesLookup({ format, val })
        // use the location info to search our map api for pricing, market, and locale
        .then(() => this.performMapApiSearch())
        // build a feature collection for the map based on the search results
        .then(() => {
          this.haulerType = 'inNetwork'
          // TODO - straighten this out, like, yesterday
          this.setupPricing()
          // this isn't great either
          this.generateFilterValues()
          // renders the map with service areas, locale, geo notes, msa etc.
          this.renderSearchMap()
        })
        .catch(e => {
          // clear search data to avoid any confusion
          this.resetSearch()
          // just making extra sure
          this.pricing = []
          // The Goog likes to 200 us failures so....here ya go
          if (e === 'OVER_QUERY_LIMIT') {
            this.runFlash({
              message: 'There was a Google rate limiting failure - please rerun your search',
              severity: 'error',
              timeout: -1
            })
          }
          Sentry.captureException(e)
        })
        .finally(() => this.setLoading(false))
    },

    /**
     * Extract the important stuff from the api response,
     * flatten, sort by size, sort by waste type
     * TODO - This is the first thing to go when separate endpoints are available
     * for pricing, service area, etc.
     */
    setupPricing () {
      this.hasInNetwork = false
      this.hasOutOfNetwork = false
      const prices = this.searchResults.map((serviceArea, index) => {
        const copy = { ...serviceArea }
        copy.color = colors[index]
        return copy
      })
        .reduce((acc, serviceArea) => {
          if (serviceArea.hauler.type === 'inNetwork') this.hasInNetwork = true
          if (serviceArea.hauler.type === 'outOfNetwork') this.hasOutOfNetwork = true
          if (this.haulerType === 'all' || serviceArea.hauler.type === this.haulerType) {
            serviceArea.rolloff_pricing.forEach(price => {
              const priceObject = {}
              const copy = price
              // add important service area info
              if (serviceArea.region !== null) {
                priceObject.alerts = [...serviceArea.alerts, ...serviceArea.region.alerts]
                priceObject.service_area_name = XmlEntities.decode(serviceArea.name)
                priceObject.service_area_color = serviceArea.color
                priceObject.service_area_id = serviceArea.id
                priceObject.permit_info = XmlEntities.decode(serviceArea.permit_info)
                priceObject.service_area_defined = serviceArea.service_area_defined
                // welp, now this has all the hauler notes
                priceObject.hauler = serviceArea.hauler
                priceObject.region_name = serviceArea.region.name
                priceObject.region_exceptions = serviceArea.region.exceptions
                priceObject.region_notes = serviceArea.region.notes
                priceObject.region_phone = serviceArea.region.phone
                priceObject.region_we_win_winter = serviceArea.region.we_win_winter ?? false
                priceObject.portal_active = serviceArea.region.portal_active
                priceObject.hauler_name = XmlEntities.decode(serviceArea.hauler.name)
                priceObject.availability = serviceArea.rolloff_pricing.availability
                priceObject.region_availability = serviceArea.region.availability
                priceObject.current_availability = serviceArea.region.current_availability
                priceObject.hauler_search_text = XmlEntities.decode(serviceArea.search_text)
                priceObject.hauler_id = serviceArea.hauler.id
                priceObject.netsuite_name = serviceArea.netsuite_name
                priceObject.notes = copy.notes
                priceObject.search_id = this.searchId
                priceObject.actual_size = Object.assign({}, price.size)
                priceObject.profitability = parseFloat(price.profitability)
                if (priceObject.profitability === 0.00) {
                  priceObject.profitability = (parseFloat(price.price) - parseFloat(price.cost)).toFixed(2)
                }
                if (price.sell_as) {
                  // set the actual volume to the existing size id
                  // copy.actual_volume = price.size.id
                  // get the size name for the sell as id and set that to the size name
                  let found = this.sizes.find(size => size.id === price.sell_as)
                  if (found) {
                    copy.size.name = found.name
                  }

                  found = this.sizes.find(size => size.id === price.size.id)
                  if (found) {
                    copy.actual_volume = found.name
                  }

                  copy.size.id = price.sell_as
                }

                // add the rest of the price related properties
                Object.keys(copy).forEach(key => {
                  // if the property is already set don't override it
                  if (!priceObject[key]) {
                    priceObject[key] = copy[key]
                  }
                })
                // push to accumulator
                acc.push(priceObject)
              }
            })
          }

          return acc
        }, [])
      if (this.hasOutOfNetwork && !this.hasInNetwork && this.haulerType !== 'outOfNetwork') {
        this.setHaulerType('outOfNetwork')
        return
      }
      this.pricing = sortProductList(prices)
      this.setProductList(this.pricing)
    }
  },
  computed: {
    ...mapGetters('serviceAreas', ['getSpecialUseAreas']),
    ...mapGetters('time', ['formattedTime']),
    ...mapState('session', ['preferences']),
    ...mapGetters('search',
      [
        'searchLocaleBoundaries',
        'searchResultsBoundaries',
        'searchMarketBoundaries',
        'searchIsBiased',
        'encodedSearchString',
        'mergedNoteables',
        'searchGeoNoteIds',
        'searchSpecialUseIds',
        'searchResultsIds',
        'searchId'
      ]),
    ...mapState('time', ['timezoneOffset']),
    ...mapState('geometry', ['boundariesCollection']),
    ...mapState('filter', ['serviceAreas']),
    ...mapState('size', ['sizes']),
    ...mapState('wasteType', ['waste-types']),
    ...mapState('search', [
      'placeGeometry',
      'placeId',
      'placeTypes',
      'searchResults',
      'searchMarket',
      'placeFormattedAddress'
    ]),
    ...mapState('map', ['removedFeatures', 'marker']),
    ...mapState('session', ['search', 'user']),
    isAdmin () {
      return this.user.roles.includes('Super Admin')
    },
    noteablesVisible () {
      return !includes('geo-note', this.selectedAdminFilters) && !includes('special-use', this.selectedAdminFilters)
    },
    bufferLat () {
      return parseFloat(this.buffer / 69).toFixed(2)
    },
    showNoteRow () {
      return !isEmpty(this.getSpecialUseAreas) || !isEmpty(this.geoNotes)
    },
    sortedHaulers () {
      const haulers = Object.assign(this.searchResults, {})
      return reverse(sortBy(prop('aggregate_ranking'), haulers))
    },
    sortedHaulerPricing () {
      return this.sortedHaulers.map(hauler => {
        hauler.rolloff_pricing
          .sort((a, b) => {
            if (b.size === null) return 1
            if (a.size === null) return -1
            return parseInt(a.size.name.split(' ')[0], 10) - parseInt(b.size.name.split(' ')[0], 10)
          })
          .sort((a, b) => {
            if (b.waste_type === null) return 1
            if (a.waste_type === null) return -1

            const aCap = a.waste_type.name.toUpperCase()
            const bCap = b.waste_type.name.toUpperCase()
            if (aCap < bCap) return -1
            if (aCap > bCap) return 1
            return 0
          })
        return hauler
      })
    }
  },
  watch: {
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @description Watch the selected filter array and when a change is made mutate the map
     */
    selectedAdminFilters () {
      // array of feature ids to hide
      const [hide, show] = [
        // mutable copy of the selected boxes to hide
        this.selectedAdminFilters.slice(),
        // mutable copy of the inverse to show
        difference([this.selectedAdminFilters, this.adminFilters])
      ]
      // gross but this isn't a long term feature
      if (hide.includes('geo-note')) {
        this.searchGeoNoteIds.forEach(id => {
          this.hidePolygon(id)
          this.hideFeature(id)
        })
      }
      if (show.includes('geo-note')) {
        this.searchGeoNoteIds.forEach(id => {
          this.showPolygon(id)
          this.showFeature(id)
        })
      }

      if (hide.includes('special-use')) {
        this.searchSpecialUseIds.forEach(id => {
          this.hidePolygon(id)
          this.hideFeature(id)
        })
      }
      if (show.includes('special-use')) {
        this.searchSpecialUseIds.forEach(id => {
          this.showPolygon(id)
          this.showFeature(id)
        })
      }

      if (hide.includes('marker')) this.toggleMarker(false)
      if (show.includes('marker')) this.toggleMarker(true)

      // handle mutating the map accordingly
      hide.forEach(id => this.hideFeature(id))
      show.forEach(id => this.showFeature(id))
    }
  },
  mounted () {
    $('.collapsible').collapsible()
    // listens for pin drag events and re-runs the search with the new lat/lng combo
    this.marker.addListener('dragend', e => {
      // Note the pin drag in sentry for transparency
      Sentry.addBreadcrumb({
        category: 'map_action',
        message: 'User has dragged the map pin.',
        level: Sentry.Severity.Info
      })
      // to avoid pinging google's geocode too often we need to buffer these calls
      const offset = new Promise(resolve => {
        setTimeout(() => {
          this.getPageData(e.latLng, 'location')
          resolve()
        }, 1500)
      })
      this.setLoading(true)
      offset.finally(() => this.setLoading(false))
    })
  },
  updated () {
    $('.collapsible').collapsible()
  },
  beforeRouteEnter (to, _, next) {
    next(vm => {
      Promise.all([
        vm.$store.dispatch('size/fetchSizes'),
        vm.$store.dispatch('wasteType/fetchWasteTypes')
      ]).then(() => vm.getPageData(to.params.id))
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.setBuffer(0)
    // this should be refactored at some point
    this.getPageData(to.params.id)
    next()
  },
  beforeDestroy () {
    this.$off()
  },
  beforeRouteLeave (to, from, next) {
    this.resetSearch()
    this.killClock()
    this.resetFilters()
    /* global google */
    // kill the listener so we don't accrue a bunch if the user takes a funky path
    google.maps.event.clearListeners(this.marker, 'dragend')
    next()
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/base/_variables.scss";
[v-cloak] {
  display: hidden
}
.col.s2.pr-0 {
  padding-right: 0;
}
.row {
  margin-bottom: 0.75rem;
  .col {
    &.filter-box {
      padding-left: 0;
      padding-bottom: 0.75rem;
    }
  }
}
.filter-wrapper {
  background-color: $light-gray;
  padding-bottom: 0.75rem;
  width: 100%;
  .filter {
    fieldset.filter {
      margin: 0 auto;
      width: 96%;
    }
  }
}
.top-filter-wrapper {
  background-color: $light-gray;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;

  span {
    white-space: nowrap;
  }

  button {
    height: 25px;
    margin: 0px;
    line-height: 6px;
  }

  .mako-btn {
    padding: 10px;
  }
}
.align-vertical {
  display: flex;
  align-items: center;
}
#show-nearby {
  font-size: 0.75em;
  margin: 0 0 0.75rem 0;
  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
#map-notification-container {
  font-size: 12px;
  height:400px;
  width:200px;
  position: absolute;
  top: 20%;
  left: 5px;
  overflow: auto;
  .map-notification{
    background-color: rgb(255, 0, 0);
    color: white;
    padding:5px;
    margin-bottom:5px;
  }
}
.admin-feature-filters{
  padding-bottom: 15px;
}
div.not-available {
  color: #aaaaaa;
  h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  padding-bottom: 20px;
}
div.no-results, div.not-available-states {
  display: flex;
  flex-direction: row;
}
div.rate-card {
  text-align: center;
  margin-bottom: 30px;
  a {
    font-size: 20px;
  }
}
ul.states {
  font-size: 20px;
  margin: 0 auto;
  text-align: left;
  li.no-bullet {
    padding-bottom: 20px;
  }
  li {
    list-style-type: none;
  }
  padding-bottom: 25px;
}

.loading-indicator {
  span {
    position: absolute;
  }

  .stateful__spinner {
    margin-top: 2em;
    left: 50%
  }
}

.map-links a {
  color: $alert !important;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: 1em;
}

.card-content.pss-card {
  text-align: right;
  padding: .5em 1em;

  .fa-toilets-portable {
    margin-right: .5em;
  }

  .pss-button {
     padding: .3em .5em;
     margin-left: 1em;
  }
}

</style>
