<template>
  <section>
    <form @submit.prevent='save' novalidate>
      <div class='title'>
        <h3>Contract Availability</h3>
        <div>
          <button class='mako-btn save-btn' :disabled='disabled' v-show="valid" type='submit'>Save Changes</button>
          <button @click.prevent='discard' :disabled='disabled' class='mako-btn mako-btn--danger-white-text' type='button'>Discard Changes</button>
        </div>
      </div>
      <p class="disclaimer">
        * Please note, changes made to contract availability will not take effect until midnight.
      </p>
      <hauler-availability-table
        :availability='regionAvailability.newDeliveries'
        tableTitle='Deliveries'
        @change='(value) => updateStagedData("newDeliveries", value)'
      />
      <hauler-availability-table
        :availability='regionAvailability.emptyReturn'
        tableTitle='Empty & Return'
        :show-column-header='false'
        @change='(value) => updateStagedData("emptyReturn", value)'
      />
      <hauler-availability-table
        :availability='regionAvailability.pickUp'
        tableTitle='Pickup'
        :show-column-header='false'
        @change='(value) => updateStagedData("pickUp", value)'
      />
      <div class='bottom-actions'>
        <button class='mako-btn save-btn' :disabled='disabled' v-show="valid" type='submit'>Save Changes</button>
        <button @click.prevent='discard' :disabled='disabled' class='mako-btn mako-btn--danger-white-text' type='button'>Discard Changes</button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import { path, omit } from 'kyanite'
import HaulerAvailabilityTable from './HaulerAvailabilityTable'
import { apiHttp } from '../utils/apiHttp'

export default {
  name: 'HaulerAvailability',
  components: { HaulerAvailabilityTable },
  data () {
    return {
      stagedData: {
        newDeliveries: {},
        emptyReturn: {},
        pickUp: {}
      },
      empty: {
        same_day_active: false,
        same_day_cutoff_time: '00:00:00',
        same_day_must_call: false,
        same_day_notes: null,
        next_day_active: false,
        next_day_cutoff_time: '00:00:00',
        next_day_must_call: false,
        next_day_notes: null,
        two_day_notice_active: false,
        two_day_notice_cutoff_time: '00:00:00',
        two_day_notice_must_call: false,
        two_day_notice_notes: null,
        saturday_active: false,
        saturday_cutoff_time: '00:00:00',
        saturday_must_call: false,
        saturday_notes: null,
        saturday_cutoff_day: null,
        sunday_active: false,
        sunday_cutoff_time: '00:00:00',
        sunday_must_call: false,
        sunday_notes: null,
        sunday_cutoff_day: null,
        must_call_all: false,
        no_service: false,
        window_active: false,
        window_days: null
      },
      disabled: true
    }
  },
  computed: {
    ...mapState('regions', ['selectedRegion']),
    ...mapGetters('regions', ['selectedRegionCurrentAvailability']),
    regionAvailability () {
      const { newDeliveries } = this.selectedRegion.availability
      const { pickUp } = this.selectedRegion.availability
      const { emptyReturn } = this.selectedRegion.availability

      return {
        newDeliveries: this.mapStagedData('newDeliveries', newDeliveries),
        emptyReturn: this.mapStagedData('emptyReturn', emptyReturn),
        pickUp: this.mapStagedData('pickUp', pickUp)
      }
    },
    valid () {
      return this.errors.items.length === 0
    }
  },
  methods: {
    ...mapActions('regions', ['fetchRegions']),
    ...mapMutations('regions', ['selectRegion']),
    ...mapMutations('events', ['setLoading', 'runFlash']),
    updateStagedData (type, value) {
      const val = { id: value.id }
      val[value.name] = value.value
      this.toggleDisabled(false)
      const stagedData = path([type, val.id], this.stagedData) || {}
      // Nested objects need to use Vue.set as described here https://vuejs.org/v2/guide/reactivity.html#For-Objects.
      this.$set(this.stagedData[type], val.id, { ...stagedData, ...val })
    },
    mapStagedData (type, availability) {
      if (!availability) {
        const avail = Object.assign({}, this.empty)
        avail.delivery_type = type
        avail.region_id = this.selectedRegion.id
        avail.id = type
        return { ...avail, ...this.stagedData[type][type] }
      }
      const stagedData = path([type, availability.id], this.stagedData) || {}
      return { ...availability, ...stagedData }
    },
    save () {
      this.$validator.validateAll()
        .then(valid => {
          if (valid) {
            this.setLoading(true)
            const requests = []
            Object.keys(this.stagedData).forEach(type => {
              const baseURL = '/availability'
              Object.keys(this.stagedData[type]).forEach(id => {
                const url = `${baseURL}/${id}`

                // If "id" is the type here, we don't have one yet and need to make a new one
                const request = (id !== type)
                  ? apiHttp.get(url)
                    .then(({ data }) => apiHttp.put(
                      url,
                      this.prepareAvailabilityForUpdate({ ...data, ...this.stagedData[type][id] })
                    ))
                  : apiHttp.post(
                    'availabilities',
                    { ...this.regionAvailability[type], ...this.stagedData[type][type] }
                  )

                requests.push(request)
              })
            })
            return Promise.all(requests)
              .then(() => this.runFlash({
                message: 'Contract availability saved',
                severity: 'success',
                timeout: 1500
              }))
              .catch(() => this.runFlash({
                message: 'Contract Availability may have been partially saved',
                severity: 'error',
                timeout: 1500
              }))
            // Fetching the region will update all contract availability with the most up to date data.
              .then(() => this.fetchRegions(this.$route.params.id))
              .then(() => {
                // This may feel redundant but the selected region does not refresh when we fetch regions.
                // selectedRegion probably should have been a computed property based off an ID and current regions.
                this.selectRegion(this.selectedRegion.id)
                this.discard()
              })
              .finally(() => {
                this.setLoading(false)
                this.toggleDisabled(true)
              })
          }
          return false
        })
    },
    discard () {
      this.stagedData = {
        newDeliveries: {},
        emptyReturn: {},
        pickUp: {}
      }
      this.toggleDisabled(true)
    },
    toggleDisabled (val) {
      this.disabled = val
    },
    /**
     * Mostly a convenient way for us to do any data prep before we do an update. An example being is when we retrieve availability
     * from MAKO we are returned a "size" but sending the "size" back to MAKO results in an error.
     *
     * @param data
     */
    prepareAvailabilityForUpdate (data) {
      let newData = data

      Object.keys(newData).forEach(key => {
        if (key.includes('_cutoff_time') && (!newData[key] || !newData[key].length)) {
          newData[key] = '00:00:00'
        }
      })

      if (newData.size !== undefined) {
        newData = omit(['size'], newData)
      }

      return newData
    }
  }
}
</script>

<style lang='scss' scoped>
@import 'src/styles/base/_variables.scss';

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.save-btn {
  margin-right: 1rem;
}

.bottom-actions {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

p.disclaimer {
  color: $gray;
  size: 16px;
}
</style>
