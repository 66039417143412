<template>
    <ul class="collapsible" data-collapsible="accordion">
        <li :class="isOpen" >
            <div class="collapsible-header" :class="isOpen">
                <i
                v-if="isSortable"
                class="drag-handle large material-icons">
                    drag_handle
                </i>
                <!-- @slot Use this slot to set the accordions heading -->
                <slot name="head"></slot>
            </div>
            <div class="collapsible-body">
                <!-- @slot Use this slot to fill the accordion body -->
                <slot name="body"></slot>
            </div>
        </li>
    </ul>
</template>

<script>
/* eslint-env jquery */

/**
 * A __functional__ component to wrap accordion content
 * and provide optional slots for header and body
 */
export default {
  name: 'BaseAccordion',
  props: {
    /**
     * Just lets the component know if it should display a drag handle
     */
    sortable: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
     * Allow for manual open state being set
     */
    open: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    isOpen () {
      return this.open ? 'active' : ''
    },
    isSortable () {
      return !!this.sortable
    }
  },
  mounted () {
    $('.collapsible').collapsible()
  }
}
</script>

<style scoped lang="scss">
    .collapsible-body{
        max-height:400px;
        overflow: scroll;
    }
</style>
