<template>
  <div class="price-card">
    <div class="price-card__content">
      <section class="price-card__section">
        <p class="price-card__head">Dumpster Size</p>
        <p class="price-card__text"> {{ item.size.name }}</p>
        <p class="price-card__notes">Sell as: {{ sizeName(item.sell_as) }}</p>
      </section>

      <section class="price-card__section">
        <p class="price-card__head">Waste Type</p>
        <p class="price-card__text">{{ item.waste_type.name }}</p>
      </section>

      <section class="price-card__section">
        <p class="price-card__head">Customer Price</p>
        <p class="price-card__text">${{ parseFloat(item.price).toFixed(2) }}</p>
      </section>

      <section class="price-card__section">
        <p class="price-card__head">Tonnage</p>
        <p class="price-card__text">{{ item.tonnage }}</p>
        <button
        class="btn price-card__btn"
        @click="$emit('edit')">Edit</button>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEmpty, find, propOr } from 'kyanite'
import placehold from '../filters/placehold'
import truncat from '../filters/truncat'

export default {
  name: 'PriceCard',
  props: {
    color: {
      type: String,
      default: '#B42525'
    },
    item: {
      type: Object,
      default: () => ({
        id: '',
        sell_as: '',
        size: { name: '???' },
        waste_type: { name: '???' },
        price: '0.00',
        product_notes: 'Something should probably go here?'
      })
    }
  },

  filters: {
    placehold: placehold('N/A'),
    truncat: truncat(85)
  },

  methods: {
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name sizeName
     * @description translate a size id to its english name
     * @param {string} id the id of a given size (volume)
     * @returns {string} the plain english name of a size (volume) id
     */
    sizeName (id) {
      if (isEmpty(id)) return 'NA'
      const found = find(s => s.id === id, this.sizes)

      return propOr('Size not found', 'name', found)
    }
  },

  computed: {
    ...mapState('size', ['sizes'])
  }
}
</script>

<style lang="scss" scoped>
  // gray: #9e9e9e
  // darkened: #616161
  // lightened: #e0e0e0
  .price-card {
    max-height: 393px;
    position: relative;
    margin: 0 0 1rem 1rem;
    background-color: #FFF;
    transition: box-shadow .25s;
    text-align: center;
    border-radius: 0 0 0.1rem 0.1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    &:nth-child(3n + 4), &:first-child {
      margin-left: 0;
    }
    &__content {
      padding: 0.75rem;
    }
    &__section {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 0.6rem;
      &:last-child {
        border-bottom: 0
      }
    }
    &__head {
      font-size: 12px;
      margin: 0.2rem 0 0.2rem 0;
      color: #616161;
    }
    &__notes {
      color: #9e9e9e;
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-size: 12px;
    }
    &__text {
      color: #616161;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 110%;
      margin: auto;
    }
    &__btn {
      margin-top: 7%;
      &--reset {
        margin-top: 0;
      }
    }
  }
</style>
