<template>
  <div class="options-container">
    <div
      v-for="option in options"
      :key="option.title"
      class="option"
    >
      <button
        @click="option.callback(option.type)"
        :class="{active: option.active}"
      >
        {{option.title}}
      </button>
    </div>
    <span><i>{{text}}</i></span>
  </div>
</template>

<script>

export default {
  name: 'BaseOptions',
  props: {
    options: {
      required: true,
      type: Array,
      validate: val => {
        val.every(obj => Object.prototype.hasOwnProperty.call(obj, 'title') &&
            Object.prototype.hasOwnProperty.call(obj, 'active') &&
            Object.prototype.hasOwnProperty.call(obj, 'type') &&
            Object.prototype.hasOwnProperty.call(obj, 'callback'))
      }
    },
    text: {
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/base/_variables.scss";
.active{
  border-color: $primary;
  color: $primary;
  background-color: #cfdcdc;
}
.option {
  display: inline-block;
}
button {
  background-color: transparent;
  border-width: thin;
  border-style: solid;
  border-color: $gray;
  color: $dark-gray;
}
span {
  font-size: 12px;
  margin: 4px;
}
</style>
