<template>
  <div class="container">
    <h1>{{ loginTxt }}</h1>
    <transition
    enter-active-class="animated--fade-in"
    leave-active-class="animated--fade-out">
      <div v-show="showAlert" :class="['animated alert', alertDeets.class]">{{ alertDeets.text }}</div>
    </transition>
    <form id="loginForm" v-if="!resetForm" @submit.prevent="doLogin">
      <div class="input-field">
        <input
        v-model="username"
        required
        id="userEmail"
        type="email"
        name="username">
        <label for="userEmail">Email</label>
      </div>
      <div class="input-field">
        <input
        v-model="password"
        required
        id="userPassword"
        type="password"
        name="password">
        <label for="userPassword">Password</label>
      </div>
      <button id="loginBtn" class="btn" type="submit" :disabled="processing">Login</button>
      <button id="forgotBtn" @click="resetForm = true" class="btn" type="button" :disabled="processing">
        Forgot Password
      </button>
    </form>
    <form id="forgotPassword" @submit.prevent="resetPassword(username)" v-else>
      <div class="input-field">
        <input
        v-model="username"
        required
        id="userEmail"
        type="email"
        name="username">
        <label for="userEmail">Email</label>
      </div>
      <button class="btn" @click="showAlert = true" type="submit">Submit</button>
      <button id="cancelForgot" @click="resetData" class="btn" type="button">Cancel</button>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import * as Sentry from '@sentry/browser'
import axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      resetForm: false,
      showAlert: false,
      username: '',
      password: '',
      reset: false,
      processing: false,
      email: ''
    }
  },
  computed: {
    ...mapState('session', ['loginErr']),
    loginTxt () {
      return this.resetForm ? 'Reset Password' : 'Login'
    },
    alertDeets () {
      if (this.loginErr) {
        return { text: 'An error occurred during login', class: 'alert--error' }
      }

      if (this.resetForm) {
        return { text: 'A reset password email has been sent to you!', class: 'alert--success' }
      }

      return { text: 'Processing Login...', class: 'alert--success' }
    }
  },
  methods: {
    ...mapActions('session', ['login']),
    ...mapMutations('events', ['setLoading']),
    ...mapMutations('session', ['setLoginErr']),

    resetData () {
      this.showAlert = false
      this.resetForm = false
    },

    doLogin () {
      this.setLoginErr(false)
      this.setLoading(true)
      this.processing = true
      this.showAlert = true
      this.login({
        username: this.username,
        password: this.password
      })
        .then(err => {
          if (!err) {
            this.$router.replace('/quote')
          }
        })
        .finally(() => {
          this.processing = false
          this.setLoading(false)
        })
    },

    resetPassword () {
      return axios({
        method: 'POST',
        url: 'https://budgetdumpster.auth0.com/dbconnections/change_password',
        data: {
          client_id: 'b70J6YFIBcGTPFEWp0ph8DFLEyvWWZ7E',
          connection: 'Username-Password-Authentication',
          email: this.username
        }
      })
        .then(() => {
          this.showAlert = true
        })
        .catch(err => Sentry.captureException(err))
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  border: 3px solid #000;
  text-align: center;
  line-height: 0.7rem;
  padding: 0.75rem;
  border-radius: 0.2rem;
  &--error {
    background-color: #ef5350;
    border-color: #ec9c9c;
  }
  &--success {
    background-color: #66bb6a;
    border-color: #81c784;
  }
}
</style>
