import * as Sentry from '@sentry/browser'
import { compose, eq, find, findIndex, prop, update } from 'kyanite'
import { authHttp } from '../../utils/apiHttp'

// keep track of requested id's to stop additional
// requests for promises that haven't resolved
const activeRequests = []
const findById = (id, { users }) => find(compose(eq(id), prop('userId')), users)

export default {
  namespaced: true,
  state: {
    // Store resolved user names for later
    users: []
  },
  mutations: {
    addUser: (state, user) => {
      const foundUser = findIndex(compose(eq(user.userId), prop('userId')), state.users)

      if (foundUser >= 0) {
        state.users = update(foundUser, user, state.users)
      } else {
        state.users.push(user)
      }
    }
  },
  getters: {
    getUserById: state => id => findById(id, state),

    getUserEmailById: state => id => {
      const user = findById(id, state)

      if (user && Object.hasOwnProperty.call(user, 'email') && user.email !== '') {
        return user.email
      }

      return 'Unknown User'
    },

    getUserFullNameById: state => id => {
      const user = findById(id, state)

      if (user) {
        return `${user.firstName} ${user.lastName}`
      }

      return 'Unknown User'
    }
  },
  actions: {
    fetchUser: ({ commit, state }, id) => {
      const user = findById(id, state)
      const defaultUser = {
        firstName: 'Unknown',
        lastName: 'User',
        nickname: 'N/A',
        userId: id,
        email: ''
      }

      if (user) {
        return user
      }
      // Check for existing in state and included in current request stack
      if (!id) {
        return defaultUser
      }

      if (activeRequests.includes(id)) {
        return commit('addUser', defaultUser)
      }

      // Add to requested stack
      activeRequests.push(id)
      return authHttp.get(`/api/v2/users/${id}`, {
        params: {
          fields: 'email,username,user_id,nickname,name,user_metadata,app_metadata'
        }
      })
        .then(({ data }) => {
          commit('addUser', data)

          return data
        })
        .catch(err => {
          commit('addUser', defaultUser)

          Sentry.captureException(err)

          return defaultUser
        })
        .finally(() => activeRequests.splice(activeRequests.indexOf(id), 1))
    }
  }
}
