import AlertSingle from '../pages/AlertSingle'
import HaulerSingle from '../pages/HaulerSingle.vue'
import Login from '../pages/Login.vue'
import MarketSingle from '../pages/MarketSingle.vue'
import Quote from '../pages/Quote.vue'
import QuoteResult from '../pages/QuoteResult.vue'
import GeoSingle from '../pages/GeoSingle.vue'
import Collection from '../pages/Collection.vue'
import AlertCollection from '../pages/AlertCollection.vue'
import AvailabilityCollection from '../pages/AvailabilityCollection.vue'
import ExceptionCollection from '../pages/ExceptionCollection.vue'
import Feedback from '../pages/Feedback.vue'

const pageDetails = {
  'geo-notes': {
    headers: ['name', 'created_at', 'updated_at', 'created_by'],
    perPage: 10
  },
  alerts: {
    headers: [
      'hauler_name',
      'service_area_name',
      'product_name',
      'alert_type',
      'created_at',
      'updated_at',
      'expires',
      'check_back',
      'created_by'
    ],
    canAdd: false,
    searchWith: 'text'
  }
}

const models = ['geo-notes', 'haulers', 'markets']

/**
 * In the short run we are using basic auth and the standard numeric roles
 * 1. Admin
 * 2. Manager
 * 3. VR/Customer Service
 * 4. Sales
 */
const routes = [
  {
    name: 'Root',
    path: '/',
    meta: {
      protected: true
    },
    redirect: '/quote'
  },
  {
    name: 'Login',
    path: '/login',
    meta: {
      protected: false
    },
    component: Login
  },
  // This would be a placeholder to the alerts collection till we find a better solution
  {
    name: 'Alerts',
    path: '/alerts',
    meta: {
      protected: true,
      roles: ['Super Admin']
    },
    component: AlertCollection
  },
  {
    name: 'Exception Report',
    path: '/exception-report',
    meta: {
      protected: true,
      roles: ['Super Admin']
    },
    component: ExceptionCollection
  },
  {
    name: 'Availability Report',
    path: '/availability-report',
    meta: {
      protected: true,
      roles: ['Super Admin']
    },
    component: AvailabilityCollection
  },
  {
    name: 'Collections',
    path: '/:model/collection',
    meta: {
      models,
      protected: true,
      roles: ['Super Admin']
    },
    component: Collection,
    props: ({ params }) => {
      const current = pageDetails[params.model]

      if (current) {
        return { model: params.model, ...current }
      }

      return {
        model: params.model
      }
    },
    beforeEnter ({ params }, _, next) {
      if (params.model) {
        return next()
      }

      return next({
        path: '/quote',
        replace: true
      })
    }
  },
  {
    name: 'home',
    path: '/',
    exact: true,
    component: Quote,
    meta: {
      protected: true
    }
  },
  {
    name: 'Quote',
    path: '/quote',
    meta: {
      protected: true
    },
    component: Quote
  },
  {
    path: '/quote/result/:id',
    meta: {
      protected: true
    },
    component: QuoteResult
  },
  {
    path: '/alerts/:id',
    meta: {
      protected: true,
      permissions: ['read:alert'],
      roles: ['Super Admin']
    },
    component: AlertSingle,
    props: true
  },
  {
    path: '/geo-notes/:id',
    meta: {
      protected: true,
      permissions: ['read:alert'],
      roles: ['Super Admin']
    },
    component: GeoSingle
  },
  {
    path: '/haulers/:id',
    meta: {
      protected: true,
      permissions: ['read:hauler'],
      roles: ['Super Admin']
    },
    component: HaulerSingle
  }, {
    path: '/markets/:id',
    meta: {
      protected: true,
      permissions: ['read:market'],
      roles: ['Super Admin']
    },
    component: MarketSingle
  },
  {
    path: '/feedback',
    meta: {
      protected: true
    },
    component: Feedback
  },
  {
    path: '*',
    component: Quote
  }
]

export default routes
