/* eslint-disable arrow-parens, camelcase, no-confusing-arrow */
import {
  always,
  compose,
  every,
  filter,
  flip,
  includes,
  length,
  omit,
  path,
  pipe,
  prop,
  when
} from 'kyanite'

const byId = prop('id')

/**
 * Creates a size filter for a list of provided size ids
 * @param {Array} sizes An array of size id strings
 * @returns {Function} A function which expects the product list to run the filter
 */
function filterBySizes (sizes) {
  /**
   * Since we need to take sell_as sizes into account we needed a special size filter function
   * This takes the product list and filters it down by the sizes provided earlier
   * Takes into account the sell_as id and will use that in place of the size_id if it is available
   * @param {Array} prodList An array of product objects
   * @returns {Array} A new array of product objects filtered down by the size id list
   */
  return function runFilter (prodList) {
    return filter(p => {
      if (p.sell_as) {
        return includes(p.sell_as, sizes)
      }

      return includes(p.size_id, sizes)
    }, prodList)
  }
}

export default {
  namespaced: true,
  state: {
    availability: [],
    productList: [],
    serviceAreas: [],
    tonnage: [],
    size: [],
    wasteType: [],
    costStructures: [],
    serviceTypes: []
  },

  getters: {
    filteredList ({
      availability,
      wasteType,
      tonnage,
      size,
      serviceAreas,
      productList,
      costStructures,
      serviceTypes
    }) {
      const check = compose(always, length)
      const includesByProp = (list, p) =>
        compose(flip(includes, list), path(p))

      return pipe([
        // Filter by availability if checked
        when(check(availability), filter(s => {
          const sizeId = s.size_id
          const currentAvail = s.current_availability.filter(ca => ca.delivery_type === 'newDeliveries')[0]
          const current = currentAvail.newDeliveries.filter(d => d.size_id === sizeId)[0]
          const mustCall = current.must_call
          const friendlyDate = current.delivery_date.friendlyText.toLowerCase()
          const contract = Object.assign({}, s.region_availability.newDeliveries)

          // for right now, tried to keep the logic statements very simple
          // may have to revisit this if availability ever gets more complex...

          if (contract.no_service) {
            return false
          }

          if (mustCall) {
            return false
          }

          if (includes('today', availability) && friendlyDate === 'today') {
            return true
          }

          if (includes('tomorrow', availability) && friendlyDate === 'tomorrow') {
            return true
          }

          if (includes('saturday', availability) && current.saturday_active) {
            return true
          }

          if (includes('sunday', availability) && current.sunday_active) {
            return true
          }

          return false
        })),

        // Filter by service areas if checked
        when(check(serviceAreas), filter(includesByProp(serviceAreas, ['service_area_id']))),

        // Filter by size if checked
        when(check(size), filterBySizes(size)),

        when(check(serviceTypes), filter(s => {
          const sizeId = s.size_id
          const sellAs = s.sell_as
          const currentAvail = s.current_availability.filter(ca => ca.delivery_type === 'newDeliveries')[0]
          const current = currentAvail.newDeliveries.filter(d => d.size_id === sizeId)[0]
          const contract = Object.assign({}, s.region_availability.newDeliveries)
          const exceptions = [...s.region_exceptions]

          // figuring out if the no service exceptions at the region - apply to a particular product (s)
          const noServiceExceptions = exceptions.filter((ex) => {
            if (ex.type !== 'noService') {
              return false
            }
            const sizes = ex.sizes
            const sizeArray = sizes.split(',')
            if (sizes !== '' && !sizeArray.includes(sizeId) && !sizeArray.includes(sellAs)) {
              return false
            }
            const wasteTypes = ex.waste_types
            const wasteTypeArray = wasteTypes.split(',')
            if (wasteTypes !== '' && !wasteTypeArray.includes(s.waste_type_id)) {
              return false
            }
            const serviceAreas = ex.service_areas
            const serviceAreasArray = serviceAreas.split(',')
            if (serviceAreas !== '' && !serviceAreasArray.includes(s.service_area_id)) {
              return false
            }
            return true
          })

          const availabilityByZoneExceptions = exceptions.filter((ex) => {
            if (ex.type !== 'availabilityByZone') {
              return false
            }
            const serviceAreas = ex.service_areas
            const serviceAreasArray = serviceAreas.split(',')
            if (serviceAreas !== '' && !serviceAreasArray.includes(s.service_area_id)) {
              return false
            }
            const sizes = ex.sizes
            const sizeArray = sizes.split(',')
            if (sizes !== '' && !sizeArray.includes(sizeId) && !sizeArray.includes(sellAs)) {
              return false
            }
            return true
          }).sort((a, b) => {
            if (a.updated_at > b.updated_at) {
              return -1
            }
            if (a.updated_at < b.updated_at) {
              return 1
            }
            return 0
          })

          const mustCallAvailabilityByZoneExceptions = (availabilityByZoneExceptions.length > 0 && availabilityByZoneExceptions[0].availability_action === 'mustCall') ? availabilityByZoneExceptions[0] : null

          const mustCall = current.must_call || !!mustCallAvailabilityByZoneExceptions
          const noService = contract.no_service

          if ((includes('must_call', serviceTypes) && mustCall)) {
            return false
          }

          if (includes('no_service', serviceTypes) && (noService || noServiceExceptions.length > 0)) {
            return false
          }

          return true
        })),

        // Filter by tonnage if checked
        when(check(tonnage), filter(t => {
          return includes(t.tonnage, tonnage)
        })),

        // Filter by waste type if checked
        when(check(wasteType), filter(x => every(t => x[t] || x.waste_type[t], wasteType))),

        // Filter by cost structures if checked
        when(check(costStructures), filter(includesByProp(costStructures, ['cost_structure', 'id'])))
      ], productList)
    },
    /**
     * @author Justin Voelkel <justin@budgetdumpster.com>
     * @name filtersActive
     * @description determines if there are any filters active at this time
     * @param {object} state
     * @returns {boolean} true if any filter arrays have length
     */
    filtersActive (state) {
      return Object.keys(omit('productList', state)).some(f => state[f].length)
    }
  },
  mutations: {
    resetFilters (state) {
      Object.assign(state, {
        availability: [],
        size: [],
        tonnage: [],
        wasteType: [],
        serviceAreas: [],
        costStructures: [],
        serviceTypes: ['no_service']
      })
    },

    setSize (state, data) {
      state.size = data.map(byId)
    },
    setTonnage (state, data) {
      state.tonnage = data.map(byId)
    },
    setWasteType (state, data) {
      state.wasteType = data.map(byId)
    },
    setServiceTypes (state, data) {
      state.serviceTypes = data.map(byId)
    },
    setServiceAreas (state, data) {
      if (data) {
        state.serviceAreas = data.map(byId)
      }
    },

    setAvailability (state, data) {
      state.availability = data.map(byId)
    },

    setProductList (state, data) {
      state.productList = data
    },

    setCostStructures (state, data) {
      state.costStructures = data.map(byId)
    },

    setFilteredList (state, data) {
      state.filteredList = data
    }
  }
}
