/**
 * @name placehold
 * @description Either returns the value or a placeholder message
 * @param {String} msg The default message to return if val is undefined
 * @param {String} val The value to use
 * @return {String} Either the value given or the placeholder text
 */
const placehold = msg => val => val || msg

export default placehold
