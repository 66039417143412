import { apiHttp } from '../utils/apiHttp'
import { truthy } from '../utils/compare'

const enabled = process.env.VUE_APP_LOG_ACTIVITY

/**
 * Send a logged event to the azure mongodb
 *
 * @param {string} collection the name of the document collection to use/create
 * @param {object} data the data to store
 */
export default {
  logAzure: (collection, data) => {
    if (truthy(enabled)) {
      apiHttp.post(`logging/${collection}`, data)
    }
  }
}
