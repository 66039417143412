/* eslint no-param-reassign: 0 */
import events from './events'
/* import requestFormatter from '../../utils/requestFormatter' */
import { abortController, apiHttp } from '../../utils/apiHttp'

export default {
  namespaced: true,

  modules: {
    events
  },

  state: {
    exceptions: [],
    selectedException: {},
    region: {
      id: '',
      pricing: [],
      sizes: [],
      wasteTypes: [],
      zones: []
    }
  },
  mutations: {
    setRegionId (state, id) {
      state.region.id = id
    },

    setSelectedException (state, exception) {
      state.selectedException = exception
    },

    setRegionSizes (state, sizes) {
      state.region.sizes = sizes
    },

    setRegionAllSizes (state, sizes) {
      state.region.allSizes = sizes
    },

    setRegionWasteTypes (state, wasteTypes) {
      state.region.wasteTypes = wasteTypes
    },

    setRegionZones (state, zones) {
      state.region.zones = zones
    },

    setRegionPricing (state, pricing) {
      state.region.pricing = pricing
    },

    setExceptions (state, exceptions) {
      state.exceptions = exceptions
    }
  },

  actions: {
    setRegionId ({ commit }, id) {
      commit('setRegionId', id)
    },
    searchExceptions ({ commit }, payload) {
      return apiHttp.post('/exception/search', payload, { signal: abortController('setExceptions') })
        .then((data) => {
          commit('setExceptions', data.data.models)
          return data.data
        })
    },
    getAllExceptions ({ commit }, { sortField, sortDirection, page, perPage }) {
      return apiHttp.get(
        `/exceptions?with=region&page=${page}&per_page=${perPage}&sort=${sortField}&sort_order=${sortDirection}`,
        { signal: abortController('setExceptions') }
      ).then((data) => {
        commit('setExceptions', data.data.models)
      })
    },
    getExceptions ({ commit }, regionId) {
      return apiHttp.get(
        `/region/${regionId}/exceptions`,
        { signal: abortController('setExceptions') }
      ).then((data) => {
        commit('setExceptions', data.data.collection)
      })
    },
    createException ({ commit }, payload) {
      return apiHttp.post('/exceptions', payload)
        .then((data) => {
          commit('events/runFlash', {
            message: 'Exception Created Successfully',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          commit('events/runFlash', {
            message: 'Error Creating Exception',
            severity: 'error',
            timeout: -1
          })
        })
    },
    updateException ({ commit }, { payload, id }) {
      return apiHttp.put(`/exception/${id}`, payload)
        .then((data) => {
          commit('events/runFlash', {
            message: 'Exception Saved Successfully',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          commit('events/runFlash', {
            message: 'Error Saving Exception',
            severity: 'error',
            timeout: -1
          })
        })
    },
    excludeBulkException ({ commit }, { exceptionId, regionId }) {
      return apiHttp.post(`/exception/${exceptionId}/exclude/${regionId}`)
        .then(({ data }) => {
          commit('events/runFlash', {
            message: 'Exception Removed Successfully',
            severity: 'success',
            timeout: 2000
          })

          return true
        })
        .catch(e => {
          commit('events/runFlash', {
            message: 'Error Removing Exception',
            severity: 'error',
            timeout: -1
          })

          return false
        })
    },
    deleteException ({ commit }, id) {
      return apiHttp.delete(`/exception/${id}`)
        .then(({ data }) => {
          commit('events/runFlash', {
            message: 'Exception Deleted Successfully',
            severity: 'success',
            timeout: 2000
          })

          return true
        })
        .catch(e => {
          commit('events/runFlash', {
            message: 'Error Deleting Exception',
            severity: 'error',
            timeout: -1
          })

          return false
        })
    },
    getRegionPricing ({ commit }, id) {
      return apiHttp.get(`/region/${id}/service-areas?with=rolloff_pricing`)
        .then(({ data }) => {
          const serviceAreas = data.collection
          const pricing = serviceAreas.reduce((acc, item) => {
            return acc.concat(item.rolloff_pricing)
          }, [])

          const zones = serviceAreas.reduce((acc, serviceArea) => {
            const zone = { id: serviceArea.id, name: serviceArea.name }
            acc.push(zone)
            return acc
          }, [])
            .sort((a, b) => {
              const x = a.name.toLowerCase()
              const y = b.name.toLowerCase()
              if (x > y) {
                return 1
              }

              if (x < y) {
                return -1
              }

              return 0
            })

          const addSize = (list, size) => {
            if (!list.some(item => item.id === size.id)) {
              list.push({ id: size.id, name: size.name })
            }

            return list
          }

          const sizes = pricing.reduce((acc, price) => {
            return addSize(acc, price.size)
          }, [])
            .sort((a, b) => {
              return parseFloat(a.name) - parseFloat(b.name)
            })

          const allSizes = pricing.reduce((acc, price) => {
            return price.sell_as_size ? addSize(acc, price.sell_as_size) : acc
          }, [...sizes])
            .sort((a, b) => {
              return parseFloat(a.name) - parseFloat(b.name)
            })

          const wasteTypes = pricing.reduce((acc, price) => {
            if (!acc.some(wasteType => wasteType.id === price.waste_type.id)) {
              acc.push({ id: price.waste_type.id, name: price.waste_type.name })
            }
            return acc
          }, [])
            .sort((a, b) => {
              const x = a.name.toLowerCase()
              const y = b.name.toLowerCase()
              if (x > y) {
                return 1
              }

              if (x < y) {
                return -1
              }

              return 0
            })

          commit('setRegionPricing', pricing)
          commit('setRegionZones', zones)
          commit('setRegionSizes', sizes)
          commit('setRegionAllSizes', allSizes)
          commit('setRegionWasteTypes', wasteTypes)
        })
    }
  },

  getters: {
    getExceptionCount (state) {
      return state.exceptions.length
    },
    getExceptions (state) {
      return state.exceptions
    },
    getSelectedException (state) {
      return state.selectedException
    },
    getZoneDictionary (state) {
      return state.region.zones.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
      }, {})
    },
    getSizeDictionary (state) {
      return state.region.sizes.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
      }, {})
    },
    getWasteTypeDictionary (state) {
      return state.region.wasteTypes.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
      }, {})
    },
    getServiceTypeDictionary (state) {
      return {
        newDeliveries: 'New Deliveries',
        emptyReturn: 'Empty & Return',
        pickup: 'Pickup'
      }
    },
    getTypeDictionary (state) {
      return {
        noService: 'No Service',
        availabilityByZone: 'Availability By Zone',
        availabilityByDate: 'Availability By Date',
        dayOfWeek: 'Day of Week',
        closure: 'Closure',
        serviceArea: 'Service Area',
        window: 'Window'
      }
    },
    getReasonDictionary (state) {
      return {
        billing: 'Billing',
        internal: 'Internal',
        inventory: 'Inventory',
        pricing: 'Pricing',
        scheduling: 'Scheduling'
      }
    },
    getActionTypeDictionary (state) {
      return {
        checkBack: 'Check Back',
        expirationDate: 'Expires',
        permanent: 'Permanent'
      }
    },
    getAvailabilityActionTypeDictionary (state) {
      return {
        mustCall: 'Must Call',
        nextAvailableDate: 'Available Date'
      }
    }
  }
}
