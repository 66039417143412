<template>
  <div class="container">
    <div class="row">
      <div class="col s6">
        <label for="exceptionType" class="active">
          Exception Type
          <form-error field="exceptionType" :active-errors="errors"/>
        </label>
        <select
          v-validate="'required'"
          v-model="exceptionRecord.type"
          @change="typeChange"
          name="exceptionType"
          class="browser-default"
          id="exceptionType"
        >
          <option value=""></option>
          <option v-for="name, type in getTypes()" :key="type" :value="type">{{name}}</option>
        </select>
      </div>
      <div class="col s6">
        <label for="exceptionReason" class="active">Exception Reason</label>
        <select name="exceptionReason" class="browser-default" id="exceptionReason" v-model="exceptionRecord.reason">
          <option value=""></option>
          <option value="Pricing">Pricing</option>
          <option value="Billing">Billing</option>
          <option value="Internal">Internal</option>
          <option value="Inventory">Inventory</option>
          <option value="Scheduling">Scheduling</option>
        </select>
      </div>
    </div>
    <div class="row" v-show="!isBulk && exceptionRecord.type !== 'closure'">
      <div class="col s12">
        <label>Specify Zones (If blank, exception will apply to all)</label>
        <MultiSelectDropDown
          id="zones"
          @change="multiChange"
          name="service_areas"
          :selected="selectedServiceAreas"
          :dataOptions="region.zones"/>
      </div>
    </div>
    <div class="row" v-show="!isAllTypesType">
      <div class="col s6">
        <label>
          Select service type(s) to apply exception
          <form-error field="serviceTypes" :active-errors="errors"/>
        </label>
        <ul class="checkboxes">
          <li>
            <input
              type="checkbox"
              id="newDeliveries"
              name="serviceTypes"
              v-validate="'required'"
              v-model="serviceTypes.newDeliveries"/>
            <label for="newDeliveries">New Deliveries</label>
          </li>
          <li>
            <input
              type="checkbox"
              id="emptyReturn"
              name="serviceTypes"
              v-model="serviceTypes.emptyReturn"/>
            <label for="emptyReturn">Empty &amp; Returns</label>
          </li>
          <li>
            <input
              type="checkbox"
              id="pickup"
              name="serviceTypes"
              v-model="serviceTypes.pickup"/>
            <label for="pickup">Pickups</label>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col s6" v-show="!isBulk && ['dayOfWeek', 'noService', 'availabilityByDate', 'availabilityByZone', 'window'].includes(exceptionRecord.type)">
        <label>Specify Sizes (If blank, exception will apply to all)</label>
        <MultiSelectDropDown
          @change="multiChange"
          id="sizes"
          name="sizes"
          :selected="selectedSizes"
          :dataOptions="exceptionRecord.type === 'noService' ? region.allSizes : region.sizes"/>
      </div>
      <div class="col s6" v-show="exceptionRecord.type === 'noService'">
        <label>Specify Waste Types (If blank, exception will apply to all)</label>
        <MultiSelectDropDown
          @change="multiChange"
          id="wastetypes"
          name="waste_types"
          :selected="selectedWasteTypes"
          :dataOptions="region.wasteTypes"/>
      </div>
    </div>

    <div class="row" v-show="exceptionRecord.type === 'window'">
      <div class="col s4">
        <label>Set the window days</label>
        <div class="row">
          <div class="col s3 center-days-vertical"><span class="right">1 to</span></div>
          <div class="col s9">
            <select
              id="window_days"
              name="window_days"
              class="browser-default"
              v-model="exceptionRecord.window_days"
              v-validate="(exceptionRecord.type === 'window') ? 'required' : false"
              >
              <option value=""></option>
              <option v-for="n in windowOptions" :value="n" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
        </div>
        <form-error field="window_days" :active-errors="errors"/>
      </div>
    </div>

    <label v-show="exceptionRecord.type === 'availabilityByZone'">
      Set the next available date or select must call
      <form-error field="availability_action" :active-errors="errors"/>
    </label>
    <div class="radio-button-container row" v-show="exceptionRecord.type === 'availabilityByZone'">
      <div class="col s12">
        <div class="radio-button">
          <input
            type="radio"
            v-validate="'required'"
            name="availability_action"
            class="action-radio"
            id="nextAvailableDate"
            value="nextAvailableDate"
            v-model="exceptionRecord.availability_action"
          />
          <label for="nextAvailableDate">Next Available Date</label>
        </div>

        <div class="radio-button">
          <input
            type="radio"
            name="availability_action"
            class="action-radio"
            id="mustCallAvailability"
            value="mustCall"
            v-model="exceptionRecord.availability_action"
          />
          <label for="mustCallAvailability">Must Call</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s6" v-show="exceptionRecord.availability_action === 'nextAvailableDate' && exceptionRecord.type === 'availabilityByZone'">
        <input
          id="nextAvailableDate"
          name="nextAvailableDate"
          type="date"
          class="datepicker"
          v-model="exceptionRecord.next_available_date"
          v-validate="(exceptionRecord.availability_action === 'nextAvailableDate' && exceptionRecord.type === 'availabilityByZone') ? 'required' : false"
          :min="today"
        />
        <form-error field="nextAvailableDate" :active-errors="errors"/>
      </div>
    </div>

    <label v-show="exceptionRecord.type === 'availabilityByDate'">Enter date range the hauler is unavailable</label>
    <label v-show="exceptionRecord.type === 'closure'">Enter date range the hauler's office is closed</label>
    <div class="row" v-show="isDateType">
      <div class="col s6">
        <input
          id="startDate"
          name="startDate"
          type="date"
          class="datepicker"
          v-model="exceptionRecord.start_date"
          v-validate="isDateType ? 'required' : false"
          :min="today"
          :max="exceptionRecord.end_date"
        />
        <form-error field="startDate" :active-errors="errors"/>
      </div>
      <div class="col s6">
        <input
          id="endDate"
          name="endDate"
          type="date"
          class="datepicker"
          v-model="exceptionRecord.end_date"
          v-validate="isDateType ? 'required' : false"
          :min="exceptionRecord.start_date || today"
        />
        <form-error field="endDate" :active-errors="errors"/>
      </div>
    </div>

    <label v-show="exceptionRecord.type === 'dayOfWeek'">Select the days the hauler does not service</label>
    <div class="row" v-show="exceptionRecord.type === 'dayOfWeek'">
      <div class="col s12">
        <ul id="daysOfWeek" class="checkboxes">
          <li v-for="day in daysOfWeek" v-bind:key="day.day">
            <input
              type="checkbox"
              :id="`dayOfWeek${day.name}`"
              name="days_of_week[]"
              :value="day.day"
              v-validate="(exceptionRecord.type === 'dayOfWeek') ? 'required' : false"
              v-model="exceptionRecord.days_of_week"/>
            <label :for="`dayOfWeek${day.name}`">{{day.name}}</label>
          </li>
        </ul>
        <form-error field="days_of_week[]" :active-errors="errors"/>
      </div>
    </div>

    <div v-show="exceptionRecord.type === 'serviceArea'">
      <geo-search
        ref="geo-search"
        :placeholder="placeholder"
        label="Enter city or zip to define service area exception"
        :search-types="['(regions)']"
        :callback="setServiceArea"
        :useHistory=false
      />
      <input
        v-validate="(exceptionRecord.type === 'serviceArea') ? 'required' : false"
        type="hidden"
        name="boundaries"
        :value="boundaries.description"
      />
      <form-error field="boundaries" message="Area is required" :active-errors="errors"/>
    </div>

    <label>
      Select the expiration type
      <form-error field="dateType" :active-errors="errors"/>
    </label>
    <div class="radio-button-container row">
      <div class="col 12">
        <div class="radio-button">
          <input
            type="radio"
            class="action-radio"
            v-validate="'required'"
            name="dateType"
            id="expirationDate"
            value="expirationDate"
            v-model="exceptionRecord.action_type"
          />
          <label for="expirationDate">Set Expiration Date</label>
        </div>

        <div class="radio-button">
          <input type="radio" name="dateType" :disabled="isBulk" class="action-radio" id="checkBackDate" value="checkBack" v-model="exceptionRecord.action_type"/>
          <label for="checkBackDate">Set Check Back Date</label>
        </div>

        <div class="radio-button">
          <input type="radio" name="dateType" :disabled="isBulk" class="action-radio" id="permanent" value="permanent" v-model="exceptionRecord.action_type"/>
          <label for="permanent">Set as Permanent</label>
        </div>
      </div>
    </div>

    <div class="row" v-show="exceptionRecord.action_type === 'checkBack' || exceptionRecord.action_type === 'expirationDate'">
      <div class="col s12">
        <input
          id="date"
          name="action_date"
          v-validate="(exceptionRecord.action_type === 'checkBack' || exceptionRecord.action_type === 'expirationDate') ? 'required' : false"
          type="date"
          class="datepicker"
          v-model="exceptionRecord.action_date"
          :min="today"
        />
        <div class="info">
          <form-error field="action_date" :active-errors="errors"/>
          <p v-if="exceptionRecord.action_type === 'expirationDate'">This exception will be deleted on the morning of the date selected</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col 12">
        <button v-if="!isBulk" type="button" class="btn hollow" @click="saveException">Save</button>
        <button v-if="isBulk" type="button" class="btn hollow" @click="confirmBulkSave">Save</button>
        <button type="button" class="btn discard danger" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import MultiSelectDropDown from './MultiSelectDropDown'
import { daysOfWeek, format } from '../utils/time'
import FormError from '../ui/FormError.vue'
import GeoSearch from '../components/GeoSearch.vue'
import { apiHttp } from '../utils/apiHttp'

function getInitialState (type) {
  return {
    exceptionRecord: {
      id: null,
      action_date: null,
      action_type: null,
      bulk: false,
      delivery_type: '',
      reason: '',
      type: '',
      service_areas: '',
      sizes: '',
      waste_types: '',
      availability_action: 'nextAvailableDate',
      days_of_week: [],
      next_available_date: null,
      start_date: null,
      end_date: null
    },
    placeholder: '',
    boundaries: {
      boundaries: '',
      description: ''
    },
    windowOptions: [2, 3, 4, 5, 6, 7, 8, 9, 10],
    serviceTypes: isAllTypesType(type) ? { newDeliveries: true, emptyReturn: true, pickup: true } : {}
  }
}
function isAllTypesType (type) {
  return ['closure', 'dayOfWeek', 'serviceArea'].includes(type)
}

export default {
  name: 'ExceptionForm',
  props: {
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    record: {
      type: Object,
      default: () => ({
        bulk: false,
        dateType: null,
        delivery_type: '',
        exception_type: null,
        exception_reason: null,
        new_deliveries: false,
        pickup: false,
        empty_return: false,
        service_areas: '',
        sizes: '',
        waste_types: '',
        days_of_week: [],
        start_date: null,
        end_date: null
      })
    }
  },
  data () {
    return getInitialState()
  },
  components: {
    'form-error': FormError,
    'geo-search': GeoSearch,
    MultiSelectDropDown
  },
  computed: {
    ...mapState('regions', ['selectedRegion']),
    ...mapState('exceptions', ['region', 'selectedException']),
    ...mapState('search', ['placeGeometry']),
    isBulk () {
      return this.bulk || this.record.bulk
    },
    isAllTypesType () {
      return isAllTypesType(this.exceptionRecord.type)
    },
    isDateType () {
      return ['availabilityByDate', 'closure'].includes(this.exceptionRecord.type)
    },
    today () {
      const fmt = d => (d ? format(d, 'YYYY-MM-DD') : null)
      return fmt(new Date())
    },
    daysOfWeek () {
      return daysOfWeek()
    },
    selectedServiceAreas () {
      if (this.exceptionRecord.service_areas === '') {
        return []
      }
      return this.exceptionRecord.service_areas.split(',')
    },
    selectedSizes () {
      if (this.exceptionRecord.sizes === '') {
        return []
      }
      return this.exceptionRecord.sizes.split(',')
    },
    selectedWasteTypes () {
      if (this.exceptionRecord.waste_types === '') {
        return []
      }
      return this.exceptionRecord.waste_types.split(',')
    }
  },
  methods: {
    ...mapActions('exceptions', ['getRegionPricing', 'setRegionId', 'createException', 'updateException', 'getExceptions']),
    ...mapGetters('exceptions', ['getTypeDictionary']),
    ...mapActions('search', ['performGooglePlacesLookup']),
    ...mapMutations('events', ['closeModal', 'setModal', 'runFlash']),
    getTypes () {
      let list = this.getTypeDictionary()
      if (this.isBulk) {
        list = {
          closure: list.closure,
          window: list.window
        }
      }

      return list
    },
    cancel (e) {
      this.$emit('cancel')
      this.closeModal()
    },
    setServiceArea (data) {
      const valueParts = data.value.split(',')
      // Remove the country
      valueParts.pop()
      let description = valueParts.join(',')

      // Format the data a little for these cases
      if (data.category === 'Zipcode') {
        // [1] is "<state> <zip>" We only want the zip
        data.search_term = valueParts[1].split(' ').pop()
      }
      if (data.category === 'Addresses') {
        data.category = 'Cities'
        // Remove the street address so we just have "<city>, <state>"
        valueParts.shift()
        data.value = valueParts.join(',').trim()
        description = data.value
      }

      apiHttp.post('/geometry/merge', {
        geometries: [data]
      }).then((response) => {
        this.boundaries = {
          boundaries: response.data.feature,
          description: description
        }
      }).catch((e) => {
        this.runFlash({
          message: 'There was a problem finding that location. Please try again.',
          severity: 'error',
          timeout: 2000
        })
        this.boundaries = {
          boundaries: '',
          description: ''
        }
        this.placeholder = ''
        this.$refs['geo-search'].clear()
      }).finally(() => {
        // Need to trigger re-validation on this
        this.$validator.validate('boundaries')
      })
    },
    confirmBulkSave () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return
        }

        this.setModal({
          header: 'Save Exception?',
          body: 'This action will add the exception to ALL haulers and ALL regions. Are you sure you want to save?',
          confirm: this.saveException,
          confirmButtons: {
            yes: 'Yes, Save Exception',
            no: 'No, Cancel'
          }
        })
      })
    },
    saveException () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return
        }

        const data = { ...this.exceptionRecord }
        let actionDate = data.action_date
        if (actionDate === '' || data.action_type === 'permanent') {
          actionDate = null
        }
        const deliveryTypes = []
        Object.keys(this.serviceTypes).forEach(key => {
          if (this.serviceTypes[key]) {
            deliveryTypes.push(key)
          }
        })
        const deliveryType = deliveryTypes.join(',')
        const nextAvailableDate = (data.availability_action === 'mustCall' || data.next_available_date === '') ? null : data.next_available_date
        const bulk = this.isBulk || data.bulk
        const regionId = bulk ? '' : this.selectedRegion.id

        const payload = {
          bulk,
          region_id: regionId,
          type: data.type,
          reason: data.reason,
          delivery_type: deliveryType,
          action_date: actionDate,
          action_type: data.action_type,
          service_areas: data.service_areas,
          service_areas_text: data.service_areas_text,
          sizes: data.sizes,
          sizes_text: data.sizes_text,
          waste_types: data.waste_types,
          waste_types_text: data.waste_types_text,
          days_of_week: data.days_of_week,
          window_days: data.window_days,
          availability_action: data.availability_action,
          next_available_date: nextAvailableDate,
          start_date: data.start_date,
          end_date: data.end_date
        }
        if (this.boundaries.boundaries) {
          payload.boundaries = this.boundaries.boundaries
          payload.boundaries_description = this.boundaries.description
        }

        let request
        if (data.id === null) {
          request = this.createException(JSON.stringify(payload))
        } else {
          request = this.updateException({ payload: JSON.stringify(payload), id: data.id })
        }
        request.then(() => this.$emit('saved', true))

        if (regionId) {
          this.getExceptions(regionId)
          this.getRegionPricing(regionId)
        }
      })
    },
    typeChange (event) {
      const type = event.target.value
      const id = this.exceptionRecord.id

      // reset form data on changing the type
      // but keep the ID if it's set so we edit vs adding a new one
      Object.assign(this.$data, getInitialState(type))
      this.exceptionRecord.type = type
      if (id) {
        this.exceptionRecord.id = id
      }

      this.$validator.reset()
    },
    multiChange (payload) {
      const name = payload.name
      const data = [...payload.data]
      const textField = payload.textField
      const fieldData = data.reduce((acc, item) => {
        acc.push(item.id)
        return acc
      }, []).join(',')
      const fieldValue = data.reduce((acc, item) => {
        acc.push(item.name)
        return acc
      }, []).sort().join(', ')
      this.exceptionRecord[name] = fieldData
      this.exceptionRecord[textField] = fieldValue
    }
  },
  watch: {
    record () {
      if (Object.keys(this.record).length > 0) {
        this.exceptionRecord = { ...this.record }
      }
    }
  },
  beforeUnmount () {
    this.closeModal()
  },
  mounted () {
    if (!this.isBulk) {
      this.setRegionId(this.selectedRegion.id)
      if (this.selectedRegion.id) {
        this.getRegionPricing(this.selectedRegion.id)
      }
    }

    if (Object.keys(this.record).length > 0) {
      this.exceptionRecord = { ...this.record }
    }
    this.serviceTypes.newDeliveries = (this.exceptionRecord.delivery_type.indexOf('newDeliveries') > -1)
    this.serviceTypes.emptyReturn = (this.exceptionRecord.delivery_type.indexOf('emptyReturn') > -1)
    this.serviceTypes.pickup = (this.exceptionRecord.delivery_type.indexOf('pickup') > -1)

    this.boundaries = {
      boundaries: this.exceptionRecord.boundaries,
      description: this.exceptionRecord.boundaries_description
    }
    this.placeholder = this.boundaries.description
  }
}
</script>

<style lang="scss">
  div.row {
    margin: 10px 0;
  }
  [type=radio]:not(:checked).action-radio + label, [type=radio]:checked.action-radio + label {
    padding-left: 27px;
  }
  div.radio-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 15px;
  }
  div.radio-button, div.inline {
    display: inline;
    padding: 0 15px;
  }
  div.multi.row {
    margin: 10px 0 25px 0;
  }
  .info {
    style: italic;
    font-size: 12px;
    color: #999;
    margin: 0;
  }
  ul#daysOfWeek li {
    display: inline-block;
    padding-right: 1em;
    label {
      padding-left: 1.5em;
    }
  }
  .center-days-vertical {
    display: flex;
    align-items: center;
    height: 45px;
    padding-right: 0 !important;
    width: auto !important;
  }
</style>
