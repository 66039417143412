import axios from 'axios'
import { from } from 'rxjs'
import crud from '../services/crud'
import modelValidator from './modelValidator'

const abortControllers = {}
export const abortController = function (name) {
  if (abortControllers[name]) {
    abortControllers[name].abort()
  }

  abortControllers[name] = new AbortController()
  return abortControllers[name].signal
}

export const apiHttp = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export const authHttp = axios.create({
  baseURL: 'https://budgetdumpster.auth0.com',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export const webHttp = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*'
  }
})

export const streamCollectionFilter = (model, field, val, full = false) => {
  if (modelValidator(model)) {
    return from(apiHttp.get(model, {
      params: {
        field,
        operator: 'LIKE',
        value: `%25${encodeURIComponent(val)}%25`,
        per_page: -1,
        full
      }
    }))
  }
  throw new Error('Invalid model to stream')
}

export const apiCRUD = crud(apiHttp)
