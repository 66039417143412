<template>
  <div>
    <table class='table'>
      <thead class='table-head'>
        <tr class='table-row' v-if='showColumnHeader'>
          <th class='table-header'></th>
          <th class='table-header'>Must Call for Avail.</th>
          <th class='table-header'>Same Day</th>
          <th class='table-header'>Next Day</th>
          <th class='table-header'>Days Out</th>
          <th class='table-header'>Next Available Service</th>
          <th class='table-header'>Saturday</th>
          <th class='table-header'>Sunday</th>
        </tr>
        </thead>
      <tbody class='table-body'>
        <tr class='table-row'>
          <td class='bg-gray table-data cutoff-time table-row-header'>Cutoff Time</td>
          <td class='bg-gray table-data'></td>
          <td class='bg-gray table-data table-data-no-lr-border'>
            <span v-if='checkMustCallByType("same_day_must_call")'><i class="material-icons contract-must-call">phone</i></span>
            {{ getCutoffTime('same_day') }}
          </td>
          <td class='bg-gray table-data table-data-no-lr-border'>
            <span v-if='checkMustCallByType("next_day_must_call")'><i class="material-icons contract-must-call">phone</i></span>
            {{ getCutoffTime('next_day') }}
          </td>
          <td class='bg-gray table-data table-data-no-lr-border'>
            <span v-if='checkMustCallByType("two_day_notice_must_call")'><i class="material-icons contract-must-call">phone</i></span>
            {{ getCutoffTime('days_out') }}
          </td>
          <td class='bg-gray table-data table-data-no-lr-border'></td>
          <td class='bg-gray table-data saturday'>
            <span v-if='checkMustCallByType("saturday_must_call")'><i class="material-icons contract-must-call">phone</i></span>
            {{ getCutoffTime('saturday') }}
          </td>
          <td class='bg-gray table-data sunday'>
            <span v-if='checkMustCallByType("sunday_must_call")'><i class="material-icons contract-must-call">phone</i></span>
            {{ getCutoffTime('sunday') }}
          </td>
        </tr>
        <tr v-for='availability in availabilities' :key='availability.id' class='table-row'>
          <td class='table-data table-row-header'>{{ availability.title }}</td>
          <td class='table-data'>
            <must-call-checkbox
              :name='"mustCall"'
              :label='`must_call_${availability.title}`'
              :value='availability.must_call'
              @input='(value) => updateAvailabilityType (availability, "must_call", value)'
            />
          </td>
          <td class='table-data table-data-no-lr-border'>
            <input
              :id='`same-day-${availability.id}`'
              class='filled-in'
              name='sameDay'
              type='checkbox'
              :checked='availability.same_day_active'
              @input='updateAvailabilityType (availability, "same_day_active", $event.target.checked)'
              :disabled='disabled'
            />
            <label :for='`same-day-${availability.id}`' class='checkbox'></label>
            </td>
            <td class='table-data table-data-no-lr-border'>
              <input
                :id='`next-day-${availability.id}`'
                name='nextDay'
                type='checkbox'
                class='filled-in'
                :checked='availability.next_day_active'
                @input='updateAvailabilityType (availability, "next_day_active", $event.target.checked)'
                :disabled='disabled'
              />
              <label :for='`next-day-${availability.id}`' class='checkbox'></label>
          </td>
          <td class='table-data table-data-no-lr-border'>
            <input
              name='daysOut'
              id='daysOut'
              type='number'
              :value='availability.days_out'
              min='2'
              max='90'
              @change='updateAvailabilityType (availability, "days_out", Number($event.target.value))'
             :disabled='disabled'
            />
          </td>
          <td class='table-data table-data-no-lr-border'>
            <input
              name='nextAvailableService'
              type='date'
              class='datepicker'
              :value='determineNextAvailableService(availability)'
              @input='updateAvailabilityType (availability, "next_available_service", $event.target.value)'
              :disabled='disabled'
            />
          </td>
          <td class='table-data bg-gray saturday'>
            <input
              :id='`saturday-${availability.id}`'
              name='saturday'
              type='checkbox'
              class='filled-in'
              :checked='availability.saturday_active'
              @input='updateAvailabilityType (availability, "saturday_active", $event.target.checked)'
              :disabled='disabled'
            />
            <label :for='`saturday-${availability.id}`' class='checkbox'></label>
          </td>
          <td class='table-data bg-gray sunday'>
            <input
              :id='`sunday-${availability.id}`'
              name='sunday'
              type='checkbox'
              class='filled-in'
              :checked='availability.sunday_active'
              @input='updateAvailabilityType (availability, "sunday_active", $event.target.checked)'
              :disabled='disabled'
            />
            <label :for='`sunday-${availability.id}`' class='checkbox'></label>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="bottom-options">
      <table class="window-options marginRight">
        <tbody class="table-body">
          <tr class="bg-grey table-row">
            <td class="table-data">
              Contract Window
            </td>
            <td class="table-data">
              <input
                v-model="contractAvailability.window_active"
                :id='`window_active_${currentRecord.delivery_type}_contract`'
                :name='`window_active_${currentRecord.delivery_type}_contract`'
                :checked='contractAvailability.window_active'
                type='checkbox'
                class="filled-in"
                disabled="true"
              />
              <label :for="`window_active_${currentRecord.delivery_type}_contract`" class='checkbox windowCheck'></label>
            </td>
            <td class="table-data">
              <span>1 to </span>
              <span>
                <select
                  :id='`${currentRecord.delivery_type}_window_days_contract`'
                  v-model="contractAvailability.window_days"
                  :name='`${currentRecord.delivery_type}_window_days_contract`'
                  class="browser-default dark-field"
                  disabled="true">
                    <option value=""></option>
                    <option v-for="n in windowOptions " :value="n" :key="n">
                      {{ n }}
                  </option>
                </select>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="window-options marginLeft">
        <tbody class="table-body">
          <tr class="bg-grey table-row">
            <td class="table-data">
              Window Override
            </td>
            <td class="table-data">
              <input
                v-model="currentRecord.window_active"
                :id='`window_active_${currentRecord.delivery_type}`'
                :name='`window_active_${currentRecord.delivery_type}`'
                :checked='currentRecord.window_active'
                type='checkbox'
                class="filled-in"
                :disabled='disabled'
                @change='updateCurrentRecord(currentRecord, "window_active", $event.target.checked)'
                @input='updateCurrentRecord(currentRecord, "window_active", $event.target.checked)'
              />
              <label :for="`window_active_${currentRecord.delivery_type}`" class='checkbox windowCheck'></label>
            </td>
            <td class="table-data">
              <span>1 to </span>
              <span>
                <select
                  :id='`${currentRecord.delivery_type}_window_days`'
                  v-model="currentRecord.window_days"
                  :name='`${currentRecord.delivery_type}_window_days`'
                  class="browser-default dark-field"
                  :disabled='disabled'
                  @input='updateCurrentRecord(currentRecord, "window_days", $event.target.value)'
                  @change='updateCurrentRecord(currentRecord, "window_days", $event.target.value)'>
                    <option value=""></option>
                    <option v-for="n in windowOptions " :value="n" :key="n">
                      {{ n }}
                  </option>
                </select>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MustCallCheckbox from './MustCallCheckbox'
import { setHours, setMinutes } from 'date-fns'
import { format } from '../utils/time'

export default {
  name: 'CurrentAvailabilityTable',
  components: {
    MustCallCheckbox
  },
  props: {
    availabilities: {
      type: Array,
      required: true
    },
    contractAvailability: {
      type: Object,
      required: false
    },
    currentRecord: {
      type: Object,
      required: true
    },
    showColumnHeader: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      windowOptions: [2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },
  methods: {
    getCutoffTime (type) {
      if (this.availabilities.length === 0) {
        return 'None'
      }

      const cutoffTime = this.availabilities[0][`${type}_cutoff_time`]

      if (!cutoffTime || cutoffTime === '00:00:00') {
        return '--'
      }

      const [hour, minute] = cutoffTime.split(':')

      return format(setHours(setMinutes(new Date(), minute), hour), 'h:mma')
    },
    checkMustCallByType (type) {
      return this.contractAvailability.must_call_all || this.contractAvailability[type]
    },
    determineNextAvailableService (availability) {
      if (!availability.must_call) {
        if (availability.next_available_service) {
          return availability.next_available_service
        }

        if (
          availability.days_out === null &&
          !availability.same_day_active &&
          !availability.next_day_active
        ) {
          return availability.delivery_date.date
        }
      }

      return null
    },
    updateAvailabilityType (availability, field, value) {
      this.$emit('change', { id: availability.id, [field]: value })
    },
    updateCurrentRecord (availability, field, value) {
      // if window active is turned off, clear the window_days drop down
      if (field === 'window_active' && value === false) {
        this.$emit('windowChange', { ...availability, window_days: null })
        this.currentRecord.window_days = null
        this.currentRecord.window_active = false
      }
      if (field === 'window_active' && value === true) {
        this.$emit('windowChange', { ...availability, window_days: 2 })
        this.currentRecord.window_days = 2
        this.currentRecord.window_active = true
      }
      // if the window days value is set to a non '' value and window_active is false, toggle window_active to true
      if (field === 'window_days' && value !== '' && !this.currentRecord.window_active) {
        this.$emit('windowChange', { ...availability, window_active: true })
        this.currentRecord.window_active = true
      }
      // if the window days value is set to '' and window_active is true, toggle window_active to false
      if (field === 'window_days' && value === '' && this.currentRecord.window_active) {
        this.$emit('windowChange', { ...availability, window_active: false })
        this.currentRecord.window_active = false
      }
      this.$emit('windowChange', { ...availability, [field]: value })
    }
  }
}
</script>

<style lang='scss' scoped>
@import 'src/styles/base/_variables.scss';

.table {
  margin: 1.5rem 0;
  width: 100%;
  border-collapse: separate;

  &-head {
    font-weight: bold;
  }

  &-header {
    font-size: 1.125rem;
    min-width: 105px;
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
  }

  &-row {
    border-bottom: 1px solid $light-gray;

    &-header {
      font-size: 1.125rem;
    }
  }

  &-body {
    border: 1px solid $light-gray;
    box-shadow: 0px 3px 5px rgba(242, 242, 242, 0.5);
  }

  &-data {
    border-right: 1px solid $light-gray;
    border-top: 1px solid $light-gray;
    min-width: 105px;
    padding: 1rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;

    &-no-lr-border {
      border-left: none;
      border-right: none;
}

  }
}

.bg-gray {
  background-color: #f8f8f8;
}

.cutoff-time {
  min-width: 150px;
}

.checkbox {
  margin-left: 15px;
}

.contract-must-call {
  font-size: 16px;
  color: $danger;
  position: relative;
  bottom: -2px;
}

.table-row-header {
  border-left: 1px solid $light-gray;
}

.saturday, .sunday {
  box-shadow: 0px 3px 5px rgba(242, 242, 242, 0.5);
  box-sizing: border-box;
}

.saturday {
  border-left: 1px solid $light-gray;
  border-right: none;
}

.table-body > .table-row:last-child > .table-data:last-child {
  border-bottom-right-radius: 5px;
}

.table-body > .table-row:first-child > .table-data:last-child {
  border-top-right-radius: 5px;
}

.table-body > .table-row:first-child > .table-data:first-child {
  border-top-left-radius: 5px;
}

.table-body > .table-row:last-child > .table-data:first-child {
  border-bottom-left-radius: 5px;
}

.table-body > .table-row:last-child > .table-data {
  border-bottom: 1px solid $light-gray;
}

.table-body > .table-row:first-child > .table-data {
  border-top: 1px solid $light-gray;
}

input[type=number] {
  -moz-appearance: textfield;
}

.bottom-options {
  width: 100%;
  justify-content: space-between;
  display: flex;
  .window-options, .mustCallCheck {
    display: flex;
    border: 1px solid #c5c5c5;
    background-color: #e5e5e5;
    border-radius: 4px;
    select {
      display: inline-flex;
      width: auto;
      margin: 0 10px 0 0;
      padding: 0;
      height: auto;
    }
  }
  .marginLeft {
    margin: 0 0 0 20px;
  }
  .marginRight {
    margin: 0 20px 0 0;
  }
  .window-options td, .mustCallCheck td {
    padding: 5px 15px;
    .windowCheck {
      top: 5px;
    }
  }
}

</style>
