<template>
  <div class="region-view">
    <section class="tabs-wrapper">
      <div class="tabs">
        <p :class="['tab', { active: activeView === 'RegionForm' }]" @click="activeView = 'RegionForm'">Region Info</p>
        <p
          :class="['tab', { active: activeView === 'CurrentAvailability' }]"
          @click="activeView = 'CurrentAvailability'"
        >
          Current Availability
        </p>
        <p
          :class="['tab', { active: activeView === 'HaulerAvailability' }]"
          @click="activeView = 'HaulerAvailability'">
          Contract Availability
        </p>
        <p :class="['tab', { active: activeView === 'AlertView' }]" @click="activeView = 'AlertView'">
          Alerts
        </p>
        <p :class="['tab', { active: activeView === 'ExceptionView' }]" @click="activeView = 'ExceptionView'">
          Exceptions
        </p>
      </div>
    </section>

    <section class="mt">
      <component
        :is="activeView"
        :levelId="selectedRegion.id"
        :level="'region'"
        :creating="creating"
        @finished="$emit('finished', 'region')"
      />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RegionForm from './RegionForm'
import HaulerAvailability from './HaulerAvailability'
import AlertView from './AlertView'
import CurrentAvailability from './CurrentAvailability'
import ExceptionView from './ExceptionView'

export default {
  props: {
    deleteCallback: {
      type: Function,
      default: () => true
    },

    creating: {
      type: Boolean,
      required: false
    }
  },

  components: {
    RegionForm,
    HaulerAvailability,
    AlertView,
    CurrentAvailability,
    ExceptionView
  },

  data () {
    return {
      activeView: 'RegionForm'
    }
  },

  computed: {
    ...mapState('regions', ['selectedRegion'])
  },

  watch: {
    // This watchers job is to reset the tab if we select a new region
    // That way for example if they're on alerts they have to re click the alerts tab to fetch new alerts for the right region
    selectedRegion ({ id }, old) {
      if (id !== old.id) {
        this.activeView = 'RegionForm'
      }
    }
  },

  beforeDestroy () {
    this.activeView = 'RegionForm'
  }
}
</script>

<style lang="scss" scoped>
.mt {
  margin-top: 1rem;
}

.region-view .tab {
  margin-right: 4.5rem;

  &:last-child {
    margin-right: 0;
  }
}
</style>
