<template>
<span>
    <button
      :disabled="confirm"
      :class="['hollow-btn hollow-btn--danger', confirm ? 'hollow-btn--disabled' : '']"
      @click="confirmDelete"
      key="delete">Delete</button>
      <transition-group name="fade">
        <button
          v-show="confirm"
          class="hollow-btn hollow-btn--danger mr"
          @click="confirmed"
          key="confirmDelete">Confirm Delete</button>
          <base-button
          v-show="confirm"
          class="not-delete"
          :callback="notConfirmed"
          key="notDelete">Do Not Delete</base-button>
      </transition-group>
</span>
</template>
<script>
import BaseButton from './BaseButton.vue'

export default {
  name: 'DeleteButton',
  components: {
    'base-button': BaseButton
  },
  data () {
    return {
      confirm: false
    }
  },
  methods: {
    confirmDelete () {
      this.confirm = true
    },
    confirmed () {
      this.$emit('delete', true)
      this.confirm = false
    },
    notConfirmed () {
      this.confirm = false
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

.mr {
  margin-right: 0.5rem;
}

.discard {
  background-color: $danger;
}
</style>
