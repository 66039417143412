<template>
  <div class="collapsible-body">
    <div class="flex-container">
      <div
        :class="`avail-info ${service.containerClass}`"
        v-for="service in availability"
        :key="service.title"
      >
        <div class="service-header">
          <span :class="[{'bad': isNoService(service) }, 'service-title']">{{ service.title }}</span>
          <span v-if="!isNoService(service) && !isMustCall(service, { noContract: true })" class="time-text">
              {{ service.friendlyTextAbbreviated }}
          </span>
          <span v-if="service.no_service" class="bad">
            {{service.no_service_reason}}
          </span>
          <span v-if="isNoService(service, true)" class="bad">
            {{service.noServiceException.reason.substring(0,1).toUpperCase() + service.noServiceException.reason.substring(1)}}
            <div class="action-information">As of: {{ formatDate(service.noServiceException.updated_at + '.500Z', 'MM/DD/YYYY') }}</div>
          </span>
          <span v-if="isMustCall(service, { noContract: true }) && !isNoService(service)" class="bad">
            Must Call
          </span>
          <p v-if="service.windowInfo.active && !isMustCall(service, { exceptionOnly: true })" class="windowInfo">
            <span class="icon"><i class="fa fa-calendar"></i></span>1 to {{service.windowInfo.days}} day window
          </p>
          <p v-if="!isNoService(service) && service.exceptionsString.length > 0" class="exceptions">
            {{ service.exceptionsString }}
          </p>
        </div>
        <div class="service-notes">
          <strong>Notes:</strong> {{ service.notes }}
        </div>

        <div class="cutoff-times">
          <section>
            <p v-show="service.cutoff_times.same_day_cutoff_time !== null && service.cutoff_times.same_day_cutoff_time !== '00:00:00'">
              <strong>Same Day Cutoff:</strong>
              {{ formatTime(service.cutoff_times.same_day_cutoff_time) }}
            </p>
            <p v-show="service.cutoff_times.next_day_cutoff_time !== null && service.cutoff_times.next_day_cutoff_time !== '00:00:00'">
              <strong>Next Day Cutoff:</strong>
              {{ formatTime(service.cutoff_times.next_day_cutoff_time) }}
            </p>
            <p v-show="service.cutoff_times.two_day_notice_cutoff_time !== null && service.cutoff_times.two_day_notice_cutoff_time !== '00:00:00' ">
              <strong>2-Day Notice Cutoff:</strong>
              {{ formatTime(service.cutoff_times.two_day_notice_cutoff_time) }}
            </p>

            <div class="bad must-call-notes" v-show="isMustCall(service) && !isNoService(service)">
              <i class="material-icons bad">
                local_phone
              </i>
              <span>
                {{ service.must_call_text != '' ? `${service.phone} | ${service.must_call_text}` : `${service.phone}` }}
              </span>
            </div>
          </section>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { setHours, setMinutes } from 'date-fns'
import { format } from '../utils/time'

export default {
  name: 'AvailabilityAccordion',
  props: {
    availability: {
      required: true,
      type: Object
    }
  },
  methods: {
    formatDate (date, formatString) {
      if (!date) return date
      return format(new Date(date).toLocaleDateString('en-US'), formatString)
    },
    formatTime (time) {
      if (!time) return time
      const [hour, minute] = time.split(':')
      return format(setHours(setMinutes(new Date(), minute), hour), 'h:mma')
    },
    isMustCall (service, options) {
      options = options || {}
      const contractMustCall = !options.noContract && service.must_call
      const currentMustCall = service.current_must_call

      return service.hasMustCallAvailabilityByZoneExceptions ||
        ((contractMustCall || currentMustCall) && !options.exceptionOnly)
    },
    isNoService (service, exceptionOnly) {
      return service.hasServiceExceptions || (service.no_service && !exceptionOnly)
    }
  }
}
</script>
<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';
.bad {
  color: $danger;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding: 0;
  .light-gray {
    background-color: rgba(242, 242, 242, 0.5);
  }
  .white {
    background-color: #ffffff;
  }
  .windowInfo {
    .icon {
      margin-right: 5px;
    }
  }
  .action-information {
    color: #aaa;
    font-size: 12px;
  }
  .avail-info {
    flex: 1;
    .service-header {
      padding-left: 8px;
      border-bottom: 1px solid #e5e5e5;
      .service-title {
        font-size: 16px;
        font-weight: 500;
      }
      .time-text {
        font-size: 16px;
        color: $dark-gray;
      }
    }
    .service-notes {
      font-size: 12px;
      color: $dark-gray;
      padding-left: 8px;
      margin-top: 8px;
    }
    p {
      font-size: 12px;
      margin: 0;
      padding: 0;
    }
    .cutoff-times {
      padding-left: 8px;
      margin-top: 8px;
    }
    .material-icons {
      font-size: 14px;
    }
    .must-call-notes {
      font-size: 12px;
      margin-top: 8px;
      i {
        position: relative;
        top: 3px;
      }
    }
    .exceptions {
      color: $alert;
      font-size: 10px;
    }
  }
}
</style>
