<template>
    <div class="container">
        <div class="input-field">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdvCAzx4t7gRJLy1EUcTU-zhFarI9Xckaxw9zWjh-qlmMg4DA/viewform?embedded=true" width="100%" height="1438" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Feedback'
}
</script>
