<template>
  <div class='pricing-container'>
    <div v-if='!submitted'>
      <div class='pricing-tabs' v-if='showCustomerPricing'>
        <button :class="['pricing-tab', { active: tabName === 'calculate' }]" @click="setTab('calculate')">
          <span class='pricing-tab-step'>Step 1</span>
          <span class='pricing-tab-name'>Calculate Price</span>
        </button>
        <button :class="['pricing-tab', { active: tabName === 'create' }]" @click="setTab('create')">
          <span class='pricing-tab-step'>Step 2</span>
          <span class='pricing-tab-name'>Create Record</span>
        </button>
      </div>
      <div v-else>
        <h5>Create a Custom Price</h5>
      </div>

      <h3 class='pricing-title'>{{ title }}</h3>

      <div v-if="tabName === 'calculate'">
        <div class='grid two-col'>
          <div class='bold'>Total Cost</div>
          <div class='bold align-right'>{{ item.cost }}</div>
        </div>

        <ul class='breakdown'>
          <li>
            <div class='grid two-col collapsible-header header' @click='toggleBreakdown'>
              <div>{{ breakdownOpen ? '-' : '+' }} Breakdown</div>
            </div>
            <div class='body' v-if='breakdownOpen'>
              <div v-if="item.cost_per_ton !== ''" class='grid two-col'>
                <div>per ton</div>
                <div class='align-right'>{{ item.cost_per_ton }}</div>
              </div>
              <div v-if="item.haul_flat_rate !== '0.00'" class='grid two-col'>
                <div>haul flat rate</div>
                <div class='align-right'>{{ item.haul_flat_rate }}</div>
              </div>
              <div v-if="item.delivery_fee !== '0.00'" class='grid two-col'>
                <div>delivery fee</div>
                <div class='align-right'>{{ item.delivery_fee }}</div>
              </div>
              <div v-if="item.franchise_fee !== '0.00'" class='grid two-col'>
                <div>franchise fee</div>
                <div class='align-right'>{{ item.franchise_fee }}</div>
              </div>
              <div v-if="item.fe_combo !== '0.00'" class='grid two-col'>
                <div>fuel/environmental fee</div>
                <div class='align-right'>{{ item.fe_combo }}</div>
              </div>
              <div v-if="item.misc_fee !== '0.00'" class='grid two-col'>
                <div>misc fee</div>
                <div class='align-right'>{{ item.misc_fee }}</div>
              </div>
            </div>
          </li>
        </ul>

        <div class='grid two-col'>
          <div class='bold top-margin'>Price</div>
          <input class='bold align-right new-price' type='text' v-model='newPrice'/>
        </div>

        <div class='grid two-col'>
          <div>Tax {{ taxRate }}%</div>
          <div class='align-right'>{{ tax }}</div>
        </div>

        <hr/>

        <section>
          <div class='grid two-col'>
            <div class='bold'>Total Price</div>
            <div class='bold align-right'>{{ newTotal }}</div>
          </div>

          <div class='grid two-col'>
            <div>Gross Margin</div>
            <div class='align-right' :class='marginClass'>{{ margin }}</div>
          </div>

          <div class='grid two-col'>
            <div>Discount</div>
            <div class='align-right'>{{ discount }}</div>
          </div>
        </section>

        <div class='calc-price-btns' v-if='showCustomerPricing'>
          <button class='btn' @click="setTab('create')">Continue to Create Price</button>
        </div>
      </div>

      <div v-if="showCustomerPricing && tabName === 'create'">
        <form @submit.prevent='save'>
          <ul class='pricing-list'>
            <li class='pricing-list-item'>
              <label for='pricing-base-price' class='pricing-list-item-name'>Dumpster</label>
              <div class='base-price-container'>MAKO Price&nbsp; <span class='base-price'>
              {{ formatAsUsd(price) }}</span>
              </div>
              <div class='pricing-list-item-input-container'>
                <label for='pricing-base-price' class='pricing-list-item-input-label'>New Price&nbsp;</label>
                <input
                    id='pricing-base-price'
                    class='pricing-list-item-input browser-default'
                    type='text'
                    v-model.number='newPrice'
                />
              </div>
            </li>
            <li class='pricing-list-item'>
              <label for='pricing-base-price' class='pricing-list-item-name'>Overage</label>
              <div class='base-price-container'>MAKO Price&nbsp; <span class='base-price'>
              {{ overageBasePriceUsd }}</span>
              </div>
              <div class='pricing-list-item-input-container'>
                <label for='pricing-base-price' class='pricing-list-item-input-label'>New Price&nbsp;</label>
                <input id='pricing-base-price'
                    class='pricing-list-item-input browser-default'
                    type='text'
                    v-model.number='customerOveragePrice'
                />
              </div>
            </li>
            <li class='pricing-list-item'>
              <label for='pricing-base-price' class='pricing-list-item-name'>Extension</label>
              <div class='base-price-container'>MAKO Price&nbsp; <span class='base-price'>
              {{ extensionBasePriceUsd }}</span>
              </div>
              <div class='pricing-list-item-input-container'>
                <label for='pricing-base-price' class='pricing-list-item-input-label'>New Price&nbsp;</label>
                <input id='pricing-base-price'
                    class='pricing-list-item-input browser-default'
                    type='text'
                    v-model.number='customerExtensionPrice'
                />
              </div>
            </li>
            <li class='pricing-list-item'>
              <label for='pricing-base-price' class='pricing-list-item-name'>Disposal</label>
              <div class='base-price-container'>MAKO Price&nbsp; <span class='base-price'>
              {{ disposalBasePriceUsd }}</span>
              </div>
              <div class='pricing-list-item-input-container'>
                <label for='pricing-base-price' class='pricing-list-item-input-label'>New Price&nbsp;</label>
                <input id='pricing-base-price'
                    class='pricing-list-item-input browser-default'
                    type='text'
                    v-model.number='customerDisposalPrice'
                />
              </div>
            </li>
            <li class='pricing-list-item'>
              <label for='pricing-base-price' class='pricing-list-item-name'>Trip Fee</label>
              <div class='base-price-container'>MAKO Price&nbsp; <span class='base-price'>
              {{ tripFeeBasePriceUsd }}</span>
              </div>
              <div class='pricing-list-item-input-container'>
                <label for='pricing-base-price' class='pricing-list-item-input-label'>New Price&nbsp;</label>
                <input id='pricing-base-price'
                    class='pricing-list-item-input browser-default'
                    type='text'
                    v-model.number='customerTripFeePrice'
                />
              </div>
            </li>
          </ul>
          <div class='pricing-customer-container'>
            <label class='pricing-customer-autocomplete-label'>
              Select Customer
              <pvue-autocomplete
                  forceSelection
                  v-model='customer'
                  :suggestions='customerSearchResults'
                  @complete='searchCustomers($event)'
                  field='searchable_name'
                  class='pricing-customer-autocomplete-input'
              />
            </label>
            <label class='pricing-eff-date-label'>
              Effective Date
              <input class='pricing-eff-date-input datepicker' type='date' v-model='effectiveDate'/>
            </label>
          </div>
          <div class='pricing-site-container'>
            <label v-if='hasValidCustomer && siteOpen' class='pricing-site-autocomplete-label'>
              Select Site
              <pvue-autocomplete
                  forceSelection
                  v-model='site'
                  :suggestions='siteSearchResults'
                  @complete='searchSites($event)'
                  field='formatted_address'
                  class='pricing-site-autocomplete-input'
              />
            </label>
            <button v-if='hasValidCustomer' :class="['pricing-site-btn browser-default', { active: siteOpen }]"
                type='button'
                @click='toggleSite'>
              {{ siteOpen ? 'x Remove Site' : '+ Add Site (optional)' }}
            </button>
          </div>
          <div class='pricing-actions'>
            <button type='button' class='pricing-actions-back-btn' @click="setTab('calculate')">Back</button>
            <button type='submit' class='btn pricing-actions-save-btn'
                :disabled='!savable'>
              Save & Create Price Record
            </button>
          </div>
        </form>
      </div>
    </div>

    <div v-if='submitted'>
      <div>
        <i class="material-icons pricing-checkmark">
          check_circle
        </i>
      </div>
      <h3 class='pricing-created-title'>Price Record Created</h3>
      <div class='pricing-title'>{{ this.title }}</div>
      <div v-if='this.customer'>{{ this.customer.searchable_name }}</div>
      <div v-if='this.site'>{{ this.site.formatted_address }}</div>
      <ul>
        <li>Dumpster {{ formatAsUsd(this.savedPricing['Base Item'].price) }}</li>
        <li v-if="this.savedPricing.Overage != null">
          Overage {{ formatAsUsd(this.savedPricing.Overage.price) }}
        </li>
        <li v-if="this.savedPricing.Extension != null">
          Extension {{ formatAsUsd(this.savedPricing.Extension.price) }}
        </li>
        <li v-if="this.savedPricing.Disposal != null">
          Disposal {{ formatAsUsd(this.savedPricing.Disposal.price) }}
        </li>
        <li v-if="this.savedPricing['Trip Charge'] != null">
          Trip Fee {{ formatAsUsd(this.savedPricing['Trip Charge'].price) }}
        </li>
      </ul>

      <button :class='`btn clip-btn-${item.id}`' :data-clipboard-text="title">
        Copy Item to Clipboard
      </button>
    </div>
  </div>
</template>

<script>
import AutoComplete from 'primevue/autocomplete'
import { apiHttp } from '../utils/apiHttp'
import * as Sentry from '@sentry/browser'
import { mapMutations, mapState } from 'vuex'
import { format, parse } from 'date-fns'

export default {
  name: 'PriceCalculator',
  components: {
    'pvue-autocomplete': AutoComplete
  },
  props: {
    title: {
      type: String
    },
    item: {
      type: Object,
      default: () => ({
        cost: 0.0,
        price: 0.0,
        tax_rate: 0.0
      })
    }
  },
  data () {
    let newPrice = parseFloat((process.env.VUE_APP_MAKO_FEATURE_PRICING_API ?? '').toLowerCase() === 'true' ? this.item.pricing?.price : this.item.price)
    if (isNaN(newPrice)) {
      newPrice = null
    } else {
      newPrice = newPrice.toFixed(2)
    }

    return {
      newPrice,
      breakdownOpen: false,
      siteOpen: false,
      tabName: 'calculate',
      customerOveragePrice: null,
      customerExtensionPrice: null,
      customerDisposalPrice: null,
      customerTripFeePrice: null,
      customer: null,
      site: null,
      effectiveDate: format(new Date(), 'YYYY-MM-DD'),
      customerSearchResults: null,
      siteSearchResults: null,
      submitted: false,
      submitting: false,
      savedPricing: {}
    }
  },
  methods: {
    ...mapMutations('events', ['runFlash']),
    toggleBreakdown () {
      this.breakdownOpen = !this.breakdownOpen
    },
    toggleSite () {
      this.siteOpen = !this.siteOpen

      if (!this.siteOpen) {
        this.site = null
      }
    },
    setTab (name) {
      this.tabName = name
    },
    formatAsUsd (val) {
      if (typeof val === 'string') {
        val = parseFloat(val)
      }

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })

      return formatter.format(typeof val === 'number' ? val : 0)
    },
    searchCustomers (event) {
      const queryParams = new URLSearchParams({
        name: event.query,
        is_active: 'true'
      })
      return apiHttp.get(`/netsuite/customers?${queryParams.toString()}`)
        .then(({ data }) => {
          this.customerSearchResults = data
        })
        .catch(e => {
          Sentry.captureException(e)
          this.customerSearchResults = null
          this.runFlash({
            message: 'There was an error querying customers',
            severity: 'error',
            timeout: 2000
          })
        })
    },
    searchSites (event) {
      if (this.customer?.id == null) return

      const queryParams = new URLSearchParams({
        customer_id: this.customer.id,
        address: event.query
      })
      return apiHttp.get(`/netsuite/sites?${queryParams.toString()}`)
        .then(({ data }) => {
          this.siteSearchResults = data
        })
        .catch(e => {
          Sentry.captureException(e)
          this.siteSearchResults = null
          this.runFlash({
            message: 'There was an error querying sites',
            severity: 'error',
            timeout: 2000
          })
        })
    },
    prepareBaseItemPricingData () {
      if (this.item.pricing == null) return
      return {
        sale_price_id: this.item.pricing.internal_id.toString(),
        item_internal_id: this.item.pricing.item_internal_id.toString(),
        category_name: this.item.pricing.product_category_name,
        item_name: this.item.pricing.item_name,
        vendor_id: this.item.pricing.vendor_id.toString(),
        service_area_name: this.item.pricing.service_area_name,
        customer_id: this.customer?.id?.toString(),
        site_id: this.site?.id?.toString(),
        eff_date: format(parse(this.effectiveDate), 'DD/MM/YYYY'),
        channel: this.item.pricing.channel,
        price_level: this.item.pricing.price_level?.toString(),
        price: this.newPrice.toString(),
        price_type: this.item.pricing.price_type
      }
    },
    prepareAncillaryPricingData (type, price) {
      const ancillary = this.pricingByType[type]
      if (this.item.pricing == null || ancillary == null || price == null || price === '' || price === 0) return

      return {
        parent_id: ancillary.internal_id.toString(),
        item_internal_id: ancillary.item_internal_id.toString(),
        customer_id: this.customer?.id?.toString(),
        category_name: ancillary.price_type_name,
        site_id: this.site?.id?.toString(),
        eff_date: format(parse(this.effectiveDate), 'DD/MM/YYYY'),
        price: price.toString(),
        price_type: ancillary.price_type
      }
    },
    save () {
      if (!this.savable) {
        this.runFlash({
          message: 'Error saving customer price',
          severity: 'error',
          timeout: 2000
        })
      }

      this.submitting = true

      const pricing = {
        'Base Item': this.prepareBaseItemPricingData(),
        Overage: this.prepareAncillaryPricingData('Overage', this.customerOveragePrice),
        Extension: this.prepareAncillaryPricingData('Extension', this.customerExtensionPrice),
        Disposal: this.prepareAncillaryPricingData('Disposal', this.customerDisposalPrice),
        'Trip Charge': this.prepareAncillaryPricingData('Trip Charge', this.customerTripFeePrice)
      }

      return apiHttp.post('/netsuite/customers/pricing', pricing)
        .then(() => {
          this.submitted = true
          this.savedPricing = pricing
        })
        .catch(e => {
          this.submitted = false
          Sentry.captureException(e)
          this.runFlash({
            message: 'Error saving customer price',
            severity: 'error',
            timeout: 2000
          })
        })
        .finally(() => {
          this.submitting = false
        })
    }
  },
  computed: {
    ...mapState('session', ['user']),
    price () {
      const p = parseFloat(this.newPrice)
      if (isNaN(p)) return 0

      return p
    },
    discount () {
      const amount = this.price - (this.pricingApiFeatureEnabled ? this.item.pricing?.price : this.item.price)

      return amount > 0 ? '-' : parseFloat((amount ?? 0) * -1).toFixed(2)
    },
    margin () {
      return (this.price - this.item.cost).toFixed(2)
    },
    marginClass () {
      return this.margin < 150 ? 'bad' : ''
    },
    newTotal () {
      const amount = this.price + parseFloat(this.tax)

      return parseFloat(amount || 0).toFixed(2)
    },
    taxRate () {
      return this.item.tax_rate || 0.0
    },
    tax () {
      const amount = this.price * this.taxRate / 100

      return parseFloat(amount || 0).toFixed(2)
    },
    pricingByType () {
      return (this.item.pricing?.ancillaries ?? []).reduce((ancillaries, ancillary) => {
        ancillaries[ancillary.price_type_name] = ancillary
        return ancillaries
      }, {})
    },
    overagePrice () {
      const price = parseFloat(this.pricingByType.Overage?.price)
      if (isNaN(price)) return null
      return price
    },
    overageBasePriceUsd () {
      return this.formatAsUsd(this.overagePrice)
    },
    extensionPrice () {
      const price = parseFloat(this.pricingByType.Extension?.price)
      if (isNaN(price)) return null
      return price
    },
    extensionBasePriceUsd () {
      return this.formatAsUsd(this.extensionPrice)
    },
    disposalPrice () {
      const price = parseFloat(this.pricingByType.Disposal?.price)
      if (isNaN(price)) return null
      return price
    },
    disposalBasePriceUsd () {
      return this.formatAsUsd(this.disposalPrice)
    },
    tripFeePrice () {
      const price = parseFloat(this.pricingByType['Trip Charge']?.price)
      if (isNaN(price)) return null
      return price
    },
    tripFeeBasePriceUsd () {
      return this.formatAsUsd(this.tripFeePrice)
    },
    hasValidCustomer () {
      return this.customer?.id != null && this.customer.id !== ''
    },
    hasValidEffectiveDate () {
      return this.effectiveDate != null && this.effectiveDate !== ''
    },
    savable () {
      return !this.submitting && this.hasValidCustomer && this.hasValidEffectiveDate && this.item.pricing != null
    },
    pricingApiFeatureEnabled () {
      return (process.env.VUE_APP_MAKO_FEATURE_PRICING_API ?? '').toLowerCase() === 'true'
    },
    showCustomerPricing () {
      return this.pricingApiFeatureEnabled && this.user.permissions.includes('customerpricing:create')
    }
  },
  watch: {
    customer () {
      // Sites are specific to customers.
      this.site = null
    }
  }
}
</script>

<style lang='scss' scoped>
@import 'src/styles/base/_variables.scss';

.pricing {
  &-container {
    text-shadow: none;
    width: 400px;
  }

  &-title {
    color: gray;
    font-size: 1rem;
    margin: 1rem 0;
    text-align: left;
    text-shadow: none;
  }

  &-tabs {
    display: flex;
    width: 100%;
  }

  &-tab {
    background-color: white;
    border: none;
    border-bottom: 3px solid $gray;
    color: $gray;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;

    &.active {
      color: black;
      border-bottom: 3px solid black;
    }

    &-step {
      font-size: .875rem;
    }

    &-name {
      font-size: 1.25rem;
    }
  }

  &-list {
    background-color: $light-gray;
    border-radius: .5rem;
    padding: 1rem;

    &-item {
      align-items: center;
      color: black;
      display: flex;
      justify-content: space-between;

      &:not(:first-child) {
        margin: 1rem 0 0 0;
      }

      &-input {
        background-color: $light-gray;
        border: 1px solid #9e9e9e;
        border-radius: 5px;
        font-weight: bold;
        height: auto;
        margin: 0;
        padding: .25rem .5rem;
        text-align: right;
        width: 5rem;

        &:focus {
          outline: none;
        }

        &-container {
          align-items: flex-end;
          display: flex;
          justify-content: flex-end;
          width: 40%;
        }

        &-label {
          color: black;
          font-size: .875rem;
          padding: 0 0 .25rem 0;
          white-space: nowrap;
        }
      }

      &-name {
        color: black;
        font-size: 1rem;
        font-weight: bold;
        text-align: left;
        width: 20%;
      }
    }
  }

  &-name {
    color: black;
    font-size: 1rem;
    font-weight: bold;
  }

  &-customer {
    &-container {
      display: flex;
      justify-content: space-between;
    }

    &-autocomplete {
      &-label {
        text-align: left;
        text-shadow: none;
        width: 60%;
      }

      &-input {
        width: 100%;
      }
    }
  }

  &-eff-date {
    &-label {
      text-align: left;
      text-shadow: none;
      width: 35%;
    }

    &-input {
      color: $dark-gray;
      width: 100%;
    }
  }

  &-site {
    &-container {
      display: flex;
      flex-wrap: wrap;
    }

    &-autocomplete {
      &-label {
        text-align: left;
        text-shadow: none;
        width: 100%;
      }

      &-input {
        width: 100%;

        ::v-deep > input {
          margin: 0 !important;
        }
      }
    }

    &-btn {
      background-color: $white;
      border: none;
      color: $primary;
      text-align: left;
      width: 100%;

      &.active {
        color: $danger;
      }
    }
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0 0 0;

    &-back-btn {
      background-color: $white;
      border: 1px solid black;
      margin: 0;
      padding: 0 1rem;
    }
  }

  &-checkmark {
    color: $success;
    font-size: 4rem !important;
  }

  &-created-title {
    font-size: 2rem;
  }
}

.base-price-container {
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
  font-size: .875rem;
  margin-left: 1rem;
  width: 40%;
}

.base-price {
  font-size: 1rem;
  font-weight: bold;
}

.grid {
  display: grid;

  &.two-col, .two-col {
    grid-template-columns: repeat(2, minmax(50px, 1fr))
  }

  div {
    text-align: left;
    text-shadow: none;
  }

  .align-right {
    text-align: right;
  }

  .bold {
    font-weight: bold;
  }

  .top-margin {
    margin-top: 12px;
  }
}

p {
  margin-bottom: 2em;
}

.breakdown {
  margin: 0;

  .header {
    padding: 0;
    border: 0;
    color: $primary;
  }

  .body {
    padding: 5px 0 10px 0;
  }
}

.new-price {
  padding: .5rem !important;
  padding-right: 5px !important;
  border: 1px solid #9e9e9e !important;
  border-radius: 5px !important;
  height: auto !important;
  width: 50% !important;
  text-align: right !important;
  margin: 0 0 20px auto !important;
}

.bad {
  color: $danger;
}

section {
  background-color: $light-gray;
  padding: .5em;
  border-radius: 12px;
}

.calc-price-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
}
</style>
