<template>
  <span>{{ getUserNameForDisplay }}</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * This is an Async Module that will go and fetch some data and populate once that data returns
 */
export default {
  props: {
    userId: {
      required: true
    }
  },

  data () {
    return {
      username: ''
    }
  },

  methods: mapActions('user', ['fetchUser']),

  computed: {
    ...mapGetters('user', ['getUserFullNameById', 'getUserEmailById']),
    getUserNameForDisplay () {
      const { userId } = this
      const type = typeof userId
      const userType = (userId.internal) ? ' (Int)' : ' (Haul)'

      if (type === 'string') {
        return userId
      }

      if (type === 'object') {
        return userId.name + userType
      }

      return 'Unknown User'
    }
  }
}
</script>
