<template>
  <div class="hauler-single">
    <div id="content" class="container">
      <div id="row">
        <div class="col s12">
            <h3>{{hauler.name}}</h3>
        </div>
      </div>
    </div>
    <edit-bar
      v-if="showMap"
      :enable="editingMap"
      :selectedArea="selectedArea"
      :hiddenAreas="hiddenAreas"
      @finishedUpdate="resetAreas" />
    <div class="container">
      <div class="row" :key="'normal'">
        <div class="col s2 mr">
          <hauler-sidebar
            @addRegion="creatingRegion"
            @addZone="creatingZone"
            @changeView="setView"
          />
        </div>
        <div class="col s9">
          <transition name="fade" mode="out-in">
            <component
              :is="activeView"
              :creating="creating"
              :locked="!creating"
              :deleteable="true"
              @save="updateHauler"
              @finished="runFinish"
              @delete="removeHauler"
              @editMap="editingMap = true"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint camelcase:0 */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { isEmpty } from 'kyanite'

import HaulerSidebar from '../components/HaulerSidebar'
import HaulerForm from '../components/HaulerForm'
import RegionView from '../components/RegionView'
import ZoneView from '../components/ZoneView'
import ZoneForm from '../components/ZoneForm'
import RegionForm from '../components/RegionForm'
import ServiceAreaForm from '../components/ServiceAreaForm'
import EditBar from '../components/EditingBar'
import HaulerAvailability from '../components/HaulerAvailability'

import { colors } from '../config/colors'

export default {
  name: 'HaulerSingle',
  components: {
    EditBar,
    HaulerAvailability,
    HaulerForm,
    RegionForm,
    ZoneForm,
    RegionView,
    HaulerSidebar,
    ServiceAreaForm,
    ZoneView
  },
  data () {
    return {
      activeView: 'HaulerForm',
      editingMap: false,
      availabilityMode: false,
      creating: false,
      colors,
      defaultPricingObject: {
        new: true,
        yard_waste: 0,
        appliances: 0,
        stumps: 0,
        electronics: 0,
        other: 0,
        rank: 0,
        mattress_box_spring: 0,
        service_area_id: null
      },
      hiddenAreas: [],
      newServiceArea: null,
      serviceArea: null,
      service_areas: [],
      pricing: []
    }
  },

  methods: {
    isEmpty,
    // relevant service area mutations
    ...mapMutations('geometry', ['setAreaList', 'setBoundaries', 'resetGeometryStore', 'addArea', 'addBoundaries', 'updateAreaList']),
    // note and alert mutations
    ...mapMutations('hauler', ['addServiceAreaPricing']),
    ...mapMutations('note', ['setNote', 'clearNotes']),
    ...mapMutations('regions', ['selectRegion', 'selectArea', 'clearServiceAreas', 'setServiceAreas']),
    ...mapMutations('pricing', ['clearPricingRecords']),
    // actions required to set service area info from api
    ...mapActions('regions', ['fetchRegions', 'fetchServiceAreas']),
    ...mapActions('hauler', [
      'clearHauler',
      'setHauler',
      'updateHauler',
      'deleteHauler'
    ]),
    ...mapActions([
      'clearChips'
    ]),

    removeHauler (id) {
      this.deleteHauler(id).then(this.$router.push('/haulers'))
    },

    creatingRegion () {
      this.activeView = 'RegionForm'
      this.creating = true
      this.selectRegion(-1)
    },

    creatingZone () {
      this.activeView = 'ZoneForm'
      this.creating = true

      this.selectArea(-1)
    },

    runFinish (type) {
      this.creating = false

      if (type === 'region') {
        this.selectRegion()
        this.activeView = 'HaulerForm'
        this.setBoundaries(this.serviceAreas)
      } else {
        this.activeView = 'RegionView'
        this.selectArea()
        this.setBoundaries(this.getAreasByRegion(this.selectedRegion.id))
      }
    },

    setView (v) {
      this.creating = false
      if (v === 'HaulerForm') {
        this.setBoundaries(this.serviceAreas)
      }

      this.activeView = v
    },

    /**
     * Clear all related state info
     */
    clearState () {
      this.resetGeometryStore()
      this.clearHauler()
      this.clearServiceAreas()
      this.selectArea({})
      this.setAreaList([])
      this.clearChips()
      this.clearNotes(['hauler', 'product', 'serviceArea'])
      this.clearPricingRecords()
    },
    /**
     * Method for retrieving the active service area once
     * editing.
     * @returns null
     */
    setHiddenAreas (id) {
      this.hiddenAreas = this.areaList.reduce((acc, area) => {
        if (id !== area.id) {
          acc.push(area.id)
        }

        return acc
      }, [])
    },
    resetAreas () {
      this.hiddenAreas = []
      this.editingMap = false
      this.selectArea({})
    },
    /**
     * Method to set the service area form into edit mode
     * which allows for the creation/editing of service areas
     * and other service area information
     * @param {object} serviceArea - the specifically selected service area
     * @param {string} color - the color of the given service area
     */
    setArea (area, color) {
      // retrieve the active service area and set in state
      this.fetchFullServiceArea({ id: area.id, color })
      // when an area is selected hide the rest on the map
      this.setHiddenAreas(area.id)
      // clear existing pricing from state
      this.clearPricing()
    },
    /**
     * Class binding to set if the current collection item is active
     * @public
     * @param {string} id - the id of the currently selected service area
     */
    serviceAreaActive (id) {
      return this.selectedArea && (this.selectedArea.id === id) ? 'highlight' : false
    }
  },
  computed: {
    ...mapState('regions', ['selectedRegion', 'serviceAreas', 'selectedArea', 'regions']),
    ...mapState('geometry', [
      'areaList',
      'boundaries'
    ]),
    ...mapState('hauler', ['hauler']),
    ...mapGetters('regions', ['getAreasByRegion']),

    showMap () {
      // For now, we're not drawing the outOfNetwork map until requested
      // This is an attempt to avoid OOM
      return this.editingMap || this.hauler.type !== 'outOfNetwork'
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.clearState()
      vm.fetchRegions(to.params.id)
        .then((data) => {
          return data.reduce((acc, region) => {
            acc.push(region.id)
            return acc
          }, [])
        })
        .then((data) => {
          data.map((id) => {
            vm.fetchServiceAreas(id)
              .then((areas) => {
                areas.map((area) => {
                  vm.addArea(area)
                  if (vm.boundaries.length < 30) {
                    vm.addBoundaries(area)
                    vm.updateAreaList(area)
                  }
                })
              })
          })
        })
      vm.setHauler(to.params.id)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.clearState()
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.clearState()
    next()
  },
  mounted () {
    $('#modal-hauler').modal()
    $('body').css('overflow', 'auto')
    if (!isEmpty(this.selectedArea)) {
      this.resetAreas()
    }
  }
}
</script>

<style lang="scss">
@import 'src/styles/base/_variables.scss';

.hauler-single .container {
  margin-top: 2rem;
}

h4 {
    font-size: 2em;
}

.mr {
  margin-right: 2rem;
}

.mb {
  margin-bottom: 2rem;
}

a.collection-item {
  &:hover {
    cursor: pointer;
  }
  &.add-area {
    background-color: $success;
    color: #fff !important;
    &:hover {
      background-color: $success-dark !important;
    }
  }
  &.show-all {
  background-color:$dark-gray;
  color: #fff !important;
  }
  &.highlight {
    font-weight: bold;
    border: 5px solid $light-gray;
  }
}
.dumpster-price-form {
  margin-bottom: 50px;
}
</style>
