const validApiModels = [
  'alert',
  'alerts',
  'hauler',
  'haulers',
  'market',
  'markets',
  'size',
  'sizes',
  'notes',
  'note',
  'service-area',
  'service-areas',
  'waste-type',
  'waste-types',
  'rolloff-pricing',
  'geo-note',
  'geo-notes'
]

const modelValidator = model => validApiModels.includes(model)

export default modelValidator
