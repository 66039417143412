<template v-if="radios">
    <div>
        <span v-for="radio in safeRadios" :key="radio.id">
            <input
            v-model="model"
            type="radio"
            :name="radio.label"
            :id="radio.id"
            :value="radio.id">
            <label :for="radio.id">{{radio.label}}</label>
        </span>
    </div>
</template>

<script>
import { defaultTo, map } from 'kyanite'

export default {
  name: 'BaseRadioInput',
  props: {
    /**
     * An array of objects to set up radio buttons
     * in the format of {id: 'foo', label: 'bar'}
     * @type {array}
     */
    radios: {
      required: true,
      type: Array
    },
    /**
     * An optional prop to set a pre-selected value
     * @type {string}
     */
    selected: {
      required: false,
      type: String
    }
  },

  data () {
    return {
      model: this.selected
    }
  },

  computed: {
    safeRadios () {
      return map(defaultTo({}), this.radios)
    }
  },

  updated () {
    this.$emit('checked', this.model)
  }
}
</script>
