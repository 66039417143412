<template>
  <div class="zone-view">
    <div class="row">
      <section class="tabs-wrapper col s8">
        <div class="tabs">
          <p :class="['tab', { active: activeView === 'ZoneForm' }]" @click="activeView = 'ZoneForm'">Zone Info</p>
        <p
          :class="['tab', { active: activeView === 'PricingBlock' }]"
          @click="activeView = 'PricingBlock'">
          Pricing
        </p>
        <p :class="['tab', { active: activeView === 'AlertView' }]" @click="activeView = 'AlertView'">
          Alerts
        </p>
        </div>
      </section>
      <div class="col s4">
        <button class="hollow-btn hollow-btn--smol" @click="$emit('editMap')">Edit Map</button>
      </div>
    </div>
    <section class="row mt">
      <component
        :is="activeView"
        :levelId="selectedArea.id"
        :level="'service-area'"
        :pricing="selectedArea.rolloff_pricing"
        @finished="$emit('finished', 'zone')"
        @save="manageForm"
      />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ZoneForm from './ZoneForm'
import AlertView from './AlertView'
import PricingBlock from './PricingBlock'

export default {
  components: {
    AlertView,
    ZoneForm,
    PricingBlock
  },

  data () {
    return {
      activeView: 'ZoneForm'
    }
  },
  methods: {
    manageForm () {
      this.activeView('PricingBlock')
    }
  },

  computed: {
    ...mapState('regions', ['selectedArea'])
  },

  watch: {
    // This watchers job is to reset the tab if we select a new zone
    // That way for example if they're on alerts they have to re click the alerts tab to fetch new alerts for the right region
    selectedArea ({ id }, old) {
      if (id !== old.id) {
        this.activeView = 'ZoneForm'
      }
    }
  },

  beforeDestroy () {
    this.activeView = 'ZoneForm'
  }
}
</script>

<style lang="scss" scoped>
.zone-view .tab {
  margin-right: 5rem;

  &:last-child {
    margin-right: 0;
  }
}
</style>
