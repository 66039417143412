<template>
  <section class="mt container">
    <router-link v-if="$route.query.hauler" :to="`/haulers/${$route.query.hauler}`" class="btn">
      Go to Hauler
    </router-link>
    <alert-form
      class="mt"
      :record="record"
      :locked="true"
      :deleteable="true"
      :editable="true"
      @save="saveAlert"
      @delete="deleteAlert" />
  </section>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { omit } from 'kyanite'
import { mapMutations } from 'vuex'
import AlertForm from '../components/AlertForm'
import { apiHttp } from '../utils/apiHttp'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    AlertForm
  },
  data () {
    return {
      record: {}
    }
  },
  methods: {
    ...mapMutations('events', ['setLoading', 'runFlash']),
    saveAlert (data) {
      this.setLoading(true)
      apiHttp.put(`/alert/${data.id}`, omit([
        '_links',
        'alert_type',
        'hauler_id',
        'hauler_name',
        'product_id',
        'product_name',
        'service_area_name',
        'service_area_id'
      ], data))
        .then(response => {
          this.record = response.data
          this.runFlash({
            message: 'Alert Updated Successfully',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(() => this.runFlash({
          message: 'Alert Update Failed',
          severity: 'error'
        }))
        .finally(() => this.setLoading(false))
    },
    deleteAlert (id) {
      this.setLoading(true)
      apiHttp.delete(`/alert/${id}`)
        .then(() => {
          this.runFlash({
            message: 'Alert Successfully Deleted',
            severity: 'success',
            timeout: 2000
          })
          this.$router.replace({ path: '/alerts' })
        })
        .catch(() => this.runFlash({
          message: 'Alert Delete Failed',
          severity: 'error'
        }))
        .finally(() => this.setLoading(false))
    }
  },
  mounted () {
    apiHttp.get(`/alert/${this.id}`).then(({ data }) => {
      this.record = data
    }).catch(e => {
      Sentry.captureException(e)

      throw new Error(e)
    })
  }
}
</script>

<style scoped>
.mt {
  margin-top: 0.75rem;
}
</style>
