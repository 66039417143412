const colors = [
  '#89cff0',
  '#009fb6',
  '#007fff',
  '#8a2be2',
  '#007ba7',
  '#0047ab',
  '#ccccff',
  '#e52b50',
  '#ffbf00',
  '#9966cc',
  '#fbceb1',
  '#7fffd4',
  '#800020',
  '#964b00',
  '#de3163',
  '#f88379',
  '#bfff00',
  '#800000',
  '#008080',
  '#c8a2c8',
  '#ffc0cb'
]

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1
}

const defaultLayerStyles = Object.seal({
  serviceArea (color, index) {
    return {
      standard: {
        fillColor: color,
        zIndex: index,
        strokeWeight: 1
      },
      hover: {
        strokeWeight: 3
      }
    }
  },
  msa: {
    standard: {
      fillOpacity: 0,
      strokeWeight: 3,
      strokeColor: '#8d8d8d',
      strokeOpacity: 1,
      visibile: true,
      zIndex: -99
    }
  },
  locale: {
    standard: {
      fillOpacity: 0,
      strokeWeight: 3,
      strokeColor: '#ffffff',
      strokeOpacity: 1,
      visibile: true,
      zIndex: 98
    }
  },
  specialUse: {
    // used in drawing dashed feature outlines
    polyline: {
      strokeColor: 'red',
      strokeOpacity: 0,
      strokeWeight: 2,
      icons: [{
        icon: lineSymbol,
        offset: '0',
        repeat: '10px'
      }],
      visibile: true,
      zIndex: 99
    },
    standard: {
      fillOpacity: 0,
      strokeOpacity: 0,
      zIndex: 99
    },
    hover: {
      fillColor: 'red',
      fillOpacity: 0.5,
      strokeOpacity: 0,
      zIndex: 99
    }
  },
  geoNote (color) {
    return {
      polyline: {
        zIndex: 99,
        strokeOpacity: 0,
        strokeColor: color || 'red',
        strokeWeight: 2,
        icons: [{
          icon: lineSymbol,
          offset: '0',
          repeat: '10px'
        }]
      },
      standard: {
        zIndex: 99,
        fillOpacity: 0,
        strokeOpacity: 0
      },
      hover: {
        zIndex: 99,
        fillColor: color || 'red',
        fillOpacity: 0.5,
        strokeOpacity: 0
      }
    }
  }
})

export { colors, defaultLayerStyles }
