<template>
    <div class="input-field ui-widget">
        <input
        v-model="text"
        :id="id"
        type="text"
        :placeholder="placeholder"
        @focus="onFocus"
        @keyup.exact="onTyping"
        @keyup.enter="enter"
        :disabled="disabled">
        <label :class="{active: text || placeholder}" v-if="label" :for="id">{{label}}</label>
    </div>
</template>

<script>
/* eslint-env jquery */
/* eslint no-underscore-dangle:
["error", { "allow": ["_create", "_renderMenu", "_renderItemData", "_super"] }] */
/* eslint import/no-unresolved: 0 */
/* eslint import/extensions: 0 */
/* eslint no-unused-vars: 0 */
/* eslint func-names: 0 */

import { autocomplete } from 'jquery-ui-dist/jquery-ui.js'

export default {
  name: 'BaseSearch',
  props: {
    autocomplete: {
      required: false,
      type: Boolean,
      default: false
    },
    autocompleteConfig: {
      required: false,
      type: Object,
      validator (obj) {
        const req = ['data', 'type']
        return req.every(r => Object.prototype.hasOwnProperty.call(obj, r))
      },
      default () {
        return {
          data: [],
          type: 'default'
        }
      }
    },
    callback: {
      required: false,
      type: Function
    },
    enterLocked: {
      required: false,
      type: Boolean,
      default: false
    },
    focus: {
      required: false,
      type: Function,
      default: () => {}
    },
    label: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String,
      default: ''
    },
    remote: {
      required: false,
      type: Function
    },
    validation: {
      required: false,
      type: Object
    },
    id: {
      required: false,
      type: String,
      default: 'searchbox'
    },
    clearAfter: {
      required: false,
      type: Boolean,
      default: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      autocompleteSource: [],
      text: null
    }
  },
  methods: {
    clear () {
      this.text = ''
    },
    complete (event, ui) {
      const item = typeof ui === 'undefined' ? this.text : ui.item
      if (this.clearAfter) {
        this.clear()
      }
      return this.callback(item)
    },
    enter () {
      if (!this.enterLocked) {
        return this.complete(this.text)
      }
      return false
    },
    initCategoryAutocomplete () {
      $.widget('custom.catcomplete', $.ui.autocomplete, {
        _create () {
          this._super()
          this.widget().menu('option', 'items', '> :not(.ui-autocomplete-category)')
        },
        _renderMenu (ul, items) {
          let currentCategory = ''
          $.each(items, (index, item = {}) => {
            if (item.category !== currentCategory) {
              ul.append(`<li class='ui-autocomplete-category'>${item.category}</li>`)
              currentCategory = item.category
            }
            const li = this._renderItemData(ul, item)
            if (item.category) {
              li.attr('aria-label', `${item.category} : ${item.label}`)
            }
          })
        }
      })
      $(`#${this.id}`).catcomplete({
        source: (request, response) => {
          this.autocompleteConfig.source(request, response)
        },
        minLength: 2,
        select: this.complete
      }).focus(function () {
        $(this).data('customCatcomplete').search($(this).val())
      })
    },
    initNormalAutocomplete () {
      $(`#${this.id}`).autocomplete({
        source: this.autocompleteConfig.data,
        select: this.complete
      })
    },
    onFocus () {
      this.focus()
    },
    onTyping () {
      this.$emit('value', this.text)
    }
  },
  mounted () {
    if (this.autocompleteConfig) {
      if (this.autocompleteConfig.type === 'default') this.initNormalAutocomplete()
      if (this.autocompleteConfig.type !== 'default') this.initCategoryAutocomplete()
    }
  }
}
</script>

  <style>

  .ui-autocomplete-category {
    font-weight: bold;
    padding: .2em .4em;
    margin: .8em 0 .2em;
    line-height: 1.5;
  }
  </style>
