import Vue from 'vue'
import { webHttp } from '../utils/apiHttp'

const listener = new Vue()
let checker
let version

const init = async () => {
  if (checker) {
    // already initialized
    return
  }

  // check every 10 minutes (arbitrary, maybe use a config setting)
  checker = setInterval(check, 10 * 60 * 1000)

  version = await fetchVersion()
}

const check = async () => {
  const current = await fetchVersion()

  if (version !== current) {
    listener.$emit('outdated')
  }
}

const fetchVersion = async () => {
  return webHttp.get('/cksum.txt')
    .then(({ data }) => data)
}

const update = () => {
  // just hard reload the page to get the latest files
  document.location.reload(true)
}

const onChange = (callback) => {
  init()
  listener.$on('outdated', callback)
}

const offChange = (callback) => {
  listener.$off('outdated', callback)
}

export { onChange, offChange, update }
