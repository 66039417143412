<template>
  <div id="the-map-container">
      <div id="the-map-wrapper" :class="{pip: pipActive, minimize: !pipOpen}">
        <transition :name="pipClosedTransition" mode="out-in">
          <div v-if="pipActive" id="pip-controls" class="row no-margin-bottom">
            <div class="col s2">
              <button id="map-toggle" class="pip-controls-btn" @click="togglePipOpen()">
                <i class="material-icons small">{{pipIcon}}</i>
              </button>
            </div>
            <div class="col s6">
              <small>{{formattedTime}} ({{timezoneOffset.timeZoneName}})</small>
            </div>
            <div class="col s4">
              <strong class="right">Service Map</strong>
            </div>
        </div>
        </transition>
        <div ref="searchMap" class="hero-map">
        </div>
        <slot name="controls"></slot>
      </div>
      <!-- default pip trigger -->
      <div ref="mapBottom"></div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'GoogleMap',
  methods: {
    ...mapActions('map', ['createMap']),
    ...mapMutations('map', ['togglePipActive', 'togglePipOpen'])
  },
  computed: {
    ...mapState('map', ['pipActive', 'pipOpen']),
    ...mapState('time', ['timezoneOffset']),
    ...mapGetters('time', ['formattedTime']),
    pipIcon () {
      return this.pipOpen ? 'minimize' : 'add'
    },
    pipClosedTransition () {
      return !this.pipOpen ? 'fade' : ''
    }
  },
  mounted () {
    // create a map instance inside of a dom element
    this.createMap({ ref: this.$refs.searchMap })
    // watch for page scroll to hit pip toggle point
    const loadPosition = this.$refs.mapBottom.offsetTop
    window.onscroll = () => {
      const open = window.pageYOffset >= loadPosition && !this.pipActive
      const close = window.pageYOffset <= loadPosition && this.pipActive
      if (open || close) this.togglePipActive()
    }
  }
}
</script>
<style lang="scss">
@import 'src/styles/base/_variables.scss';
$map-height: 500px;
$controls-height: 35px;

#the-map-container {
  height: $map-height;
}

.hero-map {
    width: 100%;
    height: $map-height;
    margin-bottom: 10px;
}

.pip-controls-btn, .pip-controls-btn:focus {
  background-color: transparent;
  border: none;
  padding: 0;
}

#the-map-wrapper.pip{
  position: fixed;
  bottom: 0;
  right: 0;
  width:30%;
  z-index:999;
  box-shadow:5px 5px 8px grey;

  &.minimize{
    height:$controls-height;
  }

  #pip-controls{
    width:100%;
    color: white;
    background-color: $secondary;
    padding: 0 15px;
    height:$controls-height;
  }
}
</style>
