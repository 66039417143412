<template>
    <transition name="fade">
        <span class="form-error error-text" v-if="activeErrors.has(field)">
            {{activeErrors.first(field)}}
        </span>
    </transition>
</template>
<script>
// override the default messages
const dict = { custom: {} }
export default {
  name: 'FormError',
  props: {
    /**
     * The 'error bag' object from vee validate
     * @type {Object}
     */
    activeErrors: {
      required: true,
      type: Object
    },
    /**
     * The field name being validated
     * @type {String}
     */
    field: {
      required: true,
      type: String
    },
    /**
     * A custom message to display in the case of a validation error
     * @type {String}
     */
    message: {
      required: false,
      type: String
    }
  },
  created () {
    dict.custom[this.field] = {
      // Since we use a date picker, just use a "required" message vs a "format" message
      date_format: this.message || 'Date is required',
      required: this.message || 'This field is required'
    }

    this.$validator.localize('en', dict)
  }
}
</script>
