/* eslint-disable arrow-parens */
import { intersection, first, pipe, isNil } from 'kyanite'
import { placesAutocomplete } from '../services/googleMapsQuery'
import { streamCollectionFilter } from './apiHttp'

// Figures out what category we want by type
// This isn't perfect but it's better
const getCategory = type => {
  const categories = {
    postal_code: 'Zipcode',
    locality: 'Cities',
    street_address: 'Addresses',
    route: 'Addresses',
    administrative_area_level_2: 'Counties'
  }

  return categories[type]
}

// A single ready to use formatter for predictions
// Still tweaking to improve on the complexity and flow
const formatPredictions = (predictions = [], accepted) => {
  const inter = intersection(accepted)
  const grabType = pipe([inter, first])

  return predictions
    .filter(({ types }) => Boolean(inter(types).length))
    .map(p => ({
      label: p.description,
      category: getCategory(grabType(p.types)),
      place_id: p.place_id,
      search_term: p.structured_formatting.main_text
    }))
}

/**
 * Handles predictions for the single hauler search bars
 * @param {Object} request The google request object
 * @param {Function} response The response callback
 */
export const singleHaulerSearch = (request, response, location) => {
  const accepted = ['locality', 'postal_code', 'administrative_area_level_2']

  if (!isNil(request.term) && request.term !== '') {
    const callback = predictions => {
      if (predictions) {
        return response(formatPredictions(predictions, accepted))
      }
      return false
    }
    return placesAutocomplete(request.term, callback, location)
  }
  return response()
}

/**
 * Handles the autocomplete for service areas used on the haulerSingle page
 * @param {Object} request The request object from google
 * @param {Function} response The response callback
 */
export const serviceAreaSearchStream = (request, response) => {
  const serviceAreaResults = []

  streamCollectionFilter('service-areas', 'netsuite_name', request.term, true)
    .subscribe((streamResponse) => {
      const zones = streamResponse.data.data
      zones.forEach((el) => {
        const category = el.netsuite_name.replace(/^(\d+)(.*)/, '$1')
        serviceAreaResults.push({
          category: `${category}`,
          search_term: el.id,
          label: `${el.netsuite_name}`,
          secondary_search: null,
          place_id: null,
          type: 'service area'
        })
      })
      return response(serviceAreaResults)
    })
}

/**
 * Handles streaming type ahead to the input for retrieving geo graphic notes by name
 * @param {Object} request
 * @param {Function} response
 */
export const geoNoteSearchStream = (request, response) => {
  streamCollectionFilter('geo-notes', 'name', request.term, true)
    .subscribe(({ data }) => {
      const notes = data.data.map(n => ({
        label: n.name,
        category: 'geonote',
        search_term: n.id
      }))
      return response(notes)
    })
}
