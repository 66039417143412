<template>
    <div v-if="loading || saving" class="progress loading-bar">
        <div class="indeterminate"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HeadPreloader',
  computed: mapState('events', ['saving', 'loading'])
}
</script>

<style lang="scss" scoped>
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  &.progress {
    height: 6px;
    margin: 0;
    z-index: 999;
  }
}
</style>
