/* eslint no-param-reassign: 0 */
import * as Sentry from '@sentry/browser'
import { apiHttp, apiCRUD } from '../../utils/apiHttp'
import events from './events'
import geometry from './geometry'

export default {
  namespaced: true,

  state: {
    market: {}
  },
  modules: {
    events,
    geometry
  },
  mutations: {
    clearMarket: state => { state.market = {} },
    setMarket: (state, data) => { state.market = data },
    updateMarketVal (state, data) {
      Object.assign(state.market, data)
    }
  },
  getters: {
    getMarket: state => state.market
  },
  actions: {
    clearMarket: ({ commit }) => commit('clearMarket'),
    setMarket: ({ commit }, id) => {
      commit('events/setLoading', true)
      return apiCRUD.record('market', id)
        .then(response => {
          commit('setMarket', response.data)
        })
        .catch(e => Sentry.captureException(e))
        .finally(() => commit('events/setLoading', false))
    },
    deleteMarket ({ commit }, id) {
      return apiHttp.delete(`/market/${id}`)
        .then(() => {
          commit('events/runFlash', {
            message: 'Market deleted successfully',
            severity: 'success',
            timeout: 2000
          })
          commit('setMarket', {})
        })
        .catch(err => {
          Sentry.captureException(err)

          throw err
        })
    },
    updateMarket ({ commit }, payload) {
      return apiHttp.put(`/market/${payload.id}`, payload)
        .then(({ data }) => {
          commit('events/runFlash', {
            message: 'Market updated successfully',
            severity: 'success',
            timeout: 2000
          })
          commit('setMarket', data)
        })
        .catch(err => {
          Sentry.captureException(err)

          throw err
        })
    }
  }
}
