import * as Sentry from '@sentry/browser'
import { ensureArray, find, findIndex, remove, update } from 'kyanite'
import events from './events'
import { apiHttp } from '../../utils/apiHttp'
import requestFormatter from '../../utils/requestFormatter'

const store = {
  namespaced: true,

  modules: {
    events
  },

  state: {
    pricingRecords: [],
    selectedPricing: {}
  },

  mutations: {
    /**
     * Sets the pricing records array state to whatever this is provided
     * PRICING RECORDS SHOULD ALWAYS BE AN ARRAY
     * @param {Object} state The vuex state for Pricing
     * @param {Array} data An array of pricing record objects
     */
    setPricingRecords (state, data) {
      state.pricingRecords = ensureArray(data)
    },

    applyPricingRecord (state, record) {
      const i = findIndex(r => r.id === record.id, state.pricingRecords)

      if (i >= 0) {
        state.pricingRecords = update(i, record, state.pricingRecords)
      } else {
        state.pricingRecords.push(record)
      }
    },

    updatePricingRecord (state, data) {
      const rollOffIndex = findIndex(r => r.id === data.id, data.service_area.rolloff_pricing)
      const i = findIndex(r => r.id === data.id, state.pricingRecords)

      if (i >= 0) {
        state.pricingRecords = update(i, data.service_area.rolloff_pricing[rollOffIndex], state.pricingRecords)
      }
    },

    removePricingRecord (state, id) {
      const i = findIndex(r => r.id === id, state.pricingRecords)

      if (i >= 0) {
        state.pricingRecords = remove(i, state.pricingRecords)
      }
    },

    clearPricingRecords (state) {
      state.pricingRecords = []
    },

    selectPricing (state, id) {
      state.selectedPricing = find(r => r.id === id, state.pricingRecords) || {}
    },

    updateSelectedPricing (state, { key, value }) {
      state.selectedPricing[key] = value
    },

    overwritePricing (state, record) {
      state.selectedPricing = record
    }
  },

  actions: {
    /**
     * Fetches a single Pricing record from the MAKO API
     * @param {Function} commit The vuex commit function to call mutations
     * @param {String} id The id of the pricing record we want to find
     * @returns {Promise} A network promise from axios
     */
    fetchPricing ({ commit }, id) {
      commit('events/setLoading', true)

      return apiHttp.get(`/rolloff-pricing/${id}`)
        .then(({ data }) => {
          commit('applyPricingRecord', data)

          return data
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: `There was an error while fetching pricing record ${id}`,
            severity: 'error',
            timeout: -1
          })
        })
        .finally(() => commit('events/setLoading', false))
    },

    /**
   * Fetches a list of Pricing records from the MAKO API.
   * NOTE: This WILL overwrite the pricingRecords store value
   * @param {Function} commit The vuex commit function to call mutations
   * @param {String} ids An array of id strings for the pricing records to fetch
   * @returns {Promise} A Promise.all of network promises from axios
   */
    fetchPricingList ({ commit, dispatch }, ids) {
      commit('events/setLoading', true)

      return Promise.all(ids.map(idx => dispatch('fetchPricing', idx)))
        .then(records => {
          commit('setPricingRecords', records)

          return records
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error while fetching pricing records',
            severity: 'error',
            timeout: -1
          })
        })
        .finally(() => commit('events/setLoading', false))
    },

    createPricing ({ commit, dispatch }, { data, userId }) {
      commit('events/setLoading', true)
      const payload = requestFormatter.rollOffPricing(data, userId)

      return apiHttp.post('/rolloff-pricing', payload)
        .then(response => {
          // addPricing will be committed in fetch pricing
          dispatch('fetchPricing', response.data.id)

          commit('events/runFlash', {
            message: 'Pricing Record created successfully',
            severity: 'success',
            timeout: 2000
          })

          return response.data
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: 'There was an error while creating pricing record',
            severity: 'error',
            timeout: -1
          })
        })
        .finally(() => commit('events/setLoading', false))
    },

    deletePricing ({ commit }, id) {
      commit('events/setLoading', true)

      return apiHttp.delete(`/rolloff-pricing/${id}`)
        .then(() => {
          commit('removePricingRecord', id)
          commit('events/runFlash', {
            message: 'Pricing Record deleted successfully',
            severity: 'success',
            timeout: 2000
          })
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: `There was an error while deleting pricing record ${id}`,
            severity: 'error',
            timeout: -1
          })
        })
        .finally(() => commit('events/setLoading', false))
    },

    updatePricing ({ commit }, { data, userId }) {
      commit('events/setLoading', true)
      const payload = requestFormatter.rollOffPricing(data, userId)

      return apiHttp.put(`/rolloff-pricing/${data.id}`, payload)
        .then(response => {
          commit('events/runFlash', {
            message: 'Pricing record updated successfully',
            severity: 'success',
            timeout: 2500
          })

          commit('updatePricingRecord', response.data)

          return response.data
        })
        .catch(e => {
          Sentry.captureException(e)
          commit('events/runFlash', {
            message: `There was an error while updating pricing record ${payload.id}`,
            severity: 'error',
            timeout: -1
          })
        })
        .finally(() => commit('events/setLoading', false))
    }
  }
}

export default store
