<template>
  <div>
    <label :for='label' class='checkbox'>
      <input
        :id='label'
        class='hidden'
        name='mustCall'
        type='checkbox'
        :checked='value'
        @input='$emit("input", $event.target.checked)'
      />
      <i v-show='value' class="material-icons must-call">phone</i>
      <i v-show='!value' class="material-icons no-must-call">phone_disabled</i>
    </label>
  </div>
</template>

<script>
export default {
  name: 'MustCallCheckbox',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
@import 'src/styles/base/_variables.scss';

.no-must-call {
  font-size: 16px;
  color: black;
  border: 2px solid black;
  cursor: pointer;
}

.must-call {
  font-size: 16px;
  color: white;
  background: $danger;
  border: 2px solid $danger;
  cursor: pointer;
}

.checkbox {
  position: relative;
}
</style>
