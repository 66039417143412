/**
 * @author Matt Frost
 * @description Convert a camel case comma separated string to normal case
 * @example
 * // returns 'Camel Case'
 * servicesNormalCase('camelCase')
 * @returns {string} the newly formatted Normal Case string
 */
import toNormalCase from './toNormalCase'
const servicesNormalCase = (val) => {
  const serviceArrayRaw = val.split(',')
  const serviceArrayCased = serviceArrayRaw.map((item) => {
    if (item !== 'emptyReturn') {
      return toNormalCase(item)
    }
    return toNormalCase(item).replace(' ', ' & ')
  })
  return serviceArrayCased.join(', ')
}
export default servicesNormalCase
