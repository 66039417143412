/**
 * @author Justin Voelkel
 * @description Convert a camel case string to normal case
 * @param {string} val the camelCase string to perform the conversion on
 * @example
 * // returns 'Camel Case'
 * toNormalCase('camelCase')
 * @returns {string} the newly formatted Normal Case string
 */
const toNormalCase = val => val.replace(/([A-Z])/g, ' $1').replace(/^./, s => s.toUpperCase())

export default toNormalCase
