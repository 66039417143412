/* eslint no-undef: 0 */
import { isEmpty, propEq } from 'kyanite'

/**
 * @author Justin Voelkel <justin@budgetdumpster.com>
 * @name coordinatesToLatLngArray
 * @description take geoJson coordinate arrays and turn them arrays of latLng instances
 * @param {array} coordinates an array of coordinates (potentially nested so recursive)
 * @example
 * // returns [latLng({lat: 1, lng: 2}), latLng({lat:3, lng:4})]
 * coordinatesToLatLng([[1, 2], [3, 4]])
 * @returns {array}
 */
export const coordinatesToLatLngArray = (coordinates, arr) => {
  if (!Array.isArray(coordinates)) return []
  return coordinates.reduce((acc, e) => {
  // in the case of multipolygons we want the level of nesting that results in [[lng, lat], [lng, lat]....]
    if (!Array.isArray(e[0]) || e[0].length !== 2) return coordinatesToLatLngArray(e, acc)
    const geo = e.map(([lng, lat]) => new google.maps.LatLng(lat, lng))
    acc.push(geo)
    return acc
  }, arr)
}

/**
 * @author Justin Voelkel <justin@budgetdumpster.com>
 * @name dynamicFeatureStyling
 * @description this function should be passed as the styling function to apply styles based on the properties applied to the feature
 * @prop {object} state
 * @prop {object} feature a google maps feature object from an active map instance
 */
export const dynamicFeatureStyling = feature => {
  // only apply styles that are explicitly set
  if (!isEmpty(feature.getProperty('styles'))) {
    // expecting at least a standard state as well as an optional hover
    const { standard } = feature.getProperty('styles')
    return standard
  }
  return {}
}

/**
 * @author Justin Voelkel <justin@budgetdumpster.com>
 * @name createPolylineArrayFromGeoJsonCoordinates
 * @description create a custom styled google maps polyline from geojson coordinate sets (polygon or multipolygon)
 * @param {array} coordinates an array coordinates or nested arrays of coordinates
 * @param {object} styles the styles to apply to the polyline
 * @returns {object} the new google maps Polyline instance
 */
export const createPolylineArrayFromGeoJsonCoordinates = (coordinates, styles) => {
  const coordinateArray = coordinatesToLatLngArray(coordinates, [])
  return coordinateArray.map(path => new google.maps.Polyline({
    path,
    ...styles
  }))
}

/**
 * @author Justin Voelkel <justin@budgetdumpster.com>
 * @name convertCollectionToMultiPolygon
 * @description accepts a geometry and, if it is a random collection, strips unwanted addtions and returns a multipolygon
 * @param {object} geometry the subject to operate on
 * @returns {object} should be a multipolygon or the original geometry
 */
export const convertCollectionToMultiPolygon = geometry => {
  // the unacceptable geometry types
  const unacceptable = ['LineString']
  if (!propEq('type', 'GeometryCollection', geometry)) return geometry
  const { geometries } = geometry
  const coordinates = geometries
    .filter(({ type }) => !unacceptable.includes(type))
    .map(f => f.coordinates)
  return { type: 'MultiPolygon', coordinates }
}
