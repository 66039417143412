<template>
  <section v-if="!loading">
    <div class="row pad-bottom">
      <div class="col s12">
        <base-switch
          v-model="haulerActive"
          :disabled="lock"
          label="Active"
          :options="[false, true]"
        />
      </div>
    </div>
    <div class="row">
      <div class="input-field col s12">
        <input
        :disabled="lock"
        v-validate="'required'"
        v-model="haulerName"
        id="name"
        type="text"
        name="name">
        <label for="name">Hauler Name</label>
        <form-error :activeErrors="errors" field="name"/>
      </div>
    </div>
    <div class="row pad-bottom">
      <div class="col s12">
        <label>Hauler Type</label><br />
        <input
          :disabled="lock"
          :id="`hauler-${hauler.id}-in-network`"
          v-validate="'required'"
          type="radio"
          name="type"
          value="inNetwork"
          v-model="haulerType">
        <label :for="`hauler-${hauler.id}-in-network`">In Network</label>
        <input
          :disabled="lock"
          :id="`hauler-${hauler.id}-out-of-network`"
          v-validate="'required'"
          type="radio"
          name="type"
          value="outOfNetwork"
          v-model="haulerType">
        <label :for="`hauler-${hauler.id}-out-of-network`">Out of Network</label>
        <form-error :activeErrors="errors" field="type"/>
      </div>
    </div>

    <form-buttons
    @delete="deleteCallback(hauler.hauler.id)"
    @discard="discard"
    @lock="lockState"
    @save="save"
    :deleteable="deleteable"
    :formChanged="true"
    :formLocked="lock"
    :formValid="valid"/>

  </section>
</template>
<script>
/* global Materialize */
/* eslint camelcase: 0 */
import { isEmpty } from 'kyanite'
import { mapActions, mapMutations, mapState } from 'vuex'
import FormButtons from './FormButtons.vue'
import BaseSwitch from '../ui/BaseSwitch.vue'
import FormError from '../ui/FormError.vue'
import states from '../config/states'
import requestFormatter from '../utils/requestFormatter'
import { apiCRUD } from '../utils/apiHttp'

/**
 *  A re-useable form to add or edit a hauler's properties
 */
export default {
  name: 'HaulerForm',
  components: {
    'base-switch': BaseSwitch,
    'form-error': FormError,
    'form-buttons': FormButtons
  },
  props: {
    /**
     * Optional value to allow the form to offer a delete option
     */
    deleteable: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
     * Callback to perform when delete is requested
     * Default to an emission of the hauler id
     */
    deleteCallback: {
      required: false,
      type: Function,
      default (id) {
        this.$emit('delete', id)
      }
    },
    /**
     * Optional boolean value to determine if inputs should be disabled
     * @type {Boolean}
     */
    locked: {
      required: false,
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      states,
      lock: this.locked,
      // a local copy only used for data dismissal (for now)
      localCopy: {}
    }
  },
  methods: {
    ...mapActions('hauler', ['updateHauler']),
    ...mapMutations('hauler', ['setHauler']),
    /**
     * Discard the changes and reset the form to initial val
     */
    discard () {
      this.lockState(true)
      this.resetValidation()
    },
    /**
     * Toggle the form lock state
     * @param {boolean} state - the locked state of the form
     */
    lockState (state) {
      this.lock = state
      this.$nextTick(() => {
        Materialize.updateTextFields()
      })
    },
    /**
     * Signal parent to save the current model data
     * @type {Object}
     * @return {mixed}
     */
    save () {
      // TODO - rework the complexity out of this all
      // hauler and location should just be separate things
      this.$validator.validateAll().then(valid => {
        if (valid) {
          // if it has an id it is an existing record
          if (this.hauler.hauler.id) {
            // boy this is a mess - really needs to get separated to make this viable
            this.updateHauler({ data: this.hauler.hauler, user: this.user.id })
              .then(() => { this.localCopy = JSON.stringify(this.hauler) })
              .finally(() => this.lockState(true))
          } else {
            this.saveNew()
          }
        }
      })
    },
    saveNew () {
      const payload = requestFormatter.hauler(this.hauler.hauler, this.user.id)
      apiCRUD.create('haulers', payload).then(response => response.data.id)
        .then(id => {
          this.$router.replace(`/haulers/${id}`)
        })
    },
    /**
     * Catch the emitted value from the dropdown and set the hauler state
     * @public
     * @param {String} val the selected state
     */
    setState (val) {
      if (val) this.location.state = val
    },
    /**
     * After the form gets updated reset the validation
     */
    resetValidation () {
      this.$nextTick(() => {
        this.$validator.reset()
      })
    }
  },
  computed: {
    ...mapState('events', ['loading']),
    ...mapState('session', ['user']),
    ...mapState(['hauler']),
    haulerName: {
      get () {
        return !isEmpty(this.hauler.hauler.name) ? this.hauler.hauler.name : ''
      },
      set (name) {
        this.setHauler({ ...this.hauler.hauler, name })
      }
    },
    haulerActive: {
      get () {
        return this.hauler.hauler.active
      },
      set (active) {
        this.setHauler({ ...this.hauler.hauler, active })
      }
    },
    haulerType: {
      get () {
        return this.hauler.hauler.type
      },
      set (type) {
        this.setHauler({ ...this.hauler.hauler, type })
      }
    },
    location: {
      get () {
        return !isEmpty(this.hauler.hauler.locations) ? this.hauler.hauler.locations[0] : {}
      },
      set (val) {
        this.setHauler({ ...this.hauler.hauler.locations[0], ...val })
      }
    },
    valid () {
      return !!(this.errors.items.length === 0)
    }
  },
  watch: {
    haulerName () {
      this.localCopy = JSON.stringify(this.hauler.hauler)
    }
  },
  updated () {
    this.$nextTick(() => {
      $(document).ready(() => {
        Materialize.updateTextFields()
      })
    })
  }
}
</script>
<style lang="scss">
  @import "src/styles/base/_variables.scss";
  .btn{
    margin:0 5px;
  }
  .save{
    background-color:$success;
    &:hover{
      background-color:$success-dark;
    }
  }
  .discard{
    background-color:$danger;
    &:hover{
      background-color:$danger-dark;
    }
  }
  div.row.pad-bottom {
    margin-bottom: 20px;
  }
</style>
