<template>
  <div class="container">
    <section class="tabs-wrapper">
      <h4>Exception Report</h4>
      <div class="tabs">
        <p :class="['tab', { active: activeView === 'ExceptionCollectionHauler' }]" @click="activeView = 'ExceptionCollectionHauler'">Hauler Exceptions</p>
        <p :class="['tab', { active: activeView === 'ExceptionCollectionBulk' }]" @click="activeView = 'ExceptionCollectionBulk'">Bulk Exceptions</p>
      </div>
    </section>

    <section>
      <component
        :is="activeView"
        class="full"
      />
    </section>
  </div>
</template>

<script>
import ExceptionCollectionBulk from '../components/ExceptionCollectionBulk'
import ExceptionCollectionHauler from '../components/ExceptionCollectionHauler'

export default {
  name: 'ExceptionCollection',
  components: {
    ExceptionCollectionBulk,
    ExceptionCollectionHauler
  },
  data () {
    return {
      activeView: 'ExceptionCollectionHauler'
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    margin-top: 2em;

    .full {
      margin-left: 1em;
    }
  }

  h4 {
    display: inline;
  }

  .tabs {
    display: inline;
    margin-left: 2em;

    p {
      display: inherit;
      margin: 1em;
      padding-bottom: .5em;
    }
  }
</style>
