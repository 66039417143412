<template>
    <div id="content" class="container">
        <div class="search-bar">
            <quote-search :preferenceVisible="true"></quote-search>
        </div>
    </div>
</template>

<script>
import QuoteSearch from '../components/QuoteSearch.vue'

export default {
  name: 'Quote',
  components: {
    'quote-search': QuoteSearch
  }
}
</script>
