import Vue from 'vue'
import vueDebounce from 'vue-debounce'
import VeeValidate from 'vee-validate'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import App from './App.vue'
import store from './state/store'
import router from './router/router'
import dialog from './services/dialog'
import PrimeVue from 'primevue/config'

const DIALOG_SERVICE = dialog()

window.$ = window.jQuery = window.jquery = require('jquery')

// if we're in a build environment go ahead and attach error tracking
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://3321bae9184b42199e6e79249e0af16b@o4504481262206976.ingest.sentry.io/4504481484570624',
    // eslint-disable-next-line no-undef
    release: `mako@${process.env.VUE_APP_VERSION}`,
    environment: process.env.VUE_APP_ENVIRONMENT,
    attachStacktrace: true,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ]
  })
}

Vue.config.productionTip = false

// form validation plugin
Vue.use(VeeValidate)
Vue.use(vueDebounce)
Vue.use(PrimeVue)

new Vue({
  router,
  store,
  render: h => h(App),
  methods: {
    createDialog (message, duration = 1000) {
      DIALOG_SERVICE.toast(message, duration)
    }
  }
}).$mount('#app')
