<template>
    <button
      @click="callback"
      class="mako-btn"
      :disabled="disabled">
        <slot>Button</slot>
    </button>
</template>

<script>
/**
 * A __functional__ component that is used as the base button for the application
 */
export default {
  name: 'BaseButton',
  props: {
    /**
     * The callback to execute when the button is clicked
     * @type {Function}
     */
    callback: {
      required: true,
      type: Function
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>
