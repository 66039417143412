<template>
  <section class="mt container">
    <div class="row" v-if="addBtn">
      <div class="right">
        <button
          type="button"
          :class="['mt btn right', creating ? 'discard' : '']"
          @click="creating = !creating">
          {{ addBtn }}
        </button>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <!-- Our actual collection itself -->
      <div class="row" v-if="!creating">
        <section class="collection">
          <section class="collection__header col s12">
            <div :class="['col', model === 'alerts' ? 's2' : 's6']">
              <h4>{{ title.normal }}</h4>
            </div>
            <div v-if="model === 'alerts'" class="col s4">
              <div class="collection__legend">
                Key:
                <i class="material-icons alert">local_shipping</i> Hauler
                <i class="material-icons alert alert--rolloff">monetization_on</i> Product Pricing
                <i class="material-icons alert alert--service">gps_fixed</i> Service Area
              </div>
            </div>
            <div class="col s6" v-if="model !== 'alerts'">
              <div class="input-field">
                <input
                  id="collSearch"
                  type="text"
                  v-model="searchText"
                  v-debounce:400ms="searchRecords">
                <label for="collSearch" :class="{ active: searchText }">
                  Search For {{ title.normal }}
                </label>
              </div>
            </div>
            <div v-else class="col s6">
              <div class="collection__legend">
                <label>Filter By</label>
                <select v-model="filterBy" class="browser-default">
                  <option value="">None</option>
                  <option v-for="f in headers" :key="f" :value="f">{{ f | normalize }}</option>
                </select>
              </div>
            </div>
          </section>
          <section>
            <table class="highlight bordered">
              <thead>
                <tr>
                  <th
                    v-for="f in headers"
                    :key="f"
                    :id="f"
                    @click="sorting(f)">
                    {{ f | normalize }}
                    <i
                      :class="['material-icons', { down: sortDir === 'desc' }]"
                      v-show="f === currField">
                      arrow_drop_up
                    </i>
                  </th>
                </tr>
              </thead>
              <transition-group v-show="filteredRecords.length" name="flip-list" tag="tbody">
                <router-link
                  v-for="rec in filteredRecords"
                  :key="rec.id"
                  tag="tr"
                  :to="model === 'alerts'
                    ? `/${model}/${rec.id}?hauler=${rec.hauler_id || ''}`
                    : `/${model}/${rec.id}`">
                  <td v-for="f in headers" :key="f">
                    <span v-if="f === 'alert_type'" :title="transform(rec[f]) || ''">
                      <i :class="[
                      'material-icons alert',
                      { 'alert--rolloff': rec[f] === 'Rolloff Pricing', 'alert--service': rec[f] === 'Service Area' }]">
                        {{ getIcon(rec[f]) }}
                      </i>
                    </span>
                    <user-name v-else-if="f === 'created_by' || f === 'updated_by'" :userId="rec[f]"/>
                    <span v-else>{{ checkData(f, rec[f]) }}</span>
                  </td>
                </router-link>
              </transition-group>
            </table>
          </section>
        </section>
      </div>
      <section class="row" v-else>
        <div class="card">
          <div class="card-content">
            <span class="card-title">New {{ title.singular }}</span>
            <div class="row">
              <component :is="currentForm"></component>
            </div>
          </div>
        </div>
      </section>
    </transition>
    <transition name="fade" mode="out-in">
      <div class="row">
        <paginate
          v-show="!creating && totalPages"
          :model="model"
          :totalPages="totalPages"
          @pageChange="updateCollection" />
      </div>
    </transition>
  </section>
</template>

<script>
/* eslint-disable semi, camelcase */
import { mapMutations } from 'vuex'
import { capitalize, compose, encase, filter, flip, identity, prop, when } from 'kyanite'
import * as Sentry from '@sentry/browser'

import AlertForm from '../components/AlertForm'
import CollectionManagerSort from '../utils/CollectionManager'
import GeoNoteForm from '../components/GeoForm'
import HaulerForm from '../components/HaulerForm'
import MarketForm from '../components/MarketForm'
import Paginate from '../components/PaginateCollection'
import UserName from '../components/UserName'
import { normalize } from '../utils/stringManipulation'
import { apiCRUD, streamCollectionFilter } from '../utils/apiHttp'
import { format } from '../utils/time'

export default {
  props: {
    model: {
      type: String,
      requied: true
    },
    headers: {
      type: Array,
      default: () => ['name']
    },
    canAdd: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 20
    },
    searchWith: {
      type: String,
      default: 'name'
    }
  },
  components: {
    AlertForm,
    GeoNoteForm,
    HaulerForm,
    MarketForm,
    Paginate,
    UserName
  },
  data () {
    return {
      creating: false,
      currField: '',
      sortDir: '',
      records: [],
      searchText: '',
      stream: null,
      totalPages: 0,
      filterBy: ''
    }
  },
  filters: {
    normalize
  },
  computed: {
    title () {
      const title = this.model.split('-')
        .reduce((acc, w) => `${acc} ${capitalize(w)}`, '').trim()

      return {
        normal: title,
        singular: title.substring(0, title.length - 1)
      }
    },
    addBtn () {
      if (this.model === 'alerts') {
        return false
      }

      return this.creating ? 'Cancel' : `Add ${this.title.singular}`
    },
    currentForm () {
      return `${this.model.substring(0, this.model.length - 1)}-form`
    },
    filteredRecords () {
      if (this.filterBy) {
        return filter(compose(identity, prop(this.filterBy)), this.records)
      }

      return this.records
    }
  },
  methods: {
    ...mapMutations('market', ['setMarket']),
    ...mapMutations('events', ['setLoading']),
    transform (str) {
      if (str === 'Rolloff Pricing') {
        return 'Product Pricing'
      }

      return str
    },
    getIcon (data) {
      const icons = {
        Hauler: 'local_shipping',
        ServiceArea: 'gps_fixed',
        RolloffPricing: 'monetization_on'
      }

      if (data) {
        return icons[data.replace(' ', '')]
      }

      return data
    },
    checkData (field, data) {
      switch (field) {
        case 'expires':
          return when(identity, flip(format, 'MM/DD/YYYY'), data)
        case 'created_at':
        case 'updated_at':
        case 'check_back':
          return when(identity, flip(format, 'MM/DD/YYYY HH:mm'), data)
        default:
          return data
      }
    },
    sorting (field) {
      this.currField = field
      this.records.sort(CollectionManagerSort.sort(field))

      this.sortDir = CollectionManagerSort.getSortDirection()
    },
    searchRecords (val) {
      const filterBy = this.model === 'alerts' ? 'text' : 'name'

      this.searchTerm = val

      if (!val) {
        if (this.stream) {
          this.stream.unsubscribe()
        }
        this.totalPages = 0
        this.updateCollection(1)
      } else {
        this.records = []
        this.setLoading(true)
        this.stream = streamCollectionFilter(this.model, filterBy, val)
          .subscribe(({ data }) => {
            this.totalPages = 0
            this.records = data.data
            this.setLoading(false)
          })
      }
    },
    updateCollection (page) {
      this.records = []
      this.setLoading(true)
      return apiCRUD.collection(this.model, {
        page,
        per_page: this.model === 'geo-notes' ? 10 : 20
      })
        .then(({ data }) => {
          this.setLoading(false)
          this.records = data.models
          this.totalPages = data.total_pages
        })
        .catch(e => {
          this.setLoading(false)

          Sentry.captureException(e)
        })
    }
  },
  mounted () {
    const fetcher = encase(this.updateCollection, 1)

    if (!fetcher) {
      this.$router.replace('/quote')
      this.setLoading(false)
    }

    // Place our resetter mutations here so that when adding a new form
    // We don't run into store issues persisting in new forms
    this.setMarket({})
  },
  watch: {
    model () {
      this.updateCollection(1)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

.alert {
  color: $primary;
  &--service {
    color: $primary-light;
  }
  &--rolloff {
    color: $primary-light-2;
  }
}

.collection {
  border: 1px solid #d0d0d0;
  &__header {
    border-bottom: 1px solid #d0d0d0;
  }
  &__legend {
    vertical-align: middle;
    margin: 1.14rem 0 0.912rem 0;
  }
}

.mt {
  margin-top: 0.5rem;
}

.right .btn.right {
  margin-right: 0;
}

.contain {
  width: 90%;
  margin: auto;
}

tbody tr {
  color: #FA8D29;
}

tbody tr td {
  cursor: pointer;
}

table tbody tr td:first-child,
table thead tr th:first-child {
  text-align: left;
  padding-left: 1.5rem;
}

.btn {
  transition: all 0.4s;
}

th .material-icons {
  vertical-align: middle;
}

.material-icons.down {
  transform: rotate(180deg);
}

.material-icons {
  transition: transform 0.3s;
}

.flip-list-move {
  transition: all 400ms ease-in-out 50ms
}
</style>
