<template v-if="haulers">
    <section>
        <base-accordion
        class="hauler-service-area"
        v-for="(hauler, key) in sortedHaulers"
        v-show="(!serviceAreas.length || serviceAreas.includes(hauler.id)) && haulerIsVisible(hauler.id)"
        :key="hauler.id">
            <section
            slot="head"
            class="accordion-head-wrapper"
            @click="addHauler(hauler.id)"
            @mouseenter="toggleHover(hauler.id)"
            @mouseleave="toggleHover()"
            :data-key="hauler.id">
              <div class="inner-wrapper">
                <div class="key-box left" :style="'background-color:'+colors[key]">&nbsp;</div>
                <span class="hauler-name"><b>{{hauler.hauler.name}} {{hauler.name}}</b></span>
                <em class="search_text" v-if="hauler.search_text">{{decode(hauler.search_text)}}</em>
              </div>
            </section>

            <section slot="body"
            v-if="renderedHaulers.includes(hauler.id)"
            :data-key="hauler.id">
              <dumpster-price
              v-for="dumpster in specifiedServiceArea(hauler.id)"
              :filtered="filters"
              :dumpster="dumpster"
              :haulerName="`${hauler.hauler.name} ${hauler.name}`"
              :haulerNotes="hauler.hauler.notes"
              :key="dumpster.id">
              <div slot="hauler-info">
                <div v-if="hasHaulerAlerts(key) || hasServiceAreaAlerts(key)" class="col s12">
                  <base-accordion :open="true" class="alert-accordion" style="margin-bottom:0">
                    <div class="alert-accordion-head" slot="head">
                      <alert-box message="HAULER/SERVICE AREA ALERT"></alert-box>
                    </div>
                    <div slot="body">
                      <div v-if="hauler.hauler.alerts.length">
                        <div v-for="alert of hauler.hauler.alerts" :key="alert.id">
                          <b>Hauler Alert For {{hauler.hauler.name}}: </b>
                          {{alert.text}}<br>
                          <small>
                            <i>
                              <b>Created At:</b> {{alert.created_at}}
                              <b>Created By:</b> <user-name :userId="alert.created_by" /> |
                              <b v-if="alert.updated_by">updated at:</b>
                              {{alert.updated_at}}
                              <b v-if="alert.updated_by">updated by:</b>
                              <user-name :userId="alert.updated_by" />
                            </i>
                          </small>
                        </div>
                        <br>
                      </div>
                      <div v-if="hauler.alerts.length">
                      <div v-for="alert of hauler.alerts" :key="alert.id">
                        <b>Service Area Alert For {{hauler.hauler.name}}: </b>
                        {{alert.text}}<br>
                        <small>
                          <i>
                            <b>Created At:</b> {{alert.created_at}}
                            <b>Created By:</b> <user-name :userId="alert.created_by" /> |
                            <b v-if="alert.updated_by">updated at:</b>
                            {{alert.updated_at}}
                            <b v-if="alert.updated_by">updated by:</b>
                            <user-name :userId="alert.updated_by" />
                          </i>
                        </small>
                      </div>
                      </div>
                    </div>
                  </base-accordion>
                </div>
              </div>
              </dumpster-price>
            </section>
        </base-accordion>
        <div v-if="!filteredList.length">
          <h4>There are no products that meet your filter criteria</h4>
        </div>
    </section>
</template>

<script>
/* eslint-disable camelcase */
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { ascendBy, prop, sort } from 'kyanite'
import { AllHtmlEntities } from 'html-entities'
import ClipboardJS from 'clipboard'
import AlertBox from '../ui/AlertBox.vue'
import { colors } from '../config/colors'
import BaseAccordion from '../ui/BaseAccordion.vue'
import DumpsterPrice from './DumpsterPrice.vue'
import UserName from './UserName'
/**
 * Will display the quote search results by hauler
 */
export default {
  name: 'HaulerPricingResults',
  props: {
    /**
     * The search result from the API call (All haulers, their products, and related data)
     * @type {Array}
     */
    haulers: {
      required: true,
      type: Array
    },
    /**
     * The properties that can be filtered (size, waste type, brand, etc.)
     * @type {Object}
     */
    filters: {
      required: false,
      type: Object
    }
  },
  components: {
    'alert-box': AlertBox,
    'base-accordion': BaseAccordion,
    'dumpster-price': DumpsterPrice,
    UserName
  },
  data () {
    return {
      renderedHaulers: [],
      haulersWithUnfilteredProducts: [],
      colors
    }
  },
  computed: {
    ...mapState(['hover']),
    ...mapState('filter', ['serviceAreas']),
    ...mapGetters('filter', ['filteredList', 'filtersActive']),
    visibleHaulers () {
      return [...new Set(this.filteredList.map(d => d.service_area_id))]
    },
    sortedHaulers () {
      // Sort the haulers based on their aggregate ranking
      return sort(ascendBy(prop('aggregate_ranking')), this.haulers)
    }
  },
  methods: {
    ...mapActions('map', ['toggleHover']),
    ...mapMutations('events', ['runFlash']),
    decode: AllHtmlEntities.decode,
    /**
     * Determines if the clicked hauler is already rendered or not
     * If not then go ahead and render it's content
     * @param {string} id The id of the hauler to verify
     * @public
     */
    addHauler (id) {
      if (!this.renderedHaulers.includes(id)) {
        this.renderedHaulers.push(id)
      }
    },
    /**
     * Check if the result has hauler specific alerts
     * @param {number} key - the index of the item in the service area array
     * @return {boolean}
     */
    hasHaulerAlerts (key) {
      return this.haulers[key].hauler.alerts && this.haulers[key].hauler.alerts.length > 0
    },
    /**
     * Check if the result has service area specific alerts
     * @param {number} key - the index of the item in the service area array
     * @return {boolean}
     */
    hasServiceAreaAlerts (key) {
      return this.haulers[key].alerts && this.haulers[key].alerts.length > 0
    },
    /**
     * Given a specifc service area id return all of it's products from state
     * @param {string} id - the id of a service area
     */
    specifiedServiceArea (id) {
      return this.filteredList.filter(el => el.service_area_id === id)
    },
    haulerIsVisible (id) {
      return this.visibleHaulers.includes(id)
    }
  },
  mounted () {
    const clipboard = new ClipboardJS('.clip-btn')
    clipboard.on('success', e => {
      const options = {
        message: `Copied ${e.text} to clipboard`,
        severity: 'success',
        timeout: 1500
      }
      this.runFlash(options)
    })

    clipboard.on('error', e => {
      const options = {
        message: `Error copying ${e.text} to clipboard`,
        severity: 'error',
        timeout: 1500
      }
      this.runFlash(options)
    })
  }
}
</script>

<style lang="scss">
.hauler-service-area {
  margin-top: 0;
  .accordion-head-wrapper {
    width: 100%;
    height:100%;
    .inner-wrapper{
      padding: 1rem;
    }
  }
  .collapsible-header {
    padding: 0;
  }
  .collapsible-body {
    max-height: 600px;
  }
}
.search_text {
  margin-left: 10px;
}
.key-box {
  margin-right: 10px;
  width: 50px;
  height: 100%;
  background-color: #eee;
}
</style>
