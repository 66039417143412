import { isEmpty } from 'kyanite'
/* eslint prefer-const: ["error", {"destructuring": "all"}] */
/**
 * @description Convert the 24 hour time string to a 12 hour time string with suffix AM/PM
 * @author Justin Voelkel
 * @param {string} time time string in HH:mm format
 * @example
 * // returns 1:30 PM
 * twelveHours('13:30')
 * @returns {string} reformatted to 12 hours with suffix added
 */
const twelveHours = time => {
  if (isEmpty(time)) return time

  const [hr, mins] = time.split(':')
  const suffix = hr > 11 ? 'PM' : 'AM'

  return `${hr > 12 ? hr - 12 : hr}:${mins} ${suffix}`
}

export { twelveHours as default }
