<template>
  <div class="alert-view">
    <button class="mako-btn" @click="creating = true">Create Alert</button>
    <div v-if="loading" class="stateful">
      <span class="stateful__spinner stateful__spinner--huge stateful__spinner--block"></span>
      <span class="stateful__msg">Loading Alerts...</span>
    </div>
    <div v-else-if="creating">
      <alert-form
        :record="{}"
        :locked="false"
        :expireable="true"
        :discardCallback="() => creating = false"
        @save="runCreate"
      />
    </div>
    <div v-else-if="!creating && !Boolean(alerts.length)">
      <h3>This {{ level === 'service-area' ? 'Zone' : 'Region' }} has no alerts</h3>
    </div>
    <div v-else>
      <alert-form
        :lock="true"
        v-for="a in alerts"
        :key="a.id"
        :record="a"
        @save="saveAlert"
        @delete="deleteAlert"
        :deleteable="true"
        :expireable="true"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'kyanite'
import { mapState, mapActions } from 'vuex'
import AlertForm from './AlertForm'

export default {
  props: {
    level: {
      type: String,
      required: true
    },

    levelId: {
      type: String,
      required: true
    }
  },

  components: {
    AlertForm
  },

  data () {
    return {
      creating: false,
      loading: true
    }
  },

  computed: {
    ...mapState('alerts', ['alerts']),
    ...mapState('regions', ['selectedRegion', 'selectedArea'])
  },

  methods: {
    ...mapActions('alerts', ['fetchAlerts', 'saveAlert', 'createAlert', 'deleteAlert']),

    runCreate (alert) {
      const id = isEmpty(this.selectedArea)
        ? { regions: [this.selectedRegion.id] }
        : { service_areas: [this.selectedArea.id] }

      this.createAlert({ ...alert, ...id })
    }
  },

  mounted () {
    this.fetchAlerts({ id: this.levelId, level: this.level })
      .then(() => {
        this.loading = false
      })
  }
}
</script>

<style lang="scss" scoped>
.stateful {
  display: grid;
}

.mako-btn {
  margin-bottom: 0.75rem;
}

.stateful span {
  place-self: center;
}
</style>
