/* eslint no-param-reassign: 0 */
import * as Sentry from '@sentry/browser'
import { convertToLowerCamelCase } from '../../utils/stringManipulation'
import { apiHttp } from '../../utils/apiHttp'
// track in progress calls to avoid duplication on unresolved promise overlapping
let inProgress = []
export default {
  state: {
    brands: [],
    markets: [],
    products: [],
    sizes: [],
    wasteTypes: [],
    costStructures: [],
    serviceTypes: []
  },
  mutations: {
    clearAllMeta: state => {
      Object.keys(state).forEach(key => { state[key] = [] })
    },
    setMeta: (state, { key, data }) => { state[key] = data.sort() },
    sortMeta: (state, { key, sortFn }) => { state[key] = state[key].sort(sortFn) }
  },
  getters: {
    meta: state => key => [...state[key]],
    sortedMeta: state => (key, sortFn) => [...state[key]].sort(sortFn),
    metaById: state => (key, id) => [...state[convertToLowerCamelCase(key)]].find(e => e.id === id)
  },
  actions: {
    /**
     * Clear all meta
     * @param {object} context - exposes all store functionality
     */
    clearAllMeta: context => context.commit('clearAllMeta'),
    /**
     * Set available meta arrays
     * @param {Object} context - destructured to commit and state
     * @param {string} meta - the type of meta to attempt to set
     */
    setMeta: ({ commit, state }, meta) => {
      const stateKey = convertToLowerCamelCase(meta)
      const valid = Object.keys(state).includes(stateKey)

      if (!valid) throw new Error(`The requested meta ${stateKey} is not available in state`)
      if (state[stateKey].length > 0 || inProgress.includes(meta)) return false
      inProgress.push(meta)
      return apiHttp(meta, { params: { per_page: -1 } })
        .then(response => commit('setMeta', { key: stateKey, data: response.data.data }))
        .catch(e => Sentry.captureException(e))
        .finally(() => {
          inProgress = inProgress.splice(inProgress.indexOf(meta), 1)
        })
    }
  }
}
