import { setCookie, removeCookie, getCookie } from 'tiny-cookie'
import Vue from 'vue'
import VuexPersistedState from 'vuex-persistedstate'
import Vuex from 'vuex'
import alerts from './modules/alerts'
import availability from './modules/availability'
import chip from './modules/chip'
import market from './modules/market'
import meta from './modules/meta'
import note from './modules/note'
import regions from './modules/regions'
import session from './modules/session'
import serviceAreas from './modules/service-areas'
import hauler from './modules/hauler'
import user from './modules/user'
import hover from './modules/hover'
import filter from './modules/filter'
import geometry from './modules/geometry'
import wasteType from './modules/waste-type'
import search from './modules/search'
import size from './modules/size'
import events from './modules/events'
import time from './modules/time'
import map from './modules/map'
import pricing from './modules/pricing'
import exceptions from './modules/exceptions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alerts,
    availability,
    chip,
    market,
    meta,
    note,
    regions,
    session,
    serviceAreas,
    hauler,
    user,
    hover,
    filter,
    geometry,
    wasteType,
    search,
    size,
    events,
    time,
    map,
    pricing,
    exceptions
  },
  // Re-introduce this at prod launch
  plugins: [
    VuexPersistedState({
      paths: ['session'],
      storage: {
        getItem: getCookie,
        setItem (key, value) {
          return setCookie(key, value, { expires: '12h' })
        },
        removeItem: removeCookie
      }
    })
  ]
})
