<template>
    <div class="switch">
        <label>
            {{label || options[0]}}
            <input type="checkbox" v-bind:checked="checked" v-on:change="emitSelectedOption" :disabled="disabled">
            <span class="lever"></span>
            {{label ? '' : options[1]}}
        </label>
    </div>
</template>

<script>
export default {
  name: 'BaseSwitch',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      required: false
    },
    options: {
      required: true,
      type: Array,
      validation (arr) {
        return arr.length === 2
      }
    },
    label: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    checked: {
      get () {
        return (typeof this.selected !== 'undefined' && this.options[1] === this.selected)
      }
    }
  },
  methods: {
    emitSelectedOption (e) {
      const selected = e ? e.target.checked : this.checked
      this.$emit('change', selected ? this.options[1] : this.options[0])
    }
  },
  mounted () {
    this.emitSelectedOption()
  }
}
</script>
