const truthy = (value) => {
  return value === true ||
    value === 'true' ||
    value === 1 ||
    value === '1'
}

const falsey = (value) => {
  return value === false ||
    value === 'false' ||
    value === 0 ||
    value === '0' ||
    value === '' ||
    typeof value === 'undefined'
}

export { truthy, falsey }
