<template>
    <div class="row collection">
      <table class="highlight bordered">
      <thead>
        <tr>
          <th @click="sorting('hauler_name')">Hauler
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'hauler_name'">
              arrow_drop_up
            </i>
          </th>
          <th>Region</th>
          <th>Zones</th>
          <th @click="sorting('territory')">Territory
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'territory'">
              arrow_drop_up
            </i>
          </th>
          <th @click="sorting('updated_at')">Last Saved
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'updated_at'">
              arrow_drop_up
            </i>
          </th>
          <th> Saved By </th>
          <th @click="sorting('action_date')"> End Date
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'action_date'">
              arrow_drop_up
            </i>
          </th>
          <th @click="sorting('type')"> Type
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'type'">
              arrow_drop_up
            </i>
          </th>
          <th @click="sorting('reason')"> Reason
            <i :class="['material-icons', { down: sortDir === 'desc' }]" v-show="sortField === 'reason'">
              arrow_drop_up
            </i>
          </th>

        </tr>
      </thead>
      <tbody v-if="loading" class="stateful centered">
        <span class="stateful__spinner stateful__spinner--huge stateful__spinner--block"></span>
        <span class="stateful__msg">Loading Results...</span>
      </tbody>
      <tbody
        v-else
        v-for="exception in exceptions"
        :key="exception.id">
        <tr @click="exception.toggleVisibility = !exception.toggleVisibility">
          <td>
            <router-link :to="`/haulers/${exception.hauler_id}`" class="hauler-link">
              {{exception.hauler_name || ''}}
            </router-link>
          </td>
          <td class="full">{{exception.region_name}}</td>
          <td>{{exception.service_areas_text || 'All'}}</td>
          <td>{{exception.territory}}</td>
          <td><span v-html="formatUpdatedAt(exception.updated_at)" /></td>
          <td><user-name :userId="exception.updated_by || ''" /></td>
          <td>
            <i class="material-icons checkBack" v-if="exception.action_type.toLowerCase() === 'checkback'">notifications</i>
            <i class="material-icons expire" v-if="exception.action_type.toLowerCase() === 'expirationdate'">hourglass_bottom</i>
            <i class="material-icons permanent" v-if="exception.action_type.toLowerCase() === 'permanent'">lock_clock</i>
            <span v-if="exception.action_type.toLowerCase() !== 'permanent'">{{formatDate(exception.action_date)}}</span>
            <span v-if="exception.action_type.toLowerCase() === 'permanent'">Permanent</span>
          </td>
          <td>{{exception.type | toNormalCase}}</td>
          <td>{{exception.reason | toNormalCase}}</td>
        </tr>
        <tr v-if="exception.toggleVisibility" class="availability-row">
          <td>&nbsp;</td>
          <td colspan="2">
            <div v-if="exception.type.toLowerCase() === 'availabilitybydate'">
              <span class="text-bold">Unavailable Dates:</span> {{formatDateRange(exception.start_date, exception.end_date)}}
            </div>
            <div v-if="exception.type.toLowerCase() === 'closure'">
              <span class="text-bold">Closure:</span> {{formatDateRange(exception.start_date, exception.end_date)}}
            </div>
            <div v-if="exception.type.toLowerCase() === 'dayofweek'">
              <span class="text-bold">Schedule by Day of Week:</span> No {{daysOfWeek(exception.days_of_week)}}
            </div>
            <div v-if="exception.type.toLowerCase() === 'availabilitybyzone' && exception.availability_action === 'mustCall'">
              <span class="text-bold">Next Available Date:</span> <span class="warning">Must Call</span>
            </div>
            <div v-if="exception.type.toLowerCase() === 'availabilitybyzone' && exception.next_available_date !== null && exception.availability_action === 'nextAvailableDate'">
              <span class="text-bold">Next Available Date:</span> {{formatDate(exception.next_available_date)}}
            </div>
            <div v-if="exception.type.toLowerCase() === 'servicearea'">
              <span class="text-bold">Service Area:</span> {{exception.boundaries_description}}
            </div>
            <div v-if="exception.type.toLowerCase() === 'noservice'">
              <span class="text-bold">No Service</span>
            </div>
          </td>
          <td colspan="5">
            <div v-show="!['closure', 'dayofweek'].includes(exception.type.toLowerCase())">
              <span class="text-bold">Service Types: </span> {{exception.delivery_type| servicesNormalCase}}
            </div>
            <div v-show="exception.type.toLowerCase() !== 'closure'">
              <span class="text-bold">Sizes: </span> {{exception.sizes_text || 'All'}}
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
    </div>
</template>
<script>
import { daysOfWeek, format, formatRange } from '../utils/time'
import UserName from '../components/UserName'
import toNormalCase from '../filters/toNormalCase'
import servicesNormalCase from '../filters/servicesNormalCase'

export default {
  name: 'ExceptionTable',
  components: {
    UserName
  },
  props: {
    exceptions: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  filters: {
    toNormalCase,
    servicesNormalCase
  },
  data () {
    return {
      sortField: 'action_date',
      sortDir: 'asc',
      territories: [
        {
          name: 'Midwest',
          value: 'Midwest'
        }, {
          name: 'Southeast',
          value: 'Southeast'
        }, {
          name: 'Northeast',
          value: 'Northeast'
        }, {
          name: 'West',
          value: 'West'
        }
      ]
    }
  },
  methods: {
    sorting (field) {
      this.sortField = field

      if (!this.sortDir || this.sortDir === 'desc') {
        this.sortDir = 'asc'
      } else {
        this.sortDir = 'desc'
      }

      this.$emit('sorted', { field, sortDir: this.sortDir })
    },
    daysOfWeek (days) {
      return daysOfWeek(days).map((day) => {
        return day.name
      }).join(', ')
    },
    formatDate (date) {
      return format(date, 'M/DD/YYYY')
    },
    formatDateRange (start, end) {
      return formatRange(start, end)
    },
    formatUpdatedAt (date) {
      if (Number.isNaN(Date.parse(date))) {
        return ''
      }

      const updatedDate = new Date(`${date}.400Z`)

      return `${format(updatedDate, 'M/DD/YYYY')} ${format(date, 'h:mm a')}`
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/base/_variables.scss';

.bad {
  color: $danger;
}
.black {
  color: #000;
}
.service {
  color: $gray
}
.service-red {
  color: #f34a61;
}

div.collection {
  overflow-x: scroll;
}

.centered {
  height: 100px;
  display: table-row;
  position: relative;

  span {
    position: absolute;
  }

  .stateful__spinner {
    bottom: 20px;
    left: 45%;
  }

  .stateful__msg {
    bottom: 0px;
    left: 43%;
  }
}

.hauler-link {
  color: #FA8D29;
  cursor: pointer;
}

.availability-row {

  .table-title {
    position: relative;
    color: $dark-gray;
    text-align: left;
    div {
       margin-left: 20px;
    }
  }

  .table-title:after {
    bottom: 10px;
    top: 20px;
    right: 24px;
    height: 60%;
  }

  .divider-header, .divider-td  {
    position: relative;
  }

  .table-title:after, .divider-header:before,
  .divider-header:after, .divider-td:after, .divider-td:before {
    content: "";
    position: absolute;
    width: 1px;
    background: $dark-gray;
  }

   .divider-header:after,  .divider-header:before {
     top: 3px;
     height: 120%
   }

  .divider-header:before {
    left: -20px;
  }

  .divider-header:after {
    right: 20px;
  }

  .divider-td:after, .divider-td:before {
    bottom: 12px;
    height: 120%;
  }

  .divider-td:after {
    right: 20px;
  }

  .divider-td:before {
    left: -20px;
  }

  .availability-details {
    thead {
      border-bottom: none;
      th {
        padding-bottom: 0px;
      }
    }
  }
}

tr.small-table-row {
  margin: 0;
  padding: 0;
  td {
    padding:0;
    margin:0;
  }
}

td.full {
  word-break: break-all;
}

th .material-icons {
  vertical-align: middle;
}

.material-icons.down {
  transform: rotate(180deg);
}

.material-icons {
  transition: transform 0.3s;
  font-size: 24px;
  margin: 0 2px;
}
.delivery-window {
    text-align: center;
}
.text-bold {
  font-weight: bold;
}
span.warning {
  color: red;
}
</style>
