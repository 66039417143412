<template>
  <section v-if='selectedRegionCurrentAvailability'>
    <form @submit.prevent='save' novalidate>
      <div class='title'>
        <h3>Current Availability</h3>
        <div>
          <button class='mako-btn save-btn' :disabled='disabled' type='submit'>Save Changes</button>
          <button @click.prevent='discard' :disabled='disabled' class='mako-btn mako-btn--danger-white-text' type='button'>Discard Changes</button>
        </div>
      </div>
      <current-availability-table
        :availabilities='currentAvailability.newDeliveries'
        :contractAvailability='selectedRegion.availability.newDeliveries'
        :currentRecord='selectedRegionCurrentAvailabilityTopLevel.newDeliveries'
        @change='(value) => updateStagedData("newDeliveries", value)'
        @windowChange='(value) => updateCurrentStagedData("newDeliveries", value)'
        :disabled='loading'
      />
      <current-availability-table
        :availabilities='currentAvailability.emptyReturn'
        :contractAvailability='selectedRegion.availability.emptyReturn'
        :currentRecord='selectedRegionCurrentAvailabilityTopLevel.emptyReturn'
        @change='(value) => updateStagedData("emptyReturn", value)'
        @windowChange='(value) => updateCurrentStagedData("emptyReturn", value)'
        :disabled='loading'
      />
      <current-availability-table
        :availabilities='currentAvailability.pickUp'
        :contractAvailability='selectedRegion.availability.pickUp'
        :currentRecord='selectedRegionCurrentAvailabilityTopLevel.pickUp'
        @change='(value) => updateStagedData("pickUp", value)'
        @windowChange='(value) => updateCurrentStagedData("pickUp", value)'
        :disabled='loading'
      />
      <div class="last-updated">
        Last Updated: {{formatUpdatedAt}} by
        <user-name :userId="selectedRegion.last_saved_by || ''" />
      </div>
      <div class='bottom-actions'>
        <button class='mako-btn save-btn' :disabled='disabled' type='submit'>Save Changes</button>
        <button @click.prevent='discard' :disabled='disabled' class='mako-btn mako-btn--danger-white-text' type='button'>Discard Changes</button>
      </div>
    </form>
  </section>
  <section class='not-found' v-else>
    <h4>No Current Availability Records</h4>
    <p>Contract availability and pricing must be set to display current availability.</p>
  </section>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import { path, omit } from 'kyanite'
import qs from 'qs'
import CurrentAvailabilityTable from './CurrentAvailabilityTable'
import UserName from '../components/UserName'
import { apiHttp } from '../utils/apiHttp'
import { format } from '../utils/time'

export default {
  name: 'CurrentAvailability',
  components: {
    CurrentAvailabilityTable,
    UserName
  },
  data () {
    return {
      stagedData: {
        newDeliveries: {},
        emptyReturn: {},
        pickUp: {}
      },
      stagedCurrentData: {
        newDeliveries: {},
        emptyReturn: {},
        pickUp: {}
      },
      deliveryTypesToURL: {
        newDeliveries: 'delivery',
        emptyReturn: 'empty-return',
        pickUp: 'pickup'
      },
      disabled: true
    }
  },
  computed: {
    ...mapState('regions', ['selectedRegion']),
    ...mapState('events', ['loading']),
    ...mapGetters('regions', ['selectedRegionCurrentAvailability', 'selectedRegionCurrentAvailabilityTopLevel']),
    currentAvailability () {
      return {
        id: this.selectedRegionCurrentAvailability.id,
        region_id: this.selectedRegionCurrentAvailability.region_id,
        delivery_type: this.selectedRegionCurrentAvailability.delivery_type,
        window_active: this.selectedRegionCurrentAvailability.window_active,
        window_days: this.selectedRegionCurrentAvailability.window_days,
        newDeliveries: this.selectedRegionCurrentAvailability.newDeliveries
          .map(availability => this.mapStagedData('newDeliveries', availability)),
        emptyReturn: this.selectedRegionCurrentAvailability.emptyReturn
          .map(availability => this.mapStagedData('emptyReturn', availability)),
        pickUp: this.selectedRegionCurrentAvailability.pickUp
          .map(availability => this.mapStagedData('pickUp', availability))
      }
    },
    formatUpdatedAt () {
      if (Number.isNaN(Date.parse(this.selectedRegion.last_saved_at))) {
        return ''
      }

      const updatedDate = new Date(`${this.selectedRegion.last_saved_at}.000Z`)

      return `${format(updatedDate, 'M/DD/YYYY')} at ${format(this.selectedRegion.last_saved_at, 'h:mm a')}`
    }
  },
  methods: {
    ...mapActions('regions', ['fetchRegions']),
    ...mapMutations('regions', ['selectRegion']),
    ...mapMutations('events', ['setLoading', 'runFlash']),
    updateCurrentStagedData (type, value) {
      this.toggleDisabled(false)
      const record = { ...value }
      this.stagedCurrentData[type] = { ...record }
    },
    updateStagedData (type, value) {
      this.setLoading(true)
      this.toggleDisabled(false)
      const stagedData = path([type, value.id], this.stagedData) || {}
      const newStagedData = { ...stagedData, ...value }
      this.$set(this.stagedData[type], value.id, newStagedData)

      apiHttp.get('current-availability/calculate', {
        params: {
          new: value,
          old: {
            ...this.selectedRegionCurrentAvailability[type].find(availability => availability.id === value.id),
            ...stagedData
          }
        },
        paramsSerializer: params => qs.stringify({
          new: this.prepareAvailabilityForAPICalculation(params.new),
          old: this.prepareAvailabilityForAPICalculation(params.old)
        })
      })
        .then(({ data: calculatedValue }) => {
          this.$set(this.stagedData[type], value.id, {
            ...newStagedData, ...calculatedValue
          })
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    mapStagedData (type, availability) {
      const stagedData = path([type, availability.id], this.stagedData) || {}
      return { ...availability, ...stagedData }
    },
    save () {
      this.setLoading(true)
      const requests = []
      const currentRequests = []
      Object.keys(this.stagedCurrentData).forEach(type => {
        const record = this.stagedCurrentData[type]
        if (record.id) {
          const baseURL = '/current-availability'
          const url = `${baseURL}/${record.id}`
          const request = apiHttp.put(
            url,
            JSON.stringify(record)
          )
          currentRequests.push(request)
        }
      })

      Object.keys(this.stagedData).forEach(type => {
        const baseURL = `/current-availability-${this.deliveryTypesToURL[type]}`
        Object.keys(this.stagedData[type]).forEach(id => {
          const url = `${baseURL}/${id}`
          const request = apiHttp.get(url)
            .then(({ data }) => apiHttp.put(
              url,
              this.prepareAvailabilityForAPIUpdate({ ...data, ...this.stagedData[type][id] })
            ))
          requests.push(request)
        })
      })

      return Promise.all(requests)
        .then(() => Promise.all(currentRequests))
        .then(() => this.runFlash({
          message: 'Current availability saved',
          severity: 'success',
          timeout: 1500
        }))
        .catch(() => this.runFlash({
          message: 'Current Availability may have been partially saved',
          severity: 'error',
          timeout: 1500
        }))
        // Fetching the region will update all current availability with the most up to date data.
        .then(() => this.fetchRegions(this.$route.params.id))
        .then(() => {
          // This may feel redundant but the selected region does not refresh when we fetch regions.
          // selectedRegion probably should have been a computed property based off an ID and current regions.
          this.selectRegion(this.selectedRegion.id)
          this.discard()
        })
        .finally(() => {
          this.setLoading(false)
          this.toggleDisabled(true)
        })
    },
    discard () {
      this.stagedData = {
        newDeliveries: {},
        emptyReturn: {},
        pickUp: {}
      }
      this.toggleDisabled(true)
    },
    toggleDisabled (val) {
      this.disabled = val
    },
    /**
     * Mostly a convenient way for us to do any data prep before we send back to the API.
     * An example being is when we retrieve availability from MAKO we are returned a "size" but
     * sending the "size" back to MAKO results in an error.
     *
     * @param data
     */
    prepareAvailabilityForAPIUpdate (data) {
      let newData = data
      newData = omit(
        ['id', 'size', 'created_at', 'updated_at', 'created_by', 'updated_by', 'delivery_date', 'title'], newData
      )

      if (newData.days_out < 2) {
        newData.days_out = null
      }

      return newData
    },
    prepareAvailabilityForAPICalculation (data) {
      let newData = data
      newData = omit(
        ['id', 'size', 'size_id', 'created_at', 'updated_at', 'created_by', 'updated_by', 'delivery_date'], newData
      )

      if (newData.days_out < 2) {
        newData.days_out = null
      }

      return newData
    }
  },
  mounted () {
    this.selectRegion(this.selectedRegion.id)
    this.stagedCurrentData = { ...this.selectedRegionCurrentAvailabilityTopLevel }
  }
}
</script>

<style lang='scss' scoped>
@import 'src/styles/base/_variables.scss';

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.save-btn {
  margin-right: 1rem;
}

.bottom-actions {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.not-found {
  margin-top: 3rem;
}

.last-updated {
  color: $gray;
  font-size: 14px;
}
</style>
