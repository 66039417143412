<template>
  <div class="full" @click="toggleMenu">
    <div class="dropdown">
      <div class="tags">
        <div class="tag" v-for="tag in tags" :key="tag.id">
          {{ tag.name }}
        </div>
      </div>
      <div class="icon down-arrow">
        <i class="fa fa-angle-down" ref="toggleIcon"></i>
      </div>
    </div>
    <div :class="cls">
      <ul class="multi-options">
        <li v-for="option in dataOptions" :key="option.id">
          <input type="checkbox" :name="option.name" :id="option.id" :value="option" @change="setTags(option, $event.target.checked)" :checked="selected.includes(option.id)"/>
          <label :for="option.id">{{option.name}}</label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelectDropDown',
  props: {
    dataOptions: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true,
      default: ''
    },
    selected: {
      type: Array,
      required: true
    }
  },
  methods: {
    toggleMenu (e) {
      const target = this.$refs.toggleIcon
      const clickTarget = e.target
      const className = clickTarget.className
      if (className.indexOf('options') > -1 || className === '') {
        return
      }

      if (this.cls === 'options hidden') {
        this.cls = 'options'
        target.className = 'fa fa-angle-up'
      } else {
        this.cls = 'options hidden'
        target.className = 'fa fa-angle-down'
      }
    },
    setTags (tag, checked) {
      if (checked && !this.tags.includes(tag)) {
        this.tags.push(tag)
      }

      if (!checked) {
        this.tags = this.tags.filter((t) => !(t.name === tag.name))
      }

      const payload = {
        name: this.name,
        data: [...this.tags],
        textField: this.tagFields[this.name]
      }
      this.$emit('change', payload)
    }
  },
  data () {
    return {
      tags: [],
      cls: 'options hidden',
      tagFields: {
        service_areas: 'service_areas_text',
        waste_types: 'waste_types_text',
        sizes: 'sizes_text'
      }
    }
  },
  watch: {
    selected () {
      const selections = [...this.selected]
      const options = this.dataOptions.filter((item) => {
        return selections.includes(item.id)
      })
      this.tags = options
    }
  }
}
</script>
<style lang="scss">
  div.dropdown {
    border: 1px solid #eee;
    min-height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    i {
      padding: 15px 2px 10px;
      z-index: 100;
      font-size: 13px;
    }
  }
  div.full {
    margin: 0;
    padding: 0;
    z-index: 0;
  }
  div.tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    div.tag {
      background-color: #007e80;
      border: 1px #fff solid;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      height: 27px;
      line-height: 16px;
      margin: 9px 5px;
      padding: 5px 5px;
      text-align: center;
      width: auto;
    }
  }
  div.options {
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    box-shadow: 1px 2px #ccc;
    max-width: 300px;
    padding: 4px 10px 4px;
    width: 100%;
    z-index: 1;
  }
  .hidden {
    display: none;
  }
  ul.multi-options {
    margin: 0;
  }
</style>
