<template>
<div class="pricing">
  <section :class="['grid', { right: formMode }]">
    <div class="sort" v-show="!formMode">
      <label>Sort By:</label>
      <select class="browser-default" v-model="sortMethod">
        <option value="def">Default</option>
        <option value="waste">Waste Type</option>
        <option value="size">Size</option>
      </select>
    </div>
    <div v-if="formMode" :class="['pricing-btn', 'full']">
      <button class="right btn" @click="triggerSave">Save</button>
    </div>
    <div v-if="formMode" class="pricing-btn full">
      <button class="right btn" @click="resetForm">Cancel</button>
    </div>
  </section>
  <transition
  class="row"
  enter-active-class="animated animated--fade-in-right"
  leave-active-class="animated animated--fade-out-right"
  mode="out-in">
    <transition-group name="flip-list" class="grid" tag="section" key="cards" v-if="!formMode">
      <price-card
      v-for="product of sortedRecs" :key="product.id"
      :item="product"
      @edit="setForm(product)" />
    </transition-group>
    <section class="row" v-else key="form">
      <dumpster-pricing-form class="col s12"
      ref="pricingForm"
      :service-area-id="selectedArea.id"
      :serviceAreaName="selectedArea.name"
      :pricing="record"
      @discard="resetForm"
      @saved="resetForm" />
    </section>
  </transition>
  <div class="filters" v-if="!formMode">
    <b>Filter By</b>
    <fieldset v-for="(type, k) of filterables" :key="k">
      <legend>{{ k | toNormalCase }}</legend>
      <div v-for="(val, i) in type" :key="i">
        <input
        v-model="checkedFilters"
        :id="`${val}-${i}`"
        type="checkbox"
        :value="val">
        <label :for="`${val}-${i}`">{{ val }}</label>
      </div>
    </fieldset>
  </div>
</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import {
  always,
  ascend,
  compose,
  concatMap,
  eq,
  filter,
  flip,
  groupBy,
  includes,
  partition,
  path,
  pipe,
  sort,
  sortBy,
  when
} from 'kyanite'
import DumpsterPricingForm from './DumpsterPricingForm'
import PriceCard from './PriceCard'
import toNormalCase from '../filters/toNormalCase'

export default {
  name: 'PricingBlock',

  props: {
    pricing: {
      type: Array,
      required: true
    }
  },

  components: {
    DumpsterPricingForm,
    PriceCard
  },

  filters: {
    toNormalCase
  },

  data () {
    return {
      formMode: false,
      sortMethod: 'def',
      record: {},
      checkedFilters: []
    }
  },

  computed: {
    ...mapState('regions', ['selectedArea']),
    ...mapState('pricing', ['pricingRecords']),
    ...mapState('session', ['user']),

    filteredList () {
      if (!this.checkedFilters.length) {
        return this.pricingRecords
      }

      const [waste, size] = partition(compose(Number.isNaN, flip(parseInt, 10)), this.checkedFilters)

      return pipe([
        when(always(waste.length), filter(compose(flip(includes, waste), path(['waste_type', 'name'])))),
        when(always(size.length), filter(compose(flip(includes, size), path(['size', 'name']))))
      ], this.pricingRecords)
    },

    filterables () {
      const pipeByType = k => pipe([
        groupBy(path([k, 'name'])),
        Object.keys,
        sort(ascend)
      ], this.pricingRecords)

      return {
        wasteType: pipeByType('waste_type'),
        sizes: pipeByType('size')
      }
    },

    sortedRecs () {
      const safeWaste = path(['waste_type', 'name'])
      const bySize = sortBy(compose(flip(parseInt, 10), path(['size', 'name'])))
      const byWaste = sortBy(safeWaste)
      const sortEq = eq(this.sortMethod)
      const byDef = pipe([
        byWaste,
        groupBy(safeWaste),
        Object.values,
        concatMap(bySize)
      ])

      return pipe([
        when(always(sortEq('def')), byDef),
        when(always(sortEq('size')), bySize),
        when(always(sortEq('waste')), byWaste)
      ], this.filteredList)
    }
  },

  methods: {
    ...mapMutations('wasteType/debris', ['resetDebris']),
    ...mapMutations('pricing', ['setPricingRecords', 'selectPricing', 'overwritePricing']),
    ...mapActions('size', ['fetchSizes']),
    setForm (prod) {
      this.formMode = !this.formMode

      this.selectPricing(prod.id)
      this.record = prod
    },
    resetForm () {
      if (!this.formMode) {
        this.formMode = true
      } else {
        this.resetDebris()
        this.overwritePricing({})
        this.record = {}
        this.formMode = false
      }
    },
    triggerSave () {
      this.$refs.pricingForm.saveRollOffPricing()
    }
  },
  mounted () {
    this.fetchSizes()
  }
}
</script>

<style lang="scss" scoped>
.pricing-btn {
  justify-self: right;
  align-self: center;
}

.grid {
  display: grid;
  grid-template-columns: 33.3333333% 33.3333333% 33.3333333%;

  .sort {
    margin-bottom: 1rem;
  }

  &.right {
    grid-template-columns: 50% 50%;
    justify-content: right;
    margin-top: 1rem;
    grid-column-gap: .25rem;
  }
}

.flip-list-move {
  transition: all 400ms ease-in-out 50ms
}

.filters {
  position: fixed;
  background-color:white;
  bottom: 0;
  left: 0;
  width:20%;
  padding:10px;
  z-index:999;
  box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
</style>
