<template>
  <div id="the-map-container">
      <div id="the-map-wrapper" :class="pipStateClass">
        <div v-if="pipActive" id="pip-controls" class="row no-margin-bottom">
          <div class="col s2">
            <a @click="togglePip">
              <i class="material-icons small">{{pipIcon}}</i>
            </a>
          </div>
          <div class="col s6">
            <small>{{formattedTime}} ({{timezoneOffset.timeZoneName}})</small>
          </div>
          <div class="col s4">
            <b class="right">Service Map</b>
          </div>
        </div>
        <div ref="searchMap" class="hero-map">
        </div>
        <slot name="controls"></slot>
      </div>
      <!-- default pip trigger -->
      <div id="map-bottom"></div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import * as Sentry from '@sentry/browser'
import { mapGetters, mapActions, mapState } from 'vuex'
import { colors } from '../config/colors'
import { geocode } from '../services/googleMapsQuery'

export default {
  name: 'TheMap',
  props: {
    /**
     * The geometry of the general service area
     * @type {Object}
     */
    localeGeometry: {
      required: false,
      type: Object,
      default () { return {} }
    },
    msaGeometry: {
      required: false,
      type: Object,
      default () { return {} }
    },
    /**
     * The centroid point of the area
     * @type {Object}
     */
    locationGeometry: {
      required: false,
      type: Object
    },
    /**
     * A haulers service area geometry
     * @type {Array}
     */
    haulerGeometry: {
      required: true,
      type: Array,
      default () { return [] }
    },
    /**
     * The ids of the service areas that need to be hidden
     * @type {Array}
     */
    hiddenAreas: {
      required: false,
      type: Array,
      default: () => []
    },
    zoom: {
      required: false,
      type: Number,
      default: 10
    },
    /**
     * Boolean for whether the drawing properties should be present
     */
    drawing: {
      required: false,
      type: Boolean,
      default: false
    },
    /**
     * Identifier for the drawing type (cut or merge) and how it should be
     * grouped
     */
    drawingType: {
      required: false,
      type: String
    },
    /**
     * The switch to turn on picture in picture
     *
     */
    pip: {
      required: false,
      type: Boolean,
      default: () => false
    },
    /**
     * The DOM element to trigger the picture in picture to show
     */
    pipTrigger: {
      required: false,
      type: String,
      default: () => '#map-bottom',
      validate (val) {
        // the value needs to be a valid DOM element
        return !!(document.getElementById(val))
      }
    }
  },
  data () {
    return {
      map: {},
      marker: null,
      geojson: {},
      features: {},
      place_id: '',
      custom_merge: [],
      custom_cut: [],
      drawingTool: new google.maps.drawing.DrawingManager(),
      defaultStyle: () => ({
        fillColor: '#000000',
        fillOpacity: 0.50,
        strokeWeight: 1,
        visible: true,
        strokeOpacity: 1,
        zIndex: '-1'
      }),
      pipActive: false
    }
  },
  methods: {
    ...mapActions('session', ['togglePip']),
    getPlaceIdFromLatLng (prop, latLng) {
      const dragFilter = el => el.types.includes('locality') || el.types.includes('postal_code')
      const placeId = geocode('location', latLng)(dragFilter)
      return placeId
    },
    handlePlaceInfo (data) {
      this.resetMap()
      const payload = { place_id: data[0].place_id }
      this.$emit('locationChanged', payload)
      this.place_id = data[0].place_id
    },
    setPin () {
      if (this.marker !== null) {
        this.marker.setMap(null)
      }
      if (this.locationGeometry !== null) {
        this.marker = new google.maps.Marker({
          map: this.map,
          title: 'Budget Dumpster Maps',
          position: this.locationGeometry,
          animation: google.maps.Animation.DROP,
          draggable: true
        })

        this.marker.addListener('click', this.enableStreetView)
        this.marker.addListener('dragend', this.markerDragListener)
        this.setZoomLevel(this.zoom)
      }
      return true
    },
    enableStreetView () {
      const streetView = this.map.getStreetView()

      streetView.setPosition(this.marker.getPosition())

      streetView.setVisible(true)

      streetView.setPov({
        heading: 265,
        pitch: 0,
        zoom: 8
      })
    },
    /**
     * Set up the inital map on the DOM element #search-map
     * @public
     * @returns {undefined} Operates on the reactive data property __map__
     */
    setMap () {
      this.map = new google.maps.Map(this.$refs.searchMap, {
        center: new google.maps.LatLng(37.0902, -95.7129),
        zoom: 8,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        panControl: true,
        streetViewControl: true,
        zoomControl: true
      })

      this.map.data.setStyle({
        color: '#ffffff',
        fillOpacity: 0.6,
        strokeOpacity: 1.0,
        strokeWeight: 1
      })
    },
    /**
     * Clear the map and revert all styles
     * @public
     * @returns {undefined} Operates on the reactive data property __map__
     */
    clearMap () {
      this.map.data.forEach(e => {
        this.map.data.remove(e)
      })
      if (this.marker !== null) {
        this.marker.setMap(null)
      }
      this.map.data.revertStyle()
    },
    /**
     * Add the locale geometry to the map instance
     * @public
     * @returns {undefined} Operates on the reactive data property __map__
     */
    setLocaleGeometry () {
      if (Object.prototype.hasOwnProperty.call(this.localeGeometry, 'features') &&
            this.localeGeometry !== null) {
        this.localeGeometry.features[0].properties = { color: '#ffffff', className: 'locale-geometry' }
        this.map.data.addGeoJson(this.localeGeometry)
        let localeFeature
        this.map.data.forEach(e => {
          if (e.getProperty('className') === 'locale-geometry') {
            localeFeature = e
          }
        })

        this.map.data.overrideStyle(localeFeature, {
          fillOpacity: 0,
          strokeWeight: 3,
          strokeColor: '#ffffff',
          strokeOpacity: 1,
          visibile: true
        })
      }
    },
    setMsaGeometry () {
      if (this.msaGeometry !== null && Object.prototype.hasOwnProperty.call(this.msaGeometry, 'features')) {
        this.map.data.addGeoJson(this.msaGeometry)

        let msaFeature

        this.map.data.forEach(e => {
          if (e.getProperty('className') === 'msa-geometry') {
            msaFeature = e
          }
        })

        this.map.data.overrideStyle(msaFeature, {
          fillOpacity: 0,
          strokeWeight: 3,
          strokeColor: '#8d8d8d',
          strokeOpacity: 1,
          visibile: true
        })
      }
    },
    /**
     * Method to set a feature for a specific hauler
     * @param {Object} feature
     */
    setHaulerStyle (feature, color) {
      this.map.data.overrideStyle(feature, {
        fillColor: color,
        fillOpacity: 0.3,
        strokeWeight: 1,
        visible: true,
        strokeOpacity: 1,
        zIndex: '-1'
      })
    },
    /**
     * Add the hauler geometry to the map instance as different colored layers
     * @public
     * @returns {undefined} Operates on the reactive data property __map__
     */
    setHaulerGeometry () {
      const features = []

      this.haulerGeometry.forEach((area, key) => {
        const color = area.features[0].properties.color || colors[key]
        area.features[0].properties = {
          color,
          className: 'service-area-overlay',
          id: key
        }
        this.map.data.addGeoJson(area)
      })

      this.map.data.forEach(feature => {
        if (feature.getProperty('className') === 'service-area-overlay') {
          features.push(feature)
          this.setHaulerStyle(feature, feature.getProperty('color'))
        }
      })

      this.features = features
      this.hideAreas(this.hiddenAreas)
    },
    /**
     * Full reset of the map instance
     * @public
     * @returns {undefined} Operates on the reactive data property __map__
     */
    resetMap () {
      this.clearMap()
      this.marker.setMap(null)
      this.setPin()
      this.setHaulerGeometry()
      this.setLocaleGeometry()
      this.setMsaGeometry()
    },
    /**
     * Event handler for a pin reposition on the map instance
     * @public
     * @param  {object}
     * @return {undefined} Queries gmaps places api and calls __handlePlaceInfo__
     */
    markerDragListener (ev) {
      this.clearMap()
      this.getPlaceIdFromLatLng('location', ev.latLng)
        .then(this.handlePlaceInfo)
        .catch(e => Sentry.captureException(e))
    },
    /**
     * Sets the zoom level for the map
     * @param {Integer} zoom a number from 1 to 10 to set the zoom level
     * @returns null
     */
    setZoomLevel (zoom) {
      if (this.marker.position !== null) {
        this.map.setCenter(this.marker.position)
      }
      this.map.setZoom(zoom)
    },
    /**
     * This will emit a custom area merge emission for the hauler
     * page to pickup and handle
     * @returns null
     */
    emitCustomAreaMerge (geojson) {
      const payload = {
        label: 'Custom Merge Drawing',
        category: 'Custom Drawing',
        place_id: null,
        search_term: 'Custom Merge',
        the_geom: geojson
      }
      this.$emit('custom_area_merged', payload)
    },
    /**
     * This will emit a custom area cut emission for the hauler
     * page to pickup and handle
     * @returns null
     */
    emitCustomAreaCut (geojson) {
      const payload = {
        label: 'Custom Cut Drawing',
        category: 'Custom Drawing',
        place_id: null,
        search_term: 'Custom Cut',
        the_geom: geojson
      }
      this.$emit('custom_area_cut', payload)
    },
    /**
     * Builds the GeoJson from a polygon path
     * @returns object
     */
    buildGeoJsonFromPath (pathArray) {
      const coords = []
      const geojson = {
        type: 'Polygon',
        coordinates: []
      }

      // for GeoJson the first and last coordinate
      // must be the same
      pathArray.push(pathArray[0])
      pathArray.forEach(element => {
        coords.push([element.lng(), element.lat()])
      })
      geojson.coordinates.push(coords)
      return geojson
    },
    /**
     * Handler for the goole.maps.event "polygoncompleted"
     * this handles converting a polygon path to geojson
     * so it can be merged/cut with the service area
     * @returns null
     */
    polygonCompleteHandler (polygon) {
      const pathArray = polygon.getPath().getArray()
      const geojson = this.buildGeoJsonFromPath(pathArray)
      if (this.drawingType === 'merge') {
        this.emitCustomAreaMerge(geojson)
      }

      if (this.drawingType === 'cut') {
        this.emitCustomAreaCut(geojson)
      }

      polygon.setMap(null)
    },
    hideAreas (val) {
      this.map.data.forEach(e => {
        if (val.includes(e.getId())) {
          this.map.data.overrideStyle(e, {
            visible: false,
            strokeOpacity: 0,
            fillOpacity: 0
          })
        }

        if (e.getProperty('className') !== 'msa-geometry' &&
        e.getProperty('className') !== 'locale-geometry' &&
        !val.includes(e.getId())) {
          this.setHaulerStyle(e, e.getProperty('color'))
        }
      })
    },
    setupPictureInPicture () {
      const trigger = $(`${this.pipTrigger}`).offset().top
      $(window).scroll(() => {
        if ($(window).scrollTop() > trigger && !this.pipActive) {
          // $('#the-map-wrapper').addClass('pip');
          this.pipActive = true
        } else if ($(window).scrollTop() < trigger && this.pipActive) {
          // $('#the-map-wrapper').removeClass('pip');
          this.pipActive = false
        }
      })
    },
    disablePictureInPicture () {
      $(window).off()
      this.pipActive = false
    }
  },
  computed: {
    ...mapState('session', ['preferences']),
    ...mapGetters(['hoverState', 'getHoverId', 'getPrevId']),
    ...mapGetters('time', ['formattedTime']),
    ...mapState('time', ['timezoneOffset']),
    pipStateClass () {
      return {
        pip: this.pipActive,
        minimize: this.preferences.showPip === true,
        maximize: this.preferences.showPip === false
      }
    },
    pipIcon () {
      return this.preferences.showPip ? 'add' : 'minimize'
    },
    readyToRender () {
      return !!(this.localeGeometry) &&
      !!(this.msaGeometry) &&
      !!(this.haulerGeometry) &&
      !!(this.localeLongitude) &&
      !!(this.localeLatitude)
    }
  },
  watch: {
    drawing (val) {
      if (val === true) {
        this.drawingTool.setDrawingMode('polygon')
        this.drawingTool.setMap(this.map)
        this.drawingTool.setOptions({
          drawingControl: true,
          drawingControlOptions: {
            drawingModes: [
              'polygon'
            ],
            position: google.maps.ControlPosition.TOP_CENTER
          }
        })
      } else {
        this.drawingTool.setMap(null)
      }
    },
    getHoverId (val) {
      const feature = this.features
        .filter(f => f.getId() === val)
        .pop()
      if (!this.hiddenAreas.includes(val)) {
        this.map.data.overrideStyle(feature, {
          strokeWeight: 3
        })
      }
    },
    hoverState (val) {
      const feature = this.features
        .find(f => f.getId() === this.getPrevId)

      if (val === 'off') {
        this.setHaulerStyle(feature, feature.getProperty('color'))
      } else {
        this.map.data.overrideStyle(feature, {
          fillColor: feature.getProperty('color'),
          fillOpacity: 0.3,
          strokeWeight: 3,
          visible: true,
          strokeOpacity: 1,
          zIndex: '-1'
        })
      }
    },
    locationGeometry () {
      this.setPin()
    },
    localeGeometry () {
      this.setLocaleGeometry()
    },
    msaGeometry () {
      this.setMsaGeometry()
    },
    haulerGeometry () {
      this.clearMap()
      this.setHaulerGeometry()
      this.setPin()
    },
    hiddenAreas (val) {
      this.hideAreas(val)
    },
    pip (applied) {
      if (applied) {
        return this.setupPictureInPicture()
      }

      return this.disablePictureInPicture()
    }
  },
  mounted () {
    this.setMap()
    this.setHaulerGeometry()
    this.map.data.addListener('mouseover', e => {
      if (e.feature.getProperty('className') !== 'locale-geometry' &&
      e.feature.getProperty('className') !== 'msa-geometry') {
        this.map.data.overrideStyle(e.feature, { strokeWeight: 3 })
      }
    })

    this.map.data.addListener('mouseout', e => {
      if (e.feature.getProperty('className') !== 'locale-geometry' &&
      e.feature.getProperty('className') !== 'msa-geometry') {
        this.map.data.overrideStyle(e.feature, { strokeWeight: 1 })
      }
    })

    google.maps.event.addListener(this.drawingTool, 'polygoncomplete', this.polygonCompleteHandler)
    if (this.pip) this.setupPictureInPicture()
  }
}
</script>

<style lang="scss">
@import 'src/styles/base/_variables.scss';
$map-height: 500px;
$controls-height: 35px;
#the-map-container {
  height: $map-height;
}
    .hero-map {
        width: 100%;
        height: $map-height;
        margin-bottom: 10px;
    }
    #the-map-wrapper.pip{
      position: fixed;
      bottom: 0;
      right: 0;
      width:30%;
      z-index:999;
      box-shadow:5px 5px 8px grey;

      &.minimize{
        height:$controls-height;
      }

      #pip-controls{
        width:100%;
        color: white;
        background-color: $secondary;
        padding: 0 15px;
        height:$controls-height;
      }
    }

</style>
