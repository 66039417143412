<template>
    <div v-show="visible" :class="typeOfModal">
        <div class="closeModal" @click="close"></div>
        <div class="modal-content">
            <i @click="close" class="material-icons right close-icon">close</i>
            <h5 v-if="content.header !== null">{{content.header}}</h5>
            <p v-if="content.body !== null" v-html="content.body"></p>
            <slot name="modalContent"></slot>
            <div v-if="content.footer || content.confirm" class="modal-footer">
                <div v-if="content.confirm">
                    <button class="mako-btn mako-btn--smol" type="button" @click="handleConfirm">{{content.confirmButtonYes}}</button>
                    <button class="mako-btn mako-btn--smol" type="button" @click="close">{{content.confirmButtonNo}}</button>
                </div>
                {{content.footer}}
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import store from '../state/modules/events'

/* eslint-env jquery */
const acceptedTypes = ['normal', 'bottom-sheet']

/**
 * The re-useable modal component for the application.
 * Comes in two flavors _normal_ and _bottom sheet_
 */
export default {
  name: 'BaseModal',
  props: {
    body: {
      type: String,
      required: false
    },
    header: {
      type: String,
      required: false
    },
    footer: {
      type: String,
      required: false
    },
    confirm: {
      type: String,
      required: false
    },
    confirmButtons: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    show: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    ...mapMutations('events', ['closeModal']),
    handleConfirm () {
      if (this.content.confirm instanceof Function) {
        this.content.confirm.call()
      }

      this.close()
    },
    close () {
      this.$emit('close')
      this.closeModal()
    }
  },
  computed: {
    content () {
      const type = this.type || store.state.modal.type

      return {
        body: this.body || store.state.modal.body || null,
        header: this.header || store.state.modal.header || null,
        footer: this.footer || store.state.modal.footer || null,
        confirm: this.confirm || store.state.modal.confirm || false,
        confirmButtonYes: (this.confirmButtonYes || store.state.modal.confirmButtons || {}).yes || 'OK',
        confirmButtonNo: (this.confirmButtonNo || store.state.modal.confirmButtons || {}).no || 'Cancel',
        type: acceptedTypes.includes(type) ? type : 'normal'
      }
    },

    visible () {
      return this.show || (store.state.modal !== false)
    },

    typeOfModal () {
      return { modal: true, 'bottom-sheet': this.content.type === 'bottom-sheet' }
    }
  }
}
</script>

<style lang="scss">
  div.closeModal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
  div.modal {
    z-index: 9999;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    align-items: center;
  }
  div.modal {
    background: rgba(0, 0, 0, 0.5);
  }
  div.modal-content {
    z-index: 1;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;

    i:first-child {
      cursor: pointer;
      font-size: smaller;
    }

    h5, p {
      text-shadow: none;
    }
  }
</style>
