/* eslint import/prefer-default-export: 0 */
import { capitalize } from 'kyanite'

function convertToLowerCamelCase (string) {
  return string.split('-').map((str, key) => ((key > 0) ? str.charAt(0).toUpperCase() + str.slice(1) : str)).join('')
}

function normalize (val) {
  const breakdown = val.split(/[-_]/g)

  return breakdown.reduce((acc, w) => {
    if (!w) {
      return capitalize(w)
    }

    return `${acc} ${capitalize(w)}`
  }, '')
}

export { convertToLowerCamelCase, normalize }
